import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'

const BancoEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctBanco, setctBanco] = useState({
		intIdBanco: props.deptoId,
		txtBanco: '',
		intIdPais: '',
		bolActivo: ''
	})
	const LimpiarValores = () => {
		setctBanco({ ...ctBanco, intIdBanco: '', intIdPais: '', txtBanco: '',bolActivo: '' })
	}

	const handleCtBanco = (event) => {
		setctBanco({ ...ctBanco, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getDepto()
		getListaPais()
	}, [props.deptoId])
	const getDepto = () => {
		if (props.deptoId != null) {
			requests
				.get('CtBanco/ObtenerPorId/' + props.deptoId)
				.then((response) => {
					setctBanco(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}
	const [_ListaPais, setListaPais] = useState([])
	const getListaPais = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPai/ObtenerLista')
			.then((response) => {
				setListaPais(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtBanco/Editar/'
		requests
			.put(url, ctBanco)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Actualizar Banco
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px'}}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								required
								id='txtBanco'
								name='txtBanco'
								label='Banco:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={ctBanco.txtBanco}
								onChange={handleCtBanco}
							/>
							<FormControl required variant='standard' size='small' fullWidth>
									<InputLabel id='intIdPais'>País</InputLabel>
									<Select
										required
										value={ctBanco.intIdPais}
										onChange={handleCtBanco}
										label='intIdPais'
										inputProps={{
											name: 'intIdPais',
											id: 'intIdPais',
										}}
										size='small'
									>
										<MenuItem aria-label='None' value='' />
										{_ListaPais.map((object, index) => (
											<MenuItem value={object.lngIdPais}>
												{object.txtPais}
											</MenuItem>
										))}
									</Select>
								</FormControl>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default BancoEdit
