/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import Menus from '../Layout/Menu'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import logo from '../../Img/systrackheads.png'
import Theme from '../Styles/Theme'
import SettingsIcon from '@mui/icons-material/Settings'
import Chip from '@mui/material/Chip'

const drawerWidth = 300

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))
const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		// position: 'relative',
		position: 'static',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(7),
			},
		}),
	},
}))
const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#008CFF',
		},
	},
})
const HeaderLayout = () => {
	
	let navigate = useNavigate()
	const [open, setOpen] = React.useState(false)
	const [auth, setAuth] = React.useState(true)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const opens = Boolean(anchorEl)

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const toggleDrawer = () => {
		setOpen(!open)
	}

	const logOut = () => {
		sessionStorage.removeItem('TokenSYS')
		sessionStorage.removeItem('TokenSYSUser')
		navigate('/')
		window.location.reload()
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const getUser = () => {
		return JSON.parse(sessionStorage.getItem('TokenSYSUser'))
	}

	const InfoData = getUser()
	return (
		<ThemeProvider theme={Theme}>
			<Box sx={{ display: 'flex' }}>
				<AppBar open={open}>
					<Toolbar
						sx={{
							pr: '30px', // keep right padding when drawer closed
						}}
					>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='open drawer'
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon sx={{color:'#fff'}}/>
						</IconButton>
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
						>
							<img src={logo} width='55px' height="55px" style={{ margin: 5 }} /> Systrack Brasil
						</Grid>

						{InfoData === null || InfoData === '' || InfoData === undefined ? null :
							<Typography gutterBottom variant='caption' display='block' sx={{color: '#fff', width: '350px'}}>
								<strong>Bienvenido (a) </strong>
								{InfoData.nombre + '	 ' + InfoData.apellido}
								<Grid>
									<Chip icon={<SettingsIcon />} color='primary'  />	
									{InfoData.departamentos}
								</Grid>
							</Typography>
						}
						
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<Tooltip title='Cuenta'>
								<IconButton
									ria-label='account of current user'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									>
									{InfoData === null ? (null) :
									<Avatar
										onClick={handleMenu}
										sx={{
											width: 32,
											height: 32,
											background: '#fff',
											color: '#073763',
										}}
									>
											(<>{InfoData.nombre
												? InfoData.nombre.substring(0, 1).toUpperCase() +
												InfoData.apellido.substring(0, 1).toUpperCase()
												: null}
											</>)
									</Avatar>
									}
								</IconButton>
							</Tooltip>
						</Box>
						{auth && (
							<div>
								<Menu
									id='menu-appbar'
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={opens}
									onClose={handleClose}
								>
									{/* <MenuItem>Perfil</MenuItem> */}
									<MenuItem onClick={logOut}>
										<Logout />
										Fechar Sessão
									</MenuItem>
								</Menu>
							</div>
						)}
					</Toolbar>
				</AppBar>
				<Drawer variant='permanent' open={open}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							px: [1],
						}}
					>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					{InfoData !== null ? (
						<>
							<List>
								<Menus data={InfoData}/>	
							</List>
						</>
					):(null)}
					
				</Drawer>
			</Box>
		</ThemeProvider>
	)
}
export default HeaderLayout
