import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { format, compareAsc } from 'date-fns'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";
import NumberFormat from 'react-number-format'
import Chip from '@mui/material/Chip'
import MoneyIcon from '@mui/icons-material/Money';
import "../Components/Styles/Styles.css";

import MUIDataTable from "../Util/MUIDataTable";

import SaldosAdd from '../Components/Saldos/SaldosAdd'
import SaldosEdit from '../Components/Saldos/SaldosEdit';
import SaldosDelete from '../Components/Saldos/SaldosDelete'
import CreditosAdd from '../Components/Creditos/CreditosAdd'
import CreditosEdit from '../Components/Creditos/CreditosEdit'
import CreditosDelete from '../Components/Creditos/CreditosDelete'

import requests from "../Components/AxiosCalls/AxiosCall";
import Theme from "../Components/Styles/Theme";

const CreditosView = (props) => {
    const [registros, setRegistros] = useState([]);
    const [saldoId, setSaldoId] = useState();

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => {
        setOpen2(!open2);
    };

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => {
        setOpen3(!open3);
    };

    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => {
        setOpen4(!open4);
    };

    const getRegistros = () => {
        props.setOpenLoadingScreen();
        requests
            .get("TbCredito/ObtenerListaVM")
            .then((response) => {
                setRegistros(response);
                props.setCloseLoadingScreen();
                setSaldoId(null)
            })
            .catch((error) => {
                console.log("Error: " + error);
                props.setCloseLoadingScreen();
            });
    };

    useEffect(() => {
        getRegistros();
    }, []);

    const columns = [
        {
            name: "lngIdCredito",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
            },
        },
        {
            name: "txtRazonSocial",
            label: "Proveedor",
            options: {
                columnOrder: true,
                filter: false,
            },
        },
        {
            name: "fecRegistro",
            label: "Fecha registro",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[3] !== null) {
                        return format(new Date(tableMeta.rowData[3]), 'dd/MM/yyyy')
                    }
                },
            },
        },
        {
            name: "dbValor",
            label: "Crédito ",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <NumberFormat value={tableMeta.rowData[4]} displayType={'text'} fixedDecimalScale={true}
                            decimalScale={2} thousandSeparator={true} prefix={'$'} />
                    );
                },
            },
        },
        {
            name: "dblValorSaldo",
            label: "Saldo ",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <NumberFormat value={tableMeta.rowData[5]} displayType={'text'} fixedDecimalScale={true}
                            decimalScale={2} thousandSeparator={true} prefix={'$'} />
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title='Editar crédito' arrow aria-label='add'>
                            <Button
                                className='font-btn-editar'
                                onClick={function () {
                                    setSaldoId(tableMeta.rowData[0])
                                    handleOpen2()
                                }}
                            >
                                <EditIcon sx={{ mr: 1 }} />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        },
        // {
        //     name: "",
        //     label: "",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             return (
        //                 <Tooltip title='Nuevo crédito' arrow aria-label='add'>
        //                     <Button
        //                         className='chip2'
        //                         onClick={function () {
        //                             setSaldoId(tableMeta.rowData[0])
        //                             handleOpen4()
        //                         }}
        //                     >
        //                         <AddIcon /> Crédito
        //                     </Button>
        //                 </Tooltip>
        //             );
        //         },
        //     },
        // },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title='Eliminar crédito' arrow aria-label='add'>
                            <Button
                                className='font-btn-eliminar'
                                onClick={function () {
                                    setSaldoId(tableMeta.rowData[0])
                                    handleOpen3()
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        },
    ];

    return (
        <ThemeProvider theme={Theme}>
            <Grid xs={12} sm={8} md={10} lg={12}>
                <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                    <Paper elevation={0} className="title-views">
                        <Grid
                            Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={12}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Chip icon={<MoneyIcon />} label='Créditos' color='primary' className='back-transparent font-letter font-barter font-20' />


                            {/* <Button
                                className='btn-Header'
                                startIcon={<AddIcon />}
                                onClick={function () {
                                    handleOpen();
                                }}
                            >
                                Nuevo
                            </Button> */}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12} alignItems="center" elevation={12}>
                    <MUIDataTable
                        title={"Lista de créditos"}
                        data={registros}
                        columns={columns}
                    />
                </Grid>
            </Grid>
            <CreditosEdit
                saldoId={saldoId}
                open={open2}
                onClose={handleOpen2}
                getRegistros={getRegistros}
                setOpenLoadingScreen={props.setOpenLoadingScreen}
                setCloseLoadingScreen={props.setCloseLoadingScreen}
                setOpenSnackBar={props.setOpenSnackBar}
                setMessageSnackBar={props.setMessageSnackBar}
                setTypeSnackBar={props.setTypeSnackBar}
            />
            <CreditosDelete
                saldoId={saldoId}
                open={open3}
                onClose={handleOpen3}
                getRegistros={getRegistros}
                setOpenLoadingScreen={props.setOpenLoadingScreen}
                setCloseLoadingScreen={props.setCloseLoadingScreen}
                setOpenSnackBar={props.setOpenSnackBar}
                setMessageSnackBar={props.setMessageSnackBar}
                setTypeSnackBar={props.setTypeSnackBar}
            />
            <CreditosAdd
                saldoId={saldoId}
                open={open4}
                onClose={handleOpen4}
                getRegistros={getRegistros}
                setOpenLoadingScreen={props.setOpenLoadingScreen}
                setCloseLoadingScreen={props.setCloseLoadingScreen}
                setOpenSnackBar={props.setOpenSnackBar}
                setMessageSnackBar={props.setMessageSnackBar}
                setTypeSnackBar={props.setTypeSnackBar}
            />
        </ThemeProvider>
    );
};
export default CreditosView;
;
