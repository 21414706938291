import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import "../../Components/Styles/Styles.css";
import Chip from '@mui/material/Chip'
import FactCheckIcon from '@mui/icons-material/FactCheck'

import MUIDataTable from "../../Util/MUIDataTable";

import requests from "../../Components/AxiosCalls/AxiosCall";
import Theme from "../../Components/Styles/Theme";
import TipoServicioAdd from "./../../Components/Catalogos/TipoDeServicio/TipoServicioAdd";
import TipoServicioEdit from "./../../Components/Catalogos/TipoDeServicio/TipoServicioEdit";
import TipoServicioDelete from "./../../Components/Catalogos/TipoDeServicio/TipoServicioDelete";

const TipoDeServicio = (props) => {
  const [registros, setRegistros] = useState([]);
  const [Id, setId] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(!open2);
  };

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => {
    setOpen3(!open3);
  };

  const getRegistros = () => {
    props.setOpenLoadingScreen();
    requests
      .get("CtTipoServicio/ObtenerLista")
      .then((response) => {
        setRegistros(response);
        props.setCloseLoadingScreen();
        setId(null)
      })
      .catch((error) => {
        console.log("Error: " + error);
        props.setCloseLoadingScreen();
      });
  };

  useEffect(() => {
    getRegistros();
  }, []);

  const columns = [
    {
      name: "intIdTipoServicio",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "id",
      label: "Numero",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
      },
    },
    {
      name: "txtTipoServicio",
      label: "Tipo de servicio",
      options: {
        columnOrder: true,
        filter: false,
      },
    },
    {
      name: "txtNomCorto",
      label: "Nombre Corto",
      options: {
        columnOrder: true,
        filter: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar servicio' arrow aria-label='add'>
              <Button
                className='font-btn-editar'
                onClick={function () {
                  setId(tableMeta.rowData[0])
                  handleOpen2()
                }}
              >
                <EditIcon sx={{ mr: 1 }} />
              </Button>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Eliminar servicio' arrow aria-label='add'>
              <Button
                className='font-btn-eliminar'
                onClick={function () {
                  setId(tableMeta.rowData[0])
                  handleOpen3()
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <Grid xs={12} sm={8} md={10} lg={12}>
        <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
          <Paper elevation={0} className="title-views">
            <Grid
              Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Chip icon={<FactCheckIcon />} label='Tipos de servicio' color='primary' className='back-transparent font-letter font-barter font-20' />


              <Button
                className='btn-Header'
                startIcon={<AddIcon />}
                onClick={function () {
                  handleOpen();
                }}
                size='small'
              >
                Nuevo
              </Button>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12} alignItems="center" elevation={12}>
          <MUIDataTable
            title={"Lista de tipos de servicio"}
            data={registros}
            columns={columns}
          />
        </Grid>
      </Grid>
      <TipoServicioAdd
        open={open}
        onClose={handleOpen}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
      <TipoServicioEdit
        tipoId={Id}
        open={open2}
        onClose={handleOpen2}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
      <TipoServicioDelete
        tipoId={Id}
        open={open3}
        onClose={handleOpen3}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
    </ThemeProvider>
  );
};
export default TipoDeServicio;
