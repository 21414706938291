import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import AsyncSelect from 'react-select/async'
import Chip from '@mui/material/Chip'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import {CLIENTES_LIST} from '../../Constants/apiConstnats'
import requests from '../AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import '../Styles/Styles.css'
import { useLocation, useParams } from 'react-router-dom';
import { Checkbox, Typography } from '@mui/material'

const ProductosAdd = (props) => {

	const {id} = useParams();

    const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()

	const [_Check, setCheck] = useState({
		//poner todas fak
	})

	const [_Producto, setProducto] = useState([])

	const [_ListaSucursal, setListaSucursal] = useState([])
	const getListSucursal = () => {
		props.setOpenLoadingScreen()
		requests
			.get("TbSucursal/ObtenerLista")
			.then((response) => {
				setListaSucursal(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaEvento, setListaEvento] = useState([])
    const getListEvento = () => {
		props.setOpenLoadingScreen()
		requests
			.get("TbEvento/ObtenerLista")
			.then((response) => {
				setListaEvento(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

    const handleProducto = (event) => {
		setCheck({ ..._Check, [event.target.name]: event.target.value })
	}

	const [_ListaPlaza, setListaPlaza] = useState([])
	const getListaPlaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListaPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	
	const [_ListaGiro, setListaGiro] = useState([])
	const getListaGiro = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtGiro/ObtenerLista')
			.then((response) => {
				setListaGiro(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaEmpresaPg, setListaEmpresaPg] = useState([])
	const getListaEmpresaPg = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbEmpresasPg/ObtenerLista')
			.then((response) => {
				setListaEmpresaPg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaPeriodicidad, setListaPeriodicidad] = useState([])
	const getListaPeriodicidad = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPeriodicidad/ObtenerLista')
			.then((response) => {
				setListaPeriodicidad(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaStatusJuri, setListaStatusJuri] = useState([])
	const getListaStatusJuri = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtStatusJuri/ObtenerLista')
			.then((response) => {
				setListaStatusJuri(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

    const [_ListaUniNegocio, setListaUniNegocio] = useState([])
	const getListaUniNegocio = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtUnidadNegocio/ObtenerLista')
			.then((response) => {
				setListaUniNegocio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	
    const [_ListaEjecutivoOp, setListaEjecutivoOp] = useState([])
	const getListaEjecutivoOp = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtEjecutivoOp/ObtenerLista')
			.then((response) => {
				setListaEjecutivoOp(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

    const [_ListaProducto, setListaProducto] = useState([])
	const getListaProducto = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtProducto/ObtenerLista')
			.then((response) => {
				setListaProducto(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getListaStatusJuri()
		getListaPeriodicidad()
		getListaEmpresaPg()
		getListaPlaza()
		getListaGiro()
        getListaUniNegocio()
        getListEvento()
        getListSucursal()
        getListaEjecutivoOp()
        getListaProducto()
	}, [])

	const guardar = () => {
		
		props.setOpenLoadingScreen()
		let url = 'TbProductoGral/Crear'
		requests
			.post(url, _Producto)
			.then((response) => { 
				let urls = 'TbProductoStr/Crear'
                requests
                    .post(urls, _Check)
                    .then((response) => { 
                        props.setTypeSnackBar('success')
                        props.setMessageSnackBar(response.message)
                        props.setOpenSnackBar(true)
                        props.setCloseLoadingScreen()
                        navigate('/productos')
                    })
                    .catch((error) => {
                        props.setTypeSnackBar('warning')
                        props.setMessageSnackBar(error.data.message)
                        props.setOpenSnackBar(true)
                        props.setCloseLoadingScreen()
                    })
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
            
	}

	///Estados Radio Buttom
    const handleBox = (e) =>{
        
        setProducto({..._Check, [e.target.name]: e.target.checked})
    }
	//Fin Estados Radio Buttom

	const [_FecFInicioOp, setFecFInicioOp] = React.useState(null)
    const handleFecFInicioOp = (date) => {
        setFecFInicioOp(date)
        setCheck({ ..._Check, FecFInicioOp: date })
    }

    const [_FecMInicioOp, setFecMInicioOp] = React.useState(null)
    const handleFecMInicioOp = (date) => {
        setFecMInicioOp(date)
        setCheck({ ..._Check, FecMInicioOp: date })
    }

    const [_FecBInicioOp, setFecBInicioOp] = React.useState(null)
    const handleFecBInicioOp = (date) => {
        setFecBInicioOp(date)
        setCheck({ ..._Check, FecBInicioOp: date })
    }

    const [_FecCInicioOp, setFecCInicioOp] = React.useState(null)
    const handleFecCInicioOp = (date) => {
        setFecCInicioOp(date)
        setCheck({ ..._Check, FecCInicioOp: date })
    }

    const [_FecNInicioOp, setFecNInicioOp] = React.useState(null)
    const handleFecNInicioOp = (date) => {
        setFecNInicioOp(date)
        setCheck({ ..._Check, FecNInicioOp: date })
    }

    const [_FecAInicioOp, setFecAInicioOp] = React.useState(null)
    const handleFecAInicioOp = (date) => {
        setFecAInicioOp(date)
        setCheck({ ..._Check, FecAInicioOp: date })
    }

    const [_FecSInicioOp, setFecSInicioOp] = React.useState(null)
    const handleFecSInicioOp = (date) => {
        setFecSInicioOp(date)
        setCheck({ ..._Check, FecSInicioOp: date })
    }

    const [_FecTInicioOp, setFecTInicioOp] = React.useState(null)
    const handleFecTInicioOp = (date) => {
        setFecTInicioOp(date)
        setCheck({ ..._Check, FecTInicioOp: date })
    }



    const hoy = new Date(Date.now());
	return (
		<ThemeProvider theme={Theme}>
			{/* <Paper className='containerViews3'> */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						elevation={12}
						sx={{ py: 2 }}
					>
						<Paper
							elevation={0}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div className='containerAlignHeader'>
								<Link to='/Productos' style={{ textDecoration: 'none' }}>
									<Button
										size='small'
										className='btn-Header-back '
										startIcon={<ArrowBackIosNewSharpIcon />}
									>
										Regresar
									</Button>
								</Link>

								<Chip icon={<ProductionQuantityLimitsIcon />} label='CONTRATAR PRODUCTOS' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							</div>
						</Paper>
					</Grid>
					<Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
                            <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                                <Paper elevation={0}>
                                    {/*start */}

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; Alta Producto &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdSucursal'>
                                                            Sucursal
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Producto.intIdSucursal}
                                                            onChange={handleProducto}
                                                            label='intIdSucursal'
                                                            inputProps={{
                                                                name: 'intIdSucursal',
                                                                id: 'intIdSucursal',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaSucursal.map((object, index) => (
                                                                <MenuItem value={object.lngIdSucursal}>
                                                                    {object.txtAlias}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdEvento'>
                                                            Evento
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Producto.intIdEvento}
                                                            onChange={handleProducto}
                                                            label='intIdEvento'
                                                            inputProps={{
                                                                name: 'intIdEvento',
                                                                id: 'intIdEvento',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaEvento.map((object, index) => (
                                                                <MenuItem value={object.lngIdEvento}>
                                                                    {object.txtAlias}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>            
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdUnidadNegocio'>
                                                            Unidad de Negocio
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Producto.intIdUnidadNegocio}
                                                            onChange={handleProducto}
                                                            label='intIdUnidadNegocio'
                                                            inputProps={{
                                                                name: 'intIdUnidadNegocio',
                                                                id: 'intIdUnidadNegocio',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaUniNegocio.map((object, index) => (
                                                                <MenuItem value={object.intIdUnidadNegocio}>
                                                                    {object.txtNombre}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>  
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdProducto'>
                                                            Producto
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Producto.intIdProducto}
                                                            onChange={handleProducto}
                                                            label='intIdProducto'
                                                            inputProps={{
                                                                name: 'intIdProducto',
                                                                id: 'intIdProducto',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaProducto.map((object, index) => (
                                                                <MenuItem value={object.intIdProducto}>
                                                                    {object.txtProducto}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>  
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdEjecutivoOp'>
                                                            Ejecutivo de Cuenta                                                       </InputLabel>
                                                        <Select                                                        
                                                            value={_Producto.intIdEjecutivoOp}
                                                            onChange={handleProducto}
                                                            label='intIdEjecutivoOp'
                                                            inputProps={{
                                                                name: 'intIdEjecutivoOp',
                                                                id: 'intIdEjecutivoOp',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaEjecutivoOp.map((object, index) => (
                                                                <MenuItem value={object.intIdEjecutivoOp}>
                                                                    {object.txtEjecutivoOp}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>                                 
                                            </Grid>
                                        </Grid>
                                    </fieldset>

                                    {_Check.intIdUnidadNegocio === 1 ?
                                    
                                        <Grid>
                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; FACTURACIÓN &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1 }}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblFHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblFHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblFHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblFCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblFCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblFCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                        <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFBaseSubTotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFDevIva' onChange={handleBox}/>} label="Devolución IVA" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolFProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel  control={<Checkbox name='bolFBancarizacion' onChange={handleBox}/>} label="Bancarizacion" />
                                                                <FormControlLabel  control={<Checkbox name='bolFConsg' onChange={handleBox}/>} label="Consg. Cuenta Bancaria" />
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel  control={<Checkbox name='bolFRetornoTransferSimples' onChange={handleBox}/>} label="Retorno en Transferencias Simples" />
                                                                <FormControlLabel  control={<Checkbox name='bolFTransaccionesCripto' onChange={handleBox}/>} label="Transacciones en Cripto Monedas" />
                                                                <FormControlLabel  control={<Checkbox name='bolFSofom' onChange={handleBox}/>} label="Sofom" />
                                                                <FormControlLabel  control={<Checkbox name='bolFSapi' onChange={handleBox}/>} label="Sapi" />
                                                                
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecFInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecFInicioOp}
                                                                        onChange={handleFecFInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; DATOS DE FACTURACIÓN &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1 }}>
                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                required
                                                                label='Importe Inicial de Facturación'
                                                                variant='standard'
                                                                value={_Check.dblImporteIniFactura}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={'$'}
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblImporteIniFactura: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblImporteIniFactura'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={3} >
                                                            <FormControl  variant='standard' size='small' fullWidth>
                                                                <InputLabel id='intIdPeriodicidad'>
                                                                    Periodicidad
                                                                </InputLabel>
                                                                <Select
                                                                    required
                                                                    value={_Check.intIdPeriodicidad}
                                                                    onChange={handleProducto}
                                                                    label='intIdPeriodicidad'
                                                                    inputProps={{
                                                                        name: 'intIdPeriodicidad',
                                                                        id: 'intIdPeriodicidad',
                                                                    }}
                                                                    size='small'
                                                                >
                                                                    <MenuItem aria-label='None' value='' />
                                                                    {_ListaPeriodicidad.map((object, index) => (
                                                                        <MenuItem value={object.intIdPeriodicidad}>
                                                                            {object.txtPeriodicidad}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid> 
                                                    </Grid>
                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                required
                                                                id='txtEmpresaFactura'
                                                                name='txtEmpresaFactura'
                                                                label='Empresa Para Facturación'
                                                                variant='standard'
                                                                fullWidth
                                                                value={_Check.txtEmpresaFactura}
                                                                onChange={handleProducto}
                                                                size='small'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                required
                                                                id='txtClaveSat'
                                                                name='txtClaveSat'
                                                                label='Clave SAT'
                                                                variant='standard'
                                                                fullWidth
                                                                value={_Check.txtClaveSat}
                                                                onChange={handleProducto}
                                                                size='small'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                required
                                                                id='txtDescripcion'
                                                                name='txtDescripcion'
                                                                label='Descripcion'
                                                                variant='standard'
                                                                fullWidth
                                                                value={_Check.txtDescripcion}
                                                                onChange={handleProducto}
                                                                size='small'
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </fieldset>
                                            
                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; MANEJO DE EFECTIVO &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1 }}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblMHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblMHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblMHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblMCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblMCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblMCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolMActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolMBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolMBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolMBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolMProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolMFacturacion' onChange={handleBox}/>} label="Facturacion" />
                                                                <FormControlLabel  control={<Checkbox name='bolMRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                                <FormControlLabel  control={<Checkbox name='bolMTransaccionesCripto' onChange={handleBox}/>} label="Transacciones en Cripto Monedas" />
                                                                <FormControlLabel  control={<Checkbox name='bolMRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolMRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel  control={<Checkbox name='bolMRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecMInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecMInicioOp}
                                                                        onChange={handleFecMInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operació"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; BANCARIZACION &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblBHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblBHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblBHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblBCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblBCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblBCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolBActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolBBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolBBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolBBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolBProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel control={<Checkbox name='bolBFacturacion' onChange={handleBox}/>} label="Facturacion" />
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel control={<Checkbox name='bolBRetornoTransferSimples' onChange={handleBox}/>} label="Retorno en Transferencias Simples" />
                                                                <FormControlLabel control={<Checkbox name='bolBTransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecBInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecBInicioOp}
                                                                        onChange={handleFecBInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; CONSIGNA DE CUENTA &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblCHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblCHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblCHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblCCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblCCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblCCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolCActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolCBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolCBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolCBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolCProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel control={<Checkbox name='bolCFacturacion' onChange={handleBox}/>} label="Facturacion" />
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel control={<Checkbox name='bolCRetornoTransferSimples' onChange={handleBox}/>} label="Retorno en Transferencias Simples" />
                                                                <FormControlLabel control={<Checkbox name='bolCTransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecCInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecCInicioOp}
                                                                        onChange={handleFecCInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; NOMINA &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblNHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblNHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblNHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblNCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblNCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblNCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolNActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolNBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolNBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolNBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolNProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolNRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel  control={<Checkbox name='bolNBanca' onChange={handleBox}/>} label="Bancarización" />
                                                                <FormControlLabel  control={<Checkbox name='bolNConsig' onChange={handleBox}/>} label="Consg. Cuenta Bancaria" />
                                                                <FormControlLabel  control={<Checkbox name='bolNRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolNRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel  control={<Checkbox name='bolNRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel  control={<Checkbox name='bolNRetornoTransferSimples' onChange={handleBox}/>} label="Retorno en Transferencias Simples" />
                                                                <FormControlLabel  control={<Checkbox name='bolNTransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecNInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecNInicioOp}
                                                                        onChange={handleFecNInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; ASIMILADOS &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblAHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblAHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblAHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblACostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblACostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblACostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}} control={<Checkbox name='bolAActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolABaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolABaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolABaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolAProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolARetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel  control={<Checkbox name='bolABanca' onChange={handleBox}/>} label="Facturacion" />
                                                                <FormControlLabel  control={<Checkbox name='bolAConsig' onChange={handleBox}/>} label="Consg. Cuenta Bancaria" />
                                                                <FormControlLabel  control={<Checkbox name='bolARetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolARetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel  control={<Checkbox name='bolARetornoTrasfer' onChange={handleBox}/>} label="Retorno en Transferencia" />
                                                                <FormControlLabel  control={<Checkbox name='bolATransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecAInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecAInicioOp}
                                                                        onChange={handleFecAInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; SINDICATO &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblSHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblSHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblSHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblSCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblSCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblSCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolSRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel  control={<Checkbox name='bolSBanca' onChange={handleBox}/>} label="Bancarizacion" />
                                                                <FormControlLabel  control={<Checkbox name='bolSConsig' onChange={handleBox}/>} label="Consg. Cuenta Bancaria" />
                                                                <FormControlLabel  control={<Checkbox name='bolSRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolSRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel  control={<Checkbox name='bolSRetornoTrasfer' onChange={handleBox}/>} label="Retorno en Transferencias Simples" />
                                                                <FormControlLabel  control={<Checkbox name='bolSTransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecSInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecSInicioOp}
                                                                        onChange={handleFecSInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>

                                            <fieldset
                                                style={{
                                                    borderRadius: '20px',
                                                    border: 'solid 3px  #c3e0f3',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                    &nbsp; TRANSFERENCIAS SIMPLES &nbsp;
                                                </legend>
                                                <Grid container sx={{ pb: 2, px: 1}}>
                                                    <Grid container alignItems='center' spacing={2}>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Honorario'
                                                                variant='standard'
                                                                value={_Check.dblTHonorario}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblTHonorario: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblTHonorario'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <NumberFormat
                                                                label='Costo Operativo'
                                                                variant='standard'
                                                                value={_Check.dblTCostoOperativo}
                                                                customInput={TextField}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix='%'
                                                                fullWidth
                                                                onValueChange={(values) => {
                                                                    setCheck({
                                                                        ..._Check,
                                                                        dblTCostoOperativo: values.floatValue,
                                                                    })
                                                                }}
                                                                size='small'
                                                                name='dblTCostoOperativo'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={3}>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolTActivo' onChange={handleBox}/>} label="Activo" />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Base</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolTBaseTotal' onChange={handleBox}/>} label="Total" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolTBaseSubtotal' onChange={handleBox}/>} label="Subtotal" />
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolTBaseRetorno' onChange={handleBox}/>} label="Retorno" />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column'}}>
                                                            <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Condiciones</p>
                                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolTProvision' onChange={handleBox}/>} label="Provision comercial 30%" />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <p style={{display: 'flex',fontSize: '14px', justifyContent: 'center',fontWeight: 'bold'}}>Incluye servicios</p>
                                                        <Grid item xs={12} sx={{display: 'flex'}}>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolTRetornoEfe' onChange={handleBox}/>} label="Retorno en Efectivo" />
                                                                <FormControlLabel  control={<Checkbox name='bolTBanca' onChange={handleBox}/>} label="Bancarizacion" />
                                                                <FormControlLabel  control={<Checkbox name='bolTConsig' onChange={handleBox}/>} label="Consg. Cuenta Bancaria" />
                                                                <FormControlLabel  control={<Checkbox name='bolTRetornoMonederos' onChange={handleBox}/>} label="Retorno en Monederos" />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <FormControlLabel  control={<Checkbox name='bolTRetornoDispNomina' onChange={handleBox}/>} label="Retorno en Dispersion Nomina" />
                                                                <FormControlLabel  control={<Checkbox name='bolTRetornoAsimilados' onChange={handleBox}/>} label="Retorno en Asimilados" />
                                                                <FormControlLabel  control={<Checkbox name='bolTRetornoSindicato' onChange={handleBox}/>} label="Retorno en Sindicato" />
                                                                <FormControlLabel  control={<Checkbox name='bolTTransaccionesCripto' onChange={handleBox}/>} label="Transacciones con Cripto Monedas" />
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <LocalizationProvider
                                                                    size='small'
                                                                    dateAdapter={AdapterDateFns}                                                      
                                                                >
                                                                    <DatePicker                                                            
                                                                        name='fecTInicioOper'
                                                                        label='Inicio operaciones'
                                                                        inputFormat='dd/MM/yyyy'
                                                                        value={_FecTInicioOp}
                                                                        onChange={handleFecTInicioOp}
                                                                        renderInput={(params) => (
                                                                            <TextField 
                                                                                label="Fecha Inicio Operación"                                                                   
                                                                                variant='standard'
                                                                                fullWidth
                                                                                size='small'
                                                                                {...params}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </fieldset>
                                            
                                        </Grid>
                                    
                                    :''}
                                    {_Check.intIdUnidadNegocio === 2 ?
                                    
                                    <Grid>
                                        <fieldset
                                            style={{
                                                borderRadius: '20px',
                                                border: 'solid 3px  #c3e0f3',
                                                marginTop: '30px',
                                            }}
                                        >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; TERMINALES PUNTO DE VENTA &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container alignItems='center' spacing={2}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        //required
                                                        id='txtDireccion'
                                                        name='txtDireccion'
                                                        label='Honorario'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.txtDireccion}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    //disabled='true'
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id='txtComplemento'
                                                        name='txtComplemento'
                                                        label='Costo Opera'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.txtComplemento}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    //disabled='true'
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl  variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdCiudad'>
                                                            Ciudad
                                                        </InputLabel>
                                                        <Select
                                                            
                                                            value={_Check.intIdCiudad}
                                                            onChange={handleProducto}
                                                            label='intIdCiudad'
                                                            inputProps={{
                                                                name: 'intIdCiudad',
                                                                id: 'intIdCiudad',
                                                            }}
                                                            size='small'
                                                        >
                                                            {/* <MenuItem aria-label='None' value='' />
                                                            {_ListaUbicacion.map((object, index) => (
                                                                <MenuItem value={object.intIdUbicacion}>
                                                                    {object.txtCiudad}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3} >
                                                    <TextField
                                                        id='numCodigoPostal'
                                                        name='numCodigoPostal'
                                                        label="Código Postal"
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.numCodigoPostal}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl  variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdEstado'>
                                                            Estado
                                                        </InputLabel>
                                                        <Select
                                                            
                                                            value={_Check.intIdEstado}
                                                            onChange={handleProducto}
                                                            label='intIdEstado'
                                                            inputProps={{
                                                                name: 'intIdEstado',
                                                                id: 'intIdEstado',
                                                            }}
                                                            size='small'
                                                        >
                                                            {/* <MenuItem aria-label='None' value='' />
                                                            {_ListaEstado.map((object, index) => (
                                                                <MenuItem value={object.intIdEstados}>
                                                                    {object.txtEstado}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id='txtTelefono'
                                                        name='txtTelefono'
                                                        label='Teléfono'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.txtTelefono}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    //disabled='true'
                                                    />
                                                </Grid> 
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id='txtCelular'
                                                        name='txtCelular'
                                                        label='Celular'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.txtCelular}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sx={{display: 'flex'}}>
                                                    <FormControlLabel  control={<Checkbox onChange={handleBox}/>} label="Holding" />
                                                    <Typography sx={{bgcolor: 'rgb(51 102 153)', display: 'grid', color:'white', alignContent:'center'}}>
                                                        Empresa de capital común o relacionados a otras Empresas</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        //required
                                                        id='txtEmail'
                                                        name='txtEmail'
                                                        label='Email'
                                                        type='email'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Check.txtEmail}
                                                        onChange={handleProducto}
                                                        size='small'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        </fieldset>

                                        <fieldset
                                            style={{
                                                borderRadius: '20px',
                                                border: 'solid 3px  #c3e0f3',
                                                marginTop: '30px',
                                            }}
                                        >
                                            <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                &nbsp; Datos de Contacto &nbsp;
                                            </legend>
                                            <Grid container sx={{ pb: 2, px: 1 }}>
                                                <Grid container spacing={2} alignItems='center'>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='txtServicio'
                                                            name='txtServicio'
                                                            label='Servicio'
                                                            variant='standard'
                                                            fullWidth
                                                            value={_Check.txtServicio}
                                                            onChange={handleProducto}
                                                            size='small'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <FormControl  variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdGiro'>
                                                                Giro
                                                            </InputLabel>
                                                            <Select
                                                                required
                                                                value={_Check.intIdGiro}
                                                                onChange={handleProducto}
                                                                label='intIdGiro'
                                                                inputProps={{
                                                                    name: 'intIdGiro',
                                                                    id: 'intIdGiro',
                                                                }}
                                                                size='small'
                                                            >
                                                                <MenuItem aria-label='None' value='' />
                                                                {_ListaGiro.map((object, index) => (
                                                                    <MenuItem value={object.intIdGiro}>
                                                                        {object.txtGiro}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='txtRecibe'
                                                            name='txtRecibe'
                                                            label='Recibe Capacitación'
                                                            variant='standard'
                                                            fullWidth
                                                            value={_Check.txtRecibe}
                                                            onChange={handleProducto}
                                                            size='small'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdBrinda'>
                                                                Capacitador
                                                            </InputLabel>
                                                            <Select                                                        
                                                                value={_Check.intIdBrinda}
                                                                onChange={handleProducto}
                                                                label='intIdBrinda'
                                                                inputProps={{
                                                                    name: 'intIdBrinda',
                                                                    id: 'intIdBrinda',
                                                                }}
                                                                size='small'
                                                            >
                                                                {/* <MenuItem aria-label='None' value='' />
                                                                {_ListaEjecutivoOp.map((object, index) => (
                                                                    <MenuItem value={object.intIdEjecutivoOp}>
                                                                        {object.txtEjecutivoOp}
                                                                    </MenuItem>
                                                                ))} */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdCobertura'>
                                                                Cobertura
                                                            </InputLabel>
                                                            <Select                                                        
                                                                value={_Check.intIdCobertura}
                                                                onChange={handleProducto}
                                                                label='intIdCobertura'
                                                                inputProps={{
                                                                    name: 'intIdCobertura',
                                                                    id: 'intIdCobertura',
                                                                }}
                                                                size='small'
                                                            >
                                                                {/* <MenuItem aria-label='None' value='' />
                                                                {_ListaCobertura.map((object, index) => (
                                                                    <MenuItem value={object.intIdCobertura}>
                                                                        {object.txtCobertura}
                                                                    </MenuItem>
                                                                ))} */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </fieldset>
                                        
                                        <fieldset
                                            style={{
                                                borderRadius: '20px',
                                                border: 'solid 3px  #c3e0f3',
                                                marginTop: '30px',
                                            }}
                                        >
                                            <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                &nbsp; Datos Adicionales &nbsp;
                                            </legend>
                                            <Grid container sx={{ pb: 2, px: 1 }}>
                                                <Grid container spacing={2} alignItems='center'>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='txtContacto'
                                                            name='txtContacto'
                                                            label='Contacto Cliente'
                                                            variant='standard'
                                                            fullWidth
                                                            value={_Check.txtContacto}
                                                            onChange={handleProducto}
                                                            size='small'
                                                        />
                                                    </Grid> 

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='txtTelContacto'
                                                            name='txtTelContacto'
                                                            label='Teléfono'
                                                            variant='standard'
                                                            fullWidth
                                                            type='number'
                                                            value={_Check.txtTelContacto}
                                                            onChange={handleProducto}
                                                            size='small'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdAreaCon'>
                                                                Área de Contacto
                                                            </InputLabel>
                                                            <Select                                                        
                                                                value={_Check.intIdAreaCon}
                                                                onChange={handleProducto}
                                                                label='intIdAreaCon'
                                                                inputProps={{
                                                                    name: 'intIdAreaCon',
                                                                    id: 'intIdAreaCon',
                                                                }}
                                                                size='small'
                                                            >
                                                                {/* <MenuItem aria-label='None' value='' />
                                                                {_ListaAreaCon.map((object, index) => (
                                                                    <MenuItem value={object.intIdAreaCon}>
                                                                        {object.txtAreaCon}
                                                                    </MenuItem>
                                                                ))} */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdContacto'>
                                                                Contacto
                                                            </InputLabel>
                                                            <Select                                                        
                                                                value={_Check.intIdContacto}
                                                                onChange={handleProducto}
                                                                label='intIdContacto'
                                                                inputProps={{
                                                                    name: 'intIdContacto',
                                                                    id: 'intIdContacto',
                                                                }}
                                                                size='small'
                                                            >
                                                                {/* <MenuItem aria-label='None' value='' />
                                                                {_ListaContacto.map((object, index) => (
                                                                    <MenuItem value={object.lngIdContacto}>
                                                                        {object.txtNombre + ' ' + object.txtApellido}
                                                                    </MenuItem>
                                                                ))} */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id='txtObservaciones'
                                                            name='txtObservaciones'
                                                            label='Observaciones'
                                                            variant='standard'
                                                            fullWidth
                                                            value={_Check.txtObservaciones}
                                                            onChange={handleProducto}
                                                            size='small'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <FormControl  variant='standard' size='small' fullWidth>
                                                            <InputLabel id='intIdStatusCli'>
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                required
                                                                value={_Check.intIdStatusCli}
                                                                onChange={handleProducto}
                                                                label='intIdStatusCli'
                                                                inputProps={{
                                                                    name: 'intIdStatusCli',
                                                                    id: 'intIdStatusCli',
                                                                }}
                                                                size='small'
                                                            >
                                                                {/* <MenuItem aria-label='None' value='' />
                                                                {_ListaStatus.map((object, index) => (
                                                                    <MenuItem value={object.intIdStatusCli}>
                                                                        {object.txtStatus}
                                                                    </MenuItem>
                                                                ))} */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid> 
                                                </Grid>
                                                
                                            </Grid>
                                        </fieldset>

                                        <fieldset
                                            style={{
                                                borderRadius: '20px',
                                                border: 'solid 3px  #c3e0f3',
                                                marginTop: '30px',
                                            }}
                                        >
                                            <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                &nbsp; Manejo De Efectivo &nbsp;
                                            </legend>
                                            <Grid container sx={{ pb: 2, px: 1}}>
                                                <Grid item xs={12} spacing={2} sx={{py: 2}} alignItems='center'>
                                                    

                                                </Grid>
                                                
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                
                                    :''}
                                    
                                    <Grid sx={{ marginTop: '30px' }}>
                                        <Button
                                            type='submit'
                                            className='btn-footer'
                                            startIcon={<SaveIcon />}
                                            size='small'
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </Paper>
                            </form>
                        </Paper>
				</Grid>
			{/* </Paper> */}
		</ThemeProvider>
	)
}
export default ProductosAdd
