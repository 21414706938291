import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

const MonedadEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctMoneda, setctMoneda]= useState({
        intIdmoneda: props.deptoId,
        txtMoneda: "",
        txtNomCorto: ""
	})
	const LimpiarValores = () => {
		setctMoneda({ ...ctMoneda, intIdmoneda: '', txtMoneda: '',txtNomCorto:''})
	}

	const handleCtBanco = (event) => {
		setctMoneda({ ...ctMoneda, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getDepto()
	}, [props.deptoId])
	const getDepto = () => {
		if (props.deptoId != null) {
			requests
				.get('CtMoneda/ObtenerPorId?id=' + props.deptoId)
				.then((response) => {
					setctMoneda(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtMoneda/Editar'
		requests
			.put(url, ctMoneda)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Actualizar Moneda
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px'}}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								required
								id='txtMoneda'
								name='txtMoneda'
								label='Nombre de la Moneda:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={ctMoneda.txtMoneda}
								onChange={handleCtBanco}
							/>
                            	<TextField
								required
								id='txtNomCorto'
								name='txtNomCorto'
								label='Nombre corto de la moneda:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={ctMoneda.txtNomCorto}
								onChange={handleCtBanco}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default MonedadEdit
