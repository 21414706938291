import { ThemeProvider } from "@emotion/react"
import { Fade } from "@mui/material"
import { Link } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People'
import ListItemText from '@mui/material/ListItemText'
import Theme from '../../Styles/Theme'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ListItem from '@mui/material/ListItem'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddIcon from '@mui/icons-material/Add'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AssessmentIcon from '@mui/icons-material/Assessment'
import MoneyIcon from '@mui/icons-material/Money'
import BarChartIcon from '@mui/icons-material/BarChart'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

const AccesoCxp = (props) => {
    return(
        <ThemeProvider theme= {Theme}>
            <div className="container-sidebar">
			{/* <Tooltip title='Clientes' arrow aria-label='add'>
				<Link to='/Clientes' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<PeopleIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Clientes
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Check List Juridico' arrow aria-label='add'>
				<Link to='/CheckList' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<PlaylistAddCheckIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Check List
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip> */}
            <Tooltip title='Contas por Pagar' arrow aria-label='add'>
				<Link to='/SolicitudCXP' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<MonetizationOnIcon className='icon-link-items2'/>
						</ListItemIcon>
						<ListItemText>
							<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
							Contas por Pagar
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Contas a pagar' arrow aria-label='add'>
				<Link to='/CuentasPg' style={{ textDecoration: 'none' }}>
					<ListItem button>
						<ListItemIcon>
							<CreditCardIcon className='icon-link-items2'/>
						</ListItemIcon>
						<ListItemText>
							<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
								Contas a pagar
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Accordion>
				<Tooltip title='Fornecedores CxP' arrow aria-label='add'>
					<ListItem button>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ ml: 9.5 }} />}
							aria-controls='panel1a-content'
							id='panel1a-header'
							sx={{ pl: 0 }}
						>
							<ListItemIcon>
								<ShoppingCartIcon className='icon-link-items2'/>
							</ListItemIcon>
							<Typography						
								sx={{ fontWeight: 'bold', textAlign: 'left' }}
								className='text-link-items2'
							>
								{' '}
								Fornecedores CxP
							</Typography>
						</AccordionSummary>
					</ListItem>
				</Tooltip>
				<Tooltip title='Fornecedores' arrow aria-label='add'>
					<Link to='/Proveedores' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ShoppingCartIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Fornecedores
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Contas' arrow aria-label='add'>
					<Link to='/Cuentas' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<CreditScoreIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Contas
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Saldos' arrow aria-label='add'>
					<Link to='/Saldos' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<LocalAtmIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Saldos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Créditos' arrow aria-label='add'>
					<Link to='/Creditos' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<MoneyIcon className='icon-link-items2' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Créditos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
			</Accordion>
			<Accordion>
				<Tooltip title='Relatório CxP' arrow aria-label='add'>
					<ListItem button>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ ml: 12 }} />}
							aria-controls='panel1a-content'
							id='panel1a-header'
							sx={{ pl: 0 }}
						>
							<ListItemIcon>
								<BarChartIcon className='icon-link-items2'/>
							</ListItemIcon>
							<Typography
								sx={{ fontWeight: 'bold', textAlign: 'left' }}								
								className='text-link-items2'
							>
								{' '}
								Relatório CxP
							</Typography>
						</AccordionSummary>
					</ListItem>
				</Tooltip>

				<Tooltip title='Relatório Geral' arrow aria-label='add'>
				<Link to='/ReporteGeneral' className='text-link'>
				<AccordionDetails>
				<ListItem button>
						<ListItemIcon>
							<AssessmentIcon className='icon-link-items2' color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography className='text-link-items3'>
								Relatório Geral
							</Typography>
						</ListItemText>
					</ListItem>
						</AccordionDetails>
					
				</Link>
			</Tooltip>

				<Tooltip
					title='Verifique se há expiração'
					arrow
					aria-label='add'
				>
					<Link to='/ReporteVencimiento' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ReportGmailerrorredIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Verifique se há expiração
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consulta por data de criação' arrow aria-label='add'>
					<Link to='/ReporteFechaRegistro' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AddIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consulta por data de criação
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consultar por data de pagamento' arrow aria-label='add'>
					<Link to='/ReporteFechaPago' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AttachMoneyIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consultar por data de pagamento
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consulte consecutivamente' arrow aria-label='add'>
					<Link to='/ReporteConsecutivo' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ArrowForwardIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consulte consecutivamente
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consultar por conceito' arrow aria-label='add'>
					<Link to='/ReporteConcepto' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PsychologyIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consultar por conceito
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Relatório de comprovantes' arrow aria-label='add'>
					<Link to='/ReporteComprobante' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ReceiptLongIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Relatório de comprovantes
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
			</Accordion>
            </div>
        </ThemeProvider>
    )
}
export default AccesoCxp