import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

// import MainContainerLayout from '../Layout/MainContainerLayout'
// import MainContentLayout from '../Layout/MainContentLayout'
// import HeaderLayout from '../Layout/HeaderLayout'

import HeaderLayout from '../Components/Layout/HeaderLayout'
import MainContainerLayout from '../Components/Layout/MainContainerLayout'
import MainContentLayout from '../Components/Layout/MainContentLayout'

import LoadingScreen from '../Components/Styles/LoadingScreen'
import MySnackBar from '../Components/Styles/MySnackBar'

import Administación from '../View/UsuariosView'


import {
	ADMINPAGE
} from '../Constants/routesConstants'

const RoutesAdmin = () => {
	const [LSopen, setLSOpen] = useState(false)
	const [SBOpen, setSBOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [Msj, setMsj] = useState('')
	const [type, setType] = useState('')

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setSBOpen(false)
	}

	const openLoadingScreen = () => {
		setLSOpen(true)
	}

	const closeLoadingScreen = () => {
		setLSOpen(false)
	}

	return (
		<>
			<MySnackBar
				Open={SBOpen}
				Type={type}
				Message={message}
				HandleClose={handleClose}
			/>
			<LoadingScreen open={LSopen} />

			<MainContainerLayout>
				<HeaderLayout
					setMsjLoadingScreen={setMsj}
					setOpenLoadingScreen={openLoadingScreen}
					setCloseLoadingScreen={closeLoadingScreen}
					setOpenSnackBar={setSBOpen}
					setMessageSnackBar={setMessage}
					setTypeSnackBar={setType}
				/>
				<MainContentLayout>
					<Routes>
						<Route
							exact
							path={"/"}
							element={
								<Administación
									setMsjLoadingScreen={setMsj}
									setOpenLoadingScreen={openLoadingScreen}
									setCloseLoadingScreen={closeLoadingScreen}
									setOpenSnackBar={setSBOpen}
									setMessageSnackBar={setMessage}
									setTypeSnackBar={setType}
								/>
							}
						/>
						<Route
							exact
							path={"/Usuarios"}
							element={
								<Administación
									setMsjLoadingScreen={setMsj}
									setOpenLoadingScreen={openLoadingScreen}
									setCloseLoadingScreen={closeLoadingScreen}
									setOpenSnackBar={setSBOpen}
									setMessageSnackBar={setMessage}
									setTypeSnackBar={setType}
								/>
							}
						/>
					</Routes>
				</MainContentLayout>
			</MainContainerLayout>
		</>
	)
}

export default RoutesAdmin
