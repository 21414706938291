import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate, useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import Chip from '@mui/material/Chip'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

import requests from '../AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import '../Styles/Styles.css'
import AsociarOperacion from './AsociarOperacion'
import AsociarOperacionEdit from './AsociarOperacionEdit'

import { PROVEEDORES_OP_LIST, STATUS_CLI_LIST, STATUS_UTILIDAD_LIST, OPERACIONES_EDIT, OPERACIONES_BY_ID, CLIENTES_LIST, REG_FLUJO_LIST_BY_ID_REGISTRO, MONEDA_OP_LIST } from '../../Constants/apiConstnats'
import { OPERACIONES_VIEW } from '../../Constants/routesConstants'

const OperacionesEdit = (props) => {
  const { id } = useParams()
  const methods = useForm()
  const { handleSubmit } = methods
  let navigate = useNavigate()

  const [_Operaciones, setOperaciones] = useState({
    lngIdRegistro: 0,
    txtRegistro: "",
    fecDia: "",
    lngIdProveedor: "",
    lngIdCliente: "",
    intIdStatusCli: "",
    dblImporteDes: 0,
    dblComisionBan: 0,
    dblTotalDls: 0,
    dblTc: 0,
    dblTotalPesos: 0,
    dblBaseComision: 0,
    dblPorComision: 0,
    dblComisionXoper: 0,
    dblPagoCliente: 0,
    dblComisionesBnCon: 0,
    dblImporteDisper: 0,
    dblComisionesBn: 0,
    dblTotalUsdUtiCam: 0,
    dblTcusdUtiCam: 0,
    dblTotalPesosUtiCam: 0,
    dblUtilidaCam: 0,
    dblPromotor: 0,
    dblUtilidaOper: 0,
    dblUtilidaGeneral: 0,
    intIdStatusUtilidad: "",
    txtDescripcionCuenta: "",
    txtComentarios: "",
    fecCierre: "",
    intIdMoneda: ""
  })

  const handleOperaciones = (event) => {
    setOperaciones({ ..._Operaciones, [event.target.name]: event.target.value });
  }

  const [_ListaStatusPago, setListaStatusPago] = useState([]);
  const getListaStatusPago = () => {
    props.setOpenLoadingScreen()
    requests
      .get(STATUS_CLI_LIST)
      .then((response) => {
        setListaStatusPago(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const [_ListaStatusUtilidad, setListaStatusUtilidad] = useState([]);
  const getListaStatusUtilidad = () => {
    props.setOpenLoadingScreen()
    requests
      .get(STATUS_UTILIDAD_LIST)
      .then((response) => {
        setListaStatusUtilidad(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const [_ListaMonedaOp, setListaMonedaOp] = useState([]);
  const getListaMonedaOp = () => {
    props.setOpenLoadingScreen()
    requests
      .get(MONEDA_OP_LIST)
      .then((response) => {
        setListaMonedaOp(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const [_ListaProveedores, setListaProveedores] = useState([]);
  const getListaProveedores = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PROVEEDORES_OP_LIST)
      .then((response) => {
        setListaProveedores(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const [_ListaClientes, setListaClientes] = useState([]);
  const getListaClientes = () => {
    props.setOpenLoadingScreen()
    requests
      .get(CLIENTES_LIST)
      .then((response) => {
        setListaClientes(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [_IdRegistro, setIdRegistro] = useState({
    id: ''
  })

  useEffect(() => {
    getListaStatusPago()
    getListaStatusUtilidad()
    getListaProveedores()
    getListaMonedaOp()
    getListaClientes()  
    getOperacion()    
    getRegFlujo()
  }, [])

  const guardar = () => {
    props.setOpenLoadingScreen()
    requests
      .put(OPERACIONES_EDIT, _Operaciones)
      .then((response) => {
        props.setTypeSnackBar('success')
        props.setMessageSnackBar(response.message)
        props.setOpenSnackBar(true)
        props.setCloseLoadingScreen()
        navigate(OPERACIONES_VIEW)
      })
      .catch((error) => {
        props.setTypeSnackBar('warning')
        props.setMessageSnackBar(error.data.message)
        props.setOpenSnackBar(true)
        props.setCloseLoadingScreen()
      })
  }

  const getOperacion = () => {
    props.setOpenLoadingScreen()
    requests
      .get(OPERACIONES_BY_ID, id)
      .then((response) => {
        setOperaciones(response)
        //setIdRegistro({..._IdRegistro, id: id})
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [_ContadorRegFlujo, setContadorRegFlujo] = useState([])
  const getRegFlujo = () => {
    props.setOpenLoadingScreen()
    requests
      .get(REG_FLUJO_LIST_BY_ID_REGISTRO, id)
      .then((response) => {
        setContadorRegFlujo(response.length)
        //setIdRegistro({..._IdRegistro, id: null})
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  // const totalUSD = () => {
  //   let usd = _Operaciones.dblImporteDes + _Operaciones.dblComisionBan
  //   setOperaciones({..._Operaciones, dblTotalDls: usd})
  //   //_Operaciones.dblTotalDls
  // }

  // const totalPesos = () => {
  //   let pesos = _Operaciones.dblTotalDls * _Operaciones.dblTc
  //   setOperaciones({..._Operaciones, dblTotalPesos: pesos})
  //   //_Operaciones.dblTotalPesos
  // }

  // const comisionXOperacion = () => {
  //   let porciento = _Operaciones.dblPorComision / 100;
  //   let comision = (_Operaciones.dblBaseComision * porciento) * _Operaciones.dblTc;
  //   //setOperaciones({..._Operaciones, dblComisionXoper: comision})
  //   pagoCliente(comision)
  //   //_Operaciones.dblComisionXoper
  // }

  // const pagoCliente = (comision) => {
  //   let pago = _Operaciones.dblTotalPesos + comision
  //   //let porcento = 15 / 100;
  //   //let prom = comision * porcento;
  //   setOperaciones({..._Operaciones, dblComisionXoper: comision, dblPagoCliente: pago})
  //   //_Operaciones.dblPagoCliente
  // }

  // const totalUSDUtilidad = () => {
  //   let total = _Operaciones.dblImporteDisper + _Operaciones.dblComisionesBn
  //   setOperaciones({..._Operaciones, dblTotalUsdUtiCam: total})
  //   //_Operaciones.dblTotalUsdUtiCam
  // }

  // const totalPesosUtilidad = () => {
  //   let pesos = _Operaciones.dblTotalUsdUtiCam * _Operaciones.dblTcusdUtiCam
  //   //setOperaciones({..._Operaciones, dblTotalPesosUtiCam:pesos })
  //   utilidadCambiaria(pesos)
  //   //_Operaciones.dblTotalPesosUtiCam
  // }

  // const utilidadCambiaria = (pesos) => {
  //   let utilidad = _Operaciones.dblTotalPesos - pesos - _Operaciones.dblComisionesBnCon
  //   setOperaciones({..._Operaciones, dblTotalPesosUtiCam:pesos , dblUtilidaCam: utilidad})
  //   //utilidadOperacion()
  //   //_Operaciones.dblUtilidaCam
  // }

  // // const promotor = () => {
  // //   let porcento = 15 / 100;
  // //   let prom = _Operaciones.dblComisionXoper * porcento;
  // //   setOperaciones({..._Operaciones, dblPromotor: prom})
  // // }

  // const utilidadOperacion = () => {
  //   let utilidad = _Operaciones.dblComisionXoper - _Operaciones.dblPromotor
  //   //setOperaciones({..._Operaciones, dblUtilidaOper: utilidad})
  //   utilidadGeneral(utilidad)
  //   //_Operaciones.dblUtilidaOper
  // }

  // const utilidadGeneral = (utilidad) => {
  //   let utilidadGen = _Operaciones.dblUtilidaCam + _Operaciones.dblPromotor + utilidad
  //   setOperaciones({..._Operaciones, dblUtilidaOper: utilidad, dblUtilidaGeneral: utilidadGen})
  //   //_Operaciones.dblUtilidaGeneral
  // }

  const calculoGeneral = () => {
    if(_Operaciones.dblImporteDes === '' || _Operaciones.dblImporteDes === null || _Operaciones.dblImporteDes === undefined){
      _Operaciones.dblImporteDes = 0;
    }
    if(_Operaciones.dblComisionBan === '' || _Operaciones.dblComisionBan === null || _Operaciones.dblComisionBan === undefined){
      _Operaciones.dblComisionBan = 0;
    }
    if(_Operaciones.dblTc === '' || _Operaciones.dblTc === null || _Operaciones.dblTc === undefined){
      _Operaciones.dblTc = 0;
    }
    if(_Operaciones.dblBaseComision === '' || _Operaciones.dblBaseComision === null || _Operaciones.dblBaseComision === undefined){
      _Operaciones.dblBaseComision = 0;
    }
    if(_Operaciones.dblPorComision === '' || _Operaciones.dblPorComision === null || _Operaciones.dblPorComision === undefined){
      _Operaciones.dblPorComision = 0;
    }
    if(_Operaciones.dblComisionesBnCon === '' || _Operaciones.dblComisionesBnCon === null || _Operaciones.dblComisionesBnCon === undefined){
      _Operaciones.dblComisionesBnCon = 0;
    }
    if(_Operaciones.dblImporteDisper === '' || _Operaciones.dblImporteDisper === null || _Operaciones.dblImporteDisper === undefined){
      _Operaciones.dblImporteDisper = 0;
    }
    if(_Operaciones.dblComisionesBn === '' || _Operaciones.dblComisionesBn === null || _Operaciones.dblComisionesBn === undefined){
      _Operaciones.dblComisionesBn = 0;
    }
    if(_Operaciones.dblTcusdUtiCam === '' || _Operaciones.dblTcusdUtiCam === null || _Operaciones.dblTcusdUtiCam === undefined){
      _Operaciones.dblTcusdUtiCam = 0;
    }
    if(_Operaciones.dblPromotor === '' || _Operaciones.dblPromotor === null || _Operaciones.dblPromotor === undefined){
      _Operaciones.dblPromotor = 0;
    }
    if(_Operaciones.dblTotalDls === '' || _Operaciones.dblTotalDls === null || _Operaciones.dblTotalDls === undefined){
      _Operaciones.dblTotalDls = 0;
    }
    if(_Operaciones.dblTotalPesos === '' || _Operaciones.dblTotalPesos === null || _Operaciones.dblTotalPesos === undefined){
      _Operaciones.dblTotalPesos = 0;
    }
    if(_Operaciones.dblComisionXoper === '' || _Operaciones.dblComisionXoper === null || _Operaciones.dblComisionXoper === undefined){
      _Operaciones.dblComisionXoper = 0;
    }
    if(_Operaciones.dblPagoCliente === '' || _Operaciones.dblPagoCliente === null || _Operaciones.dblPagoCliente === undefined){
      _Operaciones.dblPagoCliente = 0;
    }
    if(_Operaciones.dblTotalUsdUtiCam === '' || _Operaciones.dblTotalUsdUtiCam === null || _Operaciones.dblTotalUsdUtiCam === undefined){
      _Operaciones.dblTotalUsdUtiCam = 0;
    }
    if(_Operaciones.dblTotalPesosUtiCam === '' || _Operaciones.dblTotalPesosUtiCam === null || _Operaciones.dblTotalPesosUtiCam === undefined){
      _Operaciones.dblTotalPesosUtiCam = 0;
    }
    if(_Operaciones.dblUtilidaCam === '' || _Operaciones.dblUtilidaCam === null || _Operaciones.dblUtilidaCam === undefined){
      _Operaciones.dblUtilidaCam = 0;
    }
    if(_Operaciones.dblUtilidaOper === '' || _Operaciones.dblUtilidaOper === null || _Operaciones.dblUtilidaOper === undefined){
      _Operaciones.dblUtilidaOper = 0;
    }
    if(_Operaciones.dblUtilidaGeneral === '' || _Operaciones.dblUtilidaGeneral === null || _Operaciones.dblUtilidaGeneral === undefined){
      _Operaciones.dblUtilidaGeneral = 0;
    }
    
    //TOTAL DOLARES
    let usd = _Operaciones.dblImporteDes + _Operaciones.dblComisionBan
    //setOperaciones({ ..._Operaciones, dblTotalDls: usd})
    //TOTAL PESOS
    let pesos = usd * _Operaciones.dblTc
    //COMISION POR OPERACION
    let porciento = _Operaciones.dblPorComision / 100;
    let comision = (_Operaciones.dblBaseComision * porciento) * _Operaciones.dblTc;
    //PAGO CLIENTE
    let pago = pesos + comision
    //TOTAL DOLARES UTILIDAD
    let total = _Operaciones.dblImporteDisper + _Operaciones.dblComisionesBn
    //TOTAL PESOS UTILIDAD
    let pesosutilidad = total * _Operaciones.dblTcusdUtiCam
    //UTILIDAD CAMBIARIA
    let utilidad = pesos - pesosutilidad - _Operaciones.dblComisionesBnCon
    //UTILIDAD OPERACION
    let utilidadoperacion = _Operaciones.dblComisionXoper - _Operaciones.dblPromotor
    //UTILIDAD GENERAL
    let utilidadGen = utilidad - _Operaciones.dblPromotor + utilidadoperacion

    setOperaciones({ ..._Operaciones, dblTotalDls: usd, dblTotalPesos: pesos, dblComisionXoper: comision, dblPagoCliente: pago, dblTotalUsdUtiCam: total,
      dblTotalPesosUtiCam: pesosutilidad, dblUtilidaCam: utilidad, dblUtilidaOper: utilidadoperacion, dblUtilidaGeneral: utilidadGen
    })

  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={Theme}>
      {/* <Paper className='containerViews3'> */}
      <Grid xs={12} sm={8} md={10} lg={12}>
        <Grid
          item
          xs={12}
          sm={8}
          md={10}
          lg={12}
          elevation={12}
          sx={{ py: 2 }}
        >
          <Paper
            elevation={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='containerAlignHeader'>
              <Link to={OPERACIONES_VIEW} style={{ textDecoration: 'none' }}>
                <Button
                  size='small'
                  className='btn-Header-back '
                  startIcon={<ArrowBackIosNewSharpIcon />}
                >
                  Regresar
                </Button>
              </Link>
              <Chip icon={<CurrencyExchangeIcon />} label='Operaciones / Editar' color='primary' className='back-transparent font-letter font-barter font-20' />
            </div>
            <div className='containerAlignHeader'>
								<div className='container'>
									<div className='container-folio'>
										<h4 className='title-solicitud'>Folio: </h4>
										<h4 className='subtitle-name'>{_Operaciones.txtRegistro}</h4>
									</div>
								</div>
							</div>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {/* start */}
          <Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
            <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
              <Paper elevation={0}>
                {/*start */}

                <fieldset
                  style={{
                    borderRadius: '20px',
                    border: 'solid 3px rgba(143, 188, 143, 0.6)',
                    marginTop: '10px',
                  }}
                >
                  <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    &nbsp; Cálculo cliente &nbsp;
                  </legend>
                  <Grid container sx={{ pb: 2, px: 1 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='lngIdProveedor'>
                            Proveedor
                          </InputLabel>
                          <Select
                            //required
                            value={_Operaciones.lngIdProveedor}
                            onChange={handleOperaciones}
                            label='lngIdProveedor'
                            inputProps={{
                              name: 'lngIdProveedor',
                              id: 'lngIdProveedor',
                            }}
                            size='small'
                          >
                            <MenuItem aria-label='None' value='' />
                            {_ListaProveedores.map((object, index) => (
                              <MenuItem value={object.lngIdProveedorOpe}>
                                {object.txtNombreComercial}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='intIdStatusCli'>
                            Pago cliente
                          </InputLabel>
                          <Select
                            //required
                            value={_Operaciones.intIdStatusCli}
                            onChange={handleOperaciones}
                            label='intIdStatusCli'
                            inputProps={{
                              name: 'intIdStatusCli',
                              id: 'intIdStatusCli',
                            }}
                            size='small'
                          >
                            <MenuItem aria-label='None' value='' />
                            {_ListaStatusPago.map((object, index) => (
                              <MenuItem value={object.intIdStatusCli}>
                                {object.txtStatusCli}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='lngIdCliente'>
                            Cliente
                          </InputLabel>
                          <Select
                            //required
                            value={_Operaciones.lngIdCliente}
                            onChange={handleOperaciones}
                            label='lngIdCliente'
                            inputProps={{
                              name: 'lngIdCliente',
                              id: 'lngIdCliente',
                            }}
                            size='small'
                          >
                            <MenuItem aria-label='None' value='' />
                            {_ListaClientes.map((object, index) => (
                              <MenuItem value={object.lngIdCliente}>
                                {object.txtNombreCorto}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Importe a dispersar'
                          variant='standard'
                          value={_Operaciones.dblImporteDes}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblImporteDes: values.floatValue,
                            })
                          }}
                          onFocus={calculoGeneral}
                          onBlur={calculoGeneral}
                          size='small'
                          name='dblImporteDes'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Comisión bancaria'
                          variant='standard'
                          value={_Operaciones.dblComisionBan}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblComisionBan: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblComisionBan'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Total USD'
                          variant='standard'
                          value={_Operaciones.dblTotalDls}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTotalDls: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          size='small'
                          name='dblTotalDls'
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='TC'
                          variant='standard'
                          value={_Operaciones.dblTc}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={4}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTc: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblTc'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Total pesos'
                          variant='standard'
                          value={_Operaciones.dblTotalPesos}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTotalPesos: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          size='small'
                          name='dblTotalPesos'
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='intIdMoneda'>
                            Moneda
                          </InputLabel>
                          <Select
                            //required
                            value={_Operaciones.intIdMoneda}
                            onChange={handleOperaciones}
                            label='intIdMoneda'
                            inputProps={{
                              name: 'intIdMoneda',
                              id: 'intIdMoneda',
                            }}
                            size='small'
                            onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          >
                            <MenuItem aria-label='None' value='' />
                            {_ListaMonedaOp.map((object, index) => (
                              <MenuItem value={object.intIdMoneda}>
                                {object.txtMoneda}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset
                  style={{
                    borderRadius: '20px',
                    border: 'solid 3px rgba(143, 188, 143, 0.6)',
                    marginTop: '10px',
                  }}
                >
                  <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    &nbsp; Comisión por operación &nbsp;
                  </legend>
                  <Grid container sx={{ pb: 2, px: 1 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Base comisión'
                          variant='standard'
                          value={_Operaciones.dblBaseComision}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblBaseComision: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblBaseComision'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Porcentaje '
                          variant='standard'
                          value={_Operaciones.dblPorComision}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          //prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblPorComision: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          suffix={'%'}
                          size='small'
                          name='dblPorComision'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Comisión por operación'
                          variant='standard'
                          value={_Operaciones.dblComisionXoper}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblComisionXoper: values.floatValue,
                            })
                          }}
                          //onBlur={promotor}
                          size='small'
                          name='dblComisionXoper'
                          className='text-disabled'
                          disabled
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset
                  style={{
                    borderRadius: '20px',
                    border: 'solid 3px rgba(143, 188, 143, 0.6)',
                    marginTop: '10px',
                  }}
                >
                  <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    &nbsp; Pago cliente &nbsp;
                  </legend>
                  <Grid container sx={{ pb: 2, px: 1 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Pago cliente'
                          variant='standard'
                          value={_Operaciones.dblPagoCliente}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblPagoCliente: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          size='small'
                          name='dblPagoCliente'
                          className='text-disabled'
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Comisión Banpay/Cometra '
                          variant='standard'
                          value={_Operaciones.dblComisionesBnCon}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblComisionesBnCon: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          //suffix={'%'}
                          size='small'
                          name='dblComisionesBnCon'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset
                  style={{
                    borderRadius: '20px',
                    border: 'solid 3px rgba(143, 188, 143, 0.6)',
                    marginTop: '10px',
                  }}
                >
                  <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    &nbsp; Utilidad cambiaria &nbsp;
                  </legend>
                  <Grid container sx={{ pb: 2, px: 1 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Importe a dispersar'
                          variant='standard'
                          value={_Operaciones.dblImporteDisper}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblImporteDisper: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblImporteDisper'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Comisión bancaria'
                          variant='standard'
                          value={_Operaciones.dblComisionesBn}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblComisionesBn: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblComisionesBn'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Total USD'
                          variant='standard'
                          value={_Operaciones.dblTotalUsdUtiCam}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTotalUsdUtiCam: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          //suffix={'%'}
                          size='small'
                          name='dblTotalUsdUtiCam'
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems='center'>

                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='TC'
                          variant='standard'
                          value={_Operaciones.dblTcusdUtiCam}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={4}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTcusdUtiCam: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblTcusdUtiCam'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Total pesos'
                          variant='standard'
                          value={_Operaciones.dblTotalPesosUtiCam}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblTotalPesosUtiCam: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          size='small'
                          name='dblTotalPesosUtiCam'
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Utilidad cambiaria'
                          variant='standard'
                          value={_Operaciones.dblUtilidaCam}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblUtilidaCam: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          //suffix={'%'}
                          size='small'
                          name='dblUtilidaCam'
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Promotor'
                          variant='standard'
                          value={_Operaciones.dblPromotor}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblPromotor: values.floatValue,
                            })
                          }}
                          onBlur={calculoGeneral}
                          onFocus={calculoGeneral}
                          size='small'
                          name='dblPromotor'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset
                  style={{
                    borderRadius: '20px',
                    border: 'solid 3px rgba(143, 188, 143, 0.6)',
                    marginTop: '10px',
                  }}
                >
                  <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    &nbsp; Pago utilidades &nbsp;
                  </legend>
                  <Grid container sx={{ pb: 2, px: 1 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Utilidad por operación'
                          variant='standard'
                          value={_Operaciones.dblUtilidaOper}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblUtilidaOper: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          size='small'
                          name='dblUtilidaOper'
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          //required
                          label='Utilidad general'
                          variant='standard'
                          value={_Operaciones.dblUtilidaGeneral}
                          customInput={TextField}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          fullWidth
                          onValueChange={(values) => {
                            setOperaciones({
                              ..._Operaciones,
                              dblUtilidaGeneral: values.floatValue,
                            })
                          }}
                          //onBlur={handleOblur}
                          //suffix={'%'}
                          size='small'
                          name='dblUtilidaGeneral'
                          disabled
                          className='text-disabled'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='intIdStatusUtilidad'>
                            Status utilidad
                          </InputLabel>
                          <Select
                            //required
                            value={_Operaciones.intIdStatusUtilidad}
                            onChange={handleOperaciones}
                            label='intIdStatusUtilidad'
                            inputProps={{
                              name: 'intIdStatusUtilidad',
                              id: 'intIdStatusUtilidad',
                            }}
                            size='small'
                          >
                            <MenuItem aria-label='None' value='' />
                            {_ListaStatusUtilidad.map((object, index) => (
                              <MenuItem value={object.intIdStatusUtilidad}>
                                {object.txtStatusUtilidad}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                    </Grid>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={4}>
                        <TextField
                          //required
                          id='txtDescripcionCuenta'
                          name='txtDescripcionCuenta'
                          label='Cuenta de pago'
                          variant='standard'
                          fullWidth
                          value={_Operaciones.txtDescripcionCuenta}
                          onChange={handleOperaciones}
                          size='small'
                        //disabled='true'
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          //required
                          id='txtComentarios'
                          name='txtComentarios'
                          label='Comentarios'
                          variant='standard'
                          fullWidth
                          value={_Operaciones.txtComentarios}
                          onChange={handleOperaciones}
                          size='small'
                        //disabled='true'
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                </fieldset>

                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ marginTop: '30px' }}>
                  <Grid item>
                    <Button
                      //type='submit'
                      className='btn-footer mr1'
                      startIcon={<DeviceHubIcon />}
                      size='small'
                      onClick={function () {
                        handleOpen()
                      }}
                    >
                      Asociar ({_ContadorRegFlujo})
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type='submit'
                      className='btn-footer'
                      startIcon={<SaveIcon />}
                      size='small'
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {_Operaciones.lngIdRegistro !== null ? (
        <AsociarOperacionEdit
          registroId={_Operaciones.lngIdRegistro}
          open={open}
          onClose={handleOpen}
          getRegistro={getRegFlujo}
          getOperacion={getOperacion}
          setOpenLoadingScreen={props.setOpenLoadingScreen}
          setCloseLoadingScreen={props.setCloseLoadingScreen}
          setOpenSnackBar={props.setOpenSnackBar}
          setMessageSnackBar={props.setMessageSnackBar}
          setTypeSnackBar={props.setTypeSnackBar}
        />
       ) : null} 
      {/* </Paper> */}
    </ThemeProvider>
  )
}
export default OperacionesEdit
