import { ThemeProvider } from "@emotion/react"
import { Fade } from "@mui/material"
import { Link } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People'
import ListItemText from '@mui/material/ListItemText'
import Theme from '../../Styles/Theme'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ListItem from '@mui/material/ListItem'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemIcon from '@mui/material/ListItemIcon'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import PaymentsIcon from '@mui/icons-material/Payments'
import BarChartIcon from '@mui/icons-material/BarChart'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import BusinessIcon from '@mui/icons-material/Business'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddIcon from '@mui/icons-material/Add'
import PaidIcon from '@mui/icons-material/Paid'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PublicIcon from '@mui/icons-material/Public'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import ArticleIcon from '@mui/icons-material/Article'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import WorkIcon from '@mui/icons-material/Work'
import BadgeIcon from '@mui/icons-material/Badge'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MoneyIcon from '@mui/icons-material/Money';
import {PROVEEDORES_OP_VIEW, STATUS_CLI_VIEW, STATUS_UTILIDAD_VIEW, OPERACIONES_VIEW, OPERACIONES_UPDATE, OPERACIONES_CREATE, PROD_VIEW} from '../../../Constants/routesConstants'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const AccesoOperaciones = (props) => {
    return(
        <ThemeProvider theme= {Theme}>
            <div className='container-sidebar'>
			<Tooltip title='Inicio' arrow aria-label='add'>
				<Link to='/' style={{ textDecoration: 'none' }}>
					<ListItem button>
						<ListItemIcon>
							<DashboardIcon className='icon-link-items' color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography className='text-link-items' color='primary' sx={{ fontWeight: 'bold' }}>
								INÍCIO
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Clientes' arrow aria-label='add'>
				<Link to='/Clientes' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<PeopleIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Clientes
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Check List Juridico' arrow aria-label='add'>
				<Link to='/CheckList' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<PlaylistAddCheckIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Check List
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Productos' arrow aria-label='add'>
				<Link to={PROD_VIEW} className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<ProductionQuantityLimitsIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Produtos
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Operações' arrow aria-label='add'>
				<Link to={OPERACIONES_VIEW} className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<CurrencyExchangeIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Operações
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Reporte operaciones' arrow aria-label='add'>
				<Link to='/Reporte-operaciones' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<AssessmentIcon className='icon-link-items'  color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography
								color='primary'
								className='text-link-items'
								sx={{ fontWeight: 'bold' }}
							>
								Relatório de Operações
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Divider />			

			<Tooltip title='Contas por pagar' arrow aria-label='add'>
				<Link to='/SolicitudCXP' className='text-link'>
					<ListItem button>
						<ListItemIcon>
							<MonetizationOnIcon className='icon-link-items2'/>
						</ListItemIcon>
						<ListItemText>
							<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
								Contas por pagar
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Tooltip title='Contas a pagar' arrow aria-label='add'>
				<Link to='/CuentasPg' style={{ textDecoration: 'none' }}>
					<ListItem button>
						<ListItemIcon>
							<CreditCardIcon className='icon-link-items2'/>
						</ListItemIcon>
						<ListItemText>
							<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
								Contas a pagar
							</Typography>
						</ListItemText>
					</ListItem>
				</Link>
			</Tooltip>

			<Accordion>
				<Tooltip title='Fornecedores CxP' arrow aria-label='add'>
					<ListItem button>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ ml: 9.5 }} />}
							aria-controls='panel1a-content'
							id='panel1a-header'
							sx={{ pl: 0 }}
						>
							<ListItemIcon>
								<ShoppingCartIcon className='icon-link-items2'/>
							</ListItemIcon>
							<Typography						
								sx={{ fontWeight: 'bold', textAlign: 'left' }}
								className='text-link-items2'
							>
								{' '}
								Fornecedores CxP
							</Typography>
						</AccordionSummary>
					</ListItem>
				</Tooltip>
				<Tooltip title='Fornecedores' arrow aria-label='add'>
					<Link to='/Proveedores' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ShoppingCartIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Fornecedores
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Contas' arrow aria-label='add'>
					<Link to='/Cuentas' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<CreditScoreIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Contas
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Saldos' arrow aria-label='add'>
					<Link to='/Saldos' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<LocalAtmIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Saldos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Créditos' arrow aria-label='add'>
					<Link to='/Creditos' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<MoneyIcon className='icon-link-items2' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Créditos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
			</Accordion>

			<Accordion>
				<Tooltip title='Relatório CxP' arrow aria-label='add'>
					<ListItem button>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ ml: 12 }} />}
							aria-controls='panel1a-content'
							id='panel1a-header'
							sx={{ pl: 0 }}
						>
							<ListItemIcon>
								<BarChartIcon className='icon-link-items2'/>
							</ListItemIcon>
							<Typography
								sx={{ fontWeight: 'bold', textAlign: 'left' }}								
								className='text-link-items2'
							>
								{' '}
								Relatório CxP
							</Typography>
						</AccordionSummary>
					</ListItem>
				</Tooltip>

				<Tooltip title='Relatório Geral' arrow aria-label='add'>
				<Link to='/ReporteGeneral' className='text-link'>
				<AccordionDetails>
				<ListItem button>
						<ListItemIcon>
							<AssessmentIcon className='icon-link-items2' color='primary' />
						</ListItemIcon>
						<ListItemText>
							<Typography className='text-link-items3'>
								Relatório Geral
							</Typography>
						</ListItemText>
					</ListItem>
						</AccordionDetails>
					
				</Link>
			</Tooltip>

				<Tooltip
					title='Verifique se há expiração'
					arrow
					aria-label='add'
				>
					<Link to='/ReporteVencimiento' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ReportGmailerrorredIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Verifique se há expiração
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consulta por data de criação' arrow aria-label='add'>
					<Link to='/ReporteFechaRegistro' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AddIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consulta por data de criação
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consultar por data de pagamento' arrow aria-label='add'>
					<Link to='/ReporteFechaPago' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AttachMoneyIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consultar por data de pagamento
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consulte consecutivamente' arrow aria-label='add'>
					<Link to='/ReporteConsecutivo' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ArrowForwardIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consulte consecutivamente
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Consultar por conceito' arrow aria-label='add'>
					<Link to='/ReporteConcepto' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PsychologyIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Consultar por conceito
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Relatório de comprovantes' arrow aria-label='add'>
					<Link to='/ReporteComprobante' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ReceiptLongIcon className='icon-link-items2'/>
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items3'>
										Relatório de comprovantes
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
			</Accordion>

			<Accordion>
				<Tooltip title='Catálogos' arrow aria-label='add'>
					<ListItem Button>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ ml: 14.5 }} />}
							aria-controls='panel1a-content'
							id='panel1a-header'
							sx={{ pl: 0 }}
						>
							<ListItemIcon>
								<MenuBookIcon className='icon-link-items'  color='primary' />
							</ListItemIcon>
							<Typography
								sx={{ fontWeight: 'bold', textAlign: 'left' }}
								color='primary'
								className='text-link-items'
							>
								Catálogos
							</Typography>
						</AccordionSummary>
					</ListItem>
				</Tooltip>
				{/* <Tooltip title='ActividadEco' arrow aria-label='add'>
					<Link to='/ActividadEcoView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PlaylistAddIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Actividad Económica
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				{/* <Tooltip title='Area' arrow aria-label='add'>
					<Link to='/AreaView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PlaylistAddIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Area
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				{/* <Tooltip title='CFDI' arrow aria-label='add'>
					<Link to='/CfdiView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AccountBalanceWalletIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										CFDI
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				<Tooltip title='Departamentos' arrow aria-label='add'>
					<Link to='/DepartamentosView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PlaylistAddIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Departamentos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Negocios' arrow aria-label='add'>
					<Link to='/NegociosView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AddBusinessIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Negocios
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Praças' arrow aria-label='add'>
					<Link to='/PlazasView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AddLocationAltIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Praças
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Empresas pagadoras' arrow aria-label='add'>
					<Link to='/EmpresasPgView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<BusinessIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Empresas pagadoras
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Status' arrow aria-label='add'>
					<Link to='/StatusView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<CheckCircleIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Status
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Tipo gasto' arrow aria-label='add'>
					<Link to='/TipoGastoView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AccountBalanceWalletIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipo Gasto
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Tipo pagos' arrow aria-label='add'>
					<Link to='/TipoPagosView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PaymentsIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipo Pago
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Bancos' arrow aria-label='add'>
					<Link to='/BancosView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<AccountBalanceIcon className='icon-link-items' color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Bancos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Monedas' arrow aria-label='add'>
					<Link to='/Monedas' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PaidIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Monedas
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Movimientos' arrow aria-label='add'>
					<Link to='/MovimientosView' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<CurrencyExchangeIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Movimientos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Tipos de servicios' arrow aria-label='add'>
					<Link to='/TipoDeServicio' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<FactCheckIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipos De Servicio
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				{/* <Tooltip title='Tipos de documentos' arrow aria-label='add'>
					<Link to='/TipoDocumentos' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ArticleIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipo Documentos
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				<Tooltip title='Paises' arrow aria-label='add'>
					<Link to='/Paises' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<PublicIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Paises
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				{/* <Tooltip title='Estado civil' arrow aria-label='add'>
					<Link to='/EstadoCivil' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<EscalatorWarningIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Estado civil
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				{/* <Tooltip title='Tipo de profesional' arrow aria-label='add'>
					<Link to='/TipoProfesional' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<WorkIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipo de profesional
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Tipo de residencia' arrow aria-label='add'>
					<Link to='/TipoResidencia' className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<BadgeIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Tipo de residencia
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip> */}
				<Tooltip title='Retorno' arrow aria-label='add'>
					<Link to={'/RetornoView'} className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<ChangeCircleIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Retorno
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
				<Tooltip title='Status pago cliente' arrow aria-label='add'>
					<Link to={STATUS_CLI_VIEW} className='text-link'>
						<AccordionDetails>
							<ListItem button>
								<ListItemIcon>
									<CheckCircleIcon className='icon-link-items'  color='primary' />
								</ListItemIcon>
								<ListItemText>
									<Typography className='text-link-items' color='primary'>
										Status pago
									</Typography>
								</ListItemText>
							</ListItem>
						</AccordionDetails>
					</Link>
				</Tooltip>
			</Accordion>
		</div>
        </ThemeProvider>
    )
}
export default AccesoOperaciones