import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import '../Components/Styles/Styles.css'
// import MUIDataTable from '../Components/Util/MUIDataTable'
import PeopleIcon from '@mui/icons-material/People'
import MUIDataTable from '../Util/MUIDataTable'
import requests from '../Components/AxiosCalls/AxiosCall'
 import Theme from '../Components/Styles/Theme'
import UsuariosAdd from './../Components/Usuarios/UsuariosAdd';
import UsuariosEdit from './../Components/Usuarios/UsuariosEdit'
import UsuariosDelete from './../Components/Usuarios/UsuariosDelete'

const UsuariosView = (props) => {
	const [registros, setRegistros] = useState([])
	const [registroId, setregistroId] = useState()

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	const [open2, setOpen2] = useState(false)
	const handleOpen2 = () => {
		setOpen2(!open2)
	}

	const [open3, setOpen3] = useState(false)
	const handleOpen3 = () => {
		setOpen3(!open3)
	}

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbUsuario/ObtenerListaVM')
			.then((response) => {
				setRegistros(response)
				props.setCloseLoadingScreen()
				setregistroId(null)
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const columns = [
		{
			name: 'intIdUsuario',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtUsuario',
			label: 'Nombre Completo',
			options: {
				columnOrder: true,
				filter: false,
			},
		},
		
		{
			name: 'txtPais',
			label: 'Pais',
			options: {
				filter: false,
			},
		},
		{
			name: 'txtDepartamento',
			label: 'Departamento',
			options: {
				filter: false,
			},
		},
		{
			name: 'txtPlaza',
			label: 'Plaza',
			options: {
				filter: false,
			},
		},
		// {
		// 	name: 'rol',
		// 	label: 'Rol del usuario',
		// 	options: {
		// 		filter: false,
		// 		customBodyRender: (value, tableMeta, updateValue) => {
		// 			if (tableMeta.rowData[6] === 'USUARIO') {
		// 				return <Chip label='Usuario' className='chipR1' />
		// 			} 
		// 			if (tableMeta.rowData[6] === 'JEFE') {
		// 				return <Chip label='Jefe' className='chipR2' />
		// 			}
		// 			if (tableMeta.rowData[6] === 'COMPRAS') {
		// 				return <Chip label='Compras' className='chipR3' />
		// 			}
		// 			if (tableMeta.rowData[6] === 'TESORERIA') {
		// 				return <Chip label='Tesorería' className='chipR4' />
		// 			}if (tableMeta.rowData[6] === 'ADMINISTRADOR') {
		// 				return <Chip label='Administrador' className='chipR5' />
		// 			}
		// 		},
		// 	},
		// },
		{
			name: 'bolActivo',
			label: 'Status',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[6] === true) {
						return (
							<Tooltip title='Desactivar usuario' arrow aria-label='add'>
								<Button
									className='btn-activo'
									aria-label='upload picture'
									onClick={function () {
										setregistroId(tableMeta.rowData[0])
										handleOpen3()
									}}
								>
									<CheckIcon sx={{ mr: 1 }} />
									Activo
								</Button>
							</Tooltip>
						)
					} else {
						return (
							<Tooltip title='Activar usuario' arrow aria-label='add'>
								<Button
									className='btn-inactivo'
									aria-label='upload picture'
									onClick={function () {
										setregistroId(tableMeta.rowData[0])
										handleOpen3()
									}}
								>
									<ClearIcon sx={{ mr: 1 }} />
									Inactivo
								</Button>
							</Tooltip>
						)						
					}
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Editar usuario' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								aria-label='upload picture'
								onClick={function () {
									setregistroId(tableMeta.rowData[0])
									handleOpen2()
								}}
							>
								<EditIcon sx={{ mr: 1 }} />								
							</Button>
						</Tooltip>
					)
				},
			},
		}
	]



	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12}>
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<PeopleIcon />} label='Usuarios' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							{/* <h2>Usuarios</h2> */}
							<Button
								className='btn-Header'
								startIcon={<AddIcon />}
								onClick={function () {
									handleOpen()
								}}
							>
								Nuevo
							</Button>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center' elevation={12}>
					<MUIDataTable
						title={'Lista de usuarios'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
			 <UsuariosAdd
				open={open}
				onClose={handleOpen}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/> 
			 <UsuariosEdit
				registroId={registroId}
				open={open2}
				onClose={handleOpen2}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/> 
		<UsuariosDelete
				registroId={registroId}
				open={open3}
				onClose={handleOpen3}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/> 
		</ThemeProvider>
	)
}
export default UsuariosView