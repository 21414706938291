import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { HexColorPicker } from "react-colorful";
import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid'

import {STATUS_CLI_ADD} from '../../../Constants/apiConstnats'

const StatusPagoAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctStatus, setStatus] = useState({
		//intIdStatusCli: 0,
        txtStatus: ""
	})
	const LimpiarValores = () => {
		setStatus({
			...ctStatus, txtStatus: ''
		})
	}

	const handlectStatus = (event) => {
		setStatus({ ...ctStatus, [event.target.name]: event.target.value })
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		requests
			.post(STATUS_CLI_ADD, ctStatus)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar Status
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							<Grid container justifyContent='center'>
								<Grid container spacing={2}  alignItems='center' justifyContent='center'>
										<TextField
											required
											id='txtStatus'
											name='txtStatus'
											label='Status:'
											variant='standard'
											fullWidth
											sx={{ mt: 2 }}
											value={ctStatus.txtStatus}
											onChange={handlectStatus}
										/>
                                </Grid>								
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default StatusPagoAdd