import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip'
import '../Styles/Styles.css'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ThemeProvider } from '@emotion/react'
import TextField from '@mui/material/TextField'
import { useParams } from 'react-router-dom'

const UpdateContra = (props) => {

    let navigate = useNavigate()
    const { id } = useParams();

    const [mail, setMail] = useState()

    const handleMail = (event) => {
		setMail({ ...mail, mail: event.target.value })
	}

    const sendMail=() =>{
        
        props.setOpenLoadingScreen()
		let url = 'Login/'
		requests
			.put(url+'?contra=' + mail.mail)
			.then((response) => {
                
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
                navigate('/')
			})
			.catch((error) => {
                
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
    }

    return (
        <ThemeProvider theme={Theme}>
            <Grid xs={12} sm={12} md={12} lg={12} style={{height: '900px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Grid style={{backgroundColor: '#c0d9ee', padding: '25px', borderRadius: '10px'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} elevation={12} >
                        <Paper elevation={0} className='title-views'>
                            <Grid
                                Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Chip icon={<LockOpenIcon />} label='Usuario por validar' color='primary'  className='back-transparent font-letter font-barter font-20'/>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} alignItems='center' elevation={12}>
                        <Grid container sx={{ pb: 2, px: 1 }}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id='txtNomCorto'
                                        name='txtNomCorto'
                                        label='Usuario'
                                        variant='standard'
                                        fullWidth
                                        onChange={handleMail}
                                    />
                                </Grid> 
                                <Grid>
                                    <Button
                                        onClick={function() {
                                            sendMail()
                                        }}
                                        className='btn-add-modal'
                                        variant='contained'
                                        startIcon={<AddIcon />}
                                    >
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
)}

export default UpdateContra