import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { HexColorPicker } from "react-colorful";
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip';
import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'

const StatusEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctStatus, setStatus] = useState({
		intIdStatus: props.deptoId,
		txtStatus: '',
		txtColorFondo: "",
		txtColorLetra: "",
	})
	const LimpiarValores = () => {
		setStatus({ ...ctStatus, txtStatus: '',txtColorFondo: "",
		txtColorLetra: "", })
	}

	const handleCtDepto = (event) => {
		setStatus({ ...ctStatus, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getDepto()
	}, [props.deptoId])
	const getDepto = () => {
		if (props.deptoId != null) {
			requests
				.get('CtStatus/ObtenerPorId/' + props.deptoId)
				.then((response) => {
					setStatus(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtStatus/Editar'
		requests
			.put(url, ctStatus)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}
	const handlectStatus = (event) => {
		setStatus({ ...ctStatus, [event.target.name]: event.target.value })
	}

	const handleColorLetra= (color) => {
		
		setStatus({ ...ctStatus, txtColorLetra: color})
	
	  };
	  const handleColorFondo = (color) => {
		
		setStatus({ ...ctStatus, txtColorFondo: color })
	
	  };
	 
	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Actualizar Status
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '500px' }}>
						<DialogContentText id='alert-dialog-description'>
						<Grid container>
								<Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
									<Grid item xs={6}>
										<TextField
											required
											id='txtStatus'
											name='txtStatus'
											label='Status:'
											variant='standard'
											fullWidth
											sx={{ mt: 2 }}
											value={ctStatus.txtStatus}
											onChange={handlectStatus}
										/>
									</Grid>
									<Grid item xs={6}>
										<Chip 
											label={ctStatus.txtStatus} 
											variant="outlined" 
											sx={{ 
												width: '120px',
												height: '30px',
												borderRadius: '30px',
												fontWeight: 'bold', 
												color: ctStatus.txtColorLetra, 
												backgroundColor: ctStatus.txtColorFondo 
											}}  
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2} sx={{ mt: 1 }}>
									<Grid item xs={6}>
										<HexColorPicker
											onChange={handleColorFondo}
											color={ctStatus.txtColorFondo}
											style={{height: '150px', width: '150px'}}
										/>
									</Grid>
									<Grid item xs={6}>
										<HexColorPicker
											color={ctStatus.txtColorLetra}
											onChange={handleColorLetra}
											style={{height: '150px', width: '150px'}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default StatusEdit
