import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import StyleGeneral from '../../Styles/StyleGeneral'

const TipoServicioAdd = (props) => {
	const methods = useForm()
	const classes = StyleGeneral()
	const { handleSubmit } = methods

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}

	const [ctTipoServicio, setctTipoServicio] = useState({
		txtTipoServicio: '',
		txtNomCorto: '',
	})

	const LimpiarValores = () => {
		setctTipoServicio({
			...ctTipoServicio,
			txtTipoServicio: '',
			txtNomCorto: '',
		})
	}

	const handlectStatus = (event) => {
		setctTipoServicio({
			...ctTipoServicio,
			[event.target.name]: event.target.value,
		})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtTipoServicio/Crear'

		requests
			.post(url, ctTipoServicio)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar Tipo De Servicio
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '500px', height: '200px' }}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								id='txtTipoServicio'
								name='txtTipoServicio'
								label='Tipo De Servicio:'
								variant='standard'
								fullWidth
								value={ctTipoServicio.txtTipoServicio}
								onChange={handlectStatus}
								required
							/>
							<TextField
								sx={{ mt: 1 }}
								id='txtNomCorto'
								name='txtNomCorto'
								label='Nombre corto:'
								variant='standard'
								fullWidth
								value={ctTipoServicio.txtNomCorto}
								onChange={handlectStatus}
								required
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default TipoServicioAdd
