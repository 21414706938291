import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'

const ActividadEcoEdit = (props) => {
	//const id = useParams()
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ActEco, setActEco] = useState({
		intIdActividadEco: props.ActEcoId,
		txtActividadEco: ''
	})
	const LimpiarValores = () => {
		setActEco({ ...ActEco, txtActividadEco: ''})
	}

	const handleActEco = (event) => {
		setActEco({ ...ActEco, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getActEco()
	}, [props.ActEcoId])
	const getActEco = () => {
		if (props.ActEcoId != null) {
			requests
				.get('CtActividadEco/ObtenerPorId?id=' + props.ActEcoId)
				.then((response) => {
					setActEco(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtActividadEco/Editar'
		requests
			.put(url, ActEco)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Editar Actividad Económica
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px', height: '180px' }} s>
						<DialogContentText id='alert-dialog-description'>
							<Grid container>
								<TextField
									required
									id='txtActividadEco'
									name='txtActividadEco'
									label='Nombre:'
									variant='standard'
									fullWidth
									value={ActEco.txtActividadEco}
									onChange={handleActEco}
								/>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default ActividadEcoEdit
