import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import DeleteIcon from '@mui/icons-material/Delete'

import requests from '../AxiosCalls/AxiosCall'

import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'

const EmpresasPgDelete = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		requests
			.delete('TbEmpresasPg/Eliminar/' + props.empresaId)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				handleClose()
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				maxWidth={'500px'}
				sx={{ backgroundColor: 'rgba(89,13,34,0.5)' }}
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div
						style={{
							width: '600px',
							height: '350px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DialogTitle
							id='alert-dialog-title'
							className='titleContainerHeaderModal'
						>
							¿Estás seguro de eliminar esta empresa pagadora?
						</DialogTitle>
						<DialogContent>
							<div
								style={{
									backgroundColor: 'rgba(89,13,34,0.2)',
									borderRadius: '50%',
									width: '200px',
									height: '200px',
								}}
							>
								<DeleteIcon
									sx={{ fontSize: '200px', color: 'rgba(89,13,34,0.8)' }}
								/>
							</div>
						</DialogContent>

						<DialogActions>
							<Button type='submit' className='btn-close-modal'>
								Aceptar
							</Button>
							<Button
								onClick={handleClose}
								className='btn-add-modal'
								variant='contained'
							>
								Cancelar
							</Button>
						</DialogActions>
					</div>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default EmpresasPgDelete
