import React, { useEffect } from 'react'
import requests from '../AxiosCalls/AxiosCall'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: false,
			text: 'Pago',
		},
	},
}

const labels = [
	'Janeiro',
	'Fevereiro',
	'Marchar',
	'Abril',
	'Poderia',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
]

const BarChart = (props) => {
	const data = {
		labels,
		datasets: [
			{
				label: 'Pago',
				data: [
					props.datos.enero,
					props.datos.febrero,
					props.datos.marzo,
					props.datos.abril,
					props.datos.mayo,
					props.datos.junio,
					props.datos.julio,
					props.datos.agosto,
					props.datos.septiembre,
					props.datos.octubre,
					props.datos.nomviembre,
					props.datos.diciembre,
				],
				backgroundColor: '#bbdefb',
			},
		],
	}

	return (
		<div>
			<Bar options={options} data={data} />
		</div>
	)
}

export default BarChart
