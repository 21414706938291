import * as React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import '../Styles/Styles.css'
const mdTheme = createTheme()
const MainContainerLayout = ({ children }) => {
	return (
		<ThemeProvider theme={mdTheme}>
			<Box className='containerCanvas'>
				<CssBaseline />
				{children}
			</Box>
		</ThemeProvider>
	)
}
export default MainContainerLayout
