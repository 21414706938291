import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import NumberFormat from 'react-number-format'
import '../../Components/Styles/Styles.css'
import Chip from '@mui/material/Chip'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import MUIDataTable from '../../Util/MUIDataTable'

import ProveedoresOpAdd from '../../Components/ProveedoresOperaciones/ProveedoresOpAdd'
import ProveedoresOpEdit from '../../Components/ProveedoresOperaciones/ProveedoresOpEdit'
import ProveedoresOpDelete from '../../Components/ProveedoresOperaciones/ProbeedoresOpDelete'


import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'

import {PROVEEDORES_OP_LIST} from '../../Constants/apiConstnats'

const ProveedoresOpView = (props) => {
	const [registros, setRegistros] = useState([])
	const [ProveeId, setProveeId] = useState()

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	const [open2, setOpen2] = useState(false)
	const handleOpen2 = () => {
		setOpen2(!open2)
	}

	const [open3, setOpen3] = useState(false)
	const handleOpen3 = () => {
		setOpen3(!open3)
	}

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get(PROVEEDORES_OP_LIST)
			.then((response) => {
				setRegistros(response)
				props.setCloseLoadingScreen()
				setProveeId(null)
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const columns = [
		{
			name: 'lngIdProveedor',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtRazonSocial',
			label: 'Razón social',
			options: {
				columnOrder: true,
				filter: false,
			},
		},
		{
			name: 'txtNombreComercial',
			label: 'Nombre comercial',
			options: {
				filter: false,
			},
		},
		{
			name: 'txtRfc',
			label: 'NIC/Identificación',
			options: {
				filter: false,
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Editar proveedor' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									setProveeId(tableMeta.rowData[0])
									handleOpen2()
								}}
							>
								<EditIcon sx={{ mr: 1 }} />
							</Button>
						</Tooltip>
					)
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Eliminar proveedor' arrow aria-label='add'>
							<Button
								className='font-btn-eliminar'
								onClick={function () {
									setProveeId(tableMeta.rowData[0])
									handleOpen3()
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					)
				},
			},
		},
	]

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12} >
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<ShoppingCartIcon />} label='Proveedores' color='primary'  className='back-transparent font-letter font-barter font-20'/>

							<Button
								className='btn-Header'
								startIcon={<AddIcon />}
								onClick={function () {
									handleOpen()
								}}
								size='small'
							>
								Nuevo
							</Button>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} alignItems='center' elevation={12}>
					<MUIDataTable
						title={'Lista de proveedores'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
			<ProveedoresOpAdd
				open={open}
				onClose={handleOpen}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			<ProveedoresOpEdit
				ProveeId={ProveeId}
				open={open2}
				onClose={handleOpen2}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			<ProveedoresOpDelete
				ProveeId={ProveeId}
				open={open3}
				onClose={handleOpen3}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</ThemeProvider>
	)
}
export default ProveedoresOpView