import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import '../../Components/Styles/Styles.css'
import { Link, useNavigate } from 'react-router-dom'
import MUIDataTable from '../../Util/MUIDataTable'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Chip from '@mui/material/Chip'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import {CHECKLISTPRINT,CHECKLISTVM} from '../../Constants/apiConstnats'
import {CHECKLIST_EDIT, CHECKLIST_PRINT} from '../../Constants/routesConstants'


const ChecKListJuridicoView = (props) => {
	const [registros, setRegistros] = useState([])
	const [deptoId, setDeptoId] = useState()
	let navigate = useNavigate()

	const [open, setOpen] = useState(false)
	const handleOpen = (id) => {
		setDeptoId(id)
		setOpen(!open)
	}

	const handleClose = () => {
		setOpen(!open)
	}

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CHECKLISTVM)
			.then((response) => {
				setRegistros(response)
                //setColumnas(columns)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const getImpresoPDf = async (id) => {
		props.setOpenLoadingScreen()
		let url = requests.Url + 'GenerarPDF/GenerarPdf/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
					props.setCloseLoadingScreen()
				})
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
				console.log(error)
			})
	}

	const columns = [
		{
			name: 'lngIdCheckList',
			label: 'idCheckList',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'cliente',
			label: 'Cliente',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'dblMontoContrato',
			label: 'Valor  do Contrato',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
        {
			name: 'txtEmailFacturas',
			label: 'Email',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtRepresentante',
			label: 'Representante',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'Imprimir',
			label: 'Imprimir',
			options: {
				filter: false,
				sort: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Imprimir' arrow aria-label='add'>
							<Button
								className='font-btn-imp'
								onClick={function () {
									getImpresoPDf(tableMeta.rowData[0])									
								}}
							>
								<LocalPrintshopIcon/>								
							</Button>
						</Tooltip>
					)
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					// if (tableMeta.rowData[7] === 'CANCELADO' || tableMeta.rowData[7] === 'PAGADO' ) {
					// 	return (
					// 		<Tooltip title='Editar departamento' arrow aria-label='add'>
					// 			<Button disabled
					// 				className='chip-Cerrado'
					// 				onClick={function () {
					// 					navigate('/SolicitudCXP/Edit/' + tableMeta.rowData[0])
					// 				}}
					// 			>
					// 				<EditIcon sx={{ mr: 1 }} /> Editar
					// 			</Button>
					// 		</Tooltip>
					// 	)
					// }else{
					return (
						<Tooltip title='Editar check list' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									navigate(CHECKLIST_EDIT + tableMeta.rowData[0])
								}}
							>
								<EditIcon /> 
							</Button>
						</Tooltip>
					)
					//}
				},
			},
		},
		// {
		// 	name: '',
		// 	label: '',
		// 	options: {
		// 		filter: false,
		// 		customBodyRender: (value, tableMeta, updateValue) => {
		// 			if (tableMeta.rowData[7] === 'CANCELADO' || tableMeta.rowData[7] === 'PAGADO' ) {
		// 				return (
		// 					<Tooltip title='Eliminar departamento' arrow aria-label='add'>
		// 						<Button className='chip-Cancelado ' disabled
		// 							onClick={function () {
		// 								handleOpen(tableMeta.rowData[0])
		// 							}}>
		// 							<DeleteIcon sx={{ mr: 1 }} /> Cancelar
		// 						</Button>
		// 					</Tooltip>
		// 				)
		// 			}else{

		// 				return (
		// 					<Tooltip title='Eliminar departamento' arrow aria-label='add'>
		// 						<Button className='chip-Cancelado '
		// 							onClick={function () {
		// 								handleOpen(tableMeta.rowData[0])
		// 							}}>
		// 							<DeleteIcon sx={{ mr: 1 }} /> Cancelar
		// 						</Button>
		// 					</Tooltip>
		// 				)
		// 			}

		// 		},
		// 	},
		// },
	]

    

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={10} md={10} lg={12} >
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<PlaylistAddCheckIcon />} label='Check List' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							<Link
								to='New'
								className='text-link'
								style={{ textDecoration: 'none' }}
							>
								<Button className='btn-Header' startIcon={<AddIcon />} size='small'>
									Novo
								</Button>
							</Link>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} alignItems='center'>
					<MUIDataTable
						title={'Listado de check list jurídico'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default ChecKListJuridicoView