
import { makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';
const StyleGeneral = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: "90vh",
  },
  paper: {
    padding: theme.spacing(1),
    background: "#F5F5F5",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "auto",
    border: "5px",
  },
  fixedHeight: {
    height: "100%",
    marginTop: "1rem",
  },
  btn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "18ch",
    marginBlock: 10,
    height: "3.5rem",
  },
  fab: {
    width: "3rem",
    height: "3rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "15rem",
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20rem",
  },
  textField3: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "30rem",
  },
  textField4: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40rem",
  },
  card: {
    backgroundColor: "#FFFFFF",
    width: 250,
    height: 300,
    textAlign: "center",
    borderBottomColor: "#FFFFFF",
  },
  cardcontent: {
    textAlign: "center",
  },
  cardAction: {
    textAlign: "center",
  },
  btnprimary: {
    backgroundColor: "#b7e4c7",
    borderRadius: "20px;",
    padding: "5px 20px 5px 15px",
    /* arriba derecha abajo izquierda*/
  },
  customdivider: {
    border: "2px solid rgba(183,228,199,0.3)",
    borderRadius: "5px",
  },
  sizeViews: {
    padding: "30px 30px 30px 30px",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    height: "600px",
    overflow: "auto",
    "@media(maxWidth: 600px)": {
      backgroundColor: "blue",
    },
  },
}));

  export default StyleGeneral