import * as React from 'react'
import { createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

function Copyright(props) {
	return (
		<Typography
			variant='body2'
			color='text.secondary'
			align='center'
			{...props}
		>
			{'Copyright © '}
			<Link color='inherit' href='#'>
				SysTrack Brasil
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}
const mdTheme = createTheme()
const MainContentLayout = ({ children }) => {
	return (
		<>
			<Grid
				container
				sx={{ mt: 6, pt: 2, pl: 3, pr: 3 }}
				direction='row'
				justifyContent='center'
				alignItems='flex-start'
			>
				<CssBaseline />

				{children}
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					<Copyright sx={{ pt: 1, backgroupColor: 'red' }} />
				</Grid>
			</Grid>
		</>
	)
}
export default MainContentLayout
