import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Theme from '../Styles/Theme'
import LogoInicio from '../../Img/systrackcecBr.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react'
import RecContra from '../Login/RecContra'
import { styled } from '@mui/material/styles'
import '../Styles/StyleLogin.css'
import '../Styles/StyleMain.css'
import AuthApi from '../../Util/LoginServices';

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},

	'& .MuiInputBase-input': {
		fontSize: window.screen.width <= 600 ? 13 : 18,
		// padding: '5px 7px 13px',
		padding: window.screen.width <= 600 ? '15px 7px 3px' : null,
	},

	'& label.Mui-focused': {
		color: '#545454',
		fontWeight: '600',
	},
	'& label': {
		color: '#545454',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 13 : 18,
	},
	'& .MuiFilledInput-root': {
		border: 0,
		overflow: 'hidden',
		borderRadius: 5,
		backgroundColor: '#0000000f',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),

		'&:hover': {
			// backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: '#0000000f',
			boxShadow: 0,
			borderColor: '#0000000f',
		},
	},
	'& .MuiFilledInput-root.Mui-disabled': {
		color: '#00000099',
		backgroundColor: '#f0f0f0',
	},
	'& label.Mui-disabled': {
		color: '#00000099',
		letterSpacing: '1.5px',
		fontWeight: '400',
	},
}))
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        SysTrack Brasil
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login2(props) {

  const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

  const formik = useFormik({      
    initialValues:{
        Email: '',
        txtPassword: ''
    },
    validationSchema: Yup.object({
        Email: Yup.string()
        .required('El usuario es requerido'),
        txtPassword: Yup.string()
        .required('La contraseña es requerido'),
    }), 
    onSubmit: values => {
        props.setOpenLoadingScreen()
        AuthApi.logIn(values)
        .then(response => 
          
            {
              sessionStorage.setItem('TokenSYS', JSON.stringify(response.access_Token));
              sessionStorage.setItem('TokenSYSUser', JSON.stringify(response));
              sessionStorage.setItem('acceso', JSON.stringify(response.area));
              sessionStorage.setItem('Id', JSON.stringify(response.id));
            window.location.reload();
            props.setCloseLoadingScreen()
        }).catch(e => {
            if(e.message === "Network error" && !e.response){
                Swal.fire({
                    icon: 'error',
                    title: 'Ups...',
                    text: "Error de red",
                })
            }else if(e.response.status===400){
                Swal.fire({
                    icon: 'error',
                    title: 'Ups...',
                    text: e.response.data.Error,
                })
            }
			props.setCloseLoadingScreen()
        });
    },
});

  return (
    <div className='ContainerLogin'>
      <div className='CardLogin animated fadeIn '>
        <div className='section1Login'>
          <img src={LogoInicio} />
        </div>
        <div className='section2Login'>
          <div className='formLogin'>
            <h2 className='TitleLogin'>Iniciar Sesión</h2>
            <img src={LogoInicio} />
            <Box
              component='form'
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1 }}
            >
              <div className='mt1'>
                <RedditTextField
                  variant='filled'
                  required
                  focused
                  fullWidth
                  name='Email'
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  label='Usuario'
                  id='Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Email}
                  error={
                    formik.touched.Email && Boolean(formik.errors.Email)
                  }
                  helperText={formik.touched.Email && formik.errors.Email}
                />
              </div>
              <div className='mt1'>
                <RedditTextField
                  variant='filled'
                  required
                  focused
                  fullWidth
                  name='txtPassword'
                  label='Contraseña'
                  type='password'
                  id='txtPassword'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.txtPassword}
                  error={
                    formik.touched.txtPassword &&
                    Boolean(formik.errors.txtPassword)
                  }
                  helperText={
                    formik.touched.txtPassword && formik.errors.txtPassword
                  }
                />
              </div>
              <Button
                className='btn-login mt2'
                type='submit'
                fullWidth
                variant='contained'
              >
                Acceder
              </Button>
            </Box>
          </div>
          <Grid>
              <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                <Button
                className='btn'
                aria-label='upload picture'
                onClick={function () {
                  handleOpen()
                }}
                >¿Olvidaste tu contraseña?</Button>
              </Grid>
          </Grid>
          <div className='Copyright'>
            <p>
              Copyright ©{' '}
              <Link color='inherit' href='#'>
                SysTrack México
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </p>
          </div>
        </div>
      </div>
      <RecContra
        open={open}
        onClose={handleOpen}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      /> 
    </div>
  );
}