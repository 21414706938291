import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate,useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import AsyncSelect from 'react-select/async'
import Chip from '@mui/material/Chip'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

import requests from '../AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import '../Styles/Styles.css'

const ChecKListJuridicoEdit = (props) => {
    const { id } = useParams()
	const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()

	const [_Check, setCheck] = useState({
		lngIdCheckList: '',
		lngIdCliente: '',
		intIdPais: '',
		txtConcepto: '',
		dblMontoContrato: '',
		dblIva: '',
		bolActaConstitucion: "",
		bolPoderRepresentante: '',
		bolIdentificacionOficial: '',
		bolRucNit: '',
		bolComprobanteDomicilio: '',
		bolEmail: '',
		bolDatosBancarios: '',
		bolIdentificacionOficialNat: '',
		bolRegistroCliente: '',
		bolNit: '',
		bolCasamento: '',
		bolComprobanteDomicilioNat: '',
		bolEmailNat: '',
		bolDatosBancariosNat: '',
		bolInforme: '',
		bolManual: '',
		bolOtros: '',
		txtGiro: '',
		txtComision: "",
		dblComisionPactada: '',
		fecInicioOp: "2023-01-01",
		txtPeriodicidad:'',
		dblMontoOperar: "",
		txtTipoEntorno: "",
		numTerminales: "",
		txtDomicilioEntTpv: "",
		dblPagoGarantiaTpv: "",
		txtTelefono: "",
		txtEmailFacturas: "",
		txtContactoCliente: "",
		txtRegistro: "",
		txtComentarios: "",
		txtRepresentanteLegal: "",
		intIdEmpresaPg: '',
		txtEmpresa: '',
		intIdStatusJuri: ''
	})

	const [_Cliente, setCliente] = useState([])
	const getListCliente = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbCliente/ObtenerLista')
			.then((response) => {
				setCliente(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [selectedOption, setSelectedOption] = useState([])

	const loadOptions = async (inputValue, callback) => {
	  await requests
	    .get('TbCliente/ObtenerListaParam?Search=' + inputValue)
	    .then((response) => {
	      callback(
	        response.map((i) => ({ label: i.txtCliente, value: i.intIdCliente }))
	      )
	    })
	    .catch((error) => {
	      console.log('Error: ' + error)
	    })
	}
	const onChangeSelectedOption = (e) => {
		setSelectedOption(e);
	
		setCheck({..._Check, intIdCliente: e.value}) ;

	}

	const handleCheck = (event) => {
		setCheck({ ..._Check, [event.target.name]: event.target.value })
	}

	const [_ListaPlaza, setListaPlaza] = useState([])
	const getListaPlaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListaPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaEmpresaPg, setListaEmpresaPg] = useState([])
	const getListaEmpresaPg = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbEmpresasPg/ObtenerLista')
			.then((response) => {
				setListaEmpresaPg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getListaEmpresaPg()
		getListaPlaza()
		getListCliente()
        getRegistro()
	}, [])

    const getRegistro = () => {
        props.setOpenLoadingScreen()
        requests
            .get('TbCheckListJuri/ObtenerPorId/' + id)
            .then((response) => {
                AsignarValores(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const AsignarValores = (objecto) => {
        setCheck({
            ..._Check,
		txtNumContrato: objecto === null ? '' : objecto.txtNumContrato,
		fecCheckList: objecto === null ? '' : objecto.fecCheckList,
        lngIdCheckList: objecto === null ? '' : objecto.lngIdCheckList,
		lngIdCliente: objecto === null ? '' : objecto.lngIdCliente,
		intIdPais: objecto === null ? '' : objecto.intIdPais,
		txtRepresentanteLegal: objecto === null ? '' : objecto.txtRepresentanteLegal,
		txtConcepto: objecto === null ? '' : objecto.txtConcepto,
		dblMontoContrato: objecto === null ? '' : objecto.dblMontoContrato,
		dblIva: objecto === null ? '' : objecto.dblIva,
		bolActaConstitucion: objecto === null ? '' : objecto.bolActaConstitucion,
		bolPoderRepresentante: objecto === null ? '' : objecto.bolPoderRepresentante,
		bolIdentificacionOficial: objecto === null ? '' : objecto.bolIdentificacionOficial,
		bolRucNit: objecto === null ? '' : objecto.bolRucNit,
		bolComprobanteDomicilio: objecto === null ? '' : objecto.bolComprobanteDomicilio,
		bolEmail: objecto === null ? '' : objecto.bolEmail,
		bolDatosBancarios: objecto === null ? '' : objecto.bolDatosBancarios,
		bolIdentificacionOficialNat: objecto === null ? '' : objecto.bolIdentificacionOficialNat,
		bolRegistroCliente: objecto === null ? '' : objecto.bolRegistroCliente,
		bolNit: objecto === null ? '' : objecto.bolNit,
		bolCasamento: objecto === null ? '' : objecto.bolCasamento,
		bolComprobanteDomicilioNat: objecto === null ? '' : objecto.bolComprobanteDomicilioNat,
		bolEmailNat: objecto === null ? '' : objecto.bolEmailNat,
		bolDatosBancariosNat: objecto === null ? '' : objecto.bolDatosBancariosNat,
		bolInforme: objecto === null ? '' : objecto.bolInforme,
		bolManual: objecto === null ? '' : objecto.bolManual,
		bolOtros: objecto === null ? '' : objecto.bolOtros,
		txtComentarios: objecto === null ? '' : objecto.txtComentarios,
		txtContrato: objecto === null ? '' : objecto.txtContrato,
		bolActivo: objecto === null ? '' : objecto.bolActivo,
		intIdEmpresaPg: objecto === null ? '' : objecto.intIdEmpresaPg,
		txtEmpresa: objecto === null ? '' : objecto.txtEmpresa,
		txtGiro: objecto === null ? '' : objecto.txtGiro,
		txtComision: objecto === null ? '' : objecto.txtComision,
		dblComisionPactada: objecto === null ? '' : objecto.dblComisionPactada,
		fecInicioOperacion: objecto === null ? '' : objecto.fecInicioOperacion,
		txtPeriodicidad: objecto === null ? '' : objecto.txtPeriodicidad,
		dblMontoOperar: objecto === null ? '' : objecto.dblMontoOperar,
		txtTipoEntorno: objecto === null ? '' : objecto.txtTipoEntorno,
		numTerminales: objecto === null ? '' : objecto.numTerminales,
		txtDomicilioEntTpv: objecto === null ? '' : objecto.txtDomicilioEntTpv,
		dblPagoGarantiaTpv: objecto === null ? '' : objecto.dblPagoGarantiaTpv,
		txtContactoCliente: objecto === null ? '' : objecto.txtContactoCliente,
		txtTelefono: objecto === null ? '' : objecto.txtTelefono,
		txtEmailFacturas: objecto === null ? '' : objecto.txtEmailFacturas,
		txtRegistro: objecto === null ? '' : objecto.txtRegistro
        })        
        requests.get('TbCliente/ObtenerPorId/' + objecto.lngIdCliente)
        .then((response) => {
            let setdata = {
                label: response.txtNombre + ' ' +response.txtApellido,
                value: response.intIdCliente
              }
              setSelectedOption(setdata)
        })
    }

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbCheckListJuri/Editar'
		requests
			.put(url, _Check)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				navigate('/CheckList')
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	///Estados Radio Buttom

	const handleboleans = (event) => {
		if(event.target.value === "true"){
			setCheck({ ..._Check, [event.target.name] : true })
		}else if(event.target.value === "false"){
			setCheck({ ..._Check, [event.target.name] : false })
		}
		console.log(_Check)
    }


	//Fin Estados Radio Buttom

    const handleFecOperacion = (date) => {
        setCheck({ ..._Check, fecInicioOperacion: date })
    }

	return (
		<ThemeProvider theme={Theme}>
			{/* <Paper className='containerViews3'> */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						elevation={12}
						sx={{ py: 2 }}
					>
						<Paper
							elevation={0}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div className='containerAlignHeader'>
								<Link to='/CheckList' style={{ textDecoration: 'none' }}>
									<Button
										size='small'
										className='btn-Header-back '
										startIcon={<ArrowBackIosNewSharpIcon />}
									>
										Voltar
									</Button>
								</Link>

								<Chip icon={<PlaylistAddCheckIcon />} label='Check List / Editar' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							</div>
							<div className='containerAlignHeader'>
								<div className='container-check'>
									<div style={{ display: 'flex' }}>
										<h4 className='title-solicitud'>Registro: </h4>
										<h4 className='subtitle-name'>
											{_Check.txtContrato}
										</h4>
									</div>
								{/* <div className='container-folio'>
										<h4 className='title-solicitud'>Folio: </h4>
										<h4 className='subtitle-name'>{}</h4>
									</div>  */}
								</div>
							</div>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						lg={12}
						direction='row'
						justifyContent='center'
						alignItems='center'
					>
						{/* start */}
						<Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
							<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
								<Paper elevation={0}>
									{/*start */}

									<Grid container sx={{ pb: 2, px: 1 }}>
										<Grid container spacing={2} alignItems='center'>
											<Grid item xs={4}>
												<FormControl required variant='standard' size='small' fullWidth>
													<InputLabel id='lngIdCliente'>Cliente</InputLabel>
													<Select
														required
														value={_Check.lngIdCliente}
														onChange={handleCheck}
														label='lngIdCliente'
														inputProps={{
															name: 'lngIdCliente',
															id: 'lngIdCliente',
														}}
														size='small'
													>
														<MenuItem aria-label='None' value='' />
														{_Cliente.map((object, index) => (
															<MenuItem value={object.lngIdCliente}>
																{object.txtNombre}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<FormControl required variant='standard' size='small' fullWidth>
													<InputLabel id='intIdPais'>Local/Praça</InputLabel>
													<Select
														required
														value={_Check.intIdPais}
														onChange={handleCheck}
														label='intIdPais'
														inputProps={{
															name: 'intIdPais',
															id: 'intIdPais',
														}}
														size='small'
													>
														<MenuItem aria-label='None' value='' />
														{_ListaPlaza.map((object, index) => (
															<MenuItem value={object.intIdPlaza}>
																{object.txtPlaza}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<TextField
													//required
													id='txtRepresentanteLegal'
													name='txtRepresentanteLegal'
													label='Representante Legal'
													variant='standard'
													fullWidth
													value={_Check.txtRepresentanteLegal}
													onChange={handleCheck}
													size='small'
													//disabled='true'
												/>
											</Grid>
										</Grid>

										{/*end */}
										{/*start */}
										<Grid container alignItems='center' spacing={2}>
											<Grid item xs={4}>
												<TextField
													//required
													id='txtConcepto'
													name='txtConcepto'
													label='Tipo de Operação'
													variant='standard'
													fullWidth
													value={_Check.txtConcepto}
													onChange={handleCheck}
													size='small'
													//disabled='true'
												/>

											</Grid>

											<Grid item xs={4}>
												<NumberFormat
													label='Valor do contrato'
													variant='standard'
													value={_Check.dblMontoContrato}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													onValueChange={(values) => {
														setCheck({
															..._Check,
															dblMontoContrato: values.floatValue,
														})
													}}
													size='small'
													name='dblMontoContrato'
												/>
											</Grid>
											<Grid item xs={4}>
												<NumberFormat
													label='Valor Com Impostos'
													variant='standard'
													value={_Check.dblIva}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													onValueChange={(values) => {
														setCheck({
															..._Check,
															dblIva: values.floatValue,
														})
													}}
													name='dblIva'
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container>
										<Grid container>
											<Grid item xs={12} className=''>
												<p className='tab-p text-center'>INFORMAÇÕES GERAIS</p>
											</Grid>
										</Grid>
									</Grid>
									{/*  */}
									<Grid container sx={{ pb: 2 }}>
										<Grid container spacing={1}>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>SL</p>
											</Grid>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>AP</p>
											</Grid>
											<Grid item xs={10} className='tab-header'>
												<p className='tab-p text-center'>PESSOA JURÍDICA</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolActaConstitucion'
													value={_Check.bolActaConstitucion}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>Contrato Social e última alteração contratual</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolPoderRepresentante'
													value={_Check.bolPoderRepresentante}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>CNPJ atualizado</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolIdentificacionOficial'
													value={_Check.bolIdentificacionOficial}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
												Inscrição Estadual e Municipal
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolRucNit'
													value={_Check.bolRucNit}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													RG/CPF dos Representates legais
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolComprobanteDomicilio'
													value={_Check.bolComprobanteDomicilio}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Cadastro da empresa e de todos os sócios e cônjuges 			
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolNit'
													value={_Check.bolNit}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Certidão de Casamento dos Sócios			
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolEmail'
													value={_Check.bolEmail}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Conprovante de endereço e e-mail de contato
												</p>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolDatosBancarios'
													value={_Check.bolDatosBancarios}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Dados bancários
												</p>
											</Grid>
										</Grid>

										{/* Persona Natural */}
										<Grid container spacing={1} sx={{ mt: 2 }}>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>SL</p>
											</Grid>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>AP</p>
											</Grid>
											<Grid item xs={10} className='tab-header'>
												<p className='tab-p text-center'>PESSOA FÍSICA</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolIdentificacionOficialNat'
													value={_Check.bolIdentificacionOficialNat}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													RG/CPF			
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolRegistroCliente'
													value={_Check.bolRegistroCliente}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>Cadastro do cliente e cônjuge</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolCasamento'
													value={_Check.bolCasamento}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Certidão de Casamento			 
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolComprobanteDomicilioNat'
													value={_Check.bolComprobanteDomicilioNat}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Comprovante de endereço			
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolEmailNat'
													value={_Check.bolEmailNat}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													E-mail de Contato			
												</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolDatosBancariosNat'
													value={_Check.bolDatosBancariosNat}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>
													Dados Bancários
												</p>
											</Grid>
										</Grid>

										{/* Entregables */}

										<Grid container spacing={1} sx={{ mt: 2 }}>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>SL</p>
											</Grid>
											<Grid item xs={1} className='tab-header'>
												<p className='tab-p text-center'>AP</p>
											</Grid>
											<Grid item xs={10} className='tab-header'>
												<p className='tab-p text-center'>ENTREGA</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolInforme'
													value={_Check.bolInforme}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>Relatório</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolManual'
													value={_Check.bolManual}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>Manual</p>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={2} className='tab-items '>
												<RadioGroup
													aria-labelledby='demo-controlled-radio-buttons-group'
													name='bolOtros'
													value={_Check.bolOtros}
													onChange={handleboleans}
												>
													<div className='flex pl1'>
														<div className='w-50 flex'>
															<FormControlLabel
																value={true}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>

														<div className='w-50 flex'>
															<FormControlLabel
																value={false}
																control={<Radio size='small'/>}
																label=''
															/>
														</div>
													</div>
												</RadioGroup>
											</Grid>

											<Grid item xs={10} className='tab-items'>
												<p className='tab-p'>Outros</p>
											</Grid>
										</Grid>
									</Grid>
									<Grid container>
										<Grid container>
											<Grid item xs={12} className=''>
												<p className='tab-p text-center'>INFORMAÇÕES GERAIS</p>
											</Grid>
										</Grid>
									</Grid>
									<Grid container sx={{ pb: 2 }} alignItems='center'>
										<Grid container spacing={1} alignItems='center'>
											<Grid item xs={4}>
												<TextField
													required
													fullWidth
													id='txtEmpresa'
                                                    name='txtEmpresa'
													variant='standard'
													label='Empresa'
													size='small'
													value={_Check.txtEmpresa}
                                                    onChange={handleCheck}
												/>
												{/* <FormControl required variant='standard' size='small' fullWidth>
													<InputLabel id='intIdEmpresaPg'>Empresa</InputLabel>
													<Select
														required
														value={_Check.intIdEmpresaPg}
														onChange={handleCheck}
														label='intIdEmpresaPg'
														inputProps={{
															name: 'intIdEmpresaPg',
															id: 'intIdEmpresaPg',
														}}
														size='small'
													>
														<MenuItem aria-label='None' value='' />
														{_ListaEmpresaPg.map((object, index) => (
															<MenuItem value={object.intIdEmpresaPg}>
																{object.txtEmpresaPg}
															</MenuItem>
														))}
													</Select>
												</FormControl> */}
											</Grid>
											<Grid item xs={4}>
												<TextField
													required
													fullWidth
													id='txtGiro'
                                                    name='txtGiro'
													variant='standard'
													label='Giro'
													size='small'
													value={_Check.txtGiro}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item xs={4}>
												<NumberFormat
													label='Comissão pactuada'
													variant='standard'
													value={_Check.dblComisionPactada}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													suffix={'%'}
													fullWidth
													onValueChange={(values) => {
														setCheck({
															..._Check,
															dblComisionPactada: values.floatValue,
														})
													}}
													size='small'
													name='dblComisionPactada'
												/>
											</Grid>
											
											<Grid item xs={4}>
												<LocalizationProvider
                                                        size='small'
                                                        dateAdapter={AdapterDateFns}                                                      
                                                    >
                                                        <DatePicker                                                            
                                                            name='FecInicioOp'
                                                            label='Data de Início da Operação'
                                                            inputFormat='dd/MM/yyyy'
                                                            value={_Check.fecInicioOperacion}
                                                            onChange={handleFecOperacion}
                                                            renderInput={(params) => (
                                                                <TextField 
																	label="Fecha Inicio Operació"                                                                   
                                                                    variant='standard'
                                                                    fullWidth
                                                                    size='small'
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
											</Grid>
											<Grid item xs={4}>
												<TextField
													fullWidth
													id='txtPeriodicidad'
                                                    name='txtPeriodicidad'
													variant='standard'
													label='Periodicidade'
													size='small'
													value={_Check.txtPeriodicidad}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item xs={4} lg={4}>
												<NumberFormat
                                                        label='Montante da Operação'
                                                        variant='standard'
                                                        value={_Check.dblMontoOperar}
                                                        customInput={TextField}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'$'}
                                                        fullWidth
                                                        onValueChange={(values) => {
                                                            setCheck({
                                                                ..._Check,
                                                                dblMontoOperar: values.floatValue,
                                                            })
                                                        }}
                                                        size='small'
                                                        name='dblMontoOperar'
                                                    />
											</Grid>
											<Grid item lg={4} xs={4}>
												<TextField
													fullWidth
													id='txtTipoEntorno'
                                                    name='txtTipoEntorno'
													variant='standard'
													label='Tipo de Retorno'
													size='small'
													value={_Check.txtTipoEntorno}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item lg={2} xs={2}>
												<TextField
													fullWidth
													id='numTerminales'
                                                    name='numTerminales'
													variant='standard'
													label='Números de terminais a ocupar'
													size='small'
													type='number'
													value={_Check.numTerminales}
                                                    onChange={handleCheck}
												/>
											</Grid>
											
											<Grid item lg={4} xs={4}>
												<TextField
													fullWidth
													id='txtDomicilioEntTpv'
                                                    name='txtDomicilioEntTpv'
													variant='standard'
													label='Domicílio de entrega da TPV'
													size='small'
													value={_Check.txtDomicilioEntTpv}
                                                    onChange={handleCheck}
												/>
											</Grid>
											
											<Grid item lg={2}xs={2}>
												<NumberFormat
                                                        label='Pagamento da garantia da TPV'
                                                        variant='standard'
                                                        value={_Check.dblPagoGarantiaTpv}
                                                        customInput={TextField}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'$'}
                                                        fullWidth
                                                        onValueChange={(values) => {
                                                            setCheck({
                                                                ..._Check,
                                                                dblPagoGarantiaTpv: values.floatValue,
                                                            })
                                                        }}
                                                        size='small'
                                                        name='dblPagoGarantiaTpv'
                                                    />
											</Grid>
											<Grid item lg={4} xs={4}>
												<TextField
													fullWidth
													id='txtContactoCliente'
                                                    name='txtContactoCliente'
													variant='standard'
													label='Contato do cliente'
													size='small'
													value={_Check.txtContactoCliente}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item lg={2} xs={2}>
												<TextField
													fullWidth
													id='txtTelefono'
                                                    name='txtTelefono'
													variant='standard'
													label='Telefone do escritório'
													size='small'
													type='number'
													value={_Check.txtTelefono}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item lg={4} xs={4}>
												<TextField
													fullWidth
													id='txtEmailFacturas'
                                                    name='txtEmailFacturas'
													variant='standard'
													label='E-mail de contato e envio de faturas'
													size='small'
													type='email'
													value={_Check.txtEmailFacturas}
                                                    onChange={handleCheck}
												/>
											</Grid>
{/* 											
											<Grid item lg={3}>
												<TextField
													fullWidth
													id='txtRegistro'
                                                    name='txtRegistro'
													variant='standard'
													label='Registro'
													size='small'
													value={_Check.txtRegistro}
                                                    onChange={handleCheck}
												/>
											</Grid>
											<Grid item lg={3}>
												<TextField
													fullWidth
													id='txtNumContrato'
                                                    name='txtNumContrato'
													variant='standard'
													label='Contrato'
													size='small'
													value={_Check.txtNumContrato}
                                                    onChange={handleCheck}
												/>
											</Grid>

											<Grid item lg={6}>
												<TextField
													fullWidth
													id='txtComentarios'
                                                    name='txtComentarios'
													variant='standard'
													label='Comentarios'
													size='small'
													value={_Check.txtComentarios}
                                                    onChange={handleCheck}
												/>
											</Grid> */}
										</Grid>
									</Grid>

									<Grid sx={{ marginTop: '30px' }}>
										<Button
											type='submit'
											className='btn-footer'
											startIcon={<SaveIcon />}
											size='small'
										>
											Guardar
										</Button>
									</Grid>
								</Paper>
							</form>
						</Paper>
					</Grid>
				</Grid>
			{/* </Paper> */}
		</ThemeProvider>
	)
}
export default ChecKListJuridicoEdit