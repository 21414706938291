import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'

const TipoResidenciaAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctTipoResidencia, setCtTipoResidencia] = useState({
		//intIdTipoResidencia: 0,
        txtTipoResidencia: "",
        bolActivo: true
	})
	const LimpiarValores = () => {
		setCtTipoResidencia({
			...ctTipoResidencia,
			txtTipoResidencia: "",
            bolActivo: ""
		})
	}

	const handleCtTipoResidencia = (event) => {
		setCtTipoResidencia({ ...ctTipoResidencia, [event.target.name]: event.target.value })
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtTipoResidecium/Crear'
		requests
			.post(url, ctTipoResidencia)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar Tipo de Residencia
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '500px' }}>
						<DialogContentText id='alert-dialog-description'>
							<Grid container>
								<TextField
									required
									id='txtTipoResidencia'
									name='txtTipoResidencia'
									label='Tipo de residencia:'
									variant='standard'
									fullWidth
									value={ctTipoResidencia.txtTipoResidencia}
									onChange={handleCtTipoResidencia}
								/>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default TipoResidenciaAdd
