import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import SaveIcon from '@mui/icons-material/Save'

import { REG_FLUJO_EDIT_RANGE, PROYECTO_SYSTRACK_BY_ID, REG_FLUJO_LIST_BY_ID_REGISTRO } from '../../Constants/apiConstnats'
import {OPERACIONES_UPDATE} from '../../Constants/routesConstants'

function Row({ onChange, onRemove, lngIdRegistro, lngIdFlujo, txtRegistro }) {
    return (
        <>
            <TableRow>
                <TableCell align='center'>{lngIdFlujo}</TableCell>
                <TableCell align='center'>{txtRegistro}</TableCell>
                <TableCell align='center'>
                    <Tooltip title="Eliminar flujo" arrow aria-label="add">
                        <Button onClick={onRemove} className='font-btn-eliminar'>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    )
}

const AsociarOperacionEdit = (props) => {
    const methods = useForm()
    const { handleSubmit } = methods
    const navigate = useNavigate();
    const [_Ocultar, setOcultar] = useState(true)
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        props.onClose()        
        // props.getRegistro()
        props.getOperacion()
        LimpiarValores()
        window.location.reload();
        //navigate(OPERACIONES_UPDATE + props.registroId)
    }
    const [_RegFlujo, setRegFlujo] = useState({
        //lngIdRegFlujo: 0,
        lngIdRegistro: props.registroId,
        lngIdFlujo: "",
        txtFlujo: ""
    })
    const [_Proyecto, setProyecto] = useState({
        id: '',
    })
    const LimpiarValores = () => {
        setProyecto({ ..._Proyecto, id: '' })
        setRegFlujo({ ..._RegFlujo, lngIdRegistro: "", lngIdFlujo: "", txtFlujo: "" })
        setFlujo([])
        setOcultar(true)
        setRows([])
    }

    const handleProyecto = (event) => {
        setProyecto({ ..._Proyecto, [event.target.name]: event.target.value })
    }
    const [_ArrayRegFlujo, setArrayRegFlujo] = useState([]);
    const guardar = () => {
        props.setOpenLoadingScreen()
        if (rows.length > 0) {
            for (var i = 0; i < rows.length; i++) {
                let reg = rows[i];
                let Info = {
                    lngIdRegFlujo: reg.lngIdRegFlujo,
                    lngIdRegistro: props.registroId,
                    lngIdFlujo: reg.lngIdFlujo,
                    txtFlujo: reg.txtRegistro
                }
                console.log(Info)
                _ArrayRegFlujo.push(Info);
            }
        }
        requests
            .put(REG_FLUJO_EDIT_RANGE, _ArrayRegFlujo)
            .then((response) => {
                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()               
                handleClose()                
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
    }

    const [_Flujo, setFlujo] = useState([])
    const buscar = () => {
        props.setOpenLoadingScreen()
        requests
            .get(PROYECTO_SYSTRACK_BY_ID, _Proyecto.id)
            .then((response) => {
                setFlujo(response)
                setOcultar(false)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    //__-=-__-=-__-=-__-=-__-=-__-=-__ AGREGAR RESULTADO DE BUSQUEDA __-=-__-=-__-=-__-=-__-=-__-=-__

    const defaultState = {
        lngIdRegistro: "",
        lngIdFlujo: "",
        txtRegistro: ""
    }

    const [rows, setRows] = useState([]);

    const handleOnChange = (index, name, value) => {
        const copyRows = [...rows];
        copyRows[index] = {
            ...copyRows[index],
            [name]: value
        };
        setRows(copyRows);
    };

    const handleOnAdd = () => {
        setRows(rows.concat(defaultState));
        console.log(rows)
    };
    const handleOnRemove = (index) => {
        const copyRows = [...rows];
        copyRows.splice(index, 1);
        setRows(copyRows);
    };

    useEffect(() => {
        getRegFlujo();
    }, [props.registroId])
    

    const getRegFlujo = () => {
        props.setOpenLoadingScreen()
        if(props.registroId !== null){
            requests
            .get(REG_FLUJO_LIST_BY_ID_REGISTRO, props.registroId)
            .then((response) => {
                AsignarRegistros(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
        }
    }

    const AsignarRegistros = (objeto) => {
        objeto.map((object, index) => {
          let Info = {
            lngIdRegFlujo: object.lngIdRegFlujo,
            lngIdRegistro: object.lngIdRegistro,
            lngIdFlujo: object.lngIdFlujo,
            txtRegistro: object.txtFlujo   
          }
          //setProducto({ ..._Producto, lngIdProducto: object.lngIdProducto })
          rows.push(Info);
          handleOnChange(index,"lngIdRegFlujo", object.lngIdRegFlujo);
    
        });    
    }

    return (
        <ThemeProvider theme={Theme}>
            <Dialog
                open={props.open}
                onClose={props.onClose ? props.onClose : null}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <Dialog
                    open={props.open}
                    onClose={props.onClose ? props.onClose : null}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                        <div className='containerHeaderModal'>
                            <DialogTitle
                                id='alert-dialog-title'
                                className='containerHeaderModal'
                            >
                                Asociar operación {props.registroId}
                            </DialogTitle>
                            <div>
                                <Button onClick={handleClose}>
                                    <CloseIcon
                                        fontSize='large'
                                        sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
                                    />
                                </Button>
                            </div>
                        </div>
                        <DialogContent sx={{ width: '500px' }}>
                            <DialogContentText id='alert-dialog-description'>
                                <Grid container >
                                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id='id'
                                                name='id'
                                                label='No. de proyecto:'
                                                variant='standard'
                                                fullWidth
                                                //sx={{ mt: 1 }}
                                                value={_Proyecto.id}
                                                onChange={handleProyecto}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                className='btn-add-modal2'
                                                variant='contained'
                                                //type='submit'
                                                startIcon={<SearchIcon />}
                                                onClick={function () {
                                                    buscar()
                                                }}
                                            >
                                                Buscar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TableContainer hidden={_Ocultar}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center'>No. de flujo </TableCell>
                                                            <TableCell align='center'>Folio</TableCell>
                                                            <TableCell align='center'>Seleccionar</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align='center'>{_Flujo.lngIdFlujo}</TableCell>
                                                            <TableCell align='center'>{_Flujo.txtRegistro}</TableCell>
                                                            <TableCell align='center'>
                                                                <Button
                                                                    className='font-btn-info'
                                                                    startIcon={<AddIcon />}
                                                                    onClick={function () {
                                                                        defaultState.txtRegistro = _Flujo.txtRegistro
                                                                        defaultState.lngIdFlujo = _Flujo.lngIdFlujo
                                                                        handleOnAdd()
                                                                    }}
                                                                >
                                                                    Agregar
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <TableContainer sx={{mt: 1}}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center' colSpan={3}><strong>Flujos seleccionados</strong></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center'>No. de flujo </TableCell>
                                                            <TableCell align='center'>Folio</TableCell>
                                                            <TableCell align='center'></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {/* <TableRow> */}
                                                        {rows.map((row, index) => (
                                                            <Row
                                                                {...row}
                                                                onChange={(name, value) => handleOnChange(index, name, value)}
                                                                onRemove={() => handleOnRemove(index)}
                                                                key={index}
                                                            />
                                                        ))}
                                                        {/* </TableRow> */}
                                                        <TableRow>
                                                            <TableCell colSpan={3} align='right'>
                                                                <Button
                                                                    className='btn-add-modal'
                                                                    variant='contained'
                                                                    startIcon={<SaveIcon />}
                                                                    onClick={function () {
                                                                        guardar()
                                                                    }}
                                                                >
                                                                    Guardar asociacion (es)
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                        
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </Dialog>
            </Dialog>
        </ThemeProvider>
    )
}
export default AsociarOperacionEdit