import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import PeopleIcon from '@mui/icons-material/People'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@emotion/react'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Link, useNavigate, useParams } from 'react-router-dom'

import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import '../Styles/StylesClientesPrint.css'
import logo from '../../Img/systrackhead.png'
import { CLIENTES_IDVM } from '../../Constants/apiConstnats';

const ClientesPrint = (props) => {
  const { id } = useParams()
  let navigate = useNavigate()
  const [_Data, setData] = useState([])

  const captura = async () => {
    var img1 = '';
    var img2 = '';
    var doc = new jsPDF();
    html2canvas(document.querySelector("#Captura")).then(canvas => {
      img1 = canvas.toDataURL('image/jpeg');
      doc.addImage(img1, 'JPEG', 10, 5, 190, 170); //15, 20, 170, 280: 15, 10, 180, 130
      //doc.addPage();
      html2canvas(document.querySelector("#Captura2")).then(canvas => {
        img2 = canvas.toDataURL('image/jpeg');
        doc.addImage(img2, 'JPEG', 10, 176, 188, 100);
        doc.output('dataurlnewwindow');
      });
    });
  }

  const getData = () => {
    props.setOpenLoadingScreen()
    requests
      .get(CLIENTES_IDVM, id)
      .then((response) => {
        asignarValores(response)
        props.setCloseLoadingScreen()
      }).catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const asignarValores = (objecto) => {
    setData({
      ..._Data,
      intIdCliente: objecto === null ? '' : objecto.intIdCliente,
            txtCliente: objecto === null ? '' : objecto.txtCliente,
            txtNomCorto: objecto === null ? '': objecto.txtNomCorto,
            txtTelefono: objecto === null ? '': objecto.txtTelefono,
            txtRfc: objecto === null ? '': objecto.txtRfc,
            txtStatusCli: objecto === null ? '': objecto.txtStatusCli,
            txtRegCliente: objecto === null ? '': objecto.txtRegCliente,
            intIdGiro: objecto === null ? '': objecto.intIdGiro,
            txtDireccion: objecto === null ? '': objecto.txtDireccion,
            txtComplemento: objecto === null ? '': objecto.txtComplemento,
            intIdEstado: objecto === null ? '': objecto.intIdEstado,
            intIdCiudad: objecto === null ? '': objecto.intIdCiudad,
            txtEmail: objecto === null ? '': objecto.txtEmail,
            numCodigoPostal: objecto === null ? '': objecto.numCodigoPostal,
            fecAlta: objecto === null ? '': objecto.fecAlta,
            fecBaja: objecto === null ? '': objecto.fecBaja,
            txtServicio: objecto === null ? '': objecto.txtServicio,
            txtRegCliente: objecto === null ? '': objecto.txtRegCliente,
            txtObservaciones: objecto === null ? '': objecto.txtObservaciones,
            txtUsuario: objecto === null ? '': objecto.txtUsuario,
            txtTelContacto: objecto === null ? '': objecto.txtTelContacto,
            txtCelular: objecto === null ? '': objecto.txtCelular,
            intIdAreaCon: objecto === null ? '': objecto.intIdAreaCon,
            intIdContacto: objecto === null ? '': objecto.intIdContacto,
            txtTipoCliente: objecto === null ? '': objecto.txtTipoCliente,
            txtEjecutivoOp: objecto === null ? '': objecto.txtEjecutivoOp,
            txtRecibe: objecto === null ? '': objecto.txtRecibe,
            fecRecibe: objecto === null ? '': objecto.fecRecibe,
            intIdCobertura: objecto === null ? '': objecto.intIdCobertura,
            txtIdCrm: objecto === null ? '': objecto.txtIdCrm,
            bolHolding: objecto === null ? '': objecto.bolHolding,
            intIdHolding: objecto === null ? '' :objecto.intIdHolding,
            bolRequiereContrato: objecto === null ? '' :objecto.bolRequiereContrato,
            bolRequiereCap: objecto === null ? '' :objecto.bolRequiereCap,
            intIdBrinda: objecto === null ? '' :objecto.intIdBrinda,
            txtContacto: objecto === null ? '' :objecto.txtContacto
    })
        // setFecBaja(objecto.fecBaja)
        // setFecAlta(objecto.fecAlta)
        // setFecRecibe(objecto.fecRecibe)

        // const [_FecBaja, setFecBaja] = useState(null)
        // const handleFecBaja = (date) => {
        //     setFecBaja(date)
        //     setData({ ..._Data, fecBaja: date })
        // }
    
        // const [_FecRecibe, setFecRecibe] = useState(null)
        // const handleFecRecibe = (date) => {
        //     setFecRecibe(date)
        //     setData({ ..._Data, fecRecibe: date })
        // }
    
        // const [_FecAlta, setFecAlta] = useState(null)
        // const handleFecAlta = (date) => {
        //     setFecAlta(date)
        //     setData({ ..._Data, fecAlta: date })
        // }

  }

  return (
    <ThemeProvider theme={Theme}>
      <Grid
        item
        xs={12}
        sm={8}
        md={10}
        lg={12}
        elevation={12}
        sx={{ py: 2 }}
      >
        <Paper
          elevation={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className='containerAlignHeader'>
            <Link to='/Clientes' style={{ textDecoration: 'none' }}>
              <Button
                size='small'
                className='btn-Header-back '
                startIcon={<ArrowBackIosNewSharpIcon />}
              >
                Regresar
              </Button>
            </Link>
            <Chip icon={<PeopleIcon />} label='Clientes / Imprimir' color='primary' className='back-transparent font-letter font-barter font-20' />
          </div>
        </Paper>
      </Grid>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid
          item
        >
          <Tooltip title='Imprimir cliente' arrow aria-label='add'>
            <Button
              className='btn-Header-back '
              onClick={function () {
                captura()
              }}
            >
              <LocalPrintshopIcon /> imprimir
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <div id="Captura" className='w100'>
        <div class="flex-column line-height1">
          <div class="w100 center header-top"/*  */>

            <div class="flex-between py-20">
              <p class="bold">FICHA DE REGISTRO</p>
              <h4 class="bold ">DATOS DEL CLIENTE</h4>
              <p class="bold">FECHA: {_Data.fecAlta}</p>
            </div>

            <div class="flex-between">
              <div>
                <img src={logo} width='150' />
              </div>
              <div class="flex ">
                <p class="mr1">Contrato:</p>
                <div class="input-date line-height ">
                  <p>{_Data.txtRegCliente}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="w100 center header-top" /*  */  >
            <div class="flex">
              <fieldset class=" w70 ">
                <legend>Nombre Completo</legend>
                <p>{_Data.txtCliente}</p>
              </fieldset>
              <fieldset class="w30 ">
                <legend>ID CRM</legend>
                <p>{_Data.txtIdCrm}</p>
              </fieldset>
            </div>

            <div class="flex mt2">
            <fieldset class="w25 ">
                <legend>Holding:</legend>
                <p>{_Data.intIdHolding}</p>
              </fieldset>
              <fieldset class=" w25 ">
                <legend>NIT/Identificación</legend>
                <p>{_Data.txtRfc}</p>
              </fieldset>
              <fieldset class="w25 ">
                <legend>Ejecutivo de Operaciones</legend>
                <p>{_Data.txtEjecutivoOp}</p>
              </fieldset>
              <fieldset class="w25 ">
                <legend>Tipo Cliente</legend>
                <p>{_Data.txtTipoCliente}</p>
              </fieldset>
              
            </div>

            <div class="flex mt2">
              <fieldset class="w25">
                <legend>Ciudad/País</legend>
                <p>{_Data.txtCiudadPais}</p>
              </fieldset>
              <fieldset class=" w25 ">
                <legend>E-Mail:</legend>
                <p>{_Data.txtEmail}</p>
              </fieldset>
              <fieldset class="w50">
                <legend>Dirección</legend>
                <p className='line-height1'>{_Data.txtDirección} </p>
              </fieldset>
            </div>
          </div>
          <div class="w100 center header-top ">
            <div class="flex">
              
            </div>
            <div class="flex mt2">
              <fieldset class=" w25 ">
                <legend>Código Postal</legend>
                <p>{_Data.txtCodigoPostal}</p>
              </fieldset>
              <fieldset class=" w25 ">
                <legend>Tipo residencia</legend>
                <p>{_Data.txtTipoResidencia}</p>
              </fieldset>
              <fieldset class=" w25 ">
                <legend>Teléfono</legend>
                <p>{_Data.txtTelefono}</p>
              </fieldset>
              <fieldset class=" w25 ">
                <legend>Celular</legend>
                <p>{_Data.txtCelular}</p>
              </fieldset>
            </div>
          </div>
          <div class="w100 center header-top ">
            <div class="flex">
              <fieldset class=" w100">
                <legend>Datos Comerciales</legend>
                <p>{_Data.txtDatosComerciales}</p>
              </fieldset>
            </div>
            <div class="flex mt2">
              <fieldset class=" w70 ">
                <legend>Nombre de la empresa</legend>
                <p>{_Data.txtNombreEmpresa}</p>
              </fieldset>
              <fieldset class=" w30 ">
                <legend>NIT (si aplica)</legend>
                <p>{_Data.txtNitEmpresa}</p>
              </fieldset>
            </div>
            <div class="flex mt2">
              <fieldset class=" w50">
                <legend> Actividad económica</legend>
                <p>{_Data.txtActividadEconomica}</p>
              </fieldset>
              <fieldset class=" w50">
                <legend>Facturación</legend>
                <p>{_Data.txtFacturacion}</p>
              </fieldset>
            </div>
            <div class="flex mt2">
              <fieldset class=" w50">
                <legend>Dirección de la empresa</legend>
                <p>{_Data.txtDireccionComercial}</p>
              </fieldset>
              <fieldset class=" w25">
                <legend>Teléfono empresa</legend>
                <p>{_Data.txtTelefonoEmpresa}</p>
              </fieldset>
              <fieldset class=" w25">
                <legend>Codigo postal</legend>
                <p>{_Data.txtCodigoPostalEmp}</p>
              </fieldset>
            </div>
            <div class="flex mt2">
              <fieldset class=" w50">
                <legend>Contacto</legend>
                <p>{_Data.txtContrato}</p>
              </fieldset>
              <fieldset class=" w25">
                <legend>Teléfono</legend>
                <p>{_Data.txtTelefonoContacto}</p>
              </fieldset>
              <fieldset class=" w25">
                <legend>Fecha apertura</legend>
                <p>{_Data.fecApertura}</p>
              </fieldset>
            </div>
          </div>
          <div class="w100 center header-top ">
            <div class="flex">
              <fieldset class=" w80">
                <legend>Datos profesionales</legend>
                <p>{_Data.txtDatosProfecionales}</p>
              </fieldset>
              <fieldset class=" w20">
                <legend>Tipo de profesional</legend>
                <p>{_Data.txtTipoProfesional}</p>
              </fieldset>
            </div>
            <div class="flex mt2">
              <fieldset class=" w40">
                <legend>Fecha ingreso</legend>
                <p>{_Data.fecIngresoEmpresa}</p>
              </fieldset>
              <fieldset class=" w30">
                <legend>Ingresos brutos</legend>
                <p>{_Data.dblIngresosBrutos}</p>
              </fieldset>
              <fieldset class=" w30">
                <legend>Teléfono empresa</legend>
                <p>{_Data.txtTelefonoDp}</p>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div id="Captura2" className='w100'>
        <div class="flex-column line-height1">
          <div class="w100 center header-top">
            <div class="flex">
              <fieldset class=" w50">
                <legend>Tipo de producto</legend>
                <p>{_Data.txtTipoProducto}</p>
              </fieldset>
              <fieldset class=" w50">
                <legend>Monto a cotizar</legend>
                <p>{_Data.dblMontoCotizar}</p>
              </fieldset>

            </div>
            <div class="flex mt3">
              <fieldset class="line-height2 w100">
                <legend>COMENTARIOS</legend>
                <p>{_Data.txtComentarios}</p>
              </fieldset>
            </div>
          </div>

          <div class="mt10 w100 center ">
            <div class=" mt5">
              <div class="flex-between w-100 text-center">
                <div class="w30 border-top ">
                  <p>Promotor</p>
                </div>
                <div class="w30 border-top ">
                  <p>Gerencia comercial </p>
                </div>
                <div class="w30 border-top ">
                  <p>Gerencia de operaciones</p>
                </div>
              </div>
              <div class="flex-between mt6 text-center">
                <div class="w40 border-top">
                  <p>Ejecutivo de Cuenta/ Implementación </p>
                </div>
                <div class="w40 border-top">
                  <p>Dirección Ejecutiva </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid
          item

        >
          <Tooltip title='Imprimir cliente' arrow aria-label='add'>
            <Button
              className='btn-Header-back '
              onClick={function () {
                captura()
              }}
            >
              <LocalPrintshopIcon /> imprimir
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ClientesPrint