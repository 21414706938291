import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Chip from '@mui/material/Chip'
import BadgeIcon from '@mui/icons-material/Badge'

import TipoResidenciaAdd from '../../Components/Catalogos/TipoResidencia/TipoResidenciaAdd';
import TipoResidenciaDelete from '../../Components/Catalogos/TipoResidencia/TipoResidenciaDelete';
import TipoResidenciaEdit from '../../Components/Catalogos/TipoResidencia/TipoResidenciaEdit';

import MUIDataTable from "../../Util/MUIDataTable";

import "../../Components/Styles/Styles.css";
import requests from "../../Components/AxiosCalls/AxiosCall";
import Theme from "../../Components/Styles/Theme";

const TipoResidenciaView = (props) => {
    const [registros, setRegistros] = useState([]);
    const [registroId, setregistroId] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => {
        setOpen2(!open2);
    };

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => {
        setOpen3(!open3);
    };

    const getRegistros = () => {
        props.setOpenLoadingScreen();
        requests
            .get("CtTipoResidecium/ObtenerLista")
            .then((response) => {
                setRegistros(response);
                props.setCloseLoadingScreen();
                setregistroId(null)
            })
            .catch((error) => {
                console.log("Error: " + error);
                props.setCloseLoadingScreen();
            });
    };

    useEffect(() => {
        getRegistros();
    }, []);

    const columns = [
        {
            name: "intIdTipoResidencia",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
            },
        },
        {
            name: "txtTipoResidencia",
            label: "Tipo residencia",
            options: {
                columnOrder: true,
                filter: false,
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title='Editar tipo de residencia' arrow aria-label='add'>
                            <Button
                                className='font-btn-editar'
                                onClick={function () {
                                    setregistroId(tableMeta.rowData[0])
                                    handleOpen2()
                                }}
                            >
                                <EditIcon sx={{ mr: 1 }} />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title='Eliminar tipo de residencia' arrow aria-label='add'>
                            <Button
                                className='font-btn-eliminar'
                                onClick={function () {
                                    setregistroId(tableMeta.rowData[0])
                                    handleOpen3()
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        },
    ];

    return (
        <ThemeProvider theme={Theme}>
            <Grid xs={12} sm={8} md={10} lg={12}>
                <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                    <Paper elevation={0} className="title-views">
                        <Grid
                            Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={12}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Chip icon={<BadgeIcon />} label='Tipo de residencia' color='primary' className='back-transparent font-letter font-barter font-20' />


                            <Button
                                className='btn-Header'
                                startIcon={<AddIcon />}
                                onClick={function () {
                                    handleOpen();
                                }}
                                size='small'
                            >
                                Nuevo
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12} alignItems="center" elevation={12}>
                    <MUIDataTable
                        title={"Lista de tipo de residencia"}
                        data={registros}
                        columns={columns}
                    />
                </Grid>
            </Grid>
            <TipoResidenciaAdd
                open={open}
                onClose={handleOpen}
                getRegistros={getRegistros}
                setOpenLoadingScreen={props.setOpenLoadingScreen}
                setCloseLoadingScreen={props.setCloseLoadingScreen}
                setOpenSnackBar={props.setOpenSnackBar}
                setMessageSnackBar={props.setMessageSnackBar}
                setTypeSnackBar={props.setTypeSnackBar}
            />
            <TipoResidenciaEdit
                registroId={registroId}
                open={open2}
                onClose={handleOpen2}
                getRegistros={getRegistros}
                setOpenLoadingScreen={props.setOpenLoadingScreen}
                setCloseLoadingScreen={props.setCloseLoadingScreen}
                setOpenSnackBar={props.setOpenSnackBar}
                setMessageSnackBar={props.setMessageSnackBar}
                setTypeSnackBar={props.setTypeSnackBar}
            />
            {registroId !== null ? (
                <TipoResidenciaDelete
                    registroId={registroId}
                    open={open3}
                    onClose={handleOpen3}
                    getRegistros={getRegistros}
                    setOpenLoadingScreen={props.setOpenLoadingScreen}
                    setCloseLoadingScreen={props.setCloseLoadingScreen}
                    setOpenSnackBar={props.setOpenSnackBar}
                    setMessageSnackBar={props.setMessageSnackBar}
                    setTypeSnackBar={props.setTypeSnackBar}
                />
            ) : null}

        </ThemeProvider>
    );
};
export default TipoResidenciaView;
;
