import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Theme from '../../Components/Styles/Theme'
import '../../Components/Styles/Styles.css'
import TextField from '@mui/material/TextField'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NumberFormat from 'react-number-format'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import PaidIcon from '@mui/icons-material/Paid'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import GraficaInicio from '../../Components/Graficas/GraficaInicio'
import requests from '../../Components/AxiosCalls/AxiosCall'
import SearchIcon from '@mui/icons-material/Search'
import ArticleIcon from '@mui/icons-material/Article'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Chip from '@mui/material/Chip'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { format} from 'date-fns'
import AssessmentIcon from '@mui/icons-material/Assessment';

const ReporteGeneralCxp = (props) => {
	const [registros, setRegistros] = useState([])
	const [TotalGrafica, setTotal] = React.useState({})
	const [TotalDepa, setTotalDepa] = React.useState([])

	const [_fechaInicio, setfechaInicio] = React.useState(null)
	const [_fechaFin, setfechaFin] = React.useState(null)
	const [_UnidadNeg, setUnidadNeg] = React.useState(null)

	const handlefechaInicio = (date) => {
		setfechaInicio(date)
	}

	const handleUnidadNeg = (event) =>{
		if(event.target.value === ""){
			setUnidadNeg(null)
		}else{
			setUnidadNeg(event.target.value)
		}
	}

	
	const handlefechaFin = (date) => {
		setfechaFin(date)
	}
	const obtenerFechaInicioDeMes = () => {
		const fechaInicio = new Date()
		return new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1)
	}

	const obtenerFechaFinDeMes = () => {
		const fechaFin = new Date()
		return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0)
	}

	const getCargaInical = () => {
		
		const fechaInicio = obtenerFechaInicioDeMes()
		const fechaFin = obtenerFechaFinDeMes()
		var fecini = format(new Date(fechaInicio), 'yyyy/MM/dd')
		var fecFin = format(new Date(fechaFin), 'yyyy/MM/dd')
		requests
			.get(
				'Reports/GetDashboardSin?FechaIncio=' + fecini +
					'&FechaFin=' + fecFin
			)
			.then((response) => {
				setRegistros(response)
			})
			.catch((error) => {
				console.log('Error: ' + error)
			})
	}
	const getCargaParam = () => {
		if(_fechaInicio !== null && _fechaFin !== null && _UnidadNeg !== null){
		props.setOpenLoadingScreen()
		requests
			.get(
				'Reports/GetDashboard?FechaIncio=' +
					format(new Date(_fechaInicio), 'yyyy/MM/dd') +
					'&FechaFin=' +
					format(new Date(_fechaFin), 'yyyy/MM/dd') +
					'&unidadNeg='+ _UnidadNeg
			)
			.then((response) => {
				setRegistros(response)
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				
			})
			.catch((error) => {
				
				console.log('Error: ' + error)
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
		}else if(_fechaInicio !== null && _fechaFin !== null){
			props.setOpenLoadingScreen()
			requests
				.get(
					'Reports/GetDashboardSin?FechaIncio=' +
						format(new Date(_fechaInicio), 'yyyy/MM/dd') +
						'&FechaFin=' +
						format(new Date(_fechaFin), 'yyyy/MM/dd')
				)
				.then((response) => {
					setRegistros(response)
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					
				})
				.catch((error) => {
					
					console.log('Error: ' + error)
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		}else{
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar("Introduzir datas")
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}

	const getTotCantidades = () => {
		props.setOpenLoadingScreen()
		requests
			.get('Reports/GraficaMeses')
			.then((response) => {
				setTotal(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const getTotalDep = () => {
		setTotalDepa([])
		props.setOpenLoadingScreen()
		requests
			.get('Reports/DepartCountSol')
			.then((response) => {
				setTotalDepa(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaUnidadNeg, setListaUnidadNeg] = useState([])
	const getListaUnidadNeg = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtNegocio/ObtenerLista')
			.then((response) => {
				debugger
				setListaUnidadNeg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getCargaInical()
		getTotCantidades()
		getTotalDep()
		getListaUnidadNeg()
	}, [])


	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12} >
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<AssessmentIcon />} label='Reportar contas a pagar' 
							color='primary' className='back-transparent font-letter font-barter font-20' />
						</Grid>
					</Paper>
				</Grid>

				<Grid container xs={12} sm={12} md={12} lg={12} spacing={2}
					direction="row"
					alignItems="center"
				>
					<Grid item xs={3}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker                                                            
								name='Fecha inicio'
								label='Data de início da registro'
								inputFormat='dd/MM/yyyy'
								value={_fechaInicio}
								onChange={handlefechaInicio}
								renderInput={(params) => (
									<TextField 
										label="Data de início da registro"                                                                   
										variant='standard'
										fullWidth
										size='small'
										{...params}
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={3}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker                                                            
								name='Fecha Fin'
								label='Data de término do registro'
								inputFormat='dd/MM/yyyy'
								value={_fechaFin}
								onChange={handlefechaFin}
								renderInput={(params) => (
									<TextField 
										label="Data de término do registro"                                                                   
										variant='standard'
										fullWidth
										size='small'
										{...params}
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={4}>
						<FormControl variant='standard' size='small' fullWidth>
							<InputLabel id='intIdNegocio'>Empresa</InputLabel>
							<Select
								value={_UnidadNeg}
								onChange={handleUnidadNeg}
								label='intIdNegocio'
								inputProps={{
									name: 'intIdNegocio',
									id: 'intIdNegocio',
								}}
								size='small'
							>
								<MenuItem aria-label='None' value='' />
								{_ListaUnidadNeg.map((object, index) => (
									<MenuItem value={object.intIdNegocio}>
										{object.txtNegocio}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					
					<Grid item xs={1}>
						<Button
							className='btn-Header2'
							startIcon={<SearchIcon />}
							onClick={() => {
								getCargaParam()
							}}
						>
							Procurar
						</Button>
					</Grid>
				</Grid>	

					<Grid container spacing={2} sx={{ mt: 2 }}>
		 				<Grid
							item
							container
							direction='column'
							xs={12}
							sm={4}
							md={4}
							lg={3}
						>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<PaidIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>PAGO</h3>
									</div>

									<div>
										<p className='title-number'>
											Cantidad: {registros.pagado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total: </p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.pagadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={4} md={4} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<ArticleIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'> FACTURADO</h3>
									</div>

									<div>
										<p className='title-number'>
										Quantia: {registros.facturado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total: </p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.facturadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<CheckCircleIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>APROVADO</h3>
									</div>

									<div>
										<p className='title-number'>
										Quantia: {registros.aprobado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total: </p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.aprobadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<CancelIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>CANCELADO</h3>
									</div>

									<div>
										<p className='title-number'>
										Quantia: {registros.cancelado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total:</p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.canceladoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>

								
							</Paper>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<AddCircleIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>ENCERRADA</h3>
									</div>

									<div>
										<p className='title-number'>Quantia: {registros.creado}</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total:</p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.creadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<CancelIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>RECUSADA</h3>
									</div>

									<div>
										<p className='title-number'>
										Quantia: {registros.rechazado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total:</p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.rechazadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>

								
							</Paper>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<Paper
								elevation={2}
								className='container-paper'
								sx={{
									borderRadius: '10px',
								}}
							>
								<div className='container-header-icon'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='container-icon'>
											<CheckCircleIcon className='ziseIcon' />
										</div>
										<h3 className='text-paper'>EM VALIDAÇÃO</h3>
									</div>

									<div>
										<p className='title-number'>
										Quantia: {registros.ordenado}{' '}
										</p>
									</div>
								</div>

								<div className='container-footer-icon'>
									<p className='title-number1'>Total: </p>
									<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											className='bold'
											label='Importe'
											variant='standard'
											value={registros.ordenadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
								</div>
							</Paper>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='flex-start'
							item
							xs={12}
							sm={6}
							md={6}
							lg={12}
							spacing={0.5}
						>
							<Grid item xs={12} sm={6} md={6} lg={8}>
								<Paper
									elevation={2}
									className='container-paper'
									sx={{
										borderRadius: '10px',
										// backgroundColor: "#081c15",
									}}
								>
									<div className='container-footer-icon'>
										<div className='container-icon'>
											<EqualizerIcon className='ziseIcon' />
										</div>
										<div>
											<h3 className='text-paper'>Resumo de pagamento por mês</h3>
										</div>
									</div>
									<div className='container-Graph'>
										<GraficaInicio datos={TotalGrafica} />
									</div>
								</Paper>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Paper
									elevation={2}
									className='container-paper'
									sx={{
										borderRadius: '10px',
										// backgroundColor: "#081c15",
									}}
								>
									<List
										sx={{
											width: '100%',
											bgcolor: 'background.paper',
											position: 'relative',
											overflow: 'auto',
											maxHeight: 300,
											'& ul': { padding: 0 },
										}}
										subheader={<li />}
									>
										<h4 className='text-paper'>Total por Departamento</h4>
										{TotalDepa.map((item) => (
											<li key={`section-${item}`}>
												<ul>
													<ListItem key={item}>
														<ListItemAvatar>
															<Chip
																className='text-paper'
																avatar={<ApartmentIcon />}
																label={
																	item.nombre + '   ' + 'Total: ' + item.total
																}
																variant='outlined'
															/>
														</ListItemAvatar>
													</ListItem>
												</ul>
											</li>
										))}
									</List>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
		</ThemeProvider>	
	)
}
export default ReporteGeneralCxp