import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {makeStyles} from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'lottie-react'
import Loading from '../../Util/Lottie/Loading.json'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));

const LoadingScreen = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.open} >           
                <div style={{ width: '150px', margin: 'auto', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center',alignContent:'center'}}>
                    <Lottie animationData={Loading} loop={true} autoplay={true} />
                    <p className="bold co-white "> Cargando... {props.Msj}</p>
                </div>                  
            </Backdrop>
        </div>
    )
}
export default LoadingScreen;
