import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning';
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'

const CxpAddNew = (props) => {
    let navigate = useNavigate()
    const methods = useForm()

    const [_Registro, SetRegistro] = useState({
        intIdSolicitdCxp: '',
        txtSolicitante: '',
        txtRegistro: '',
        intIdUsuario: '',
        intIdDepartamentos: '',
        intIdPlaza: '',
        intIdNegocio: '',
        intIdTipoServicio: '',
        intIdTipoGasto: '',
        txtFolio: '',
        txtConcepto: '',
        txtRazonSocial: '',
        txtBeneficiario: '',
        txtRfc: '',
        intTipoPago: '',
        txtTipoPago: '',
        intIdMoneda: '',
        dblImporte: '',
        dblIva: '',
        dblTotal: '',
        txtImporteTexto: '',
        fecDia: '',
        fecPago: '',
        fecProxVencimiento: '',
        intIdEmpresaPg: '',
        txtComentarios: '',
        intIdBanco: '',
        txtTarjeta: '',
        txtClabe: '',
        txtCuenta: '',
        txtIban: '',
        intIdStatus: '',
        lngIdProveedor: null,
        intIdProveedor: '',
        txtReferencia: '',
        bolCongelado: false,
        bolPrioridad: false,
        bolGastofijo: false,
        intIdRegCuenta: 0
    })

    const { handleSubmit } = methods
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        props.onClose()
    }

    const guardar = () => {
        props.setOpenLoadingScreen()
        requests.get('TbSolicitudCxp/ObtenerPorId/' + props.getRegistros.intIdSolicitdCxp)
            .then((response) => {
                response.fecPago = null;
                response.intIdStatus = 3;
                response.IntIdSolicitdCxp = 0;
            
                requests.post('TbSolicitudCxp/Crear', response)
                    .then((response) => {
                        props.setTypeSnackBar('success')
                        props.setMessageSnackBar(response.message)
                        props.setOpenSnackBar(true)
                        props.setCloseLoadingScreen()
                        navigate('/SolicitudCXP/Edit/' + response.data)
                        props.setCloseLoadingScreen()
                        window.location.reload()
                    })
                    .catch((error) => {
                        console.log('console', error)
                        props.setTypeSnackBar('warning')
                        props.setMessageSnackBar(error.data.message)
                        props.setOpenSnackBar(true)
                        props.setCloseLoadingScreen()
                    })
            })
            .catch((error) => {
                props.setCloseLoadingScreen()
            })
    }

    return (
        <ThemeProvider theme={Theme}>
            <Dialog
                maxWidth={'500px'}
                sx={{ backgroundColor: 'rgb(255 255 0 / 25%)' }}
                open={props.open}
                onClose={props.onClose ? props.onClose : null}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                    <div
                        style={{
                            width: '500px',
                            height: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DialogTitle
                            id='alert-dialog-title'
                            className='titleContainerHeaderModal'
                        >
                            Deseja replicar esta solicitação?
                        </DialogTitle>
                        <DialogContent>
                            <div
                                style={{
                                    backgroundColor: 'rgba(255, 255, 0, 0.502)',
                                    borderRadius: '50%',
                                    width: '200px',
                                    height: '200px',
                                }}
                            >
                                <WarningIcon
                                    sx={{ fontSize: '177px', color: 'rgb(89 74 13 / 80%)', marginLeft: '14px' }}
                                />
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button type='submit' className='btn-close-modal'>
                            Aceitar
                            </Button>
                            <Button
                                onClick={handleClose}
                                className='btn-add-modal'
                                variant='contained'
                            >
                                Cancelar
                            </Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
        </ThemeProvider>
    )
}
export default CxpAddNew;