import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import '../Components/Styles/Styles.css'
import { Link, useNavigate } from 'react-router-dom'
import MUIDataTableS from '../Util/MUIDataTableS'
import requests from '../Components/AxiosCalls/AxiosCall'
import Theme from '../Components/Styles/Theme'
import { format} from 'date-fns'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Chip from '@mui/material/Chip'
import SolictudCxpDelete from './../Components/SolicitudCxp/SolictudCxpDelete'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import NumberFormat from 'react-number-format'
const SolicitudCXPView = (props) => {
	const [registros, setRegistros] = useState([])
	const [deptoId, setDeptoId] = useState()
	let navigate = useNavigate()

	const [open, setOpen] = useState(false)
	const handleOpen = (id) => {
		setDeptoId(id)
		setOpen(!open)
	}
	
	const handleClose = () => {
		setOpen(!open)
	}

	const handleTextBusqueda = (text) => {
		getRegistrosConParam(text)
	}

	const getRegistrosConParam = (text) => {
		props.setOpenLoadingScreen()
		if(InfoData.departamento === 11 || InfoData.departamento === 1 || InfoData.departamento === 35 || InfoData.departamento === 49 ){
			if (text !== '' && text !== null) {
				props.setOpenLoadingScreen()
				requests
					.get('TbSolicitudCxp/ObtenerListaCon?concepto=' + text)
					.then((response) => {
						setRegistros(response)
						props.setCloseLoadingScreen()
					})
					.catch((error) => {
						console.log('Error: ' + error)
						props.setCloseLoadingScreen()
					})
			} else {
				getRegistros()
			}
		}else if(InfoData.departamento === 14 ){
			requests
			.get('TbSolicitudCxp/ObtenerListaProcesada/' + 31)
			.then((response) => {
				
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		}else{
			requests
			.get('TbSolicitudCxp/ObtenerListaPorDepto/' + InfoData.departamento )
			.then((response) => {
				
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		}
	}

	const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		if(InfoData.departamento === 11 || InfoData.departamento === 1 || InfoData.departamento === 35 || InfoData.departamento === 49 ){
			requests
			.get('TbSolicitudCxp/ObtenerListaVM')
			.then((response) => {
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		}else if(InfoData.departamento === 14 ){
			requests
			.get('TbSolicitudCxp/ObtenerListaProcesada/' + 31)
			.then((response) => {
				
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		}else{
			requests
			.get('TbSolicitudCxp/ObtenerListaPorSolicitante/' + InfoData.id )
			.then((response) => {
				
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		}
		
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const getImpresoPDf = async (id) => {
		props.setOpenLoadingScreen()
		let url = requests.Url + 'GenerarPDF/GenerarPdfSolicitudCxp/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
					props.setCloseLoadingScreen()
				})
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
				console.log(error)
			})
	}

	const columns = [
		{
			name: 'intIdSolicitdCxp',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtRegistro',
			label: 'Reg',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<strong>{tableMeta.rowData[2]}</strong>
					</div>
					);
				}
			},
		},
		{
			name: 'txtConcepto',
			label: 'Conceito ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtSolicitante',
			label: 'Candidato ',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtDepartamento',
			label: 'Departamento ',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtPlaza',
			label: 'Praça ',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtNegocio',
			label: 'Negócios ',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtTipoServicio',
			label: 'Tipo de serviço',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'dblTotal',
			label: 'Quantia',
			options: {

				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
					<NumberFormat
						className='bold'
						displayType={'text'}
						value={tableMeta.rowData[9]}
						thousandSeparator={true}
						fixedDecimalScale={true}
						decimalScale={2}
						prefix={'$'}
					/>
					</div>
					);
				}
			},
		},
		{
			name: 'txtStatus',
			label: 'Status',
			options: {
				filter: true,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[10] === 'ENCERRADA') {
						return (
							<Chip
								className='chip-Creado '
								size='small'
								label={tableMeta.rowData[10]}
							></Chip>
						)
					}
					if (tableMeta.rowData[10] === 'CANCELADA') {
						return (
							<Chip
								className='chip-Cancelado'
								size='small'
								label={tableMeta.rowData[10]}
							></Chip>
						)
					}
					if (tableMeta.rowData[10] === 'APROVADA') {
						return (
							<Chip
								className='chip-Aprobado '
								size='small'
								label={tableMeta.rowData[10]}
							></Chip>
						)
					}
					if (tableMeta.rowData[10] === 'PAGA') {
						return (
							<Chip
								className='chip-Pagado'
								size='small'
								label={tableMeta.rowData[10]}
							></Chip>
						)
					}if (tableMeta.rowData[10]=== 'RECUSADA'){
						return (
							<Chip
								className='chip-Cancelado'
								size='small'
								label={tableMeta.rowData[10]}
							>
							</Chip>
						)
					}if (tableMeta.rowData[10]=== 'PROCESSADA'){
						return (
							<Chip
								className='chip-Procesada'
								size='small'
								label={tableMeta.rowData[10]}
							>
							</Chip>
						)
					} if (tableMeta.rowData[10]=== 'EM VALIDAÇÃO'){
						return (
							<Chip
								className='chip-Validacion'
								size='small'
								label={tableMeta.rowData[10]}
							>
							</Chip>
						)
					}else{
						<Chip
								className='chip-Pagado'
								size='small'
								label={tableMeta.rowData[10]}
							></Chip>
					}
				},
			},
		},
		{
			name: 'fecDia',
			label: 'Data Día',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[11] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'fecProxVencimiento',
			label: 'Expiração',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[12] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'fecPago',
			label: 'Data do Pagamento',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[13] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'Imprimir',
			label: 'Imprimir',
			options: {
				filter: false,
				sort: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='imprimir' arrow aria-label='add'>
							<Button
								className='font-btn-imp'
								onClick={function () {
									getImpresoPDf(tableMeta.rowData[0])
								}}								
							>
								<LocalPrintshopIcon sx={{ mr: 1 }} />								
							</Button>
						</Tooltip>
					)
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					
					return (
						<Tooltip title='Editar solicitud' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									navigate('/SolicitudCXP/Edit/' + tableMeta.rowData[0])
								}}
							>
								<EditIcon sx={{ mr: 1 }} />
							</Button>
						</Tooltip>
					)
				},
			},
		},
	]

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={10} md={10} lg={12} >
				<Grid item xs={4} sm={10} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<MonetizationOnIcon />} label='Contas por pagar' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							<Button className='btn-Header' startIcon={<AddIcon />} size='small' 
								onClick={() => {navigate('/SolicitudCXP/Add')}}>
									Nuevo
								</Button>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center'>
				<MUIDataTableS
						title={'Listado de solicitudes'}
						data={registros}
						columns={columns}
						searchtexto={handleTextBusqueda}
					/>
				</Grid>
			</Grid>
			<SolictudCxpDelete
						open={open}
						onClose={handleClose}
						deptoId={deptoId}
						getRegistros={getRegistros}
						setOpenLoadingScreen={props.setOpenLoadingScreen}
						setCloseLoadingScreen={props.setCloseLoadingScreen}
						setOpenSnackBar={props.setOpenSnackBar}
						setMessageSnackBar={props.setMessageSnackBar}
						setTypeSnackBar={props.setTypeSnackBar}
					/>
		</ThemeProvider>
		
	)
}
export default SolicitudCXPView
