import axios from 'axios';
import respon from '../Components/AxiosCalls/Dictionary';

const LoginServices = () => {
    
    const baseUrl = respon.baseUrls.getBaseUrl;
    const getLocalToken = () => {
        return JSON.parse(localStorage.getItem('TokenSYS'));
    };

    const isLoggedIn = () => !!getLocalToken;
    const logIn = (user) => {
    
      
        return new Promise((resolve, reject) => {
            const instance = axios.create({
                baseURL: baseUrl,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            instance.post('Login/Login', user)
            .then (r => {
                resolve(r.data);
            }).catch(e=> {
                reject(e);
            });
        });
    };

    const logOut = () => {
        localStorage.removeItem('TokenSYS')
        return window.location.reload();
    };

    return {
        logIn,
        getLocalToken,
        isLoggedIn,
        logOut,
    }
};

export default LoginServices();
