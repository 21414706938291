import React from 'react'
import Lottie from 'lottie-react'
import Backdrop from '@mui/material/Backdrop';
import '../Styles/Styles.css'
import Loading from '../../Util/Lottie/Loading.json'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));

const NoAccess = (props) => {
	const classes = useStyles();
	
	let navigate = useNavigate()

	const logOut = () => {
		sessionStorage.removeItem('TokenSYS')
		sessionStorage.removeItem('TokenSYSUser')
		navigate('/Contacto')
		window.location.reload()
	}

	const begin = () => {
		sessionStorage.removeItem('TokenSYS')
		sessionStorage.removeItem('TokenSYSUser')
		navigate('/')
		window.location.reload()
	}
	return (
		<div>
            <Grid>           
				<Backdrop className={'container-column ziseIcon-cliente'} open={true} >           
					<div style={{ width: '150px', margin: 'auto', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center',alignContent:'center'}}>
						<Lottie animationData={Loading} loop={true} autoplay={true} />
						<p className="bold co-white "> Cargando... {props.Msj}</p>
					</div>                					
				</Backdrop>
            </Grid>
        </div>
	)
}

export default NoAccess
