import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'

const TipoGastoEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctTipoGasto, setctTipoGasto] = useState({
		intIdTipoGasto: props.tipoId,
		txtTipoGasto: '',
		txtCuenta: '',
	})
	const [tbSerGast, settbSerGast] = useState({
		lngIdSerGast: '',
		intIdTipoGasto: '',
		intIdTipoServicio: '',
	})
	const LimpiarValores = () => {
		setctTipoGasto({ ...ctTipoGasto, 
			intIdTipoGasto: '',
			txtTipoGasto: '',
			txtCuenta: '', })
		settbSerGast({
			...tbSerGast,
			lngIdSerGast: '',
			intIdTipoGasto: '',
			intIdTipoServicio: '',
		})
	}
	const handletbSerGast = (event) => {
		settbSerGast({ ...tbSerGast, [event.target.name]: event.target.value })
	}
	const handleCtTipoGasto = (event) => {
		setctTipoGasto({ ...ctTipoGasto, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getDepto()
		getTipoGasto()
		getTipoServicio()
	}, [props.tipoId])
	const getDepto = () => {
		if (props.tipoId != null) {
			requests
				.get('CtTipoGasto/ObtenerPorId/' + props.tipoId)
				.then((response) => {
					setctTipoGasto(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}
	const getTipoServicio = () => {
		if (props.tipoId != null) {
			requests
				.get('TbSerGast/ObtenerTipoGastoPorId/' + props.tipoId)
				.then((response) => {
					settbSerGast(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}
	const [ListctTipoSer, setListctTipoServi] = useState([])
	const getTipoGasto = () => {
		requests
			.get('CtTipoServicio/ObtenerLista')
			.then((response) => {
				setListctTipoServi(response)
			})
			.catch((error) => {
				console.log('Error: ' + error)
			})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtTipoGasto/Editar'
		let url2 = 'TbSerGast/Editar'
		requests
			.put(url, ctTipoGasto)
			.then((response) => {
				requests
					.put(url2, tbSerGast)
					.then((response) => {
						handleClose()
						console.log(response)
					})
					.catch((error) => {
						console.log('Error: ' + error)
					})
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Actualizar tipo de gasto
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '500px', height: '230px' }}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								required
								id='txtTipoGasto'
								name='txtTipoGasto'
								label='Tipo Gasto:'
								variant='standard'
								fullWidth
								value={ctTipoGasto.txtTipoGasto}
								onChange={handleCtTipoGasto}
							/>
							<TextField
								required
								sx={{ mt: 2 }}
								id='txtCuenta'
								name='txtCuenta'
								label='Cuenta:'
								variant='standard'
								fullWidth
								value={ctTipoGasto.txtCuenta}
								onChange={handleCtTipoGasto}
							/>
							<FormControl sx={{ mt: 1 }} variant='standard' fullWidth>
								<InputLabel required id='intIdTipoServicio'>
									Tipo de servicio
								</InputLabel>
								<Select
									labelId='intIdTipoServicio'
									value={tbSerGast.intIdTipoServicio}
									onChange={handletbSerGast}
									name='intIdTipoServicio'
									inputProps={{
										name: 'intIdTipoServicio',
										id: 'intIdTipoServicio',
									}}
									label='Tipo de gasto'
								>
									<MenuItem value=''></MenuItem>
									{ListctTipoSer.map((object, index) => (
										<MenuItem value={object.intIdTipoServicio}>
											{object.txtTipoServicio}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default TipoGastoEdit
