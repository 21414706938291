import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import NumberFormat from 'react-number-format'
import AsyncSelect from 'react-select/async'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Grid from '@mui/material/Grid'
import StyleGeneral from '../Styles/StyleGeneral';

const CuentasProvEdit = (props) => {
    const methods = useForm()
    const { handleSubmit } = methods
    const [_ListBanco, setListBanco] = useState([])
    const [open, setOpen] = useState(false)
    const classes = StyleGeneral();

    const handleClose = () => {
        props.onClose()
        props.getRegistros()
        LimpiarValores()
    }
    const [_TbCuentasProv, setTbCuentasProv] = useState({
        lngIdProveedor: 0,
        intIdBanco: 0,
        txtClabe: "",
        txtCuenta: "",
        bolCancelada: false
    })

    const LimpiarValores = () => {
        setTbCuentasProv({
            ..._TbCuentasProv,
            lngIdProveedor: "",
            intIdBanco: "",
            txtClabe: "",
            txtCuenta: "",
            bolCancelada: ""
        })
        setSelectedOption(null);
    }
    const handleTbCuentasProv = (event) => {

        setTbCuentasProv({ ..._TbCuentasProv, [event.target.name]: event.target.value })
    }

    const [_Proveedores, setProveedores] = useState([])
    const getProveedores = () => {
        props.setOpenLoadingScreen()
        requests
            .get('TbProveedores/ObtenerLista')
            .then((response) => {
                setProveedores(response)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                console.log('Error: ' + error)
                props.setCloseLoadingScreen()
            })
    }

    const guardar = () => {
        props.setOpenLoadingScreen()
        let url = 'TbCuentasProv/Editar'
        requests
            .put(url, _TbCuentasProv)
            .then((response) => {
                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
                handleClose()
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
    }

    const [selectedOption, setSelectedOption] = useState([])
    const loadOptions = async (inputValue, callback) => {
        await requests
            .get('TbProveedores/ObtenerListaParam?Search=' + inputValue)
            .then((response) => {
                callback(
                    response.map((i) => ({ label: i.txtRazonSocial, value: i.lngIdProveedor }))
                )
            })
            .catch((error) => {
                console.log('Error: ' + error)
            })
    }
    const onChangeSelectedOption = (e) => {
        setSelectedOption(e);
        setTbCuentasProv({ ..._TbCuentasProv, lngIdProveedor: e.value });

    }
    const getBanco = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtBanco/ObtenerLista')
            .then((response) => {
                setListBanco(response)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                console.log('Error: ' + error)
                props.setCloseLoadingScreen()
            })
    }

    useEffect(() => {
        getRegistro();
        getBanco();
        getProveedores();
    }, [props.registroId])
    const getRegistro = () => {
        props.setOpenLoadingScreen()
        requests
            .get('TbCuentasProv/ObtenerPorId/' + props.registroId)
            .then((response) => {
                AsignarValores(response)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                console.log('Error: ' + error)
                props.setCloseLoadingScreen()
            })
    }
    const AsignarValores = (objeto) => {
        setTbCuentasProv({
            ..._TbCuentasProv,
            lngIdCuentasProv: objeto === null ? '' : objeto.lngIdCuentasProv,
            lngIdProveedor: objeto === null ? '' : objeto.lngIdProveedor,
            intIdBanco: objeto === null ? '' : objeto.intIdBanco,
            txtClabe: objeto === null ? '' : objeto.txtClabe,
            txtCuenta: objeto === null ? '' : objeto.txtCuenta,
            bolCancelada: objeto === null ? '' : objeto.bolCancelada,
        })
        requests
            .get('TbProveedore/ObtenerPorId/' + objeto.lngIdProveedor)
            .then((response) => {
                let setdata = {
                    label: response.txtRazonSocial,
                    value: response.lngIdProveedor,
                }
                setSelectedOption(setdata)
                //setProducto(response)			
            })
            .catch((error) => {
                console.log('erroo' + error)
            })
    }

    return (
        <ThemeProvider theme={Theme}>
            <Dialog
                open={props.open}
                onClose={props.onClose ? props.onClose : null}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                    <div className='containerHeaderModal'>
                        <DialogTitle
                            id='alert-dialog-title'
                            className='containerHeaderModal'
                        >
                            Editar cuenta {props.registroId}
                        </DialogTitle>
                        <div>
                            <Button onClick={handleClose}>
                                <CloseIcon
                                    fontSize='large'
                                    sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
                                />
                            </Button>
                        </div>
                    </div>

                    <DialogContent sx={{ width: '570px', height: '250px' }}>
                        <DialogContentText id='alert-dialog-description'>
                            <Grid container>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                    <FormControl variant='standard' fullWidth >
                                            <InputLabel id='lngIdProveedor' >
                                                Listado de proveedores
                                            </InputLabel>
                                            <Select
                                                value={_TbCuentasProv.lngIdProveedor}
                                                onChange={handleTbCuentasProv}
                                                label='lngIdProveedor'
                                               
                                                inputProps={{
                                                    name: 'lngIdProveedor',
                                                    id: 'lngIdProveedor',
                                                }}
                                            >
                                                <MenuItem aria-label='None' value='' />
                                                {_Proveedores.map((object, index) => (
                                                    <MenuItem value={object.lngIdProveedor}>
                                                        {object.txtNombreComercial}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl variant='standard' fullWidth >
                                            <InputLabel id='intIdBanco' >
                                                Listado de bancos
                                            </InputLabel>
                                            <Select
                                                value={_TbCuentasProv.intIdBanco}
                                                onChange={handleTbCuentasProv}
                                                label='intIdBanco'

                                                inputProps={{
                                                    name: 'intIdBanco',
                                                    id: 'intIdBanco',
                                                }}
                                            >
                                                <MenuItem aria-label='None' value='' />
                                                {_ListBanco.map((object, index) => (
                                                    <MenuItem value={object.intIdBanco}>
                                                        {object.txtBanco}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            sx={{ mr: 1 }}
                                            id='txtClabe'
                                            label='Clabe'
                                            variant='standard'
                                            name='txtClabe'
                                            type="number"
                                            fullWidth
                                            value={_TbCuentasProv.txtClabe}
                                            onChange={handleTbCuentasProv}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            sx={{ mr: 1 }}
                                            id='txtCuentae'
                                            label='Cuenta'
                                            variant='standard'
                                            name='txtCuenta'
                                            type="number"
                                            fullWidth
                                            value={_TbCuentasProv.txtCuenta}
                                            onChange={handleTbCuentasProv}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} className='btn-close-modal'>
                            Cerrar
                        </Button>
                        <Button
                            className='btn-add-modal'
                            variant='contained'
                            type='submit'
                            startIcon={<CachedIcon />}
                        >
                            Actualizar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </ThemeProvider>
    )
}
export default CuentasProvEdit
