import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { format, compareAsc } from 'date-fns'
import '../../Components/Styles/Styles.css'
import MUIDataTable from '../../Util/MUIDataTable'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberFormat from 'react-number-format'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import SearchIcon from '@mui/icons-material/Search'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Checkbox } from '@mui/material'
import DownloadingIcon from '@mui/icons-material/Downloading';
import Chip from '@mui/material/Chip'
import { DEPTO_LISTA, NEGOCIO_LISTA, PROVE_LISTA, REP_CONCEPTO, REP_CONCEPTOEX, REP_CONSECUTIVO, REP_FECPAGO, REP_FECPAGOEX, STATUS_LIST, TIPOPAGO_LISTA, USU_LISTA } from '../../Constants/apiConstnats'

const ReporteFechaPago = (props) => {
	const [registros, setRegistros] = useState([])
	const [LisStatus, setLisStatus] = useState([])
	const [Mostrar, setMostrar] = useState(false)

	const [param, setparam] = useState({
		idStatus: null,
		gastoFijo: null,
		unidadNeg: null,
		concepto: null,
	})
	const Consultar = () => {
		if (param.concepto !== null) {

			if (param.gastoFijo === true) {
				param.gastoFijo = 1
			} else if (param.gastoFijo === false) {
				param.gastoFijo = 0
			}
			props.setOpenLoadingScreen()
			let url = REP_CONCEPTO
			requests
				.post(url, param)
				.then((response) => {
					console.log('console', response);
					setRegistros(response)
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		} else {
			setMostrar(true)
		}
	}

	const GetReporte = () => {
		if (param.concepto !== null) {

			let url =
				requests.Url + "Reports/ReporteConceptoExcel" + '?Concepto=' + param.concepto

			var urln = url

			if (param.idStatus !== null) {
				urln = urln + '&IdStatus=' + param.idStatus
			}
			if (param.gastoFijo !== null) {
				urln = urln + '&GastoFijo=' + param.gastoFijo
			}
			if (param.unidadNeg !== null) {
				urln = urln + '&UnidadNeg=' + param.unidadNeg
			}

			var link = document.createElement('a')
			link.target = '_blank'
			link.href = urln
			document.body.appendChild(link)
			link.click()
		} else {
			setMostrar(true)
		}
	}

	const getStatus = () => {
		props.setOpenLoadingScreen()
		requests
			.get(STATUS_LIST)
			.then((response) => {
				setLisStatus(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_ListaUnidadNeg, setListaUnidadNeg] = useState([])
	const getListaUnidadNeg = () => {
		props.setOpenLoadingScreen()
		requests
			.get(NEGOCIO_LISTA)
			.then((response) => {
				setListaUnidadNeg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const handleText = (event) => {
		if (event.target.value === "") {
			setparam({ ...param, [event.target.name]: null })
		} else {
			setparam({ ...param, [event.target.name]: event.target.value })
			setMostrar(false)
		}
	}

	const handleBol = (e) => {
		setparam({ ...param, gastoFijo: e.target.checked })
	}

	useEffect(() => {
		getStatus()
		getListaUnidadNeg()
	}, [])

	const columns = [
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'registro',
			label: 'Reg ',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<strong>{tableMeta.rowData[1]}</strong>
					</div>
					);
				}
			},
		},
		{
			name: 'concepto',
			label: 'Conceito',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'solicitante',
			label: 'Candidato',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'beneficiario',
			label: 'Beneficiario',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'departamento',
			label: 'Departamento ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'plaza',
			label: 'Praça',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'negocio',
			label: 'Negócios',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoServicio',
			label: 'Tipo de serviço',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoGasto',
			label: 'Tipo gasto',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'importe',
			label: 'Quantia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <NumberFormat className='bold mr1 ml1'
						value={tableMeta.rowData[10]} displayType={'text'} fixedDecimalScale={true}
                            decimalScale={2} thousandSeparator={true} prefix={'$'} />
                    );
                },
			},
		},
		{
			name: 'referencia',
			label: 'Referencia',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'dia',
			label: 'Data Dia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[13] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'proxVencimiento',
			label: 'Expiração',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[14] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'pago',
			label: 'Data do Pagamento',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[15] !== '01/01/0001'? value : ''
				},
			},
		},
	]

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12} >
			<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<div >
							<div style={{ width: '100%', paddingTop: '20px', margin:'auto' }}>
								<Grid container  justifyContent="center" spacing={2}>
                                    <Grid item xs={12} lg={3}>
                                        <TextField
                                            required
                                            multiline
                                            maxRows={4}
                                            id='concepto'
                                            name='concepto'
                                            label='Conceito'
                                            variant='standard'
                                            fullWidth
                                            value={param.concepto}
                                            onChange={handleText}
                                            size='small'
                                        />
										<Grid sx={{display:'flex', justifyContent: 'center'}}>
											{Mostrar? 
												<Paper elevation={8} sx={{ p: 1, borderRadius: '10px', display:'flex',alignItems: 'center',	justifyContent: 'center',width: '180px', color: '#ff0000', position: 'fixed' }} ><ReportProblemIcon />Campo obrigatório</Paper>:null
											}
										</Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3} >
										<FormControl variant='standard' fullWidth sx={{paddingTop:1}}>
											<InputLabel id='intIdStatus' >
												Status
											</InputLabel>
											<Select
												size='small'
												value={param.idStatus}
												onChange={handleText}
												label='intIdStatus'
												inputProps={{
													name: 'idStatus',
													id: 'idStatus',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{LisStatus.map((object, index) => (
													<MenuItem value={object.intIdStatus}>
														{object.txtStatus}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
                                    <Grid item xs={9} lg={3}>
                                        <FormControl variant='standard' size='small' fullWidth sx={{paddingTop:1}}>
                                            <InputLabel id='intIdNegocio'>Negócios</InputLabel>
                                            <Select
                                                
                                                value={param.unidadNeg}
                                                onChange={handleText}
                                                label='intIdNegocio'
                                                inputProps={{
                                                    name: 'unidadNeg',
                                                    id: 'unidadNeg',
                                                }}
                                                size='small'
                                            >
                                                <MenuItem aria-label='None' value='' />
                                                {_ListaUnidadNeg.map((object, index) => (
                                                    <MenuItem value={object.intIdNegocio}>
                                                        {object.txtNegocio}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} lg={3}>
                                        <FormControlLabel control={<Checkbox onChange={handleBol}/>} label="Gasto Fijo" />
                                    </Grid>
								</Grid>
                                <Grid container direction="row"  justifyContent="flex-end" alignItems="center" spacing={2} sx={{mt:1}}>
                                    <Grid item xs={12} lg={2}>
										<Button
											className='btn-Header2'
											startIcon={<SearchIcon />}
											onClick={() => {
												Consultar()
											}}
										>
											Procurar
										</Button>
									</Grid>
									<Grid item xs={12} lg={2}>
										<Button
											className='btn-Header2'
											startIcon={<DownloadingIcon />}
											onClick={() => {
												GetReporte()
											}}
										>
											Excel
										</Button>
									</Grid>
                                </Grid>
							</div>
						</div>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center' elevation={12}>
					<MUIDataTable
						title={'Lista de solicitudes'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default ReporteFechaPago
