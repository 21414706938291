import MUIDataTable from 'mui-datatables'
import React from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material/styles'
import '../Components/Styles/Styles.css'

import Theme from '../Components/Styles/Theme'

export default function MyDataTable(props) {
	const theme = createTheme({
		components: {
			MUIDataTable: {
				styleOverrides: {
					root: {
						//backgroundColor: '#000!important',
					},
					paper: {
						boxShadow: 'none',
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						//backgroundColor: '#000',
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						//backgroundColor: '#000!important',
						fontSize: '0.65rem',
						padding: '1px!important',
						textAlign: 'center',
					}
				},
			},
			MuiTableRow:{
				styleOverrides:{
					root: { 
						//backgroundColor: '#021!important'
					},
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						//backgroundColor: '#000',
						fontSize: '0.65rem',
						fontWeight: 700,
						textAlign: 'center',
						margin: 'auto!important'
					}
				}
			},		
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'blue',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						'& .MuiToolbar-root': {
							backgroundColor: 'white',
						},
					},
				},
			},
		},
	})

	const options = {
		rowsPerPage: 20,
		rowsPerPageOptions: [20, 50, 100],
		fixedHeader: true,
		jumpToPage: true,
		tableBodyHeight: window.screen.width === 1366 ? '365px' : '500px',
		//tableBodyHeight: props.data.length < 5 ? "250px" : "250px",
		filterType: 'multiselect',
		selectableRows: 'none',
		responsive: 'standard',
		grouping: 'Módulo',
		download: props.download,
		print: props.export,
		viewColumns: false,
		textLabels: {
			body: {
				noMatch: 'Ops, nenhuma correspondência encontrada.',
				toolTip: 'Ordenar',
				columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
			},
			pagination: {
				next: 'Siguiente',
				previous: 'Anterior',
				rowsPerPage: 'Registros por página:',
				displayRows: 'de',
				jumpToPage: 'Ir a página',
			},
			toolbar: {
				search: 'Buscar',
				downloadCsv: 'Descargar CSV',
				print: 'Imprimir',
				viewColumns: 'Elegir Columnas',
				filterTable: 'Filtrar Tabla',
			},
			filter: {
				all: 'Todo',
				title: 'FILTROS',
				reset: 'LIMPIAR',
			},
			viewColumns: {
				title: 'Mostrar Columnas',
				titleAria: 'Mostrar/Ocultar Columnas',
			},
			selectedRows: {
				text: 'fila(s) seleccionada(s)',
				delete: 'Borrar',
				deleteAria: 'Borrar filas seleccionadas',
			},
		},
		onSearchChange: (searchText) => {
			props.searchtexto(searchText)
		},
	}
	return (
		<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					sx={{ textAling: 'center' }}
					title={props.title}
					data={props.data}
					columns={props.columns}
					options={options}
				/>
			</ThemeProvider>
		</Grid>
	)
}
