import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'

const PaisesEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctPais, setCtPais] = useState({
		intIdPais: props.registroId,
		txtPais: '',
		bolActivo: true,
	})
	const LimpiarValores = () => {
		setCtPais({ ...setCtPais, intIdPais: '', txtPais: '', bolActivo: '' })
	}

	const handleCtPais = (event) => {
		setCtPais({ ...setCtPais, [event.target.name]: event.target.value })
	}
	useEffect(() => {
		getRegistro()
	}, [props.registroId])
	const getRegistro = () => {
		
		if (props.registroId != null) {
			
			requests
				.get('CtPais/ObtenerPorId?id=' + props.registroId)
				.then((response) => {
					setCtPais(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		ctPais.intIdPais = props.registroId
		let url = 'CtPais/Editar'
		requests
			.put(url, ctPais)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Actualizar País
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px' }}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								required
								id='txtPais'
								name='txtPais'
								label='País:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={ctPais.txtPais}
								onChange={handleCtPais}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default PaisesEdit
