import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import ProveedoresView from '../View/ProveedoresView'
import SaldosView from '../View/SaldosView'
import CreditosView from '../View/CreditosView'
import CuentasProvView from '../View/CuentasProvView'
import HeaderLayout from '../Components/Layout/HeaderLayout'
import MainContainerLayout from '../Components/Layout/MainContainerLayout'
import MainContentLayout from '../Components/Layout/MainContentLayout'
import SolicitudCXPView from '../View/SolicitudCXPView'
import SolicitudCXPViewN from '../View/SolicitudCxpViewN'
import LoadingScreen from '../Components/Styles/LoadingScreen'
import MySnackBar from '../Components/Styles/MySnackBar'
import CuentasPgView from '../View/CuentasPgView'
import SolictudCxpAdd from '../Components/SolicitudCxp/SolictudCxpAdd'
import SolictudCxpEdit from '../Components/SolicitudCxp/SolictudCxpEdit'
import EmpresasPgView from '../View/EmpresasPgView'
import SolictudCxpEditDetails from '../Components/SolicitudCxp/SolictudCxpDetails'
import ReporteVencimiento from '../View/Reportes/ReporteVencimiento'
import ReporteFechaRegistro from '../View/Reportes/ReporteFechaRegistro'
import ReporteFechaPago from '../View/Reportes/ReporteFechaPago'
import ReporteConsecutivo from '../View/Reportes/ReporteConsecutivo'
import ReporteConcepto from '../View/Reportes/ReporteConcepto'
import ReporteComprobante from '../View/Reportes/ReporteComprobante'
import ReporteGeneralCxp from '../View/Reportes/ReporteGeneralCxp'
import SolicitudCxpPrint2 from '../Components/SolicitudCxp/SolicitudCxpPrint2'
import SolicitudCXPPrintDetails from '../Components/SolicitudCxp/SolicitudCXPPrintDetails'

import {
	ADMINPAGE
} from '../Constants/routesConstants'
import ClientesAdd from '../Components/Clientes/ClientesAdd'
import ClientesView from '../View/Clientes/ClientesView'
import ClientesEdit from '../Components/Clientes/ClientesEdit'
import ChecKListJuridicoAdd from '../Components/ChecKListJuridico/ChecKListJuridicoAdd'
import ChecKListJuridicoEdit from '../Components/ChecKListJuridico/ChecKListJuridicoEdit'
import ChecKListJuridicoView from '../View/ChecKListJuridico/ChecKListJuridicoView'

const RoutesAdmin = () => {
	const [LSopen, setLSOpen] = useState(false)
	const [SBOpen, setSBOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [Msj, setMsj] = useState('')
	const [type, setType] = useState('')

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') { return	}
		setSBOpen(false)
	}

	const openLoadingScreen = () => {
		setLSOpen(true)
	}

	const closeLoadingScreen = () => {
		setLSOpen(false)
	}

	return (
		<>
			<MySnackBar
				Open={SBOpen}
				Type={type}
				Message={message}
				HandleClose={handleClose}
			/>
			<LoadingScreen open={LSopen} />

			<MainContainerLayout>
				<HeaderLayout
					setMsjLoadingScreen={setMsj}
					setOpenLoadingScreen={openLoadingScreen}
					setCloseLoadingScreen={closeLoadingScreen}
					setOpenSnackBar={setSBOpen}
					setMessageSnackBar={setMessage}
					setTypeSnackBar={setType}
				/>
				<MainContentLayout>
					<Routes>
                    <Route
                            exact
                            path='/'
                            element={
                                <SolicitudCXPViewN
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP'
                            element={
                                <SolicitudCXPViewN
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/CuentasPg'
                            element={
                                <CuentasPgView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP/Add'
                            element={
                                <SolictudCxpAdd
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP/Edit/:id'
                            element={
                                <SolictudCxpEdit
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP/EditDetails/:id'
                            element={
                                <SolictudCxpEditDetails
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP/PrintDetails/:id'
                            element={
                                <SolicitudCXPPrintDetails
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/SolicitudCXP/Imprimir/:id'
                            element={
                                <SolicitudCxpPrint2
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/EmpresasPgView'
                            element={
                                <EmpresasPgView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteGeneral'
                            element={
                                <ReporteGeneralCxp
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteVencimiento'
                            element={
                                <ReporteVencimiento
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteFechaRegistro'
                            element={
                                <ReporteFechaRegistro
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteFechaPago'
                            element={
                                <ReporteFechaPago
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteConsecutivo'
                            element={
                                <ReporteConsecutivo
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteConcepto'
                            element={
                                <ReporteConcepto
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/ReporteComprobante'
                            element={
                                <ReporteComprobante
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Proveedores'
                            element={
                                <ProveedoresView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Saldos'
                            element={
                                <SaldosView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Creditos'
                            element={
                                <CreditosView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Cuentas'
                            element={
                                <CuentasProvView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Clientes/Add'
                            element={
                                <ClientesAdd
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Clientes'
                            element={
                                <ClientesView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/Clientes/Edit/:id'
                            element={
                                <ClientesEdit
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/CheckList/Add/:id'
                            element={
                                <ChecKListJuridicoAdd
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/CheckList/New'
                            element={
                                <ChecKListJuridicoAdd
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/CheckList/Edit/:id'
                            element={
                                <ChecKListJuridicoEdit
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
                        <Route
                            exact
                            path='/CheckList'
                            element={
                                <ChecKListJuridicoView
                                    setMsjLoadingScreen={setMsj}
                                    setOpenLoadingScreen={openLoadingScreen}
                                    setCloseLoadingScreen={closeLoadingScreen}
                                    setOpenSnackBar={setSBOpen}
                                    setMessageSnackBar={setMessage}
                                    setTypeSnackBar={setType}
                                />
                            }
                        />
					</Routes>
				</MainContentLayout>
			</MainContainerLayout>
		</>
	)
}

export default RoutesAdmin
