import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Link, useNavigate } from 'react-router-dom'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import PeopleIcon from '@mui/icons-material/People'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import '../Styles/Styles.css'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import { Typography } from '@mui/material'
import { CLIENTES_ADD } from '../../Constants/apiConstnats'

const ClientesAdd = (props) => {
    const methods = useForm()
    const { handleSubmit } = methods
    let navigate = useNavigate()

    const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))

    const [_Cliente, setCliente] = useState({
        txtContrato: "",
        txtCliente: "",
        numCPF: "",
        intIdGrupo: 0,
        intIdCrm:"",
        txtActividad:"",
        intIdTipoCliente:0,
        intIdPlaza:0,
        intIdEjecutivo:0,
        txtAlias: "",
        txtNombre: "",
        txtCargo: "",
        txtTelefono: "",
        txtEmail: "",
        txtContacto:"",
        txtDireccion: "",
        txtReferencia: "",
        txtLocalidad: "",
        txtMunicipio: "",
        txtEstado: "",
        intIdPais: 0,
        numCEP: null,
        txtTelefone:"",
        txtTelefonoContacto:"",
        txtEmailMatriz:'',
        fecIniOpera: null,
        bolActivo: null,
        fecEmision: null,
        txtObservaciones: ''
    })

    const handleCliente = (event) => {
        setCliente({ ..._Cliente, [event.target.name]: event.target.value });
    }

    const [_ListaPlaza, setListaPlaza] = useState([])
    const getListaPlaza = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtPlaza/ObtenerLista')
            .then((response) => {
                setListaPlaza(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaTipoProfesional, setListaTipoProfesional] = useState([])
    const getListaTipoProfesional = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtTipoProfesional/ObtenerLista')
            .then((response) => {
                setListaTipoProfesional(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaTipoCliente, setListaTipoCliente] = useState([]);
    const getListaTipoCliente = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtTipoCliente/ObtenerLista')
            .then((response) => {
                setListaTipoCliente(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    useEffect(() => {
        getListaTipoCliente()
        getListaPlaza()
        getListaTipoProfesional()
    }, [])

    const handleFecAltaEmp = (date) => {
        setCliente({ ..._Cliente, fecIniOpera: date })
    }
    const regexPattern = /\b[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}\b/;

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setCliente({ ..._Cliente, txtNitEmpresa: newValue });

        if (regexPattern.test(newValue)) {
            props.setTypeSnackBar('success')
            props.setMessageSnackBar("CNPJ CORRECTO")
            props.setOpenSnackBar(true)
        }else{
            props.setTypeSnackBar('warning')
            props.setMessageSnackBar("VERIFIQUE O SEU CNPJ")
            props.setOpenSnackBar(true)
        }
    };

    const guardar = () => {
        props.setOpenLoadingScreen()
        let url = CLIENTES_ADD
        requests
            .post(url, _Cliente)
            .then((response) => {

                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
                navigate('/Clientes')
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
    }
    const hoy = new Date(Date.now());

    return (
        <ThemeProvider theme={Theme}>
            {/* <Paper className='containerViews3'> */}
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        elevation={12}
                        sx={{ py: 2 }}
                    >
                        <Paper
                            elevation={0}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className='containerAlignHeader'>
                                <Link to='/Clientes' style={{ textDecoration: 'none' }}>
                                    <Button
                                        size='small'
                                        className='btn-Header-back '
                                        startIcon={<ArrowBackIosNewSharpIcon />}
                                        
                                    >
                                        Regresar
                                    </Button>
                                </Link>

                                <Chip icon={<PeopleIcon />} label='Clientes / Nuevo' color='primary'  className='back-transparent font-letter font-barter font-20'/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid
                        item

                        xs={12}
                        md={12}
                        lg={12}
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                    >
                        {/* start */}
                        <Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
                            <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                                <Paper elevation={0}>
                                    {/*start */}

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; DADOS GERAIS &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id='txtUsuario'
                                                        name='txtUsuario'
                                                        label='Usuario'
                                                        variant='standard'
                                                        fullWidth
                                                        value={InfoData.nombre + ' ' + InfoData.apellido}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        disabled='true'
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>Fecha: {hoy.toLocaleDateString()}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtCliente'
                                                        name='txtCliente'
                                                        label='Cliente'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtCliente}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='numCPF'
                                                        name='numCPF'
                                                        label='CPF'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.numCPF}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdGrupo'>
                                                            Grupo
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdGrupo}
                                                            onChange={handleCliente}
                                                            label='intIdGrupo'
                                                            inputProps={{
                                                                name: 'intIdGrupo',
                                                                id: 'intIdGrupo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='intIdCrm'
                                                        name='intIdCrm'
                                                        label='Id CRM'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.intIdCrm}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 10 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtActividad'
                                                        name='txtActividad'
                                                        label='Atividade preponderante:'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtActividad}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdTipoCliente'>
                                                            Tipo de Cliente
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdTipoCliente}
                                                            onChange={handleCliente}
                                                            label='intIdTipoCliente'
                                                            inputProps={{
                                                                name: 'intIdTipoCliente',
                                                                id: 'intIdTipoCliente',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdPlaza'>
                                                        Plaza de Operação
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdPlaza}
                                                            onChange={handleCliente}
                                                            label='intIdPlaza'
                                                            inputProps={{
                                                                name: 'intIdPlaza',
                                                                id: 'intIdPlaza',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {/* <Grid item xs={4}>
                                                    <LocalizationProvider
                                                        size='small'
                                                        dateAdapter={AdapterDateFns}                                                        
                                                    >
                                                        <DatePicker                                                            
                                                            name='fecNacimiento'
                                                            label='Data Nascimento'
                                                            inputFormat='dd/MM/yyyy'
                                                            value={_Cliente.fecNacimiento}
                                                            onChange={handleFecNac}
                                                            renderInput={(params) => (
                                                                <TextField                                                                    
                                                                    variant='standard'
                                                                    fullWidth
                                                                    size='small'
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>  */}
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdEjecutivo'>
                                                        Executivo definido
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdEjecutivo}
                                                            onChange={handleCliente}
                                                            label='intIdEjecutivo'
                                                            inputProps={{
                                                                name: 'intIdEjecutivo',
                                                                id: 'intIdEjecutivo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>  
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtAlias'
                                                        name='txtAlias'
                                                        label='Alias WWP, Mayoreo, BarterLuxe'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtAlias}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>                            
                                            </Grid>
                                        </Grid>
                                    </fieldset>

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '30px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; DADOS DE CONTATO &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container alignItems='center' spacing={2}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtNombre'
                                                        name='txtNombre'
                                                        label='Nome'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtNombre}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtCargo'
                                                        name='txtCargo'
                                                        label='Cargo'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtCargo}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtTelefono'
                                                        name='txtTelefono'
                                                        label='Teléfono'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefono}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    //disabled='true'
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtEmail'
                                                        name='txtEmail'
                                                        label='Email'
                                                        type='email'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEmail}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                </Grid>                                         
                                                <Grid item xs={4} >
                                                    <TextField
                                                        required
                                                        id='txtContacto'
                                                        name='txtContacto'
                                                        label="Contato Interno"
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtContacto}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px #c3e0f3',
                                            marginTop: '30px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; ENDEREÇO MATRIZ &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtDireccion'
                                                        name='txtDireccion'
                                                        label='Rua e No int./No ext.'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtDireccion}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtReferencia'
                                                        name='txtReferencia'
                                                        label='Referencia'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtReferencia}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtLocalidad'
                                                        name='txtLocalidad'
                                                        label='Localidad'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtLocalidad}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtMunicipio'
                                                        name='txtMunicipio'
                                                        label='Municipio'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtMunicipio}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEstado'
                                                        name='txtEstado'
                                                        label='Estado'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEstado}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 50 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <FormControl  variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdPais'>
                                                            Pais
                                                        </InputLabel>
                                                        <Select
                                                            value={_Cliente.intIdPais}
                                                            onChange={handleCliente}
                                                            label='intIdPais'
                                                            inputProps={{
                                                                name: 'intIdPais',
                                                                id: 'intIdPais',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaTipoProfesional.map((object, index) => (
                                                                <MenuItem value={object.intIdTipoProfesional}>
                                                                    {object.txtTipoProfesional}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='numCEP'
                                                        name='numCEP'
                                                        label='CEP'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.numCEP}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 25 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtTelefone'
                                                        name='txtTelefone'
                                                        label='Telefone1'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefone}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtTelefonoContacto'
                                                        name='txtTelefonoContacto'
                                                        label='Telefone2'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefonoContacto}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEmailMatriz'
                                                        name='txtEmailMatriz'
                                                        label='Email'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEmailMatriz}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider
                                                        size='small'
                                                        dateAdapter={AdapterDateFns}                                                        
                                                    >
                                                        <DatePicker                                                            
                                                            name='fecIniOpera'
                                                            label='Fecha Inicio Operaciones'
                                                            inputFormat='dd/MM/yyyy'
                                                            value={_Cliente.fecIniOpera}
                                                            onChange={handleFecAltaEmp}
                                                            renderInput={(params) => (
                                                                <TextField                                                                    
                                                                    variant='standard'
                                                                    fullWidth
                                                                    size='small'
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEmailMatriz'
                                                        name='txtEmailMatriz'
                                                        label='Email'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtObservaciones}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 250 }}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </fieldset>

                                    <Grid sx={{ marginTop: '30px' }}>
                                        <Button
                                            type='submit'
                                            className='btn-footer'
                                            startIcon={<SaveIcon />}
                                            size='small'
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </Paper>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            {/* </Paper> */}
            
        </ThemeProvider>
    )
}

export default ClientesAdd