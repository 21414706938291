import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";
import "../../Components/Styles/Styles.css";
import Chip from '@mui/material/Chip'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

import MUIDataTable from "../../Util/MUIDataTable";

import requests from "../../Components/AxiosCalls/AxiosCall";
import Theme from "../../Components/Styles/Theme";
import TipoGastoAdd from "./../../Components/Catalogos/TipoGastos/TipoGastoAdd";
import TipoGastoEdit from "./../../Components/Catalogos/TipoGastos/TipoGastoEdit";
import TipoGastoDelete from "./../../Components/Catalogos/TipoGastos/TipoGastoDelete";

const TipoGastoView = (props) => {
  const [registros, setRegistros] = useState([]);
  const [Id, setId] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(!open2);
  };

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => {
    setOpen3(!open3);
  };

  const getRegistros = () => {
    props.setOpenLoadingScreen();
    requests
      .get("CtTipoGasto/ObtenerLista")
      .then((response) => {
        setRegistros(response);
        props.setCloseLoadingScreen();
        setId(null)
      })
      .catch((error) => {
        console.log("Error: " + error);
        props.setCloseLoadingScreen();
      });
  };

  useEffect(() => {
    getRegistros();
  }, []);

  var id = 0;
  const eliminarDepto = (id) => {
    props.setOpenLoadingScreen();
    requests
      .delete("CtTipoGasto/Eliminar/" + id)
      .then((response) => {
        props.setTypeSnackBar("success");
        props.setMessageSnackBar(response.message);
        props.setOpenSnackBar(true);
        props.setCloseLoadingScreen();
        getRegistros();
      })
      .catch((error) => {
        props.setTypeSnackBar("warning");
        props.setMessageSnackBar(error.data.message);
        props.setOpenSnackBar(true);
        props.setCloseLoadingScreen();
      });
  };

  const columns = [
    {
      name: "intIdTipoGasto",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "id",
      label: "#",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
      },
    },
    {
      name: "txtTipoGasto",
      label: "Tipo de gasto",
      options: {
        columnOrder: true,
        filter: false,
      },
    },
    {
      name: "txtCuenta",
      label: "Cuenta",
      options: {
        columnOrder: true,
        filter: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar gasto' arrow aria-label='add'>
              <Button
                className='font-btn-editar'
                onClick={function () {
                  setId(tableMeta.rowData[0])
                  handleOpen2()
                }}
              >
                <EditIcon sx={{ mr: 1 }} />
              </Button>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Eliminar gasto' arrow aria-label='add'>
              <Button
                className='font-btn-eliminar'
                onClick={function () {
                  setId(tableMeta.rowData[0])
                  handleOpen3()
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <Grid xs={12} sm={8} md={10} lg={12}>
        <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
          <Paper elevation={0} className="title-views">
            <Grid
              Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Chip icon={<AccountBalanceWalletIcon />} label='Tipos de gastos' color='primary' className='back-transparent font-letter font-barter font-20' />


              <Button
                className='btn-Header'
                startIcon={<AddIcon />}
                onClick={function () {
                  handleOpen();
                }}
                size='small'
              >
                Nuevo
              </Button>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12} alignItems="center" elevation={12}>
          <MUIDataTable
            title={"Lista de gastos"}
            data={registros}
            columns={columns}
          />
        </Grid>
      </Grid>
      <TipoGastoAdd
        open={open}
        onClose={handleOpen}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
      <TipoGastoEdit
        tipoId={Id}
        open={open2}
        onClose={handleOpen2}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
      <TipoGastoDelete
        tipoId={Id}
        open={open3}
        onClose={handleOpen3}
        getRegistros={getRegistros}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
    </ThemeProvider>
  );
};
export default TipoGastoView;
;
