import { ThemeProvider } from "@emotion/react"
import { Fade } from "@mui/material"
import { Link } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People'
import ListItemText from '@mui/material/ListItemText'
import Theme from '../../Styles/Theme'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ListItem from '@mui/material/ListItem'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemIcon from '@mui/material/ListItemIcon'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

const AccesoAdmin = (props) => {
    return(
        <ThemeProvider theme= {Theme}>
            <div className="container-sidebar">
            <Accordion>
				<Tooltip title="Administración" arrow aria-label="add">
				<ListItem Button>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ ml: 8 }} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{ pl: 0 }}
					>
					<ListItemIcon>
						<AdminPanelSettingsIcon color="primary" />
					</ListItemIcon>
					<Typography
						sx={{ fontWeight: "bold", textAlign: "left" }}
						color="primary"
					>
						{" "}
						Administación
					</Typography>
					</AccordionSummary>
				</ListItem>
				</Tooltip>
				<Tooltip title="Usuarios" arrow aria-label="add">
				<Link to="/Usuarios" className="text-link">
					<AccordionDetails>
					<ListItem button>
						<ListItemIcon>
						<PeopleIcon color="primary" />
						</ListItemIcon>
						<ListItemText>
						<Typography className="text-link-items" color="primary">
							Usuarios
						</Typography>
						</ListItemText>
					</ListItem>
					</AccordionDetails>
				</Link>
				</Tooltip>
			</Accordion>
            </div>
        </ThemeProvider>
    )
}
export default AccesoAdmin