import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton'
import requests from '../AxiosCalls/AxiosCall'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	boxShadow: 24,
	border: 'none!important',
}


const SolictudCxpDelete = (props) => {
    const methods = useForm();
    const { handleSubmit } = methods;
    const [_Registro, setRegistro] = useState({
        intIdSolicitdCxp: "",
        txtMotivo: "",
      });
      const LimpiarValores = () => {
        setRegistro({..._Registro,
            intIdSolicitdCxp: "",
             txtMotivo: "",
        })
      }
      const handleClose = () => {
        props.onClose();
        props.getRegistros();
        LimpiarValores();
      }
      const handleText = (event) => {
        setRegistro({..._Registro, [event.target.name]: event.target.value});
      }
    const guardar = () => {
        
        _Registro.intIdSolicitdCxp=props.deptoId;
        props.setOpenLoadingScreen();
        let url = "TbSolicitudesCxp/EliminarMv"
        requests.post(url, _Registro)
        .then((response) => {
          props.setTypeSnackBar('success');
          props.setMessageSnackBar(response.message);
          props.setOpenSnackBar(true);
          props.setCloseLoadingScreen(); 
          handleClose()
         
        }).catch((error) => {
            
          props.setTypeSnackBar('warning');
          props.setMessageSnackBar(error.data.message);
          props.setOpenSnackBar(true);
          props.setCloseLoadingScreen();  
        })
      }

  return (
    <>
        	<Modal
							open={props.open}
							onClose={props.onClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'
						>
							<Box sx={style}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											padding: '0px 10px 0px 10px',
											borderBottom: '1px solid #6c757d',
										}}
									>
										<h4>
                                           
											Describe el motivo por cual estas dando de baja esta
											solicitud de cuentas por pagar
                                            {props.deptoId}
										</h4>
										<IconButton onClick={props.onClose}>
											<CloseIcon fontSize='20px' />
										</IconButton>
									</div>
                                    <form onSubmit={handleSubmit(guardar)} autoComplete="off">
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '50px 20px 50px 20px',
										}}
									>
                                       
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<TextareaAutosize
													minRows={3}
													placeholder='Minimum 3 rows'
													id='txtMotivo'
													name='txtMotivo'
													label='Observación'
													variant='standard'
													fullWidth
													value={_Registro.txtMotivo}
													onChange={handleText}
													style={{ width: '750px', height: '300px' }}
												/>
											</Grid>
										</Grid>
                                     
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
											padding: '20px',
											borderTop: '1px solid #adb5bd',
										}}
									>
										<Button type='submit' className='btn-aceptar-modal'>
											Aceptar
										</Button>
										<Button
										onClick={props.onClose}
											className='btn-cancelar-modal '
										>
											Cancelar
										</Button>
									</div>
                                    </form>
								</div>
							</Box>
						</Modal>
    </>
  )
}

export default SolictudCxpDelete