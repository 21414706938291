import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import AddIcon from '@mui/icons-material/Add';
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import { format } from 'date-fns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import AsyncSelect from 'react-select/async'
import Chip from '@mui/material/Chip'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import {GETCUENTAPROMO_ID, CPROMO_BYID, CPROMO_EDITAR, CUENTAPROMO_CREAR} from '../../Constants/apiConstnats'
import requests from '../AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import '../Styles/Styles.css'
import { useLocation, useParams } from 'react-router-dom';
import { Checkbox, Typography } from '@mui/material'
import MUIDataTableP from '../../Util/MuiDataTableP'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DataObjectOutlined } from '@mui/icons-material'

const PromotoresEdit = (props) => {

	const {id} = useParams()

    const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))

    const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()


    const [_DFactura, setDFactura] = useState({
		lngIdPromotor: '',
		txtAlias: '',
		txtBanco: '',
		txtCuenta: '',
		txtClabe: '',
		bolActivo: false
    })

	const handleDFactura = (event) =>{
		if(event.target.value === ""){
			setDFactura({..._DFactura, [event.target.name]: '' })	
		}else{
			setDFactura({..._DFactura, [event.target.name]: event.target.value })
		}
	}

    let now = new Date();
    const FechaDia = format(new Date(now), 'yyyy/MM/dd')

	const [_Promotor, setPromotor] = useState({
        lngIdPromotor: 0,
		txtUsuario: '',
		txtNombre: '',
		txtAPaterno: '',
		txtAMaterno: '',
		intIdSexo: 0,
		txtRfc: '',
		numCodigoPostal: '',
		txtRegimenFiscal:'',
		txtNacionalidad: '',
		intIdClasificacion:'',
		intIdMetodo: '',
		intIdMoneda: '',
		intIdCalculo: '',
		intIdPeriodicidad: '',
		dblAsimilados: 0,
		dblSindicato: 0,
		dblSTP: 0,
		dblPTerceros: 0,
		dblEfectivo: 0,
		dblCostoMayoreo: 0,
		fecDia: null,
		txtFolio: '',
    })

    const handlePromotor = (event) => {
        if(event.target.value === ''){
            setPromotor({ ..._Promotor, [event.target.name]: null })
        }else{
            setPromotor({ ..._Promotor, [event.target.name]: event.target.value })
        }
	}

	const getPromotor = () => {		
		requests
			.get(CPROMO_BYID + id)
			.then((response) => {
				asignarValores(response)
			})
			.catch((error) => {
				console.log('Error: ' * error)
			})
	}

	const asignarValores = (objeto) =>{
		setPromotor({
			..._Promotor,
			lngIdPromotor: objeto === null ? '' : objeto.lngIdPromotor,
			txtUsuario: objeto === null ? '' : objeto.txtUsuario,
			txtNombre: objeto === null ? '' : objeto.txtNombre,
			txtAPaterno: objeto === null ? '' : objeto.txtAPaterno,
			txtAMaterno: objeto === null ? '' : objeto.txtAMaterno,
			intIdSexo: objeto === null ? '' : objeto.intIdSexo,
			txtRfc: objeto === null ? '' : objeto.txtRfc,
			numCodigoPostal: objeto === null ? '' : objeto.numCodigoPostal,
			txtRegimenFiscal: objeto === null ? '' : objeto.txtRegimenFiscal,
			txtNacionalidad: objeto === null ? '' : objeto.txtNacionalidad,
			intIdClasificacion: objeto === null ? '' : objeto.intIdClasificacion,
			intIdMetodo: objeto === null ? '' : objeto.intIdMetodo,
			intIdMoneda: objeto === null ? '' : objeto.intIdMoneda,
			intIdCalculo: objeto === null ? '' : objeto.intIdCalculo,
			intIdPeriodicidad: objeto === null ? '' : objeto.intIdPeriodicidad,
			dblAsimilados: objeto === null ? '' : objeto.dblAsimilados,
			dblSindicato: objeto === null ? '' : objeto.dblSindicato,
			dblSTP: objeto === null ? '' : objeto.dblSTP,
			dblPTerceros: objeto === null ? '' : objeto.dblPTerceros,
			dblEfectivo: objeto === null ? '' : objeto.dblEfectivo,
			dblCostoMayoreo: objeto === null ? '' : objeto.dblCostoMayoreo,
			fecDia: objeto === null ? '' : objeto.fecDia,
			txtFolio: objeto === null ? '' : objeto.txtFolio,
		})
	}
	const [_ListaCuenta, setListaCuentas] = useState([])
	const getCuentaPromotor = () => {		
		requests
			.get(GETCUENTAPROMO_ID + id)
			.then((response) => {
				setListaCuentas(response)
			})
			.catch((error) => {
				console.log('Error: ' * error)
			})
	}

	useEffect(() => {
		getPromotor()
		getCuentaPromotor()
	}, [])

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = CPROMO_EDITAR
		requests
		.put(url, _Promotor)
		.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				window.location.reload()			
			})
			.catch((error) => {				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})		            
	}

	const CuentaGuardar = () =>{
		if(_DFactura.txtAlias !== '' && _DFactura.txtBanco !== ''
		&& _DFactura.txtCuenta !== '' && _DFactura.txtClabe !== ''){
			let urls = CUENTAPROMO_CREAR
			_DFactura.lngIdPromotor = id
			_DFactura.bolPrincipal = false
			requests
				.post(urls, _DFactura)
				.then((response) => { 
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					window.location.reload()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
			}else{
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar("Completa todos los Campos de Cuentas Bancarias")
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			}
	}

	///Estados Radio Buttom

    const handlePromotorbol = (e) =>{
        setPromotor({..._Promotor, [e.target.name]: e.target.checked})
    }

	const handlebolCuenta = (e) =>{
        setDFactura({..._DFactura, [e.target.name]: e.target.checked})
    }

    const columns = [
		{
			name: 'lngIdCuentaPromotor',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'bolPrincipal',
			label: 'Principal',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[1]) {
						return (
							<Chip
								className='chip-Creado '
								size='small'
								label='Verdadero'
							></Chip>
						)
					}else{
						return (
							<Chip
								className='chip-Creado '
								size='small'
								label='No'
							></Chip>
						)
					}
				},
			},
		},
        {
            name: 'txtFolio',
            label: 'Folio',
            options: {
                filter: false,
                print: true,
                download: true,
            },
        },
        {
            name: 'txtAlias',
            label: 'Alias',
            options: {
                filter: false,
                print: true,
                download: true,
            },
        },
        {
            name: 'txtBanco',
            label: 'Banco',
            options: {
                filter: false,
                print: true,
                download: true,
            },
        },
		{
            name: 'txtCuenta',
            label: 'Cuenta',
            options: {
                filter: false,
                print: true,
                download: true,
            },
        },
		{
            name: 'txtClabe',
            label: 'Clabe',
            options: {
                filter: false,
                print: true,
                download: true,
            },
        },
        {
            name: 'bolActivo',
            label: 'Status',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[6]) {
                        return (
                            <div>
                                <p>VERDADERO</p>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                <p>FALSO</p>
                            </div>
                        )
                    }
                },
            }

        }
	]

    const hoy = new Date(Date.now());
	return (
		<ThemeProvider theme={Theme}>
			{/* <Paper className='containerViews3'> */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						elevation={12}
						sx={{ py: 2 }}
					>
						<Grid
							container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
						>
                            <Link to='/Promotores' style={{ textDecoration: 'none' }}>
                                <Button
                                    size='small'
                                    className='btn-Header-back '
                                    startIcon={<ArrowBackIosNewSharpIcon />}
                                >
                                    Regresar
                                </Button>
                            </Link>

                            <Chip icon={<ProductionQuantityLimitsIcon />} label='CONTROL DE PROMOTORES' color='primary'  className='back-transparent font-letter font-barter font-20'/>

                            <Chip icon={<AccountCircleIcon/>} label={InfoData.nombre + ' ' + InfoData.apellido} color='primary'  className='back-transparent font-letter font-barter font-20'/>

                            <Grid><p>Fecha y hora: {FechaDia}</p></Grid>
						</Grid>
					</Grid>
					<Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
                            <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                                <Paper elevation={0}>
                                    {/*start */}

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; ALTA PROMOTOR &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
												<Grid item xs={3}>
													<TextField
														required
														id='txtNombre'
														name='txtNombre'
														label='Nombre(s)'
														variant='standard'
														fullWidth
														value={_Promotor.txtNombre}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid> 
                                                <Grid item xs={3}>
													<TextField
														required
														id='txtAPaterno'
														name='txtAPaterno'
														label='Apellido Paterno'
														variant='standard'
														fullWidth
														value={_Promotor.txtAPaterno}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid>            
                                                <Grid item xs={3}>
													<TextField
														required
														id='txtAMaterno'
														name='txtAMaterno'
														label='Apellido Paterno'
														variant='standard'
														fullWidth
														value={_Promotor.txtAMaterno}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid> 
                                                <Grid item xs={3}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdSexo'>
                                                            Sexo                                                      
                                                        </InputLabel>
                                                        <Select              
															required                                          
                                                            value={_Promotor.intIdSexo}
                                                            onChange={handlePromotor}
                                                            label='intIdSexo'
                                                            inputProps={{
                                                                name: 'intIdSexo',
                                                                id: 'intIdSexo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Femenino</MenuItem>
															<MenuItem value={2}>Masculino</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
												<Grid item xs={3}>
													<TextField
														required
														id='txtRfc'
														name='txtRfc'
														label='RFC'
														variant='standard'
														fullWidth
														value={_Promotor.txtRfc}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid> 
                                                <Grid item xs={3}>
													<TextField
														required
														id='numCodigoPostal'
														name='numCodigoPostal'
														label='Código Postal'
														variant='standard'
														fullWidth
														value={_Promotor.numCodigoPostal}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid>            
                                                <Grid item xs={3}>
													<TextField
														required
														id='txtRegimenFiscal'
														name='txtRegimenFiscal'
														label='Régimen Fiscal'
														variant='standard'
														fullWidth
														value={_Promotor.txtRegimenFiscal}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid> 
                                                <Grid item xs={3}>
													<TextField
														required
														id='txtNacionalidad'
														name='txtNacionalidad'
														label='Nacionalidad'
														variant='standard'
														fullWidth
														value={_Promotor.txtNacionalidad}
														onChange={handlePromotor}
														size='small'
													/>
                                                </Grid>  
												<Grid item xs={2}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdClasificacion'>
                                                            Clasificacion                                                      
                                                        </InputLabel>
                                                        <Select     
															required                                                   
                                                            value={_Promotor.intIdClasificacion}
                                                            onChange={handlePromotor}
                                                            label='intIdClasificacion'
                                                            inputProps={{
                                                                name: 'intIdClasificacion',
                                                                id: 'intIdClasificacion',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Interno</MenuItem>
															<MenuItem value={2}>Externo</MenuItem>
															<MenuItem value={3}>Socio Comercial</MenuItem>
															<MenuItem value={4}>Alianza</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
												<Grid item xs={3}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdMetodo'>
															Metodo principal de pago                                                      
                                                        </InputLabel>
                                                        <Select     
															required                                                   
                                                            value={_Promotor.intIdMetodo}
                                                            onChange={handlePromotor}
                                                            label='intIdMetodo'
                                                            inputProps={{
                                                                name: 'intIdMetodo',
                                                                id: 'intIdMetodo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Transferencia</MenuItem>
															<MenuItem value={2}>Efectivo</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
												<Grid item xs={2}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdMoneda'>
                                                            Moneda                                                      
                                                        </InputLabel>
                                                        <Select   
															required                                                     
                                                            value={_Promotor.intIdMoneda}
                                                            onChange={handlePromotor}
                                                            label='intIdMoneda'
                                                            inputProps={{
                                                                name: 'intIdMoneda',
                                                                id: 'intIdMoneda',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Pesos</MenuItem>
															<MenuItem value={2}>Dolares</MenuItem>
															<MenuItem value={3}>Euros</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
												<Grid item xs={3}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdCalculo'>
															Metodo de calculo principal                                                      
                                                        </InputLabel>
                                                        <Select    
															required                                                    
                                                            value={_Promotor.intIdCalculo}
                                                            onChange={handlePromotor}
                                                            label='intIdCalculo'
                                                            inputProps={{
                                                                name: 'intIdCalculo',
                                                                id: 'intIdCalculo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Tradicional</MenuItem>
															<MenuItem value={2}>Socio-Promotor</MenuItem>
															<MenuItem value={3}>Antes de Costo Opertivo</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
												<Grid item xs={2}>
                                                    <FormControl required variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdPeriodicidad'>
															Periodicidad de pago                                                       </InputLabel>
                                                        <Select   
															required                                                     
                                                            value={_Promotor.intIdPeriodicidad}
                                                            onChange={handlePromotor}
                                                            label='intIdPeriodicidad'
                                                            inputProps={{
                                                                name: 'intIdPeriodicidad',
                                                                id: 'intIdPeriodicidad',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
															<MenuItem value={1}>Al Dia</MenuItem>
															<MenuItem value={2}>Quincenal</MenuItem>
															<MenuItem value={3}>Mensual</MenuItem>
															<MenuItem value={4}>Por Indicacion</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>                                
                                            </Grid>
                                        </Grid>
                                    </fieldset>

									<fieldset
										style={{
											borderRadius: '20px',
											border: '3px solid rgb(63 149 205)',
											marginTop: '30px',
										}}
									>
										<legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
											&nbsp; SERVICIOS CONTRATADOS &nbsp;
										</legend>
										<Grid container sx={{ pb: 2, px: 1 }}>
											<Grid container alignItems='center' spacing={2}>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolCActivo' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='Asimilados'
														variant='standard'
														value={_Promotor.dblAsimilados}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblAsimilados: values.floatValue,
															})
														}}
														size='small'
														name='dblAsimilados'
													/>
												</Grid>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bol' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='Sindicato'
														variant='standard'
														value={_Promotor.dblSindicato}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblSindicato: values.floatValue,
															})
														}}
														size='small'
														name='dblSindicato'
													/>
												</Grid>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolCActivo' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='STP'
														variant='standard'
														value={_Promotor.dblSTP}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblSTP: values.floatValue,
															})
														}}
														size='small'
														name='dblSTP'
													/>
												</Grid>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bol' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='Pagos a terceros'
														variant='standard'
														value={_Promotor.dblPTerceros}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblPTerceros: values.floatValue,
															})
														}}
														size='small'
														name='dblPTerceros'
													/>
												</Grid>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bol' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='Efectivo'
														variant='standard'
														value={_Promotor.dblEfectivo}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblEfectivo: values.floatValue,
															})
														}}
														size='small'
														name='dblEfectivo'
													/>
												</Grid>
												<Grid item xs={2} sx={{display: 'flex'}}>
													{/* <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bol' onChange={handlePromotorbol}/>} label="" /> */}
													<NumberFormat
														label='Costo Mayoreo'
														variant='standard'
														value={_Promotor.dblCostoMayoreo}
														customInput={TextField}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														suffix='%'
														fullWidth
														onValueChange={(values) => {
															setPromotor({
																..._Promotor,
																dblCostoMayoreo: values.floatValue,
															})
														}}
														size='small'
														name='dblCostoMayoreo'
													/>
												</Grid>
											</Grid>
										</Grid>
									</fieldset>

                                    
                                    <Grid sx={{ marginTop: '30px' }}>
                                        <Button
                                            type='submit'
                                            className='btn-footer'
                                            startIcon={<SaveIcon />}
                                            size='small'
                                        >
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Paper>
                            </form>
							<fieldset
								style={{
									borderRadius: '20px',
									border: '3px solid rgb(63 149 205)',
									marginTop: '30px',
								}}
							>
								<legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
									&nbsp; CUENTAS BANCARIAS &nbsp;
								</legend>
								<Grid container sx={{ pb: 2, px: 1 }}>
									<Grid container alignItems='center' spacing={2}>
										<Grid item xs={12} sx={{display: 'flex',justifyContent: 'flex-end'}}>
											<FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolActivo' onChange={handlebolCuenta}/>} label="Activo" />
										</Grid>
										<Grid item xs={3}>
											<TextField
												required
												id='txtAlias'
												name='txtAlias'
												label='Alias'
												variant='standard'
												fullWidth
												value={_DFactura.txtAlias}
												onChange={handleDFactura}
												size='small'
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												required
												id='txtBanco'
												name='txtBanco'
												label='Banco'
												variant='standard'
												fullWidth
												value={_DFactura.txtBanco}
												onChange={handleDFactura}
												size='small'
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												required
												id='txtCuenta'
												name='txtCuenta'
												label='Cuenta'
												variant='standard'
												fullWidth
												value={_DFactura.txtCuenta}
												onChange={handleDFactura}
												size='small'
												inputProps={{ maxLength:  22}}
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												required
												id='txtClabe'
												name='txtClabe'
												label='Clabe'
												variant='standard'
												fullWidth
												value={_DFactura.txtClabe}
												onChange={handleDFactura}
												size='small'
												inputProps={{ maxLength:  24}}
											/>
										</Grid>													
									</Grid>
									<MUIDataTableP
										data={_ListaCuenta}
										columns={columns}
									/>
									<Grid sx={{ marginTop: '30px' }}>
										<Button
											onClick={function () {
												CuentaGuardar()
											}}
											className='btn-footer'
											startIcon={<SaveIcon />}
											size='small'
										>
											Guardar
										</Button>
									</Grid>
								</Grid>
							</fieldset>
                        </Paper>
				</Grid>
			{/* </Paper> */}
		</ThemeProvider>
	)
}
export default PromotoresEdit
