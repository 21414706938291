import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
// import NumberFormat from 'react-number-format'
import AsyncSelect from 'react-select/async'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Grid from '@mui/material/Grid'
import StyleGeneral from '../Styles/StyleGeneral';

const SaldosEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
    const classes = StyleGeneral();
    const [_ListTipoSaldo, setListTipoSaldo] = useState([])
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_TbSaldos, setTbSaldos] = useState({   
        lngIdProveedor: 0,
        fecInicio: "",
        fecFin: "",
        dblValor: 0,
        intIdTipoSaldo: 0,
        bolCancelado: false
	})

	const LimpiarValores = () => {
		setTbSaldos({ ..._TbSaldos,
            lngIdProveedor: "",
            fecInicio: "",
            fecFin: "",
            dblValor: "",
            intIdTipoSaldo: "",
            bolCancelado: ""
            })
        setSelectedOption(null);
        setFecInicio(null)
        setFecFin(null)
	}
	const handleTbSaldos = (event) => {
		
		setTbSaldos({ ..._TbSaldos, [event.target.name]: event.target.value })
	}

    const [_Proveedores, setProveedores] = useState([])
    const getProveedores = () => {
        props.setOpenLoadingScreen()
        requests
            .get('TbProveedore/ObtenerLista')
            .then((response) => {
                setProveedores(response)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                console.log('Error: ' + error)
                props.setCloseLoadingScreen()
            })
    }

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbSaldo/Editar'
		requests
			.put(url, _TbSaldos)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

    const [selectedOption, setSelectedOption] = useState([])
    const loadOptions = async (inputValue, callback) => {
      await requests
        .get('TbProveedore/ObtenerListaParam?Search=' + inputValue)
        .then((response) => {
          callback(
            response.map((i) => ({ label: i.txtRazonSocial, value: i.lngIdProveedor}))
          )
        })
        .catch((error) => {
          console.log('Error: ' + error)
        })
    }
	const onChangeSelectedOption = (e) => {
		setSelectedOption(e);  
		setTbSaldos({..._TbSaldos, lngIdProveedor: e.value}) ; 
	
	}
    const [_FecInicio, setFecInicio] = useState (null);
    const handleFecInicio = (date) => {
        setFecInicio(date)
        setTbSaldos({ ..._TbSaldos, fecInicio: date })
    }
    const [_FecFin, setFecFin] = useState(null);
    const handleFecFin = (date) => {
        setFecFin(date);
        setTbSaldos({..._TbSaldos, fecFin: date})
    }

    const getTipoSaldo = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtTipoSaldo/ObtenerLista')
			.then((response) => {
				setListTipoSaldo(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
    useEffect(() => {
		getTipoSaldo();
        getRegistro();
	}, [props.saldoId])

	const getRegistro = () => {
		if (props.saldoId != null) {
			requests
				.get('TbSaldo/ObtenerPorId/' + props.saldoId)
				.then((response) => {
					AsignarValores(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}
    const AsignarValores = (objeto) => {
        setTbSaldos({
            ..._TbSaldos,
            lngIdSaldos: objeto === null ? '' : objeto.lngIdSaldos,
            lngIdProveedor: objeto === null ? '' : objeto.lngIdProveedor,
            fecInicio: objeto === null ? '' : objeto.fecInicio,
            fecFin: objeto === null ? '' : objeto.fecFin,
            dblValor: objeto === null ? '' : objeto.dblValor,
            intIdTipoSaldo: objeto === null ? '' : objeto.intIdTipoSaldo,
            bolCancelado: objeto === null ? '' : objeto.bolCancelado,
        })
        setFecFin(objeto.fecFin);
        setFecInicio(objeto.fecInicio);
        requests
            .get('TbProveedore/ObtenerPorId/' + objeto.lngIdProveedor)
            .then((response) => {
                let setdata = {
                    label: response.txtRazonSocial,
                    value: response.lngIdProveedor,
                }
                setSelectedOption(setdata)
                //setProducto(response)			
            })
            .catch((error) => {
                console.log('erroo' + error)
            })
    }

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar saldo
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '570px'}}>
                        <DialogContentText id='alert-dialog-description'>
                            <Grid container>
                                <Grid container alignItems='center' spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={6}>
                                    <FormControl variant='standard' fullWidth >
                                            <InputLabel id='lngIdProveedor' >
                                                Listado de proveedores
                                            </InputLabel>
                                            <Select
                                                value={_TbSaldos.lngIdProveedor}
                                                onChange={handleTbSaldos}
                                                label='lngIdProveedor'
                                               
                                                inputProps={{
                                                    name: 'lngIdProveedor',
                                                    id: 'lngIdProveedor',
                                                }}
                                            >
                                                <MenuItem aria-label='None' value='' />
                                                {_Proveedores.map((object, index) => (
                                                    <MenuItem value={object.lngIdProveedor}>
                                                        {object.txtNombreComercial}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider
                                            size="small"
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DesktopDatePicker
                                                name="fecInicio"
                                                label="Fecha inicio"
                                                inputFormat="dd/MM/yyyy"
                                                value={_FecInicio}
                                                onChange={handleFecInicio}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        //className={classes.textField}                                                      
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                        <LocalizationProvider
                                            size="small"
                                            dateAdapter={AdapterDateFns}
                                            
                                        >
                                            <DesktopDatePicker
                                                name="fecFin"
                                                label="Fecha fin"
                                                inputFormat="dd/MM/yyyy"
                                                value={_FecFin}
                                                onChange={handleFecFin}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        fullWidth 
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <NumberFormat
                                            required
                                            label='Valor'
                                            variant='standard'
                                            value={_TbSaldos.dblValor}
                                            customInput={TextField}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}                                            
                                            prefix={'$'}
                                            className={classes.textField1} 
                                            onValueChange={(values) => {
                                                setTbSaldos({
                                                    ..._TbSaldos,
                                                    dblValor: values.floatValue,
                                                })
                                            }}
                                            size='small'
                                            name='dblValor'
                                        /> */}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                        <FormControl variant='standard' fullWidth >
                                            <InputLabel id='intIdTipoSaldo' >
                                                Listado de tipo saldo
                                            </InputLabel>
                                            <Select
                                                value={_TbSaldos.intIdTipoSaldo}
                                                onChange={handleTbSaldos}
                                                label='intIdTipoSaldo'
                                               
                                                inputProps={{
                                                    name: 'intIdTipoSaldo',
                                                    id: 'intIdTipoSaldo',
                                                }}
                                            >
                                                <MenuItem aria-label='None' value='' />
                                                {_ListTipoSaldo.map((object, index) => (
                                                    <MenuItem value={object.lngIdTipoSaldo}>
                                                        {object.txtNombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default SaldosEdit
