import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import '../../Components/Styles/Styles.css'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import MUIDataTable from '../../Util/MUIDataTable'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Chip from '@mui/material/Chip'
import PeopleIcon from '@mui/icons-material/People'
import { CLIENTES_LIST, CLIENTES_REGPRINT } from '../../Constants/apiConstnats'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const ProductosView = (props) => {
	const [registros, setRegistros] = useState([])
	const [deptoId, setDeptoId] = useState()
	let navigate = useNavigate()

	const [open, setOpen] = useState(false)
	const handleOpen = (id) => {
		setDeptoId(id)
		setOpen(!open)
	}

	const handleClose = () => {
		setOpen(!open)
	}

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbProductoGral/ObtenerLista')
			.then((response) => {
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const getImprimir = (id) => {
		
		var link = document.createElement('a')
		link.target = '_blank'
		link.href = requests.Url + CLIENTES_REGPRINT + id
		
		document.body.appendChild(link)
		link.click()
	}

	const columns = [
		{
			name: 'lngIdProductoGral',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'intId',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtFolio',
			label: 'Folio',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtUsuario',
			label: 'Usuario',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'fecOperacion',
			label:'Fecha de Operación',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'intIdUnidadNegocio',
			label: 'Unidad de Negocio',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		// {
		// 	name: 'Imprimir',
		// 	label: 'Imprimir',
		// 	options: {
		// 		filter: false,
		// 		sort: true,
		// 		download: false,
		// 		customBodyRender: (value, tableMeta, updateValue) => {
		// 			return (
		// 				<Tooltip title='Imprimir' arrow aria-label='add'>
		// 					<Button
		// 						className='font-btn-imp'
		// 						onClick={function () {
		// 							navigate('/Clientes/Print/'+ tableMeta.rowData[0])
		// 						}}
		// 					>
		// 						<LocalPrintshopIcon/>								
		// 					</Button>
		// 				</Tooltip>
		// 			)
		// 		},
		// 	},
		// },
		{
			name: 'Editar',
			label: 'Editar',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Editar cliente' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									navigate('/Clientes/Edit/' + tableMeta.rowData[0])
								}}
							>
								<EditIcon /> 
							</Button>
						</Tooltip>
					)
					//}
				},
			},
		},
	]

    

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={10} md={10} lg={12} >
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<ProductionQuantityLimitsIcon />} label='Productos' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							<Link
								to='Add'
								className='text-link'
								style={{ textDecoration: 'none' }}
							>
								<Button className='btn-Header' startIcon={<AddIcon />} size='small'>
									Nuevo
								</Button>
							</Link>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center'>
					<MUIDataTable
						title={'Listado de clientes'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default ProductosView
