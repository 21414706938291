import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Checkbox, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import NumberFormat from 'react-number-format'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {SUCURSAL_LISTA,EVENTO_LISTA, CPROMO_LISTA, ASIGNA_PROMO_CREAR} from '../../Constants/apiConstnats'

const AsignarPromotor = (props) => {

	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
	}
	const [_TbAsignarPromotor, setTbAsignarPromotor] = useState({
        lngIdPromotor: null,
        intIdSucursal: null,
        intIdEvento: null,
        intIdClasificacion: null,
        intIdMetodo: null,
        intIdMoneda: null,
        bolSocio: false,
        bolAntes: false,
        bolPromotor: false,
        dblSocio: 0,
        dblAntes: 0,
        dblPromotor: 0,
        bolActivo: false,
	})

	const handlePromotor = (event) => {		
		setTbAsignarPromotor({ ..._TbAsignarPromotor, [event.target.name]: event.target.value })
	}

    const handlePromotorbol = (event) =>{
        setTbAsignarPromotor({ ..._TbAsignarPromotor, [event.target.name] : event.target.checked})
    }

    const [_ListaSucursal, setListaSucursal] = useState([])
	const getListSucursal = () => {
		props.setOpenLoadingScreen()
		requests
			.get(SUCURSAL_LISTA)
			.then((response) => {
				setListaSucursal(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

    const [_ListaEvento, setListaEvento] = useState([])
    const getListEvento = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EVENTO_LISTA)
			.then((response) => {
				setListaEvento(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

    const [_ListaPromotor, setListaPromotor] = useState([])
    const getListPromotor = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CPROMO_LISTA)
			.then((response) => {
				setListaPromotor(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getListSucursal()
        getListEvento()
        getListPromotor()
	}, [])

	

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = ASIGNA_PROMO_CREAR
		requests
			.post(url, _TbAsignarPromotor)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				window.location.reload()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
                maxWidth={'calc(100%)'}
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Asignar Promotor
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: 'calc(100%)' }}>
						<DialogContentText id='alert-dialog-description'>
                            <fieldset
                                style={{
                                    borderRadius: '20px',
                                    border: '3px solid rgb(63 149 205)',
                                    marginTop: '30px',
                                }}
                            >
                                <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    &nbsp; ASIGNAR PROMOTOR &nbsp;
                                </legend>
                                <Grid container sx={{ pb: 2, px: 1 }}>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl required variant='standard' fullWidth>
                                                <InputLabel id='intIdSucursal'>
                                                    Sucursal
                                                </InputLabel>
                                                <Select
                                                    disabled={_TbAsignarPromotor.intIdEvento !== null ? true : false} 
                                                    value={_TbAsignarPromotor.intIdSucursal}
                                                    onChange={handlePromotor}
                                                    label='intIdSucursal'
                                                    inputProps={{
                                                        name: 'intIdSucursal',
                                                        id: 'intIdSucursal',
                                                    }}
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    {_ListaSucursal.map((object, index) => (
                                                        <MenuItem value={object.lngIdSucursal}>
                                                            {object.txtAlias}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl required variant='standard' fullWidth>
                                                <InputLabel id='intIdEvento'>
                                                    Evento
                                                </InputLabel>
                                                <Select
                                                    disabled={_TbAsignarPromotor.intIdSucursal !== null ? true : false}
                                                    value={_TbAsignarPromotor.intIdEvento}
                                                    onChange={handlePromotor}
                                                    label='intIdEvento'
                                                    inputProps={{
                                                        name: 'intIdEvento',
                                                        id: 'intIdEvento',
                                                    }}
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    {_ListaEvento.map((object, index) => (
                                                        <MenuItem value={object.lngIdEvento}>
                                                            {object.txtAlias}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolActivo' onChange={handlePromotorbol}/>} label="Activo" />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl required variant='standard' size='small' fullWidth>
                                                <InputLabel id='lngIdPromotor'>
                                                    Promotor                                                      
                                                </InputLabel>
                                                <Select     
                                                    required                                                   
                                                    value={_TbAsignarPromotor.lngIdPromotor}
                                                    onChange={handlePromotor}
                                                    label='lngIdPromotor'
                                                    inputProps={{
                                                        name: 'lngIdPromotor',
                                                        id: 'lngIdPromotor',
                                                    }}
                                                    size='small'
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    {_ListaPromotor.map((object, index) => (
                                                        <MenuItem value={object.lngIdPromotor}>
                                                            {object.txtFolio + ' - ' + object.txtNombre + ' ' + object.txtAPaterno + ' ' + object.txtAMaterno}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                        <Grid item xs={3}>
                                            <FormControl required variant='standard' size='small' fullWidth>
                                                <InputLabel id='intIdClasificacion'>
                                                    Clasificacion                                                      
                                                </InputLabel>
                                                <Select     
                                                    required                                                   
                                                    value={_TbAsignarPromotor.intIdClasificacion}
                                                    onChange={handlePromotor}
                                                    label='intIdClasificacion'
                                                    inputProps={{
                                                        name: 'intIdClasificacion',
                                                        id: 'intIdClasificacion',
                                                    }}
                                                    size='small'
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    <MenuItem value={1}>Interno</MenuItem>
                                                    <MenuItem value={2}>Externo</MenuItem>
                                                    <MenuItem value={3}>Socio Comercial</MenuItem>
                                                    <MenuItem value={4}>Alianza</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                        <Grid item xs={3}>
                                            <FormControl required variant='standard' size='small' fullWidth>
                                                <InputLabel id='intIdMetodo'>
                                                    Metodo de pago                                                      
                                                </InputLabel>
                                                <Select     
                                                    required                                                   
                                                    value={_TbAsignarPromotor.intIdMetodo}
                                                    onChange={handlePromotor}
                                                    label='intIdMetodo'
                                                    inputProps={{
                                                        name: 'intIdMetodo',
                                                        id: 'intIdMetodo',
                                                    }}
                                                    size='small'
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    <MenuItem value={1}>Transferencia</MenuItem>
                                                    <MenuItem value={2}>Efectivo</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                        <Grid item xs={3}>
                                            <FormControl required variant='standard' size='small' fullWidth>
                                                <InputLabel id='intIdMoneda'>
                                                    Moneda                                                      
                                                </InputLabel>
                                                <Select   
                                                    required                                                     
                                                    value={_TbAsignarPromotor.intIdMoneda}
                                                    onChange={handlePromotor}
                                                    label='intIdMoneda'
                                                    inputProps={{
                                                        name: 'intIdMoneda',
                                                        id: 'intIdMoneda',
                                                    }}
                                                    size='small'
                                                >
                                                    <MenuItem aria-label='None' value='' />
                                                    <MenuItem value={1}>Pesos</MenuItem>
                                                    <MenuItem value={2}>Dolares</MenuItem>
                                                    <MenuItem value={3}>Euros</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> 
                                        <Grid item xs={4} sx={{display: 'flex'}}>
                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolSocio' onChange={handlePromotorbol}/>} label="" />
                                            <NumberFormat
                                                label='Socio-Promotor'
                                                variant='standard'
                                                value={_TbAsignarPromotor.dblSocio}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                suffix='%'
                                                fullWidth
                                                onValueChange={(values) => {
                                                    setTbAsignarPromotor({
                                                        ..._TbAsignarPromotor,
                                                        dblSocio: values.floatValue,
                                                    })
                                                }}
                                                size='small'
                                                name='dblSocio'
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{display: 'flex'}}>
                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolAntes' onChange={handlePromotorbol}/>} label="" />
                                            <NumberFormat
                                                label='Antes de Cost. Op.'
                                                variant='standard'
                                                value={_TbAsignarPromotor.dblAntes}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                suffix='%'
                                                fullWidth
                                                onValueChange={(values) => {
                                                    setTbAsignarPromotor({
                                                        ..._TbAsignarPromotor,
                                                        dblAntes: values.floatValue,
                                                    })
                                                }}
                                                size='small'
                                                name='dblAntes'
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{display: 'flex'}}>
                                            <FormControlLabel style={{justifyContent: 'center'}}  control={<Checkbox name='bolPromotor' onChange={handlePromotorbol}/>} label="" />
                                            <NumberFormat
                                                label='Promotor Tradicional'
                                                variant='standard'
                                                value={_TbAsignarPromotor.dblPromotor}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                suffix='%'
                                                fullWidth
                                                onValueChange={(values) => {
                                                    setTbAsignarPromotor({
                                                        ..._TbAsignarPromotor,
                                                        dblPromotor: values.floatValue,
                                                    })
                                                }}
                                                size='small'
                                                name='dblPromotor'
                                            />
                                        </Grid>											
                                    </Grid>
                                </Grid>
                            </fieldset>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Asignar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default AsignarPromotor
