import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import NumberFormat from 'react-number-format'

import {PROVEEDORES_OP_ADD} from '../../Constants/apiConstnats'

const ProveedoresOpAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_TbProveedoresOp, setTbProveedoresOp] = useState({
        txtRazonSocial: "",
        txtNombreComercial: "",
        txtRfc: "",
        bolActivo: true
	})

	const LimpiarValores = () => {
		setTbProveedoresOp({ ..._TbProveedoresOp,
                txtRazonSocial: "",
                txtNombreComercial: "",
                txtRfc: "",
                bolActivo: ""
            })
	
	}
	const handleTbProveedoresOp = (event) => {
		
		setTbProveedoresOp({ ..._TbProveedoresOp, [event.target.name]: event.target.value })
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		requests
			.post(PROVEEDORES_OP_ADD, _TbProveedoresOp)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar proveedor
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '500px'}}>
						<DialogContentText id='alert-dialog-description'>
							<TextField
								required
								id='txtRazonSocial'
								name='txtRazonSocial'
								label='Razón social:'
								variant='standard'
								fullWidth
								value={_TbProveedoresOp.txtRazonSocial}
								onChange={handleTbProveedoresOp}
							/>
							<TextField
								required
								id='txtNombreComercial'
								name='txtNombreComercial'
								label='Nombre comercial:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={_TbProveedoresOp.txtNombreComercial}
								onChange={handleTbProveedoresOp}
							/>
							<TextField
								required
								id='txtRfc'
								name='txtRfc'
								label='NIC/Identificación:'
								variant='standard'
								fullWidth
								sx={{ mt: 1 }}
								value={_TbProveedoresOp.txtRfc}
								onChange={handleTbProveedoresOp}
							/>                            
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default ProveedoresOpAdd