import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'

import { useParams } from 'react-router-dom'

import requests from '../AxiosCalls/AxiosCall'

import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'

const EmpresasPgEdit = (props) => {
	//const id = useParams()
	const classes = StyleGeneral()
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [tbEmpresasPg, setTbEmpresasPg] = useState({
		//intIdEmpresaPg: 0,
		intId: 0,
		txtEmpresaPg: '',
		txtRfc: '',
		intIdPlaza: 0,
	})
	const handleTbEmpresasPg = (event) => {
		setTbEmpresasPg({
			...tbEmpresasPg,
			[event.target.name]: event.target.value,
		})
	}
	const LimpiarValores = () => {
		setTbEmpresasPg({
			...tbEmpresasPg,
			//intIdEmpresaPg: 0,
			intId: 0,
			txtEmpresaPg: '',
			txtRfc: '',
			intIdPlaza: 0,
		})
	}

	useEffect(() => {
		getDepto()
	}, [props.empresaId])
	const getDepto = () => {
		if (props.empresaId != null) {
			requests
				.get('TbEmpresasPg/ObtenerPorId/' + props.empresaId)
				.then((response) => {
					setTbEmpresasPg(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbEmpresasPg/Editar'
		requests
			.put(url, tbEmpresasPg)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const [ListCtPlaza, setListCtPlaza] = useState([])
	const getCtPlaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListCtPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getCtPlaza()
	}, [])

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Editar empresa pagadora
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px', height: '250px' }}>
						<DialogContentText id='alert-dialog-description'>
							<Grid justifyContent='center'>
								<Grid container>
									<TextField
										required
										fullWidth
										id='txtEmpresaPg'
										label='Nombre de la empresa'
										variant='standard'
										name='txtEmpresaPg'
										className={classes.textField2}
										value={tbEmpresasPg.txtEmpresaPg}
										onChange={handleTbEmpresasPg}
									/>
								</Grid>
								<Grid container>
									<TextField
										required
										sx={{ mt: 2 }}
										fullWidth
										id='txtRfc'
										label='NIC'
										variant='standard'
										name='txtRfc'
										value={tbEmpresasPg.txtRfc}
										onChange={handleTbEmpresasPg}
									/>
								</Grid>
								<Grid container>
									<FormControl variant='standard' fullWidth sx={{ mt: 4 }}>
										<InputLabel required id='intIdPlaza'>
											Plaza
										</InputLabel>
										<Select
											labelId='intIdPlaza'
											id='intIdPlaza'
											value={tbEmpresasPg.intIdPlaza}
											onChange={handleTbEmpresasPg}
											inputProps={{
												name: 'intIdPlaza',
												id: 'intIdPlaza',
											}}
											label='Plaza'
										>
											<MenuItem aria-label='None' value=''>
												{' '}
											</MenuItem>

											{ListCtPlaza.map((object, index) => (
												<MenuItem value={object.intIdPlaza}>
													{object.txtPlaza}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default EmpresasPgEdit
