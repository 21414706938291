import React from 'react'
import { useState } from 'react'
import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import requests from '../AxiosCalls/AxiosCall'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { format } from 'date-fns'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import '../Styles/Styles.css'
import SaveIcon from '@mui/icons-material/Save'
import Chip from '@mui/material/Chip'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import {
	DEPTO_LISTA, PLAZA_LISTA, NEGOCIO_LISTA,
	TIPOSERVICIO_LISTA, TIPOPAGO_LISTA, BANCO_LISTA,
	STATUS_LISTA, CUENTASPG_LISTA, EMPRESASPG_LISTA, CUENTASPG_LISTA_EM_PG
} from '../../Constants/apiConstnats'

const SolictudCxpAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()
	const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))
	const [_Registro, SetRegistro] = useState({
		txtSolicitante: InfoData.nombre + ' ' + InfoData.apellido,
		intIdUsuario: InfoData.id,
		intIdDepartamentos: '',
		intIdProveedor: null,
		intIdPlaza: '',
		intIdNegocio: '',
		intIdTipoServicio: '',
		intIdTipoGasto: '',
		txtFolio: '',
		txtConcepto: '',
		txtRazonSocial: '',
		txtBeneficiario: '',
		txtRFC: '',
		intTipoPago: '',
		txtTipoPago: ' ',
		intIdmoneda: '',
		dblTotal: '',
		dblImporte: '',
		dblIva: '',
		txtImporteTexto: '',
		fecProxVencimiento: '',
		intIdEmpresaPg: '',
		intIdRegCuenta: null,
		txtComentarios: '',
		intIdBanco: null,
		txtTarjeta: '',
		txtClabe: '',
		txtCuenta: '',
		txtIban: '',
		intIdStatus: 3,
		txtReferencia: '',
		txtFolio: '',
		bolPriodidad: false,
		bolGastoFijo: false,
		bolCongelado: false,
	})
	const [_ListDepart, setListDepart] = useState([])
	const [_ListPlaza, setListPlaza] = useState([])
	const [_ListNegocio, setListnegocio] = useState([])
	const [_ListTipoServicio, setListTipoServicio] = useState([])
	const [_ListTipoGasto, setListTipoGasto] = useState([])
	const [_ListTipoPago, setListTipoPago] = useState([])
	const [_ListBanco, setListBanco] = useState([])
	const [_ListStatus, setListStatus] = useState([])
	const [_ListEmpresaPG, setListEmpresaPG] = useState([])
	const [_ListMonenda, setListMoneda] = useState([])
	const [_ListCuentasProv, setListCuentasProv] = useState([])
	const [_ListCuentasPg, setListCuentasPg] = useState([])
	const [_ListsProv, setListProv] = useState([])

	const getDepartamentos = () => {
		props.setOpenLoadingScreen()
		requests
			.get(DEPTO_LISTA)
			.then((response) => {
				setListDepart(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getplaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get(PLAZA_LISTA)
			.then((response) => {
				setListPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getNegocio = () => {
		props.setOpenLoadingScreen()
		requests
			.get(NEGOCIO_LISTA)
			.then((response) => {
				setListnegocio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getTipoServicio = () => {
		props.setOpenLoadingScreen()
		requests
			.get(TIPOSERVICIO_LISTA)
			.then((response) => {
				setListTipoServicio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getTipoPago = () => {
		props.setOpenLoadingScreen()
		requests
			.get(TIPOPAGO_LISTA)
			.then((response) => {
				setListTipoPago(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_BancoRequired, setBancoRequired] = useState(true)
	const handleTipoPago = (event) => {
		if (event.target.value === 1) {
			setBancoRequired(false)
		} else {
			setBancoRequired(true)
		}
		SetRegistro({ ..._Registro, intTipoPago: event.target.value })
	}
	const getBanco = () => {
		props.setOpenLoadingScreen()
		requests
			.get(BANCO_LISTA)
			.then((response) => {
				setListBanco(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getStatus = () => {
		props.setOpenLoadingScreen()
		requests
			.get(STATUS_LISTA)
			.then((response) => {

				setListStatus(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getEmpresaPg = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPRESASPG_LISTA)
			.then((response) => {
				setListEmpresaPG(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getMonenda = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtMoneda/ObtenerLista')
			.then((response) => {
				setListMoneda(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getproveedores = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbProveedores/ObtenerLista')
			.then((response) => {
				setListProv(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const handleTextProveedor = (event) => {
		//SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
		requests
			.get('TbProveedores/ObtenerPorId/' + event.target.value)
			.then((response) => {
				SetRegistro({
					..._Registro,
					intIdProveedor: response.lngIdProveedor,
				})
				requests
					.get('TbCuentasProv/ObtenerPorIdProveedor/' + response.lngIdProveedor)
					.then((response) => {
						setListCuentasProv(response)
						props.setCloseLoadingScreen()
					})
					.catch((error) => {
						console.log('Error: ' + error)
						props.setCloseLoadingScreen()
					})
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
		
	}

	const handleTextEmpresa = (event) => {
		SetRegistro({ ..._Registro, intIdEmpresaPg: event.target.value })
		requests
			.get('TbCuentasPg/ObtenerListaEmpPgVM/' + event.target.value)
			.then((response) => {
				setListCuentasPg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getDepartamentos()
		getplaza()
		getNegocio()
		getTipoServicio()
		getTipoPago()
		getBanco()
		getStatus()
		getEmpresaPg()
		getMonenda()
		getproveedores()
	}, [])

	const getUser = () => {
		return JSON.parse(sessionStorage.getItem('TokenSYSUser'))
	}

	const handleText = (event) => {
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
	}

	const handleBolPrio = (e) => {

		SetRegistro({ ..._Registro, bolPriodidad: e.target.checked })
	}

	const handleBolGasto = (e) => {
		SetRegistro({ ..._Registro, bolGastoFijo: e.target.checked })
	}

	const handleOblur = (event) => {
		let importe =
			(parseFloat(_Registro.dblIva) * parseFloat(_Registro.dblImporte)) / 100
		let importeiva = importe + parseFloat(_Registro.dblImporte)
		SetRegistro({ ..._Registro, dblTotal: importeiva })
	}
	const handleTextTipoSErv = (event) => {
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
		requests
			.get('CtTipoGasto/ObtnerListaPorServicio/' + event.target.value)
			.then((response) => {
				setListTipoGasto(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [fecPago, setfecPago] = React.useState(null)
	const handlefecPago = (date) => {
		setfecPago(date)
		SetRegistro({ ..._Registro, fecProxVencimiento: date })
	}

	const [_Proveedor, setProveedor] = useState({})
	const guardar = () => {
		props.setOpenLoadingScreen()
		_Registro.txtCuenta = _Registro.txtCuenta.toString().replace(/\s+/g, '') 
		_Registro.txtClabe = _Registro.txtClabe.toString().replace(/\s+/g, '') 
		
		if (_Registro.intIdStatus === 5 && getUser().area === 2 || _Registro.intIdStatus !== 5) {
			props.setOpenLoadingScreen()
			let url = 'TbSolicitudCxp/Crear'
			requests
				.post(url, _Registro)
				.then((responses) => {
					_Proveedor.txtFornecedores = _Registro.txtBeneficiario
					_Proveedor.txtcpf = _Registro.txtRFC
					requests.post('CtFornecedoresBr/Crear', _Proveedor)
						.then((response) => {
							console.log(response.message)
							window.location.reload()
						})
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(responses.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					navigate('/SolicitudCXP/Edit/' + responses.data)
					window.location.reload()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar('Permissões limitadas para salvar o registro [PAGA]')
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}
	const [_CuentaProv, setCuentaProv] = useState(null)
	const [_Cuenta, setCuenta] = useState(null)
	const handleCuentasProv = (event) => {
		setCuentaProv(event.target.value)
		SetRegistro({ ..._Registro, intIdRegCuenta: event.target.value })
	}

	const [_CuentaPg, setCuentaPg] = useState(null)
	const handleCuentasPg = (event) => {
		if(event.target.value === '' || event.target.value === ""){
			setCuentaPg(event.target.value)
			SetRegistro({ ..._Registro, intIdRegCuenta: null })	
		}else{
			setCuentaPg(event.target.value)
			SetRegistro({ ..._Registro, intIdRegCuenta: event.target.value })
		}
	}

	return (
		<ThemeProvider theme={Theme}>
			{/* <Paper className='containerViews2'> */}
			<Grid xs={12} sm={12} md={12} lg={12}>
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12} sx={{ py: 2 }}>
					<Paper
						elevation={0}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<div className='containerAlignHeader'>
							<Link to='/SolicitudCXP' style={{ textDecoration: 'none' }}>
								<Button
									size='small'
									className='btn-Header-back '
									startIcon={<ArrowBackIosNewSharpIcon />}
								>
									Voltar
								</Button>
							</Link>

							<Chip
								icon={<MonetizationOnIcon />}
								label='Contas por Pagar / Novo'
								color='primary'
								className='back-transparent font-letter font-barter font-20'
							/>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<div className='container'>
								<h4 className='title-solicitud'>Solicitado por: </h4>
								<h4 className='subtitle-name'>
									{InfoData.nombre + ' ' + InfoData.apellido}
								</h4>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					{/* start */}
					<Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
						<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
							<Paper elevation={0}>
								{/*start */}

								<Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={2}
								>
									<Grid item xs={4}>
										<TextField
											required
											id='txtSolicitante'
											name='txtSolicitante'
											label='Candidato'
											variant='standard'
											fullWidth
											value={_Registro.txtSolicitante}
											disabled='true'
										/>
									</Grid>
									<Grid item xs={2}>
										<TextField
											sx={{fontSize: '.5'}}
											size="small"
											id='fecDia'
											name='fecDia'
											label='Fecha'
											variant='standard'
											fullWidth
											value={format(new Date(), 'yyyy/MM/dd')}
											disabled='true'
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdNegocio'>
												Listagem de empresas
											</InputLabel>
											<Select
												required
												value={_Registro.intIdNegocio}
												onChange={handleText}
												label='intIdNegocio'
												inputProps={{
													name: 'intIdNegocio',
													id: 'intIdNegocio',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListNegocio.map((object, index) => (
													<MenuItem value={object.intIdNegocio}>
														{object.txtNegocio}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel control={<Checkbox onChange={handleBolPrio} />} label="Prioridade" />
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdDepartamentos'>
												Departamentos
											</InputLabel>
											<Select
												required
												value={_Registro.intIdDepartamentos}
												onChange={handleText}
												label='intIdDepartamentos'
												inputProps={{
													name: 'intIdDepartamentos',
													id: 'intIdDepartamentos',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListDepart.map((object, index) => (
													<MenuItem value={object.intIdDepartamentos}>
														{object.txtDepartamento}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<LocalizationProvider
											size='small'
											dateAdapter={AdapterDateFns}
											required
										>
											<DatePicker
												required
												name='fecPago'
												label='Data de pagamento'
												inputFormat='dd/MM/yyyy'
												value={fecPago}
												onChange={handlefecPago}
												renderInput={(params) => (
													<TextField
														required
														variant='standard'
														fullWidth
														size='small'
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdPlaza'>Listagem de praças</InputLabel>
											<Select
												required
												value={_Registro.intIdPlaza}
												onChange={handleText}
												label='intIdPlaza'
												inputProps={{
													name: 'intIdPlaza',
													id: 'intIdPlaza',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListPlaza.map((object, index) => (
													<MenuItem value={object.intIdPlaza}>
														{object.txtPlaza}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel control={<Checkbox onChange={handleBolGasto} />}
											label="Despesa Fixa (Aluguel, pagamento de luz, água, papelaria, etc.)" />
									</Grid>
									<Grid item xs={2}>
										<TextField
											id='txtFolio'
											name='txtFolio'
											label='Nota Fiscal'
											variant='standard'
											fullWidth
											value={_Registro.txtFolio}
											onChange={handleText}
											inputProps={{ maxLength: 20 }}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdTipoServicio'>
												Tipo de serviço
											</InputLabel>
											<Select
												required
												value={_Registro.intIdTipoServicio}
												onChange={handleTextTipoSErv}
												label='intIdTipoServicio'
												inputProps={{
													name: 'intIdTipoServicio',
													id: 'intIdTipoServicio',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListTipoServicio.map((object, index) => (
													<MenuItem value={object.intIdTipoServicio}>
														{object.txtTipoServicio}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdTipoGasto'>
												Tipo de despesa
											</InputLabel>
											<Select
												required
												value={_Registro.intIdTipoGasto}
												onChange={handleText}
												label='intIdTipoGasto'
												inputProps={{
													name: 'intIdTipoGasto',
													id: 'intIdTipoGasto',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListTipoGasto.map((object, index) => (
													<MenuItem value={object.intIdTipoGasto}>
														{object.txtTipoGasto}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<TextField
											required
											id='txtConcepto'
											name='txtConcepto'
											label='Conceito'
											variant='standard'
											fullWidth
											value={_Registro.txtConcepto}
											onChange={handleText}
										/>
									</Grid>
								</Grid>

								<fieldset
									style={{
										borderRadius: '20px',
										border: 'solid 3px #c3e0f3',
										marginTop: '20px',
									}}
								>
									<legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
										&nbsp; Dados para pagamento &nbsp;
									</legend>
									<Grid container sx={{ p: 2, px: 1 }}>
										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='flex-start'
											alignItems='center'
										>

											{/* <Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='intIdProveedor'>
														Razão Social
													</InputLabel>
													<Select

														value={_Registro.intIdProveedor}
														onChange={handleTextProveedor}
														label='intIdProveedor'
														inputProps={{
															name: 'intIdProveedor',
															id: 'intIdProveedor',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListsProv.map((object, index) => (
															<MenuItem value={object.lngIdProveedor}>
																{object.txtNombreComercial +
																	' ' +
																	object.txtRfc}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid> */}
											{/* <Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='lngIdCuentasProv'>
													Lista de contas
													</InputLabel>
													<Select
														value={_CuentaProv}
														onChange={handleCuentasProv}
														label='Cuentas'
														inputProps={{
															name: 'lngIdCuentasProv',
															id: 'lngIdCuentasProv',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListCuentasProv.map((object, index) => (
															<MenuItem value={object.lngIdCuentasProv}>
																{object.txtCuenta}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid> */}

											<Grid item xs={6}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdEmpresaPg'>
														Empresa Pagadora
													</InputLabel>
													<Select
														value={_Registro.intIdEmpresaPg}
														onChange={handleTextEmpresa}
														label='intIdEmpresaPg'
														inputProps={{
															name: 'intIdEmpresaPg',
															id: 'intIdEmpresaPg',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListEmpresaPG.map((object, index) => (
															<MenuItem value={object.intIdEmpresaPg}>
																{object.txtEmpresaPg +
																	' ' +
																	object.txtRfc}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='intIdRegCuenta'>
													Lista de contas
													</InputLabel>
													<Select
														value={_Registro.intIdRegCuenta}
														onChange={handleCuentasPg}
														label='Cuentas'
														inputProps={{
															name: 'intIdRegCuenta',
															id: 'intIdRegCuenta',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListCuentasPg.map((object, index) => (
															<MenuItem value={object.intIdCuentasPg}>
																{object.txtBanco }
																 &nbsp; - &nbsp;
																{ object.numCuenta}
																&nbsp; - &nbsp;
																{ object.numClabe}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required
													id='txtBeneficiario'
													name='txtBeneficiario'
													label='Beneficiário'
													variant='standard'
													fullWidth
													value={_Registro.txtBeneficiario}
													onChange={handleText}
													inputProps={{ maxLength: 200 }}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required
													id='txtRFC'
													name='txtRFC'
													label='CPF/CNPJ'
													variant='standard'
													fullWidth
													value={_Registro.txtRFC}
													onChange={handleText}
													inputProps={{ maxLength: 14 }}
												/>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='center'
											alignItems='center'
										>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdmoneda'>
													Lista de moedas
													</InputLabel>
													<Select
														required
														value={_Registro.intIdmoneda}
														onChange={handleText}
														label='Bancos'
														inputProps={{
															name: 'intIdmoneda',
															id: 'intIdmoneda',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListMonenda.map((object, index) => (
															<MenuItem value={object.intIdmoneda}>
																{object.txtMoneda + '-' + object.txtNomCorto}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<NumberFormat
													required
													label='Quantia'
													variant='standard'
													value={_Registro.dblImporte}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													onValueChange={(values) => {
														SetRegistro({
															..._Registro,
															dblImporte: values.floatValue,
														})
													}}
													onBlur={handleOblur}
													size='small'
													name='dblImporte'
												/>
											</Grid>
											<Grid item xs={2}>
												<NumberFormat
													required
													label='Impostos'
													variant='standard'
													value={_Registro.dblIva}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													suffix={'%'}
													fullWidth
													onValueChange={(values) => {
														SetRegistro({
															..._Registro,
															dblIva: values.floatValue,
														})
													}}
													onBlur={handleOblur}
													size='small'
													name='dblIva'
												/>
											</Grid>
											<Grid item xs={2}>
												<NumberFormat
													readOnly
													label='Total'
													variant='standard'
													value={_Registro.dblTotal}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													size='dblTotal'
													name='dblTotal'
												/>
											</Grid>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intTipoPago'>
														Tipo de pagamento
													</InputLabel>
													<Select
														required
														value={_Registro.intTipoPago}
														onChange={handleTipoPago}
														label='intTipoPago'
														inputProps={{
															name: 'intTipoPago',
															id: 'intTipoPago',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListTipoPago.map((object, index) => (
															<MenuItem value={object.intTipoPago}>
																{object.txtTipoPago}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={8}>
												<TextField
													required = {
														_Registro.intTipoPago === 2 || _Registro.intTipoPago === 7 ||
														_Registro.intTipoPago === 8 || _Registro.intTipoPago === 9 ? true : false
													}
													id='txtIban'
													name='txtIban'
													label='Referencia'
													variant='standard'
													fullWidth
													value={_Registro.txtIban}
													onChange={handleText}
													inputProps={{ maxLength: 250 }}
												/>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='flex-start'
											alignItems='center'
										>
											<Grid item xs={4}>
												<FormControl
													variant='standard'
													fullWidth
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
												>
													<InputLabel id='intIdBanco'>
													Lista de Bancos
													</InputLabel>
													<Select
														value={_Registro.intIdBanco}
														onChange={handleText}
														label='Bancos'
														inputProps={{
															name: 'intIdBanco',
															id: 'intIdBanco',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListBanco.map((object, index) => (
															<MenuItem value={object.intIdBanco}>
																{object.txtBanco}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
													id='txtClabe'
													name='txtClabe'
													label='Agencia'
													variant='standard'
													fullWidth
													value={_Registro.txtClabe}
													onChange={handleText}
													inputProps={{ maxLength: 10 }}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
													id='txtCuenta'
													name='txtCuenta'
													label='Conta'
													variant='standard'
													fullWidth
													value={_Registro.txtCuenta}
													onChange={handleText}
													inputProps={{ maxLength: 25 }}
												/>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={2}
											direction='row'
											alignItems='center'
										>
											
											<Grid item xs={4}>
												<TextField
													multiline
													maxRows={4}
													id='txtComentarios'
													name='txtComentarios'
													label='Observação'
													variant='standard'
													fullWidth
													value={_Registro.txtComentarios}
													onChange={handleText}
												/>
											</Grid>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdStatus'>Status</InputLabel>
													<Select
														required
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														disabled='true'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListStatus.map((object, index) => (
															<MenuItem value={object.intIdStatus}>
																{object.txtStatus}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</fieldset>

								<Grid sx={{ marginTop: '30px', textAlign: 'right' }}>
									<Button
										type='submit'
										className='btn-footer'
										startIcon={<SaveIcon />}
									>
										Salvar
									</Button>
								</Grid>
							</Paper>
						</form>
					</Paper>
				</Grid>
			</Grid>
			{/* </Paper> */}
		</ThemeProvider>
	)
}

export default SolictudCxpAdd
