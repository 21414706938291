import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { ThemeProvider } from '@mui/material/styles'
import requests from '../AxiosCalls/AxiosCall'

import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'

const CuentasPgAdd = (props) => {
	const classes = StyleGeneral()
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}

	const [tbCuentasPg, setTbCuentasPg] = useState({
		//intIdCuentasPg: 0,
		intIdEmpresaPg: 0,
		intIdBanco: 0,
		numCuenta: 0,
		numClabe: 0,
		intIdPlaza: 0,
		txtAlias: '',
		bolActiva: true,
	})
	const handleTbCuentasPg = (event) => {
		setTbCuentasPg({ ...tbCuentasPg, [event.target.name]: event.target.value })
	}
	const LimpiarValores = () => {
		setTbCuentasPg({
			...tbCuentasPg,
			intIdEmpresaPg: 0,
			intIdBanco: 0,
			numCuenta: 0,
			numClabe: 0,
			intIdPlaza: 0,
			txtAlias: '',
			bolActiva: true,
		})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbCuentasPg/Crear'
		requests
			.post(url, tbCuentasPg)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const [ListCtPlaza, setListCtPlaza] = useState([])
	const getCtPlaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListCtPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [ListTbEmpresaPg, setListTbEmpresaPg] = useState([])
	const getTbEmpresaPg = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbEmpresasPg/ObtenerLista')
			.then((response) => {
				setListTbEmpresaPg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [ListCtBanco, setListCtBanco] = useState([])
	const getCtBanco = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtBanco/ObtenerLista')
			.then((response) => {
				setListCtBanco(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getCtPlaza()
		getTbEmpresaPg()
		getCtBanco()
	}, [])

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				maxWidth={'700px'}
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='titleContainerHeaderModal'
						>
							Agregar nueva cuenta
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							<Grid container>
								<Grid container spacing={2} sx={{ mt: 1 }}>
									<Grid item xs={6}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intIdEmpresaPg'>
												Empresa pagadora
											</InputLabel>
											<Select
												required
												fullWidth
												labelId='intIdEmpresaPg'
												value={tbCuentasPg.intIdEmpresaPg}
												onChange={handleTbCuentasPg}
												inputProps={{
													name: 'intIdEmpresaPg',
													id: 'intIdEmpresaPg',
												}}
												label='Empresa pagadora'
											>
												<MenuItem value=''></MenuItem>

												{ListTbEmpresaPg.map((object, index) => (
													<MenuItem value={object.intIdEmpresaPg}>
														{object.txtEmpresaPg}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intIdBanco'>Banco</InputLabel>
											<Select
												required
												fullWidth
												labelId='intIdBanco'
												id='intIdBanco'
												value={tbCuentasPg.intIdBanco}
												onChange={handleTbCuentasPg}
												inputProps={{
													name: 'intIdBanco',
													id: 'intIdBanco',
												}}
												label='Banco'
											>
												<MenuItem value=''></MenuItem>

												{ListCtBanco.map((object, index) => (
													<MenuItem value={object.intIdBanco}>
														{object.txtBanco}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={2} >
									<Grid item xs={6}>
										<TextField
											required
											sx={{ mr: 2 }}
											id='numCuenta'
											label='Número de cuenta'
											variant='standard'
											name='numCuenta'
											fullWidth
											value={tbCuentasPg.numCuenta}
											onChange={handleTbCuentasPg}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											required
											sx={{ mr: 2 }}
											id='numClabe'
											label='Clabe'
											variant='standard'
											name='numClabe'
											fullWidth
											value={tbCuentasPg.numClabe}
											onChange={handleTbCuentasPg}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2} >
									<Grid item xs={6}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intIdPlaza'>Plaza</InputLabel>
											<Select
												required
												fullWidth
												labelId='intIdPlaza'
												id='intIdPlaza'
												value={tbCuentasPg.intIdPlaza}
												onChange={handleTbCuentasPg}
												inputProps={{
													name: 'intIdPlaza',
													id: 'intIdPlaza',
												}}
												label='Plaza'
											>
												<MenuItem value=''></MenuItem>

												{ListCtPlaza.map((object, index) => (
													<MenuItem value={object.intIdPlaza}>
														{object.txtPlaza}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<TextField
											required
											id='txtAlias'
											label='Alias'
											variant='standard'
											name='txtAlias'
											fullWidth
											value={tbCuentasPg.txtAlias}
											onChange={handleTbCuentasPg}
										/>
									</Grid>
								</Grid>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cancelar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default CuentasPgAdd
