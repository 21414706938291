import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { format, compareAsc } from 'date-fns'
import '../../Components/Styles/Styles.css'
import MUIDataTable from '../../Util/MUIDataTable'
import NumberFormat from 'react-number-format'
import TextField from '@mui/material/TextField'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import SearchIcon from '@mui/icons-material/Search'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Chip from '@mui/material/Chip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DownloadingIcon from '@mui/icons-material/Downloading';

const ReporteFechaRegistro = (props) => {
	const [registros, setRegistros] = useState([])
	const [LisStatus, setLisStatus] = useState([])
	const [Lisuser, setLisuser] = useState([])
	const [Lisprovee, setLisprovee] = useState([])
	const [Listipog, setListipog] = useState([])
	const [LisDepa, setLisDepa] = useState([])
	
	const [param, setparam] = useState({
		fechaIncio: '',
		fechaFin: '',
		intIdStatus: null,
		intIdUsuario: null,
		intIdDepartamentos: null,
		lngIdProveedor: null,
		idProveedor: null,
		intTipoPago: null,
		unidadNeg: null,
	})
	
	const [fechaIni, setfechaIni] = React.useState(null)
	const handlefechaIni = (date) => {
		setfechaIni(date)
	}

	const [fechaFin, setfechaFin] = React.useState(null)
	const handlefechaFin = (date) => {
		setfechaFin(date)
	}

	const Consultar = () => {
		if(fechaIni !== null && fechaFin !== null){

		param.fechaIncio = fechaIni
		param.fechaFin = fechaFin

		props.setOpenLoadingScreen()
		let url = 'Reports/ReporteFechaRegistro'
		requests
			.post(url, param)
			.then((response) => {
				setRegistros(response)
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
		}else{
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar("Ingresa Datas")
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}

	const GetReporte = () => {

		if(fechaIni !== null && fechaFin !== null ){
			let url =
				requests.Url +
				'Reports/ReportSolicitudExcel?FechaIncio=' +
				format(new Date(param.fechaIncio), 'yyyy/MM/dd') +
				'&FechaFin=' +
				format(new Date(param.fechaFin), 'yyyy/MM/dd')

			var urln =  url

			if(param.unidadNeg !== null){
				urln = urln + '&UnidadNeg=' + param.unidadNeg
			}
			if(param.intIdUsuario !== null){
				urln = urln + '&IntIdUsuario=' + param.intIdUsuario
			}
			if(param.intIdDepartamentos !== null){
				urln = urln + '&IntIdDepartamentos=' +	param.intIdDepartamentos
			}
			if(param.lngIdProveedor !== null){
				urln = urln + '&LngIdProveedor=' + param.lngIdProveedor
			}
			if(param.intIdStatus !== null){
				urln = urln + '&IntIdStatus=' +	param.intIdStatus
			}
			if(param.intTipoPago !== null){
				urln = urln + '&IntTipoPago=' +	param.intTipoPago
			}

			var link = document.createElement('a')
			link.target = '_blank'
			link.href = urln
			document.body.appendChild(link)
			link.click()
		}else{
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar("Ingresa Datas")
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}
	const getStatus = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtStatus/ObtenerLista')
			.then((response) => {
				setLisStatus(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getUsuario = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbUsuario/ObtenerLista')
			.then((response) => {
				setLisuser(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const gettipopago = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtTipoPago/ObtenerLista')
			.then((response) => {
				setListipog(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getProveedor = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbProveedore/ObtenerLista')
			.then((response) => {
				setLisprovee(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getDepa = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtDepartamento/ObtenerLista')
			.then((response) => {
				setLisDepa(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const handleText = (event) => {
		
		if(event.target.value === ""){
			setparam({ ...param, [event.target.name]: null })
		}else{
			setparam({ ...param, [event.target.name]: event.target.value })
		}
	}

	const [_ListaUnidadNeg, setListaUnidadNeg] = useState([])
	const getListaUnidadNeg = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtNegocio/ObtenerLista')
			.then((response) => {
				setListaUnidadNeg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getStatus()
		getUsuario()
		gettipopago()
		getProveedor()
		getDepa()
		getListaUnidadNeg()
	}, [])

	const columns = [
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'folio',
			label: 'Reg ',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<strong>{tableMeta.rowData[1]}</strong>
					</div>
					);
				}
			},
		},
		{
			name: 'concepto',
			label: 'Conceito',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'solicitante',
			label: 'Candidato',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'beneficiario',
			label: 'Beneficiario',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'departamento',
			label: 'Departamento ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'plaza',
			label: 'Praça',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'negocio',
			label: 'Negócios',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoServicio',
			label: 'Tipo de serviço',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoGasto',
			label: 'Tipo gasto',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'total',
			label: 'Quantia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <NumberFormat className='bold mr1 ml1'
						 value={tableMeta.rowData[10]} displayType={'text'} fixedDecimalScale={true}
                            decimalScale={2} thousandSeparator={true} prefix={'$'} />
                    );
                },
			},
		},
		{
			name: 'referencia',
			label: 'Referencia',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'fechaCreacion',
			label: 'Data Dia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[13] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'fechaVencimiento',
			label: 'Expiração',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[14] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'fechaPagado',
			label: 'Data do Pagamento',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[15] !== '01/01/0001'? value : ''
				},
			},
		},
	]

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={10} sm={12} md={12} lg={12} >
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<div className='containerHeader'>
							<div style={{ width: '100%', paddingTop: '20px' }}>
								<Grid container
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={2}>
									<Grid item xs={12} lg={3}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker                                                            
												name='Fecha inicio'
												label='Data de início da criação'
												inputFormat='dd/MM/yyyy'
												value={fechaIni}
												onChange={handlefechaIni}
												renderInput={(params) => (
													<TextField 
														label="Data de início da criação"                                                                   
														variant='standard'
														fullWidth
														size='small'
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} lg={3}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker                                                            
												name='Fecha Fin'
												label='Data término da criação'
												inputFormat='dd/MM/yyyy'
												value={fechaFin}
												onChange={handlefechaFin}
												renderInput={(params) => (
													<TextField 
														label="Data término da criação"                                                                   
														variant='standard'
														fullWidth
														size='small'
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} lg={3}>
										<FormControl variant='standard' size='small' fullWidth>
											<InputLabel id='intIdNegocio'>Negócios</InputLabel>
											<Select
												value={param.unidadNeg}
												onChange={handleText}
												label='intIdNegocio'
												inputProps={{
													name: 'unidadNeg',
													id: 'unidadNeg',
												}}
												size='small'
											>
												<MenuItem aria-label='None' value='' />
												{_ListaUnidadNeg.map((object, index) => (
													<MenuItem value={object.intIdNegocio}>
														{object.txtNegocio}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								
								<Grid container spacing={1}
									direction="row"
									justifyContent="center"
									alignItems="center"
									sx={{mt: 2}}
								>
									<Grid item xs={12} lg={3}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intTipoPago'>
											Tipo de pagamento
											</InputLabel>
											<Select
												size='small'
												value={param.intTipoPago}
												onChange={handleText}
												label='intTipoPago'
												inputProps={{
													name: 'intTipoPago',
													id: 'intTipoPago',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{Listipog.map((object, index) => (
													<MenuItem value={object.intTipoPago}>
														{object.txtTipoPago}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} lg={3}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intIdDepartamentos'>
												Departamento
											</InputLabel>
											<Select
												size='small'
												value={param.intIdDepartamentos}
												onChange={handleText}
												label='intIdDepartamentos'
												inputProps={{
													name: 'intIdDepartamentos',
													id: 'intIdDepartamentos',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{LisDepa.map((object, index) => (
													<MenuItem value={object.intIdDepartamentos}>
														{object.txtDepartamento}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} lg={3}>
										<FormControl variant='standard' fullWidth>
											<InputLabel id='intIdStatus'>
												Status
											</InputLabel>
											<Select
												size='small'
												value={param.intIdStatus}
												onChange={handleText}
												label='intIdStatus'
												inputProps={{
													name: 'intIdStatus',
													id: 'intIdStatus',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{LisStatus.map((object, index) => (
													<MenuItem value={object.intIdStatus}>
														{object.txtStatus}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={2}
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
									sx={{mt: 2}}
								>
									<Grid item xs={12} lg={2}>
										<Button
											className='btn-Header2'
											startIcon={<SearchIcon />}
											onClick={() => {
												Consultar()
											}}
										>
											Procurar
										</Button>
									</Grid>
									<Grid item xs={12} lg={2}>
										<Button
											className='btn-Header2'
											startIcon={<DownloadingIcon />}
											onClick={() => {
												GetReporte()
											}}
										>
											Excel
										</Button>
									</Grid>
								</Grid>

								<Grid
									Grid
									item
									xs={4}
									sm={4}
									md={12}
									lg={12}
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<Grid container spacing={1}>
										<Grid item xs={2}></Grid>

										<Grid item xs={2}></Grid>

										<Grid item xs={2}></Grid>
										<Grid item xs={2}></Grid>
										<Grid item xs={2}></Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								</Grid>
							</div>
						</div>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center' elevation={12}>
					<MUIDataTable
						title={'Lista de solicitudes'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default ReporteFechaRegistro
