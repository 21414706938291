import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import '../../Components/Styles/Styles.css'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import MUIDataTable from '../../Util/MUIDataTableChk'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import Chip from '@mui/material/Chip'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { format } from 'date-fns'
import {CPROMO_LISTA,ASIGNA_PROMO } from '../../Constants/apiConstnats'
import AsignarPromotor from '../../Components/Promotores/AsignarPromotor'
import PersonIcon from '@mui/icons-material/Person';
import EditAsign from '../../Components/Promotores/EditAsign'
import NumberFormat from 'react-number-format';

const PromotoresView = (props) => {
	const [registros, setRegistros] = useState([])
	const [regis, setRegis] = useState([])
	const [IdAsignar, setIdAsignar] = useState(0)

	let navigate = useNavigate()

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	const [openEdit, setOpenEdit] = useState(false)
	const handleOpenEdit = () =>{
		setOpenEdit(!openEdit)
	}

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CPROMO_LISTA)
			.then((response) => {
				setRegistros(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getRegis = () => {
		props.setOpenLoadingScreen()
		requests
			.get(ASIGNA_PROMO)
			.then((response) => {
				setRegis(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
		getRegis()
	}, [])

	const columns = [
		{
			name: 'lngIdPromotor',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'intId',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtFolio',
			label: 'Folio',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {                    
                    return (<strong>{tableMeta.rowData[2]}</strong>)
                }
			},
		},
		{
			name: 'txtNombre',
			label: 'Nombre',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'fecDia',
			label:'Fecha de Operación',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
                    var fecReg = format(new Date(tableMeta.rowData[4]), 'dd/MM/yyyy');
                    return (<p>{fecReg}</p>)
                }
			},
		},
		{
			name: 'txtRfc',
			label: 'RFC',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtNacionalidad',
			label: 'Nacionalidad',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'Editar',
			label: 'Editar',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Editar Promotor' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									navigate('/Promotores/Edit/' + tableMeta.rowData[0])
								}}
							>
								<EditIcon /> 
							</Button>
						</Tooltip>
					)
				},
			},
		},
	]

	const columnas = [
		{
			name: 'lngIdAPromotor',
			label: '#',
			options: {
				display: 'excluded',
				filter: false,
				sort: true,
			},
		},
		{
			name: 'intId',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'promotor',
			label: 'Promotor',
			options: {
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'sucursal',
			label: 'Sucursal',
			options: {
				display: 'excluded',
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'evento',
			label: 'Evento',
			options: {
				display: 'excluded',
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'Sucursal/Evento',
			label: 'Sucursal/Evento',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[3] !== null && tableMeta.rowData[3] !== undefined && tableMeta.rowData[3] !== '' ) {
						return (
							<p>{tableMeta.rowData[3]}</p>
						)
					}
					if (tableMeta.rowData[4] !== null && tableMeta.rowData[4] !== undefined && tableMeta.rowData[4] !== '') {
						return (
							<p>{tableMeta.rowData[4]}</p>
						)
					}
				},
			},
		},
		{
			name: 'clasificacion',
			label: 'Clasificacion',
			options: {
				filter: true,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[6] === 1) {
						return (
							<p>Interno</p>
						)
					}
					if (tableMeta.rowData[6] === 2) {
						return (
							<p>Externo</p>
						)
					}
					if (tableMeta.rowData[6] === 3) {
						return (
							<p>Socio Comercial</p>
						)
					}
					if (tableMeta.rowData[6] === 4) {
						return (
							<p>Alianza</p>
						)
					}
				},
			},
		},
		{
            name: 'dblSocio',
			label: 'Socio-Promotor %',
			options: {
				filter: false,
				print: true,
				download: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<NumberFormat
							className='bold'
							displayType={'text'}
							value={tableMeta.rowData[7]}
							thousandSeparator={true}
							fixedDecimalScale={true}
							decimalScale={2}
							prefix={'$'}
						/>
					</div>
					);
				}
			},
        },
        {
            name: 'dblAntes',
			label: 'Antes Cost Op %',
			options: {
				filter: false,
				print: true,
				download: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<NumberFormat
							className='bold'
							displayType={'text'}
							value={tableMeta.rowData[8]}
							thousandSeparator={true}
							fixedDecimalScale={true}
							decimalScale={2}
							prefix={'$'}
						/>
					</div>
					);
				}
			},
        },
        {
            name: 'dblPromotor',
			label: 'Tradicional',
			options: {
				filter: false,
				print: true,
				download: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<NumberFormat
							className='bold'
							displayType={'text'}
							value={tableMeta.rowData[9]}
							thousandSeparator={true}
							fixedDecimalScale={true}
							decimalScale={2}
							prefix={'$'}
						/>
					</div>
					);
				}
			},
        },
        {
			name: 'periodicidad',
			label: 'Periodicidad',
			options: {
				filter: true,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[10] === 1) {
						return (
							<p>Al Día</p>
						)
					}
					if (tableMeta.rowData[10] === 2) {
						return (
							<p>Quincenal</p>
						)
					}
					if (tableMeta.rowData[10] === 3) {
						return (
							<p>Mensual</p>
						)
					}
					if (tableMeta.rowData[10] === 4) {
						return (
							<p>Por Indicación</p>
						)
					}
				},
			},
		},
        {
			name: 'fecInicio',
			label: 'Inicio Oper.',
			options: {
				filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {                  
                    var fecReg = format(new Date(tableMeta.rowData[11]), 'dd/MM/yyyy');
                    return (<p>{fecReg}</p>)
                }
			},
		},
        {
			name: 'bolActivo',
			label: 'Activo',
			options: {
				filter: true,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[12] === true) {
						return (
							<p>VERDADERO</p>
						)
					}
					if (tableMeta.rowData[12] === false) {
						return (
							<p>FALSO</p>
						)
					}
				},
			},
		},
		{
			name: 'Editar',
			label: 'Editar',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Editar Promotor' arrow aria-label='add'>
							<Button
								className='font-btn-editar'
								onClick={function () {
									setIdAsignar(tableMeta.rowData[0])
									handleOpenEdit()
								}}
							>
								<EditIcon /> 
							</Button>
						</Tooltip>
					)
				},
			},
		},

	]

    

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={10} md={10} lg={12} >
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<PersonIcon />} label='Promotores' color='primary'  className='back-transparent font-letter font-barter font-20'/>
							<Link
								to='Add'
								className='text-link'
								style={{ textDecoration: 'none' }}
							>
								<Button className='btn-Header' startIcon={<AddIcon />} size='small'>
									Nuevo
								</Button>
							</Link>							
							<Button 
								className='btn-Header' 
								startIcon={<PersonAddAltIcon />} 
								size='small'
								onClick={function () {
									handleOpen()
								}}
								sx={{minWidth: '120px'}}
							>
								Asignar
							</Button>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center'>
					<MUIDataTable
						title={'Listado de Promotores'}
						data={registros}
						columns={columns}
					/>
				</Grid>
				<Grid item xs={12} md={12} lg={12} alignItems='center'>
					<MUIDataTable
						title={'Listado de Asignaciones'}
						data={regis}
						columns={columnas}
					/>
				</Grid>
			</Grid>
			<AsignarPromotor
				open={open}
				onClose={handleOpen}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			<EditAsign
				open={openEdit}
				onClose={handleOpenEdit}
				IdAsignar= {IdAsignar}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</ThemeProvider>
	)
}
export default PromotoresView
