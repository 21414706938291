import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MUIDataTableChk from '../../Util/MUIDataTableChk'
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import NumberFormat from 'react-number-format'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import SaveIcon from '@mui/icons-material/Save'
import Chip from '@mui/material/Chip'
import PeopleIcon from '@mui/icons-material/People'
import requests from '../AxiosCalls/AxiosCall'
import Theme from '../Styles/Theme'
import '../Styles/Styles.css'
import { Checkbox, Typography } from '@mui/material'
import { AREACON_OP_LIST, 
    CLIENTES_LISTVM, 
    COBERTURA_OP_LIST, 
    CONTACTOINT_OP_LIST, 
    EJEOP_LIST, 
    TIPOCLI_OP_LIST, 
    UBICACION_OP_LIST,
    CLIENTES_EDIT,
    GIRO_OP_LIST,
    STATUS_CLI_LIST,
    ESTADO_OP_LIST,
    CLIENTES_BY_ID
 } from '../../Constants/apiConstnats'
 import {CHECKLISTPRINT,CHECKLISTVM} from '../../Constants/apiConstnats'
import {CHECKLIST_EDIT, CHECKLIST_PRINT} from '../../Constants/routesConstants'

const ClientesEdit = (props) => {
    const { id } = useParams()
    const methods = useForm()
    const { handleSubmit } = methods
    let navigate = useNavigate()

    const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))

    const [_Cliente, setCliente] = useState({
        txtNombre: "",
        txtApellido: "",
        txtIdentificacion: "",
        txtNitIdentificacion:"",
        bolOrganoRegistro: null,
        txtOrganoOtro: "",
        fecNacimiento: null,
        txtNacionalidad: "",
        txtCiudadPais: "",
        intIdEstadoCivil: 0,
        txtEmail: "",
        txtDireccion: "",
        txtCodigoPostal: null,
        intIdTipoResidencia: 0,
        txtTelefono: "",
        txtCelular: "",
        txtDatosComerciales: "",
        txtNombreEmpresa:"",
        txtActividadEconomica: "",
        txtFacturacion: "",
        txtDireccionComercial: "",
        txtTelefonoEmpresa:"",
        txtContacto:"",
        txtTelefonoContacto:"",
        fecApertura: null,
        txtNitEmpresa: "",
        txtCodigoPostalEmp: "",
        txtDatosProfesionales: "",
        intIdTipoProfesional:"",
        fecIngresoEmpresa:null,
        fecEmision: null,
        dblIngresosBrutos:null,
        txtTelefonoDP: "",
        txtContrato: "",
    })

    const handleCliente = (event) => {
        setCliente({ ..._Cliente, [event.target.name]: event.target.value });
    }

    const [_ListaGiro, setListaGiro] = useState([]);
    const getListaGiro = () => {
        props.setOpenLoadingScreen()
        requests
            .get(GIRO_OP_LIST)
            .then((response) => {
                setListaGiro(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaStatus, setListaStatus] = useState([]);
    const getListaStatus = () => {
        props.setOpenLoadingScreen()
        requests
            .get(STATUS_CLI_LIST)
            .then((response) => {
                setListaStatus(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaEstado, setListaEstado] = useState([]);
    const getListaEstado = () => {
        props.setOpenLoadingScreen()
        requests
            .get(ESTADO_OP_LIST)
            .then((response) => {
                setListaEstado(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaUbicacion, setListaUbicacion] = useState([]);
    const getListaUbicacion = () => {
        props.setOpenLoadingScreen()
        requests
            .get(UBICACION_OP_LIST)
            .then((response) => {
                setListaUbicacion(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaAreaCon, setListaAreaCon] = useState([]);
    const getListaAreaCon = () => {
        props.setOpenLoadingScreen()
        requests
            .get(AREACON_OP_LIST)
            .then((response) => {
                setListaAreaCon(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaContacto, setListaContacto] = useState([]);
    const getListaContacto = () => {
        props.setOpenLoadingScreen()
        requests
            .get(CONTACTOINT_OP_LIST)
            .then((response) => {
                setListaContacto(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaCobertura, setListaCobertura] = useState([]);
    const getListaCobertura = () => {
        props.setOpenLoadingScreen()
        requests
            .get(COBERTURA_OP_LIST)
            .then((response) => {
                setListaCobertura(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }
    
    const [_ListaTipoCliente, setListaTipoCliente] = useState([]);
    const getListaTipoCliente = () => {
        props.setOpenLoadingScreen()
        requests
            .get(TIPOCLI_OP_LIST)
            .then((response) => {
                setListaTipoCliente(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaEjecutivoOp, setListaEjecutivoOp] = useState([]);
    const getListaEjecutivoOp = () => {
        props.setOpenLoadingScreen()
        requests
            .get(EJEOP_LIST)
            .then((response) => {
                setListaEjecutivoOp(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaTipoResidencia, setListaTipoResidencia] = useState([])
    const getListaTipoResidencia = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtTipoResidencia/ObtenerLista')
            .then((response) => {
                setListaTipoResidencia(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaTipoProfesional, setListaTipoProfesional] = useState([])
    const getListaTipoProfesional = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtTipoProfesional/ObtenerLista')
            .then((response) => {
                setListaTipoProfesional(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    // const [_ListaHolding, setListaHolding] = useState([]);
    // const getListaHolding = () => {
    //     props.setOpenLoadingScreen()
    //     requests
    //         .get(CLIENTES_LISTVM)
    //         .then((response) => {
    //             setListaHolding(response)
    //             props.setCloseLoadingScreen()
    //         }).catch((error) => {
    //             console.log(error)
    //             props.setCloseLoadingScreen()
    //         })
    // }

    const [_ListaCheck, setListaCheck] = useState([]);
    const getListaCheck = () => {
        props.setOpenLoadingScreen()
        requests
            .get("TbCheckListJuri/ObtenerPorIdCliente/" + id)
            .then((response) => {
                setListaCheck(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [_ListaEdoCivil, setListaEdoCivil] = useState([]);
    const getListaEdoCivil = () => {
        props.setOpenLoadingScreen()
        requests
            .get('CtEstadoCivil/ObtenerLista')
            .then((response) => {
                setListaEdoCivil(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }


    useEffect(() => {
        getListaTipoResidencia()
        getListaTipoProfesional()
        getListaCheck()
        getListaEdoCivil()
        getRegistro()
    }, [])

    const handleFecNac = (date) => {
        setCliente({ ..._Cliente, fecNacimiento: date })
    }

    const handleFecApe = (date) => {
        setCliente({ ..._Cliente, fecApertura: date })
    }

    const handleFecAltaEmp = (date) => {
        setCliente({ ..._Cliente, fecIngresoEmpresa: date })
    }

    const getRegistro = () => {
        props.setOpenLoadingScreen()
        requests
            .get(CLIENTES_BY_ID + id)
            .then((response) => {
                AsignarValores(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const AsignarValores = (objecto) => {
        setCliente({
            ..._Cliente,
            lngIdCliente: objecto === null ? '' : objecto.lngIdCliente,
            txtNombre: objecto === null ? '' : objecto.txtNombre,
            txtNomCorto: objecto === null ? '': objecto.txtNomCorto,
            txtTelefono: objecto === null ? '': objecto.txtTelefono,
            txtIdentificacion: objecto === null ? '': objecto.txtIdentificacion,
            intIdStatusCli: objecto === null ? '': objecto.intIdStatusCli,
            txtNitIdentificacion: objecto === null ? '': objecto.txtNitIdentificacion,
            bolOrganoRegistro: objecto === null ? '': objecto.bolOrganoRegistro,
            txtOrganoOtro: objecto === null ? '': objecto.txtOrganoOtro,
            fecNacimiento: objecto === null ? '': objecto.fecNacimiento,
            txtNacionalidad: objecto === null ? '': objecto.txtNacionalidad,
            txtCiudadPais: objecto === null ? '': objecto.txtCiudadPais,
            intIdEstadoCivil: objecto === null ? '': objecto.intIdEstadoCivil,
            txtEmail: objecto === null ? '': objecto.txtEmail,
            txtDireccion: objecto === null ? '': objecto.txtDireccion,
            txtCodigoPostal: objecto === null ? '': objecto.txtCodigoPostal,
            intIdTipoResidencia: objecto === null ? '': objecto.intIdTipoResidencia,
            txtTelefono: objecto === null ? '': objecto.txtTelefono,
            txtCelular: objecto === null ? '': objecto.txtCelular,
            txtDatosComerciales: objecto === null ? '': objecto.txtDatosComerciales,
            txtNombreEmpresa:objecto === null ? '': objecto.txtNombreEmpresa,
            txtActividadEconomica: objecto === null ? '': objecto.txtActividadEconomica,
            txtFacturacion: objecto === null ? '': objecto.txtFacturacion,
            txtDireccionComercial: objecto === null ? '': objecto.txtDireccionComercial,
            txtTelefonoEmpresa:objecto === null ? '': objecto.txtTelefonoEmpresa,
            txtContacto:objecto === null ? '': objecto.txtContacto,
            txtTelefonoContacto: objecto === null ? '': objecto.txtTelefonoContacto,
            fecApertura: objecto === null ? '': objecto.fecApertura,
            fecEmision: objecto === null ? '' : objecto.fecEmision,
            txtNitEmpresa: objecto === null ? '': objecto.txtNitEmpresa,
            txtCodigoPostalEmp: objecto === null ? '': objecto.txtCodigoPostalEmp,
            txtDatosProfesionales: objecto === null ? '': objecto.txtDatosProfesionales,
            intIdTipoProfesional:objecto === null ? '': objecto.intIdTipoProfesional,
            fecIngresoEmpresa:objecto === null ? '': objecto.fecIngresoEmpresa,
            dblIngresosBrutos: objecto === null ? '': objecto.dblIngresosBrutos,
            txtTelefonoDP: objecto === null ? '': objecto.txtTelefonoDp,
            txtContrato: objecto === null ? '' : objecto.txtContrato,
        })
    }

    if(_Cliente.bolOrganoRegistro === null ){
        _Cliente.bolOrganoRegistro = 2
    }


    const regexPattern = /\b[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}\b/;

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setCliente({ ..._Cliente, txtNitEmpresa: newValue });

        if (regexPattern.test(newValue)) {
            props.setTypeSnackBar('success')
            props.setMessageSnackBar("CNPJ CORRECTO")
            props.setOpenSnackBar(true)
        }else{
            props.setTypeSnackBar('warning')
            props.setMessageSnackBar("VERIFIQUE O SEU CNPJ")
            props.setOpenSnackBar(true)
        }
    };


    const guardar = () => {
        props.setOpenLoadingScreen()
        let url = CLIENTES_EDIT
        if(_Cliente.bolOrganoRegistro === 2 ){
            _Cliente.bolOrganoRegistro = null
        }
        requests
            .put(url, _Cliente)
            .then((response) => {
                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
                navigate('/Clientes')
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
    }

    const hoy = new Date(Date.now());
    
    const getImpresoPDf = async (id) => {
		props.setOpenLoadingScreen()
		let url = requests.Url + 'GenerarPDF/GenerarPdf/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
					props.setCloseLoadingScreen()
				})
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
				console.log(error)
			})
	}

    const columns = [
		{
			name: 'lngIdCheckList',
			label: 'idCheckList',
			options: {
				display: 'excluded',
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtContrato',
			label: 'Reg',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'dblMontoContrato',
			label: 'Valor  do Contrato',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
        {
			name: 'txtEmailFacturas',
			label: 'Email',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'txtRepresentanteLegal',
			label: 'Representante',
			options: {
				filter: true,
				print: true,
				download: true,
			},
		},
		{
			name: 'Imprimir',
			label: 'Imprimir',
			options: {
				filter: false,
				sort: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title='Imprimir' arrow aria-label='add'>
							<Button
								className='font-btn-imp'
								onClick={function () {
									getImpresoPDf(tableMeta.rowData[0])									
								}}
							>
								<LocalPrintshopIcon/>								
							</Button>
						</Tooltip>
					)
				},
			},
		},
	]


    
    return (
        <ThemeProvider theme={Theme}>
            {/* <Paper className='containerViews3'> */}
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        elevation={12}
                        sx={{ py: 2 }}
                    >
                        <Paper
                            elevation={0}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className='containerAlignHeader'>
                                <Link to='/Clientes' style={{ textDecoration: 'none' }}>
                                    <Button
                                        size='small'
                                        className='btn-Header-back '
                                        startIcon={<ArrowBackIosNewSharpIcon />}
                                        
                                    >
                                        Voltar
                                    </Button>
                                </Link>

                                <Chip icon={<PeopleIcon />} label='Clientes / Editar' color='primary'  className='back-transparent font-letter font-barter font-20'/>
                            </div>
                            <div className='containerAlignHeader'>
								<div className='container-client'>
									<div style={{ display: 'flex' }}>
										<h4 className='title-solicitud'>Contrato: </h4>
										<h4 className='subtitle-name'>
											{_Cliente.txtContrato}
										</h4>
									</div>

									{/* <div className='container-folio'>
										<h4 className='title-solicitud'>Folio: </h4>
										<h4 className='subtitle-name'>{}</h4>
									</div> */}
								</div>
							</div>
                        </Paper>
                    </Grid>
                    <Grid
                        item

                        xs={12}
                        md={12}
                        lg={12}
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                    >
                        {/* start */}
                        <Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
                            <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                                <Paper elevation={0}>
                                    {/*start */}

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; DADOS GERAIS &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id='txtUsuario'
                                                        name='txtUsuario'
                                                        label='Usuario'
                                                        variant='standard'
                                                        fullWidth
                                                        value={InfoData.nombre + ' ' + InfoData.apellido}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        disabled='true'
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>Fecha: {hoy.toLocaleDateString()}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtCliente'
                                                        name='txtCliente'
                                                        label='Cliente'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtCliente}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='numCPF'
                                                        name='numCPF'
                                                        label='CPF'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.numCPF}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdGrupo'>
                                                            Grupo
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdGrupo}
                                                            onChange={handleCliente}
                                                            label='intIdGrupo'
                                                            inputProps={{
                                                                name: 'intIdGrupo',
                                                                id: 'intIdGrupo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='intIdCrm'
                                                        name='intIdCrm'
                                                        label='Id CRM'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.intIdCrm}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 10 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtActividad'
                                                        name='txtActividad'
                                                        label='Atividade preponderante:'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtActividad}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdTipoCliente'>
                                                            Tipo de Cliente
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdTipoCliente}
                                                            onChange={handleCliente}
                                                            label='intIdTipoCliente'
                                                            inputProps={{
                                                                name: 'intIdTipoCliente',
                                                                id: 'intIdTipoCliente',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdPlaza'>
                                                        Plaza de Operação
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdPlaza}
                                                            onChange={handleCliente}
                                                            label='intIdPlaza'
                                                            inputProps={{
                                                                name: 'intIdPlaza',
                                                                id: 'intIdPlaza',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {/* <Grid item xs={4}>
                                                    <LocalizationProvider
                                                        size='small'
                                                        dateAdapter={AdapterDateFns}                                                        
                                                    >
                                                        <DatePicker                                                            
                                                            name='fecNacimiento'
                                                            label='Data Nascimento'
                                                            inputFormat='dd/MM/yyyy'
                                                            value={_Cliente.fecNacimiento}
                                                            onChange={handleFecNac}
                                                            renderInput={(params) => (
                                                                <TextField                                                                    
                                                                    variant='standard'
                                                                    fullWidth
                                                                    size='small'
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>  */}
                                                <Grid item xs={4}>
                                                    <FormControl variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdEjecutivo'>
                                                        Executivo definido
                                                        </InputLabel>
                                                        <Select                                                        
                                                            value={_Cliente.intIdEjecutivo}
                                                            onChange={handleCliente}
                                                            label='intIdEjecutivo'
                                                            inputProps={{
                                                                name: 'intIdEjecutivo',
                                                                id: 'intIdEjecutivo',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {/* {_ListaEdoCivil.map((object, index) => (
                                                                <MenuItem value={object.intIdEstadoCivil}>
                                                                    {object.txtEstadoCivil}
                                                                </MenuItem>
                                                            ))} */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>  
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtAlias'
                                                        name='txtAlias'
                                                        label='Alias WWP, Mayoreo, BarterLuxe'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtAlias}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>                            
                                            </Grid>
                                        </Grid>
                                    </fieldset>

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px  #c3e0f3',
                                            marginTop: '30px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; DADOS DE CONTATO &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container alignItems='center' spacing={2}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtNombre'
                                                        name='txtNombre'
                                                        label='Nome'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtNombre}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtCargo'
                                                        name='txtCargo'
                                                        label='Cargo'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtCargo}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtTelefono'
                                                        name='txtTelefono'
                                                        label='Teléfono'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefono}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    //disabled='true'
                                                    />
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        id='txtEmail'
                                                        name='txtEmail'
                                                        label='Email'
                                                        type='email'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEmail}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                </Grid>                                         
                                                <Grid item xs={4} >
                                                    <TextField
                                                        required
                                                        id='txtContacto'
                                                        name='txtContacto'
                                                        label="Contato Interno"
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtContacto}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>

                                    <fieldset
                                        style={{
                                            borderRadius: '20px',
                                            border: 'solid 3px #c3e0f3',
                                            marginTop: '30px',
                                        }}
                                    >
                                        <legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            &nbsp; ENDEREÇO MATRIZ &nbsp;
                                        </legend>
                                        <Grid container sx={{ pb: 2, px: 1 }}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtDireccion'
                                                        name='txtDireccion'
                                                        label='Rua e No int./No ext.'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtDireccion}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtReferencia'
                                                        name='txtReferencia'
                                                        label='Referencia'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtReferencia}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtLocalidad'
                                                        name='txtLocalidad'
                                                        label='Localidad'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtLocalidad}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtMunicipio'
                                                        name='txtMunicipio'
                                                        label='Municipio'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtMunicipio}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEstado'
                                                        name='txtEstado'
                                                        label='Estado'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEstado}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 50 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <FormControl  variant='standard' size='small' fullWidth>
                                                        <InputLabel id='intIdPais'>
                                                            Pais
                                                        </InputLabel>
                                                        <Select
                                                            value={_Cliente.intIdPais}
                                                            onChange={handleCliente}
                                                            label='intIdPais'
                                                            inputProps={{
                                                                name: 'intIdPais',
                                                                id: 'intIdPais',
                                                            }}
                                                            size='small'
                                                        >
                                                            <MenuItem aria-label='None' value='' />
                                                            {_ListaTipoProfesional.map((object, index) => (
                                                                <MenuItem value={object.intIdTipoProfesional}>
                                                                    {object.txtTipoProfesional}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='numCEP'
                                                        name='numCEP'
                                                        label='CEP'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.numCEP}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 25 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtTelefone'
                                                        name='txtTelefone'
                                                        label='Telefone1'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefone}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtTelefonoContacto'
                                                        name='txtTelefonoContacto'
                                                        label='Telefone2'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtTelefonoContacto}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 15 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEmailMatriz'
                                                        name='txtEmailMatriz'
                                                        label='Email'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtEmailMatriz}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider
                                                        size='small'
                                                        dateAdapter={AdapterDateFns}                                                        
                                                    >
                                                        <DatePicker                                                            
                                                            name='fecIniOpera'
                                                            label='Fecha Inicio Operaciones'
                                                            inputFormat='dd/MM/yyyy'
                                                            value={_Cliente.fecIniOpera}
                                                            onChange={handleFecAltaEmp}
                                                            renderInput={(params) => (
                                                                <TextField                                                                    
                                                                    variant='standard'
                                                                    fullWidth
                                                                    size='small'
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id='txtEmailMatriz'
                                                        name='txtEmailMatriz'
                                                        label='Email'
                                                        type='text'
                                                        variant='standard'
                                                        fullWidth
                                                        value={_Cliente.txtObservaciones}
                                                        onChange={handleCliente}
                                                        size='small'
                                                        inputProps={{ maxLength: 250 }}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </fieldset>


                                    <Grid sx={{ marginTop: '30px' }}>
                                        <Button
                                            type='submit'
                                            className='btn-footer'
                                            startIcon={<SaveIcon />}
                                            size='small'
                                        >
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Paper>
                            </form> 
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} alignItems='center'>
                    <MUIDataTableChk
                        title={'Listado de check list jurídico'}
                        data={_ListaCheck}
                        columns={columns}
                    />
                </Grid>
            {/* </Paper> */}
            
        </ThemeProvider>
    )
}

export default ClientesEdit