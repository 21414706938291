import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { Checkbox, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import NumberFormat from 'react-number-format'

const DetalleEdit = (props) => {
    const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const [ValorAnt, setValorAnt] = useState()
	const handleClose = () => {
		props.onClose()
		LimpiarValores()
	}
	
    const [Detalle, setDetalle] = useState({
        intIdCxpDetalle: props.DetalleId,
        intIdSolicitdCxp: '',
        txtConcepto: '',
        txtNombre: '',
        dblIva: '',
        dblSubTotal: '',
        fecRegistro: '',
        txtNumFactura: '',
    })

	const [_fecRegistro, setfecRegistro] = React.useState(null)
    const handlefecRegistro = (date) => {
        setfecRegistro(date)
        setDetalle({ ...Detalle, fecRegistro: date })
    }

	const AsignarValores = (objeto) => {
		setDetalle({
			...Detalle,
            intIdCxpDetalle: objeto === null ? '' : objeto.intIdCxpDetalle,
            intIdSolicitdCxp: objeto === null ? '' : objeto.intIdSolicitdCxp,
            txtConcepto: objeto === null ? '' : objeto.txtConcepto,
            txtNombre: objeto === null ? '' : objeto.txtNombre,
            dblIva: objeto === null ? '' : objeto.dblIva,
            dblSubTotal: objeto === null ? '' : objeto.dblSubTotal,
            fecRegistro: objeto === null ? '' : objeto.fecRegistro,
            txtNumFactura: objeto === null ? '' : objeto.txtNumFactura,
		})
        setfecRegistro(Date(objeto.fecRegistro.toString()))
	}

	const LimpiarValores = () => {
		setDetalle({
			...Detalle,
			intIdSolicitdCxp: '',
            txtConcepto: '',
            txtNombre: '',
            dblIva: '',
            dblSubTotal: '',
            fecRegistro: '',
            txtNumFactura: '',
		})
	}

	const handleDetalle = (event) => {
		setDetalle({ ...Detalle, [event.target.name]: event.target.value })
	}

	const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))
	
	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbCxpDetalle/Editar'
		requests
			.put(url, Detalle)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
				window.location.reload()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
        if(ValorAnt < Detalle.dblSubTotal){
            var nuevo=  Detalle.dblSubTotal - ValorAnt
            _Registro.dblImporte = _Registro.dblImporte + nuevo
        } else 
        if( ValorAnt > Detalle.dblSubTotal){
            var nuevo = ValorAnt - Detalle.dblSubTotal
            _Registro.dblImporte = _Registro.dblImporte  - nuevo
        }

        requests
            .put('TbSolicitudCxp/Editar', _Registro)
            .then((response) => {
                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
                window.location.reload()
                //navigate('/SolicitudCXP/Edit/' + id)
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
	}
	const hoy = new Date(Date.now());

	useEffect(() => {
        getRegistro()
    }, [props.DetalleId])

    const getRegistro = () => {
        if(props.DetalleId != null){
            props.setOpenLoadingScreen()
            requests
			.get('TbCxpDetalle/ObtenerPorId/' + props.DetalleId)
			.then((response) => {
                AsignarValores(response)
				setValorAnt(response.dblSubTotal)
                requests
                    .get('TbSolicitudCxp/ObtenerPorId/' + response.intIdSolicitdCxp)
                    .then((response) => {
                        AsignarValor(response)
                        props.setCloseLoadingScreen()
                    })
                    .catch((error) => {
                        props.setCloseLoadingScreen()
                    })
            
			})
			.catch((error) => {
                props.setCloseLoadingScreen()
			})
        }
	}

	const [_Registro, SetRegistro] = useState({})
    

    const AsignarValor = (objeto) => {
		SetRegistro({
			..._Registro,
			intIdSolicitdCxp: objeto === null ? '' : objeto.intIdSolicitdCxp,
			txtSolicitante: objeto === null ? '' : objeto.txtSolicitante,
			txtRegistro: objeto === null ? '' : objeto.txtRegistro,
			intIdNegocio: objeto === null ? '' : objeto.intIdNegocio,
			txtConcepto: objeto === null ? '' : objeto.txtConcepto,
			intIdUsuario: objeto === null ? '' : objeto.intIdUsuario,
			intIdDepartamentos: objeto === null ? '' : objeto.intIdDepartamentos,
			intIdPlaza: objeto === null ? '' : objeto.intIdPlaza,
			intIdTipoServicio: objeto === null ? '' : objeto.intIdTipoServicio,
			intIdTipoGasto: objeto === null ? '' : objeto.intIdTipoGasto,
			txtFolio: objeto === null ? '' : objeto.txtFolio,
			txtRazonSocial: objeto === null ? '' : objeto.txtRazonSocial,
			txtBeneficiario: objeto === null ? '' : objeto.txtBeneficiario,
			txtRFC: objeto === null ? '' : objeto.txtRfc,
			intTipoPago: objeto === null ? '' : objeto.intTipoPago,
			intIdMoneda: objeto === null ? '' : objeto.intIdMoneda,
			dblTotal: objeto === null ? '' : objeto.dblTotal,
			dblImporte: objeto === null ? '' : objeto.dblImporte,
			dblIva: objeto === null ? '' : objeto.dblIva,
			txtImporteTexto: objeto === null ? '' : objeto.txtImporteTexto,
			fecDia: objeto === null ? '' : objeto.fecDia,
			fecPago: objeto === null ? '' : objeto.fecPago,
			fecProxVencimiento: objeto === null ? '' : objeto.fecProxVencimiento,
			intIdEmpresaPg: objeto === null ? '' : objeto.intIdEmpresaPg,
			txtComentarios: objeto === null ? '' : objeto.txtComentarios,
			intIdBanco: objeto === null ? '' : objeto.intIdBanco,
			txtTarjeta: objeto === null ? '' : objeto.txtTarjeta,
			txtClabe: objeto === null ? '' : objeto.txtClabe,
			txtCuenta: objeto === null ? '' : objeto.txtCuenta,
			txtIban: objeto === null ? '' : objeto.txtIban,
			intIdStatus: objeto === null ? '' : objeto.intIdStatus,
			// lngIdProveedor: objeto === null ? '' : objeto.lngIdProveedor,
			txtReferencia: objeto === null ? '' : objeto.txtReferencia,
			bolGastofijo: objeto === null ? '' : objeto.bolGastofijo,
			bolPrioridad: objeto === null ? '' : objeto.bolPrioridad,
			bolGastoVariable: objeto === null ? '' : objeto.bolGastoVariable,
			intIdRegCuenta: objeto === null ? '' : objeto.intIdRegCuenta,
		})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				maxWidth={'1000px'}
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Detalhes
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '650px', height: '370px' }}>
						<DialogContentText id='alert-dialog-description'>
							<Grid container sx={{ pb: 2, px: 1 }}>
								<Grid container spacing={2} alignItems='center' justifyContent='space-between'>
									<Grid item xs={4}>
										<Button onClick={handleClose} size='small'
                                        className='btn-Header-back '
                                        startIcon={<ArrowBackIosNewSharpIcon />}>Voltar</Button>
									</Grid>
									<Grid item xs={4}>
										<Typography>Data: {hoy.toLocaleDateString()}</Typography>
									</Grid>
								</Grid>
							</Grid>
							<fieldset
								style={{
									borderRadius: '20px',
									border: 'solid 3px  #c3e0f3',
									marginTop: '30px',
								}}
							>
								<legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
									&nbsp; Editar Detalhe &nbsp;
								</legend>
								<Grid container sx={{ pb: 2, px: 1 }}>
									<Grid container spacing={2} alignItems='center'>
										<Grid item xs={6}>
											<TextField
												required
												id='txtConcepto'
												name='txtConcepto'
												label='Conceito:'
												variant='standard'
												fullWidth
												value={Detalle.txtConcepto}
												onChange={handleDetalle}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												required
												sx={{ mt: 1 }}
												id='txtNombre'
												name='txtNombre'
												label='Nome'
												fullWidth
												variant='standard'
												value={Detalle.txtNombre}
												onChange={handleDetalle}
											/>
										</Grid>
										<Grid item xs={4}>
                                            <NumberFormat
												disabled
                                                className='bold'
                                                label='Imposto'
                                                variant='standard'
                                                value={Detalle.dblIva}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                suffix={'%'}
                                                fullWidth
                                                onValueChange={(values) => {
                                                    setDetalle({
                                                        ...Detalle,
                                                        dblIva: values.floatValue,
                                                    })
                                                }}
                                                // onBlur={handleOblur}
                                                size='small'
                                                name='dblIva'
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NumberFormat
                                                required
                                                className='bold'
                                                label='SubTotal'
                                                variant='standard'
                                                value={Detalle.dblSubTotal}
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                prefix={'$'}
                                                fullWidth
                                                onValueChange={(values) => {
                                                    setDetalle({
                                                        ...Detalle,
                                                        dblSubTotal: values.floatValue,
                                                    })
                                                }}
                                                // onBlur={handleOblur}
                                                size='small'
                                                name='dblSubTotal'
                                            />
                                        </Grid>
										<Grid item xs={4}>
                                            <LocalizationProvider
                                                size='small'
                                                dateAdapter={AdapterDateFns}                                                      
                                            >
                                                <DatePicker                                                            
                                                    name='FecRegistro'
                                                    label='Data do Registro'
                                                    inputFormat='dd/MM/yyyy'
                                                    value={_fecRegistro}
                                                    onChange={handlefecRegistro}
                                                    renderInput={(params) => (
                                                        <TextField 
                                                            label="Data do Registro"                                                                   
                                                            variant='standard'
                                                            fullWidth
                                                            size='small'
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
										</Grid>

										<Grid item xs={4}>
											<TextField
												required
												id='txtNumFactura'
												name='txtNumFactura'
												label='Num. Factura'
												fullWidth
												variant='standard'
												value={Detalle.txtNumFactura}
												onChange={handleDetalle}
											/>
										</Grid>
									</Grid>
								</Grid>
							</fieldset>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Fechar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Atualização
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default DetalleEdit
