import React, {useEffect} from 'react'
import { ThemeProvider } from '@emotion/react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import Login2 from './Components/Login/Login2'
import HeaderLayout from './Components/Layout/HeaderLayout'
import MainContainerLayout from './Components/Layout/MainContainerLayout'
import MainContentLayout from './Components/Layout/MainContentLayout'
import LoadingScreen from './Components/Styles/LoadingScreen'
import MySnackBar from './Components/Styles/MySnackBar'
import Theme from './Components/Styles/Theme'

import RoutesAdmin from './Routing/RoutesAdmin'
import NoAccess from './Components/Layout/NoAccess'

import InicioView from './View/InicioView'
import DepartamentosView from './View/CatalogosView/DepartamentosView'
import MovimientosView from './View/CatalogosView/MovimientosView'
import RetornoView from './View/CatalogosView/RetornoView'
import NegociosView from './View/CatalogosView/NegociosView'
import PlazasView from './View/CatalogosView/PlazasView'
import SolicitudCXPView from './View/SolicitudCXPView'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StatusView from './View/CatalogosView/StatusView'
import TipoGastoView from './View/CatalogosView/TipoGastoView'
import TipoPagosView from './View/CatalogosView/TipoPagosView'
import BancosView from './View/CatalogosView/BancosView'
import CuentasPgView from './View/CuentasPgView'
import EmpresasPgView from './View/EmpresasPgView'
import TipoDeServicio from './View/CatalogosView/TipoDeServicio'
import SolictudCxpAdd from './Components/SolicitudCxp/SolictudCxpAdd'
import SolictudCxpEdit from './Components/SolicitudCxp/SolictudCxpEdit'
import SolictudCxpEditDetails from './Components/SolicitudCxp/SolictudCxpDetails'
import ReporteVencimiento from './View/Reportes/ReporteVencimiento'
import ReporteFechaRegistro from './View/Reportes/ReporteFechaRegistro'
import ReporteFechaPago from './View/Reportes/ReporteFechaPago'
import SolicitudCxpPrint2 from './Components/SolicitudCxp/SolicitudCxpPrint2'
import SolicitudCXPPrintDetails from './Components/SolicitudCxp/SolicitudCXPPrintDetails'
import MonedaView from './View/CatalogosView/MonedaView'
import UsuariosView from './View/UsuariosView'
import ProveedoresView from './View/ProveedoresView'
import SaldosView from './View/SaldosView'
import CreditosView from './View/CreditosView'
import CuentasProvView from './View/CuentasProvView'
import TipoDocumentosView from './View/CatalogosView/TipoDocumentosView'
import PaisesView from './View/CatalogosView/PaisesView'
import EstadoCivilView from './View/CatalogosView/EstadoCivilView'
import TipoProfesionalView from './View/CatalogosView/TipoProfesionalView'
import TipoResidenciaView from './View/CatalogosView/TipoResidenciaView'
import ClientesAdd from './Components/Clientes/ClientesAdd'
import ClientesEdit from './Components/Clientes/ClientesEdit'
import ClientesView from './View/Clientes/ClientesView'
import ClientesPrint from './Components/Clientes/ClientesPrint'
import ChecKListJuridicoAdd from './Components/ChecKListJuridico/ChecKListJuridicoAdd'
import ChecKListJuridicoEdit from './Components/ChecKListJuridico/ChecKListJuridicoEdit'
import ChecKListJuridicoView from './View/ChecKListJuridico/ChecKListJuridicoView'
import CheckListJuridicoPrint from './Components/ChecKListJuridico/CheckListJuridicoPrint'

import MantenimientoView from './View/Mantenimiento/MantenimientoView'

import ProveedoresOpView from './View/ProveedoresOp/ProveedoresOpView'
import StatusUtilidadView from './View/StatusUtilidad/StatusUtilidadView'
import StatusPagoView from './View/StatusPagos/StatusPagoView'

import OperacionesView from './View/Operaciones/OperacionesView'
import OperacionesAdd from './Components/Operaciones/OperacionesAdd'
import OperacionesEdit from './Components/Operaciones/OperacionesEdit'

import ReporteGeneralCxp from './View/Reportes/ReporteGeneralCxp'

import ReporteOperaciones from './View/Reportes/ReporteOperaciones'
import UpdateContra from './Components/Login/UpdateContra'
import Contacto from './Components/Login/Contacto'
import MonedasOpView from './View/CatalogosView/MonedasOpView'
import ActividadEcoView from './View/CatalogosView/ActividadEcoView'
import AreaView from './View/CatalogosView/AreaView'
import CfdiView from './View/CatalogosView/CfdiView'
import { useState } from 'react'
import requests from './Components/AxiosCalls/AxiosCall'

import {
	PROVEEDORES_OP_VIEW,
	OPERACIONES_CREATE,
	OPERACIONES_UPDATE,
	OPERACIONES_VIEW,
	STATUS_CLI_VIEW,
	STATUS_UTILIDAD_VIEW,
} from './Constants/routesConstants'
import RoutesOperaciones from './Routing/RoutesOperaciones'
import RoutesImpJuri from './Routing/RoutesImpJuri'
import RoutesCxp from './Routing/RoutesCxp'

function App() {
	const [LSopen, setLSOpen] = React.useState(false)
	const [SBOpen, setSBOpen] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [Msj, setMsj] = React.useState('')
	const [type, setType] = React.useState('')

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setSBOpen(false)
	}
	const openLoadingScreen = () => {
		setLSOpen(true)
	}
	const closeLoadingScreen = () => {
		setLSOpen(false)
	}
	const getLocalToken = () => {
		
		return JSON.parse(sessionStorage.getItem('TokenSYS'))
	}
	const [_InfoUsuario, setInfoUsuario] = useState({})

	useEffect(() => {
		
		if(isAuth!== null && isAuth!== undefined){
			requests
				.get('TbUsuario/ObtenerPorId/' + JSON.parse(sessionStorage.getItem('Id')))
				.then((response) => {
					
					setInfoUsuario(response)
				})
				.catch((error) => {
					console.log(error)
				})
		
		}
	},[])
	const isAuth = getLocalToken()
	
	return (
		// <LocalizationProvider dateAdapter={AdapterDateFns}>
			<ThemeProvider theme={Theme}>
				<BrowserRouter>
					<MySnackBar
						Open={SBOpen}
						Type={type}
						Message={message}
						HandleClose={handleClose}
					/>
					<LoadingScreen open={LSopen} />

					{!isAuth ? (
						<Routes>
							<Route
								path='/'
								element={
									<Login2
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/>
							<Route
								path='/Recovery/:id'
								element={
									<UpdateContra
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/>
							<Route
								path='/Contacto'
								element={
									<Contacto
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/>
						</Routes>
					) : (
						(() => {
							switch (_InfoUsuario.intIdArea) {
								case 1:
									return <RoutesCxp />

								case 2:
									switch(_InfoUsuario.intIdSubarea){
										case 3:
											return <RoutesCxp/>

										default:
											return <RoutesCxp />
									}

								case 3:
									return <RoutesCxp/>

								case 4:
									return <RoutesImpJuri />

								case 5:
									return <NoAccess />

								case 6:
									return <RoutesImpJuri />
								case 7:
									return <RoutesCxp/>
								case 8:
									return <RoutesOperaciones />
								case 9:
									return <RoutesCxp />
								case 10:
									return <RoutesCxp />
								case 11:
									return <RoutesCxp />
								case 12:
									return <RoutesCxp />				
								case 13:
									switch(_InfoUsuario.intIdSubarea){
										case 1:
											return <RoutesAdmin />

										default:
											return <RoutesCxp />
									}
								case 14:
									return <RoutesCxp />
								case 15:
									return <RoutesCxp />
								case 16:
									return <RoutesCxp />
								case 17:
									return <RoutesCxp />
								case 18:
									return <RoutesCxp />
								case 19:
									return <RoutesCxp />
								case 21:
									return <RoutesCxp />	
								default:
									return <NoAccess />
							}
						})()						
					)}
				</BrowserRouter>
			</ThemeProvider>
		// </LocalizationProvider>
	)
}

export default App
