import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@emotion/react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import html2pdf from 'html-to-pdf-js'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import '../Styles/StylesSolicitudCxpPrint.css'
import { Divider } from '@mui/material'

const SolicitudCxpPrint2 = (props) => {
	const { id } = useParams()
	let navigate = useNavigate()
	const [_Data, setData] = useState({
		txtSolicitante: '',
		departamento: '',
		plaza: '',
		negocio: '',
		tipoServicio: '',
		tipoGasto: '',
		folio: '',
		concepto: '',
		razonSocial: '',
		beneficiario: '',
		proveedor: '',
		rfc: '',
		tipoPago: '',
		registro: '',
		moneda: '',
		dblImporte: '',
		dblIva: '',
		txtImporteTexto: '',
		banco: '',
		clabe: '',
		cuenta: '',
		txtIban: '',
		fecDia: '',
		fecProxVencimiento: '',
		fecPago: '',
		txtComentarios: '',
		status: '',
		txtMotivo: '',
		tarjeta: '',
		dblTotal: '',
		referencia: '',
	})

	const captura = async () => {
		props.setOpenLoadingScreen()
		let url = requests.Url + 'GenerarPDF/GenerarPdfSolicitudCxp/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
					props.setCloseLoadingScreen()
				})
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
				console.log(error)
			})
	}

	const getData = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbSolicitudCxp/ObtenerListaVMPorId?id='+id)
			.then((response) => {
				asignarValores(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getData()
	}, [])

	const asignarValores = (objeto) => {
		setData({
			..._Data,
			txtSolicitante: objeto.txtSolicitante === null ? '' : objeto.txtSolicitante,
			departamento: objeto.txtDepartamento === null ? '' : objeto.txtDepartamento,
			plaza: objeto.txtPlaza === null ? '' : objeto.txtPlaza,
			negocio: objeto.txtNegocio === null ? '' : objeto.txtNegocio,
			tipoServicio: objeto.txtTipoServicio === null ? '' : objeto.txtTipoServicio,
			tipoGasto: objeto.txtTipoGasto === null ? '' : objeto.txtTipoGasto,
			folio: objeto.txtFolio === null ? '' : objeto.txtFolio,
			concepto: objeto.txtConcepto === null ? '' : objeto.txtConcepto,
			razonSocial: objeto.txtEmpresaPg === null ? '' : objeto.txtEmpresaPg,
			beneficiario: objeto.txtBeneficiario === null ? '' : objeto.txtBeneficiario,
			proveedor: objeto.txtProveedor === null ? '' : objeto.txtProveedor,
			rfc: objeto.txtRfc === null ? '' : objeto.txtRfc,
			tipoPago: objeto.txtTipoPago === null ? '' : objeto.txtTipoPago,
			registro: objeto.txtRegistro === null ? '' : objeto.txtRegistro,
			moneda: objeto.txtNomCorto === null ? '' : objeto.txtNomCorto,
			dblImporte: objeto.dblImporte === null ? '' : objeto.dblImporte,
			dblIva: objeto.dblIva === null ? '' : objeto.dblIva,
			txtImporteTexto:
				objeto.txtImporteTexto === null ? '' : objeto.txtImporteTexto,
			banco: objeto.txtBanco === null ? '' : objeto.txtBanco,
			clabe: objeto.txtClabe === null ? '' : objeto.txtClabe,
			cuenta: objeto.txtCuenta === null ? '' : objeto.txtCuenta,
			txtIban: objeto.txtIban === null ? '' : objeto.txtIban,
			fecDia: objeto.fecDia === null ? '' : objeto.fecDia,
			fecProxVencimiento:
				objeto.fecProxVencimiento === null ? '' : objeto.fecProxVencimiento,
			fecPago: objeto.fecPago === null ? '' : objeto.fecPago,
			txtComentarios:
				objeto.txtComentarios === null ? '' : objeto.txtComentarios,
			status: objeto.txtStatus === null ? '' : objeto.txtStatus,
			txtMotivo: objeto.txtMotivo === null ? '' : objeto.txtMotivo,
			tarjeta: objeto.txtTarjeta === null ? '' : objeto.txtTarjeta,
			dblTotal: objeto.dblTotal === null ? '' : objeto.dblTotal,

		})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Grid item xs={12} sm={8} md={10} lg={12} elevation={12} sx={{ py: 2 }}>
				<Paper
					elevation={0}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div className='containerAlignHeader'>
						<Link to='/SolicitudCXP' style={{ textDecoration: 'none' }}>
							<Button
								size='small'
								className='btn-Header-back '
								startIcon={<ArrowBackIosNewSharpIcon />}
							>
								Regresar
							</Button>
						</Link>
						<Chip
							icon={<MonetizationOnIcon />}
							label='Contas por pagar / Imprimir'
							color='primary'
							className='back-transparent font-letter font-barter font-20'
						/>
					</div>
				</Paper>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
			>
				<Grid item>
					<Tooltip title='Imprimir solicitud' arrow aria-label='add'>
						<Button
							className='btn-Header-back '
							onClick={function () {
								captura()
							}}
							sx={{ mb: 2 }}
						>
							<LocalPrintshopIcon /> imprimir
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
			<div id='Captura' className='w100'>
				<div>
					<table className='auto-style63' sx='border-style: solid'>
						<tr>
							<td className='auto-style39' colSpan='7'>
								<h2 className='auto-style137'>
									<strong style={{ fontFamily: 'Century Gothic' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SOLICITUD DE
										TRANSEFERNCIA Y/O CHEQUE
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</strong>
								</h2>
							</td>
						</tr>

						<tr>
							<td
								className='auto-style113'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								SOLICITADO POR:
							</td>
							<td
								className='auto-style114'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.txtSolicitante}</strong>
							</td>
							<td
								className='auto-style115'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								FECHA:
							</td>
							<td
								className='auto-style115'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style121'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.fecDia.substring(0,10)}</strong>
							</td>
							<td
								className='auto-style117'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style9'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								DEPARTAMENTO:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.departamento}</strong>
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								FECHA P/P:
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.fecProxVencimiento.substring(0,10)}</strong>
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								PLAZA:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.plaza}</strong>
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								U/NEGOCIO:
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.negocio}</strong>
							</td>
							<td
								className='auto-style85'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								TIPO:
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='2'
							>
								<strong>{_Data.tipoServicio}</strong>
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style35'
								colSpan='2'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								GASTO:
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='5'
							>
								<strong>{_Data.tipoGasto}</strong>
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td colSpan='7'>
								<Divider />
							</td>
						</tr>
						<tr>
							<td className='auto-style39' colspan='7'>
								<h2 className='auto-style128' sx='background-color: #C0C0C0'>
									<strong sx="font-family: 'Century Gothic'">
										DATOS PARA EL PAGO
									</strong>
								</h2>
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								FORMA DE PAGO:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.tipoPago}</strong>
							</td>
							<td
								colSpan='4'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								REGISTRO:
								<strong>&nbsp;{_Data.registro}</strong>
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style133'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								RAZON SOCIAL:
							</td>
							<td
								className='auto-style46'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='4'
							>
								<strong>{_Data.razonSocial}</strong>
							</td>
							<td
								className='auto-style126'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style50'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								BENEFICIARIO:
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='4'
							>
								<strong>{_Data.beneficiario}</strong>
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								RFC:
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='4'
							>
								<strong>{_Data.rfc}</strong>
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								CONCEPTO:
							</td>
							<td
								className='auto-style99'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='4'
							>
								<strong>{_Data.concepto}</strong>
							</td>
							<td
								className='auto-style78'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td
								className='auto-style81'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style98'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style103'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style103'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style123'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style70'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style71'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								MONEDA
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.moneda}</strong>
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								IMPORTE:
							</td>
							<td
								className='auto-style35'
								colSpan='4'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.dblTotal}</strong>
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								BANCO:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.banco}</strong>
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								CLABE:
							</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style97'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='3'
							>
								<strong>{_Data.clabe}</strong>
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								CUENTA:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.cuenta}</strong>
							</td>
							<td className='auto-style138'>TARJETA:</td>
							<td
								className='auto-style108'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.tarjeta}</strong>
							</td>
						</tr>
						<tr>
							{/* <td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								REFERENCIA:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.referencia}</strong>
							</td> */}
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								FECHA PAGO:
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style35'
								colSpan='3'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
								<strong>
									{_Data.fecPago === '01/01/0001' ? '' : _Data.fecPago.substring(0,10)}
								</strong>
							</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								STATUS:
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
							<td
								className='text-left'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='2'
							>
								<strong>{_Data.status}</strong>
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style113'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								COMENTARIOS:
							</td>
							<td
								className='auto-style114'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style134'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style134'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style121'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style135'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
							<td
								className='auto-style136'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td
								colSpan='6'
								rowSpan='2'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.txtComentarios}</strong>
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style55'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							></td>
						</tr>
						<tr>
							<td className='auto-style96'>&nbsp;</td>
							<td className='auto-style120'>&nbsp;</td>
							<td className='auto-style104'>&nbsp;</td>
							<td className='auto-style104'>&nbsp;</td>
							<td className='auto-style122'>&nbsp;</td>
							<td className='auto-style125'>&nbsp;</td>
							<td className='auto-style10'>&nbsp;</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style122'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style125'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic' }}
							>
								<hr className='auto-style58' />
							</td>
							<td
								className='auto-style35'
								style={{ fontFamily: 'Century Gothic' }}
								colSpan='2'
							>
								{/* <hr className="auto-style130" sx={{color: ''}}/> */}
							</td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style35'
								colspan='2'
								style={{ fontFamily: 'Century Gothic' }}
							>
								<hr className='auto-style129' />
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td
								className='auto-style96'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								SOLICITANTE
							</td>
							<td
								className='auto-style57'
								sx="font-family: 'Century Schoolbook'; font-size: 11pt; font-style: italic; color: #C0C0C0;"
								colspan='2'
							></td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style2'
								colSpan='2'
								style={{
									fontFamily: 'Century Schoolbook',
									fontSize: '11pt',
									fontStyle: 'italic',
								}}
							>
								Dir.Admón y Finanzas
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td className='auto-style96' sx="font-family: 'Century Gothic'">
								<strong>{_Data.solicitante}</strong>
							</td>
							<td
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
								colSpan='2'
							></td>
							<td
								className='auto-style104'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
							<td
								className='auto-style2'
								colSpan='2'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								AUTORIZA
							</td>
							<td
								className='auto-style10'
								style={{ fontFamily: 'Century Gothic' }}
							>
								&nbsp;
							</td>
						</tr>
						<tr>
							<td className='auto-style96'>
								{/* <asp:Label ID="Lbl_Area" runat="server" Font-Bold="False" Font-Names="Berlin Sans FB"></asp:Label> */}
							</td>
							<td className='auto-style120'>&nbsp;</td>
							<td className='auto-style104'>&nbsp;</td>
							<td className='auto-style104'>&nbsp;</td>
							<td className='auto-style122'>&nbsp;</td>
							<td className='auto-style125'>&nbsp;</td>
							<td className='auto-style10'>&nbsp;</td>
						</tr>
					</table>
				</div>
			</div>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
			>
				<Grid item>
					<Tooltip title='Imprimir solicitud' arrow aria-label='add'>
						<Button
							className='btn-Header-back '
							onClick={function () {
								captura()
							}}
							sx={{ mt: 2 }}
						>
							<LocalPrintshopIcon /> imprimir
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default SolicitudCxpPrint2
