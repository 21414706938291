import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import NumberFormat from 'react-number-format'
import AsyncSelect from 'react-select/async'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Grid from '@mui/material/Grid'
import StyleGeneral from '../Styles/StyleGeneral';

const CreditosEdit = (props) => {
    const methods = useForm()
    const { handleSubmit } = methods
    const [open, setOpen] = useState(false)
    const classes = StyleGeneral();

    const handleClose = () => {
        props.onClose()
        props.getRegistros()
        LimpiarValores()
    }
    const [_TbCredito, setTbCredito] = useState({
        lngIdSaldos: props.saldoId,
        fecRegistro: "",
        dbValor: 0,
        bolCancelado: false
    })

    const LimpiarValores = () => {
        setTbCredito({
            ..._TbCredito,
            lngIdSaldos: "",
            fecRegistro: "",
            dbValor: "",
            bolCancelado: ""
        })
    }
    const handleTbCredito = (event) => {
        setTbCredito({ ..._TbCredito, [event.target.name]: event.target.value })
    }

    const guardar = () => {
        props.setOpenLoadingScreen()
        let url = 'TbCredito/Editar'
        _TbCredito.lngIdSaldos = props.saldoId
        requests
            .put(url, _TbCredito)
            .then((response) => {
                props.setTypeSnackBar('success')
                props.setMessageSnackBar(response.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
                handleClose()
            })
            .catch((error) => {
                props.setTypeSnackBar('warning')
                props.setMessageSnackBar(error.data.message)
                props.setOpenSnackBar(true)
                props.setCloseLoadingScreen()
            })
    }
    useEffect(() => {
        getRegistro();
	}, [props.saldoId])

	const getRegistro = () => {
		if (props.saldoId != null) {
			requests
				.get('TbCredito/ObtenerPorId/' + props.saldoId)
				.then((response) => {
					setTbCredito(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}
    return (
        <ThemeProvider theme={Theme}>
            <Dialog
                open={props.open}
                onClose={props.onClose ? props.onClose : null}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <Dialog
                    open={props.open}
                    onClose={props.onClose ? props.onClose : null}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <form onSubmit={handleSubmit(guardar)} autoComplete='off'>
                        <div className='containerHeaderModal'>
                            <DialogTitle
                                id='alert-dialog-title'
                                className='containerHeaderModal'
                            >
                                Agregar Banco
                            </DialogTitle>
                            <div>
                                <Button onClick={handleClose}>
                                    <CloseIcon
                                        fontSize='large'
                                        sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
                                    />
                                </Button>
                            </div>
                        </div>
                        <DialogContent sx={{ width: '500px', height: '180px' }}>
                            <DialogContentText id='alert-dialog-description'>
                                <NumberFormat
                                    required
                                    label='Valor'
                                    variant='standard'
                                    value={_TbCredito.dbValor}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                    fullWidth
                                    onValueChange={(values) => {
                                        setTbCredito({
                                            ..._TbCredito,
                                            dbValor: values.floatValue,
                                        })
                                    }}
                                    size='small'
                                    name='dblValor'
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} className='btn-close-modal'>
                                Cerrar
                            </Button>
                            <Button
                                className='btn-add-modal'
                                variant='contained'
                                type='submit'
                                startIcon={<CachedIcon />}
                            >
                                Actualizar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Dialog>
        </ThemeProvider>
    )
}
export default CreditosEdit
