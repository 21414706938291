import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { Checkbox, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'


const RecContra = (props) =>{

	const methods = useForm()
    const { handleSubmit } = methods

    const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
	}

	let navigate = useNavigate()

    const [mail, setMail] = useState()

    const sendMail=() =>{
		
        props.setOpenLoadingScreen()
		requests
			.post("Login/RecContra/"+ mail)
			.then(response => {
                
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				console.log(response.message)
				setMail(null)
				navigate('/')
				handleClose()
			}).catch((error) => {
				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				console.log(error.data.message)
				setMail(null)
				navigate('/')
			})
    }
    return (
		<ThemeProvider theme={Theme}>
			<Dialog
				maxWidth={'1000px'}
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(sendMail)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Recuperar Contraseña
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '305px', height: '180px' }}>
						<DialogContentText id='alert-dialog-description'>
								<Grid container sx={{ pb: 2, px: 1 }}>
									<Grid container spacing={2} alignItems='center'>
										<Grid item xs={12}>
											<TextField
												required
												type='mail'
												label='Ingresa tu correo:'
												fullWidth
												variant='standard'
												value={mail}
												onChange={(e) => setMail(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Button
												className='btn-add-modal'
												variant='contained'
												type='submit'
												startIcon={<AddIcon />}
											>
												Enviar
											</Button>
										</Grid>
										
									</Grid>
								</Grid>
						</DialogContentText>
					</DialogContent>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}

export default RecContra