//PROVEEDORES (OPERACIONES)
export const PROVEEDORES_OP_VIEW = '/propeedores-op'

//OPERACIONES
export const OPERACIONES_VIEW = '/operaciones'
export const OPERACIONES_CREATE = '/operaciones/add'
export const OPERACIONES_UPDATE = '/operaciones/edit/'

//STATUS CLI
export const STATUS_CLI_VIEW = '/status-cli'

//STATUS UTILIDAD
export const STATUS_UTILIDAD_VIEW = '/status-utilidad'

//CHECKLIST
export const CHECKLIST_PRINT = '/CheckList/Print/'
export const CHECKLIST_EDIT = '/CheckList/Edit/'

export const ADMINPAGE = '/Usuarios'

//PRODUCTOS
export const PROD_VIEW = '/productos'
