import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Theme from '../Components/Styles/Theme'
import '../Components/Styles/Styles.css'
import TextField from '@mui/material/TextField'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from 'date-fns'
import NumberFormat from 'react-number-format'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import requests from '../Components/AxiosCalls/AxiosCall'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Chip from '@mui/material/Chip'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PercentIcon from '@mui/icons-material/Percent';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';
import CampaignIcon from '@mui/icons-material/Campaign';
import { FLUJO_OPTOTAL, MONEDA_OP_LIST } from '../Constants/apiConstnats'
const InicioView = (props) => {
	const [registros, setRegistros] = useState([])
	const [param, setparam] = useState({
		intIdmoneda: '',
		Dia1: '',
		Dia2: '',
	})

	const handleParam = (event) => {
		setparam({ ...param, [event.target.name]: event.target.value })
	}

	const [_fechaIni, setfechaIni] = React.useState(null)
	const handlefechaIni = (date) => {
		setfechaIni(date)
	}

	const [_fechaFin, setfechaFin] = React.useState(null)
	const handlefechaFin = (date) => {
		setfechaFin(date)
	}
	const obtenerFechaInicioDeMes = () => {
		const fechaIni = new Date()
		return new Date(fechaIni.getFullYear(), fechaIni.getMonth(), 1)
	}

	const obtenerFechaFinDeMes = () => {
		const fechaFin = new Date()
		return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0)
	}

	const getCargaInical = () => {
		const fechaInicio = obtenerFechaInicioDeMes()
		const fechaFin = obtenerFechaFinDeMes()

		// param.idMoneda = 1;
		param.Dia1 = fechaInicio
		param.Dia2 = fechaFin
		requests
			.get(FLUJO_OPTOTAL + '?Dia1='+
			format(new Date(param.Dia1), 'yyyy/MM/dd') + '&Dia2='
			+ format(new Date(param.Dia2), 'yyyy/MM/dd'))
			.then((response) => {
				setRegistros(response)
			})
			.catch((error) => {
				console.log('Error: ' + error)
			})
	}
	const getCargaParam = () => {
		
		param.Dia1 = _fechaIni
		param.Dia2 = _fechaFin
		requests
			.get(FLUJO_OPTOTAL + '?Dia1='+
			format(new Date(param.Dia1), 'yyyy-MM-dd') + '&Dia2='
			+ format(new Date(param.Dia2), 'yyyy-MM-dd'))
			.then((response) => {
				
				setRegistros(response)
			})
			.catch((error) => {
				
				console.log('Error: ' + error)
			})
	}

	const [_Moneda, setMoneda] = useState([])
	const getMonedad = () => {
		props.setOpenLoadingScreen()
		requests
			.get(MONEDA_OP_LIST)
			.then((response) => {
				setMoneda(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getCargaInical()
		getMonedad()
	}, [])


	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={8} md={10} lg={12} >
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<DashboardIcon />} label='Inicio' color='primary' className='back-transparent font-letter font-barter font-20' />
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center'>
					<Grid container spacing={2}>
						
						<Grid item xs={3}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker                                                            
									name='Fecha inicio'
									label='Fecha Inicio operación'
									inputFormat='dd/MM/yyyy'
									value={_fechaIni}
									onChange={handlefechaIni}
									renderInput={(params) => (
										<TextField 
											label="Fecha Inicio Operació"                                                                   
											variant='standard'
											fullWidth
											size='small'
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={3}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker                                                            
									name='Fecha Fin'
									label='Fecha Fin operación'
									inputFormat='dd/MM/yyyy'
									value={_fechaFin}
									onChange={handlefechaFin}
									renderInput={(params) => (
										<TextField 
											label="Fecha Inicio Operació"                                                                   
											variant='standard'
											fullWidth
											size='small'
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={2}>
		 					<FormControl variant='standard' size='small' fullWidth required>
		 						<InputLabel id='intIdmoneda'>
		 							Moneda
		 						</InputLabel>
		 						<Select
									//required
									value={param.intIdmoneda}
									onChange={handleParam}
									label='intIdmoneda'
									inputProps={{
										name: 'intIdmoneda',
										id: 'intIdmoneda',
									}}
									size='small'
								>
									<MenuItem aria-label='None' value='' />
									{_Moneda.map((object, index) => (
										<MenuItem value={object.intIdmoneda}>
											{object.txtMoneda}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
		 					<Button
								className='btn-Header2'
								onClick={() => {
									getCargaParam()
								}}
								startIcon={<SearchIcon />}
							>
								Buscar
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mt: 2 }}>
		 			<Grid
						item
						container
						direction='column'
						xs={12}
						sm={4}
						md={4}
						lg={3}
					>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cliente'>
										<CallSplitIcon className='ziseIcon-cliente' />
									</div>
									<h3 className='text-paper-cliente'> IMPORTE A DISPERSAR</h3>
								</div>

								<div>
									<p className='title-number'>
										
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total: </p>
								<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											label='Importe'
											variant='standard'
											value={registros.facturadoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={4} md={4} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cliente'>
										<AccountBalanceIcon className='ziseIcon-cliente' />
									</div>
									<h3 className='text-paper-cliente'> COMISIÓN BANCARIA</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.facturado}{' '} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total: </p>
								<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											label='Importe'
											variant='standard'
											value={registros.comisionBan}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cliente'>
										<AttachMoneyIcon className='ziseIcon-cliente' />
									</div>
									<h3 className='text-paper-cliente'>TOTAL USD</h3>
								</div>

								<div>
									<p className='title-number-cliente'>
										{/* Cantidad: {registros.aprobado}{' '} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total: </p>
								<p className='title-number1'>{registros.totalDls}</p>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cliente'>
										<MoneyIcon className='ziseIcon-cliente' />
									</div>
									<h3 className='text-paper-cliente'>TOTAL PESOS</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.cancelado}{' '} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<Grid item xs={4}>
										<NumberFormat
											displayType={'text'}
											label='Importe'
											variant='standard'
											value={registros.canceladoTotal}
											customInput={TextField}
											thousandSeparator={true}
											fixedDecimalScale={true}
											decimalScale={2}
											prefix={'$'}
											fullWidth
											size='small'
										/>
									</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cxo'>
										<LocalAtmIcon className='ziseIcon-cxo' />
									</div>
									<h3 className='text-paper-cxo'>BASE COMISIÓN</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.dblComisionBase}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-cxo'>
										<PercentIcon className='ziseIcon-cxo' />
									</div>
									<h3 className='text-paper-cxo'>COMISIÓN X OPERACIÓN</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.comisionXoper}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon'>
										<PersonIcon className='ziseIcon' />
									</div>
									<h3 className='text-paper'>PAGO CLIENTE</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.pagoCliente}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon'>
										<PercentIcon className='ziseIcon' />
									</div>
									<h3 className='text-paper'>COMISIÓN BANPAY/COMETRA</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.comisionesBnCon}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<CallSplitIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>IMPORTE A DISPERSAR</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.importeDisper}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<AccountBalanceIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>COMISIÓN BANCARIA</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.comisionesBn}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<AttachMoneyIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>TOTAL USD</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.totalUsdUtiCam}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<MoneyIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>TOTAL PESOS</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.totalPesosUtiCam}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<CurrencyExchangeIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>UTILIDAD CAMBIARIA</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.utilidaCam}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<CampaignIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>PROMOTOR</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.promotor}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<CurrencyExchangeIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>UTILIDAD POR OPERACIÓN</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.utilidaOper}</p>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={3} md={3} lg={3}>
						<Paper
							elevation={2}
							className='container-paper'
							sx={{
								borderRadius: '10px',
							}}
						>
							<div className='container-header-icon'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className='container-icon-util'>
										<CurrencyExchangeIcon className='ziseIcon-util' />
									</div>
									<h3 className='text-paper-util'>UTILIDAD GENERAL</h3>
								</div>

								<div>
									<p className='title-number'>
										{/* Cantidad: {registros.creado} */}
									</p>
								</div>
							</div>

							<div className='container-footer-icon'>
								<p className='title-number1'>Total:</p>
								<p className='title-number1'>{registros.utilidaGeneral}</p>
							</div>
						</Paper>
					</Grid>

				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default InicioView
