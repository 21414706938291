import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { Link } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import PaymentsIcon from '@mui/icons-material/Payments'
import BarChartIcon from '@mui/icons-material/BarChart'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import BusinessIcon from '@mui/icons-material/Business'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddIcon from '@mui/icons-material/Add'
import Theme from '../Styles/Theme'
import PaidIcon from '@mui/icons-material/Paid'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PublicIcon from '@mui/icons-material/Public'
import PeopleIcon from '@mui/icons-material/People'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import ArticleIcon from '@mui/icons-material/Article'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import WorkIcon from '@mui/icons-material/Work'
import BadgeIcon from '@mui/icons-material/Badge'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MoneyIcon from '@mui/icons-material/Money';
import { PROVEEDORES_OP_VIEW, STATUS_CLI_VIEW, STATUS_UTILIDAD_VIEW, OPERACIONES_VIEW, OPERACIONES_UPDATE, OPERACIONES_CREATE } from '../../Constants/routesConstants'
import AccesoAdmin from './Menu/AccesoAdmin'
import AccesoOperaciones from './Menu/AccesoOperaciones'
import AccesoCxp from './Menu/AccesoCxp'
import AccesoImpJuri from './Menu/AccesoImpJuri'


const Menus = (props) => {


	return (
		<ThemeProvider theme={Theme}>
			<div className='container-sidebar'>
				{(() => {

					switch (props.data.area) {
						case 1:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 2:
							return <AccesoCxp />
						case 3:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 4:
							return <AccesoImpJuri />
						case 6:
							return <AccesoImpJuri />
						case 7:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 8:
							return <AccesoOperaciones />
						case 9:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 10:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 11:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 12:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 13:
							return <AccesoAdmin />

						case 14:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 15:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>

						case 16:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 17:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 18:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 19:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						case 21:
							return <Tooltip title='Contas por Pagar' arrow aria-label='add'>
								<Link to='/SolicitudCXP' className='text-link'>
									<ListItem button>
										<ListItemIcon>
											<MonetizationOnIcon className='icon-link-items2' />
										</ListItemIcon>
										<ListItemText>
											<Typography sx={{ fontWeight: 'bold', textAlign: 'left' }} className='text-link-items2'>
												Contas por Pagar
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							</Tooltip>
						default:
							return <p>No tienes acceso</p>
					}
				})()}
			</div>
		</ThemeProvider>
	)
}

export default Menus