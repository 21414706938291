import MUIDataTable from 'mui-datatables'
import React from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material/styles'
import '../Components/Styles/Styles.css'
import Theme from '../Components/Styles/Theme'

export default function MyDataTable(props) {
	const theme = createTheme({
		components: {
			MuiTableRow:{
				styleOverrides:{
					root: { 
						backgroundColor: 'rgb(201 218 248)!important'
					},
				}
			},
			MuiTableFooter: {
				styleOverrides:{
					root: { 
						display: 'none!important'
					},
				}
			}
		},
	})

	const options = {
		filter: false,
		search: false,
		print: false,
		download: false,
		viewColumns: false,
		customToolbar: null,
		responsive: "standard",
		selectableRows: 'none',
		textLabels: {
			body: {
				noMatch: 'Ups, no se han encontrado coincidencias.',
				toolTip: 'Ordenar',
				columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
			},
			pagination: {
				next: 'Siguiente',
				previous: 'Anterior',
				rowsPerPage: 'Registros por página:',
				displayRows: 'de',
				jumpToPage: 'Ir a página',
				root: {
					'@media print': {
						display: 'none'
					}
				}
			},
			toolbar: {
				search: 'Buscar',
				print: 'Imprimir',
				viewColumns: 'Elegir Columnas',
				filterTable: 'Filtrar Tabla',
			},
			filter: {
				all: 'Todo',
				title: 'FILTROS',
				reset: 'LIMPIAR',
			},
			viewColumns: {
				title: 'Mostrar Columnas',
				titleAria: 'Mostrar/Ocultar Columnas',
			},
			selectedRows: {
				text: 'fila(s) seleccionada(s)',
				delete: 'Borrar',
				deleteAria: 'Borrar filas seleccionadas',
			},
		},
	}
	return (
		<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					sx={{ textAling: 'center' }}
					title={props.title}
					data={props.data}
					columns={props.columns}
					options={options}
				/>
			</ThemeProvider>
		</Grid>
	)
}
