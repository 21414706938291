import React from 'react';
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login2 from './Components/Login/Login2'
import { AppBar } from '@mui/material';

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();



