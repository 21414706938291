import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import Theme from '../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'

const BancoAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [ctBanco, setCtBanco] = useState({
		txtBanco: '',
		intIdPais: '',
		bolActivo: ''
	})
	const LimpiarValores = () => {
		setCtBanco({ ...ctBanco, txtBanco: '', intIdPais: '', bolActivo: '' })
	}

	const handleCtBanco = (event) => {
		setCtBanco({ ...ctBanco, [event.target.name]: event.target.value })
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'CtBanco/Crear/'
		requests
			.post(url, ctBanco)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const [_ListaPais, setListaPais] = useState([])
	const getListaPais = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPais/ObtenerLista')
			.then((response) => {
				setListaPais(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getListaPais()
	}, [])
	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Dialog
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
						<div className='containerHeaderModal'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal'
							>
								Agregar Banco
							</DialogTitle>
							<div>
								<Button onClick={handleClose}>
									<CloseIcon
										fontSize='large'
										sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
									/>
								</Button>
							</div>
						</div>
						<DialogContent sx={{ width: '500px' }}>
							<DialogContentText id='alert-dialog-description'>
								<TextField
									required
									id='txtBanco'
									name='txtBanco'
									label='Banco:'
									variant='standard'
									fullWidth
									sx={{ mt: 1 }}
									value={ctBanco.txtBanco}
									onChange={handleCtBanco}
								/>
								<FormControl required variant='standard' size='small' fullWidth>
									<InputLabel id='intIdPais'>País</InputLabel>
									<Select
										required
										value={ctBanco.intIdPais}
										onChange={handleCtBanco}
										label='intIdPais'
										inputProps={{
											name: 'intIdPais',
											id: 'intIdPais',
										}}
										size='small'
									>
										<MenuItem aria-label='None' value='' />
										{_ListaPais.map((object, index) => (
											<MenuItem value={object.intIdPais}>
												{object.txtPais}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} className='btn-close-modal'>
								Cerrar
							</Button>
							<Button
								className='btn-add-modal'
								variant='contained'
								type='submit'
								startIcon={<AddIcon />}
							>
								Agregar
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</Dialog>
		</ThemeProvider>
	)
}
export default BancoAdd
