import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import NumberFormat from 'react-number-format'

import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import { useParams } from 'react-router-dom'
import {PROVEEDORES_OP_BY_ID, PROVEEDORES_OP_EDIT} from '../../Constants/apiConstnats'

const ProveedoresOpEdit = (props) => {
	//const id = useParams()
	const methods = useForm()
	const { handleSubmit } = methods

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_TbProveedoresOp, setTbProveedoresOp] = useState({
        txtRazonSocial: "",
        txtNombreComercial: "",
        txtRfc: "",
        bolActivo: true
	})

	const LimpiarValores = () => {
		setTbProveedoresOp({ ..._TbProveedoresOp,
                txtRazonSocial: "",
                txtNombreComercial: "",
                txtRfc: "",
                bolActivo: ""
            })	
	}
	const handleTbProveedoresOp = (event) => {		
		setTbProveedoresOp({ ..._TbProveedoresOp, [event.target.name]: event.target.value })
	}

	useEffect(() => {
		getProvee()
	}, [props.ProveeId])

	const getProvee = () => {
		if (props.ProveeId != null) {
			requests
				.get(PROVEEDORES_OP_BY_ID, props.ProveeId)
				.then((response) => {
					setTbProveedoresOp(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		requests
			.put(PROVEEDORES_OP_EDIT, _TbProveedoresOp)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Editar proveedor
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>
					<DialogContent sx={{ width: '500px' }} s>
						<DialogContentText id='alert-dialog-description'>
                        <TextField
								required
								id='txtRazonSocial'
								name='txtRazonSocial'
								label='Razón social:'
								variant='standard'
								fullWidth
								value={_TbProveedoresOp.txtRazonSocial}
								onChange={handleTbProveedoresOp}
							/>
							<TextField
								required
								id='txtNombreComercial'
								name='txtNombreComercial'
								label='Nombre comercial:'
								variant='standard'
								fullWidth
								sx={{ mt: 2 }}
								value={_TbProveedoresOp.txtNombreComercial}
								onChange={handleTbProveedoresOp}
							/>
							<TextField
								required
								id='txtRfc'
								name='txtRfc'
								label='NIC/Identificación:'
								variant='standard'
								fullWidth
								sx={{ mt: 2 }}
								value={_TbProveedoresOp.txtRfc}
								onChange={handleTbProveedoresOp}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<CachedIcon />}
						>
							Actualizar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default ProveedoresOpEdit