//OPERACIONES
export const OPERACIONES_ADD = 'TbRegistro/Crear'
export const OPERACIONES_EDIT = 'TbRegistro/Editar'
export const OPERACIONES_LIST = 'TbRegistro/ObtenerLista'
export const OPERACIONES_LIST_VM = 'TbRegistro/ObtenerListaVM'
export const OPERACIONES_BY_ID = 'TbRegistro/ObtenerPorId'

//PROVEEDORES (OPERACIONES)
export const PROVEEDORES_OP_ADD = 'TbProveedoresOpe/Crear'
export const PROVEEDORES_OP_EDIT = 'TbProveedoresOpe/Editar'
export const PROVEEDORES_OP_LIST = 'TbProveedoresOpe/ObtenerLista'
export const PROVEEDORES_OP_BY_ID = 'TbProveedoresOpe/ObtenerPorId'
export const PROVEEDORES_OP_DELETE = 'TbProveedoresOpe/Eliminar'

//ESTATUS UTILIDAD
export const STATUS_UTILIDAD_ADD = 'CtStatusUtilidad/Crear'
export const STATUS_UTILIDAD_EDIT = 'CtStatusUtilidad/Editar'
export const STATUS_UTILIDAD_LIST = 'CtStatusUtilidad/ObtenerLista'
export const STATUS_UTILIDAD_BY_ID = 'CtStatusUtilidad/ObtenerPorId'

//ESTATUS CLI
export const STATUS_CLI_ADD = 'CtStatusCli/Crear'
export const STATUS_CLI_EDIT = 'CtStatusCli/Editar'
export const STATUS_CLI_LIST = 'CtStatusCli/ObtenerLista'
export const STATUS_CLI_BY_ID = 'CtStatusCli/ObtenerPorId'

//ESTATUS
export const STATUS_LIST = 'CtStatus/ObtenerLista'

//CLIENTES
export const CLIENTES_ADD = 'TbCliente/Crear'
export const CLIENTES_EDIT = 'TbCliente/Editar'
export const CLIENTES_LIST = 'TbCliente/ObtenerLista'
export const CLIENTES_LISTVM = 'TbCliente/ObtenerListaVM'
export const CLIENTES_BY_ID = 'TbCliente/ObtenerPorId/'
export const CLIENTES_PRINT = 'TbCliente/RegistroClientePdf'
export const CLIENTES_DELETE = 'TbCliente/Eliminar'
export const CLIENTES_IDVM = 'TbCliente/ObtenerPorIdVM'
export const CLIENTES_REGPRINT = 'TbCliente/RegistroClientePrint/'

//PROYECTO SYSTRACK
export const PROYECTO_SYSTRACK_BY_ID = 'TbRegistro/ObtenerProyecto'

//REGISTRO-FLUJO
export const REG_FLUJO_ADD = 'TbRegFlujo/Crear'
export const REG_FLUJO_ADD_RANGE = 'TbRegFlujo/CrearList'
export const REG_FLUJO_EDIT = 'TbRegFlujo/Editar'
export const REG_FLUJO_EDIT_RANGE = 'TbRegFlujo/EditarList'
export const REG_FLUJO_LIST = 'TbRegFlujo/ObtenerLista'
export const REG_FLUJO_LIST_BY_ID_REGISTRO = 'TbRegFlujo/ObtenerListPorIdRegistro'
export const REG_FLUJO_BY_ID = 'TbRegFlujo/ObtenerPorId'
export const REG_FLUJO_BY_ID_REGISTRO = 'TbRegFlujo/ObtenerPorIdRegistro'

//MONEDAS OPERACIONES
export const MONEDA_OP_ADD = 'CtMonedaOp/Crear'
export const MONEDA_OP_EDIT = 'CtMonedaOp/Editar'
export const MONEDA_OP_LIST = 'CtMonedaOp/ObtenerLista'
export const MONEDA_OP_BY_ID = 'CtMonedaOp/ObtenerPorId'

//CHECKLIST
export const CHECKLISTPRINT = 'TbCheckListJuri/CheckListPrint'
export const CHECKLISTVM = 'TbCheckListJuri/ObtenerListaVM'

//GIRO
export const GIRO_OP_LIST = 'CtGiro/ObtenerLista'

//ESTADO
export const ESTADO_OP_LIST = 'CtEstado/ObtenerLista'

//CIUDAD
export const UBICACION_OP_LIST = 'CtUbicacion/ObtenerLista'

//AREACON
export const AREACON_OP_LIST = 'CtAreaCon/ObtenerLista'

//CONTACTOINT
export const CONTACTOINT_OP_LIST = 'TbContactoInt/ObtenerLista'

//COBERTURA
export const COBERTURA_OP_LIST = 'CtCobertura/ObtenerLista'

//TipoCliente
export const TIPOCLI_OP_LIST = 'CtTipoCliente/ObtenerLista'

//EjecutivoOp
export const EJEOP_LIST = 'CtEjecutivoOp/ObtenerLista'

//Pais
export const PAIS_LIST = 'CtPais/ObtenerLista'
// export const AREACON_OP_LIST = 'CtAreaCon/ObtenerLista'

// Reportes
export const REP_VEN = 'Reports/ReporteVencimiento'
export const REP_FECPAGO = 'Reports/ReporteFechaPago'
export const REP_FECPAGOEX = 'Reports/ReporteFechaPagoExcel'
export const REP_CONSECUTIVO = 'Reports/ReporteConsecutivo/'
export const REP_CONCEPTO = 'Reports/ReporteConcepto'
export const REP_CONCEPTOEX = 'Reports/ReporteConceptoExcel'
export const REP_COMPROBANTE = 'Reports/ReporteComprobante'
export const REP_COMPROBANTEEX = 'Reports/ReporteComprobanteExcel'
//USUARIO   
export const USU_LISTA = 'TbUsuario/ObtenerLista'

//TIPO PAGO
export const TIPOPAGO_LISTA = 'CtTipoPago/ObtenerLista'

//PROVEEDORES
export const PROVE_LISTA = 'TbProveedores/ObtenerLista'

//DEPARTAMENTO
export const DEPTO_LISTA = 'CtDepartamento/ObtenerLista'

//FLUJO
export const FLUJO_OPTOTAL = 'TbFlujo/ReporteFlujoOperacionestotal'

//MONEDA
export const MONEDA_LIST = 'CtMoneda/ObtenerLista'

//PLAZA
export const PLAZA_LISTA = 'CtPlaza/ObtenerLista'

//NEGOCIO
export const NEGOCIO_LISTA = 'CtNegocio/ObtenerLista'

//TIPO SERIVICO 
export const TIPOSERVICIO_LISTA = 'CtTipoServicio/ObtenerLista'

//BANCO 
export const BANCO_LISTA = 'CtBanco/ObtenerLista'
export const BANCO_CREAR = 'CtBanco/Crear/'
export const BANCO_EDITAR = 'CtBanco/Editar/'
export const BANCO_BYID = 'CtBanco/ObtenerPorId/'

//STATUS
export const STATUS_LISTA = 'CtStatus/ObtenerLista'

//CUENTAS PG
export const CUENTASPG_LISTA = 'TbCuentasPg/ObtenerListaVM'
export const CUENTASPG_LISTAVM = 'TbCuentasPg/ObtenerListaVM'
export const CUENTASPG_LISTA_EM_PG = 'TbCuentasPg/ObtenerListaEmpPgVM/'

//EMPRESAS
export const EMPRESASPG_LISTA = 'TbEmpresasPg/ObtenerListaVM'

//COMPONENTES
export const CREAR_RUTASOL = 'TbRutaSolicitud/Crear'
export const ELIMINAR_RUTASOL = 'TbRutaSolicitud/Eliminar/'

//Fornecedores
export const FORNECEDORES_LIST = 'CtFornecedoresBr/ObtenerLista'

//ESTATUS JURI
export const STATUSJURI_LISTA = 'CtStatusJuri/ObtenerLista'

//CHECKLIST
export const CHECKLIST_CREAR = 'TbCheckListJuri/Crear'
export const CHECKLIST_BYID = 'TbCheckListJuri/ObtenerPorId/'
export const CHECKLIST_EDIT = 'TbCheckListJuri/Editar'

//USUARIO   
export const USU_CREAR = 'TbUsuario/Crear'
export const USU_BYID = 'TbUsuario/ObtenerPorId/'
export const USU_EDITAR = 'TbUsuario/Editar'

//SOLICITUDES CXP
export const SOLICITUD_CREAR = 'TbSolicitudCxp/Crear'
export const SOLICITUD_EDITAR = 'TbSolicitudCxp/Editar'

export const SOLDETALLE_EDITAR = '/SolicitudCXP/EditDetails/'


//CUENTAS PG


//EMPRESAPG
export const EMPRESAPG_LISTA = 'TbEmpresasPg/ObtenerLista'
export const EMPRESAPG_BYID = 'TbEmpresasPg/ObtenerPorId/'
export const EMPRESAPG_CREAR = 'TbEmpresasPg/Crear'
export const EMPRESAPG_DELETE = 'TbEmpresasPg/Eliminar/'
export const EMPRESAPG_EDIT = 'TbEmpresasPg/Editar'

//COMPONENTES
export const GET_RUTASOL_ID = 'TbRutaSolicitud/GetById/'

//Promotores
export const CPROMO_LISTA = 'TbCPromotore/ObtenerLista'
export const CPROMO_BYID = 'TbCPromotore/ObtenerPorId/'
export const CPROMO_EDITAR = 'TbCPromotore/Editar'
export const CPROMO_CREAR = 'TbCPromotore/Crear'

//ASIGNAR PROMOTOR
export const ASIGNA_PROMO = 'TbAsignarPromotor/ObtenerListaVM'
export const ASIGNA_PROMO_CREAR = 'TbAsignarPromotor/Crear'
export const ASIGNA_PROMO_EDITAR = 'TbAsignarPromotor/Editar'
export const ASIGNA_PROMO_BYID = 'TbAsignarPromotor/ObtenerPorId/'

//CuentaPromo
export const GETCUENTAPROMO_ID = 'TbCuentaPromotor/ObtenerListaPorId/'
export const CUENTAPROMO_CREAR = 'TbCuentaPromotor/Crear'


//SUCURSAL
export const SUCURSAL_LISTA = 'TbSucursal/ObtenerLista'

//PRODUCTO
export const PROD_LIST = 'CtProducto/ObtenerLista'

export const PRODGRAL_CREAR = 'TbProductoGral/Crear' 
export const PRODSTR_CREAR = 'TbProductoStr/Crear' 
export const PRODTPV_CREAR = 'TbProductoTpv/Crear' 
export const PRODTC_CREAR = 'TbProductoTC/Crear'
export const PRODATE_CREAR = 'TbProductoATE/Crear' 
export const PRODFP_CREAR = 'TbProductoFP/Crear' 
export const PRODWWP_CREAR = 'TbProductoWWP/Crear' 

export const PRODGRAL_EDIT = 'TbProductoGral/Editar' 
export const PRODSTR_EDIT = 'TbProductoStr/Editar' 
export const PRODTPV_EDIT = 'TbProductoTpv/Editar' 
export const PRODTC_EDIT = 'TbProductoTC/Editar'
export const PRODATE_EDIT = 'TbProductoATE/Editar' 
export const PRODFP_EDIT = 'TbProductoFP/Editar' 
export const PRODWWP_EDIT = 'TbProductoWWP/Editar' 

//DATOS TABLA PROD
export const DATOS_LISTA = 'TbDatosFac/ObtenerLista'

//EVENTOS
export const EVENTO_LISTA = 'TbEvento/ObtenerLista'
export const EVENTO_CREAR = 'TbEvento/Crear'
export const EVENTO_EDITAR = 'TbEvento/Editar'
export const EVENTO_BYID = 'TbEvento/ObtenerPorId/'
//TipoGasto

export const TIPOGASTO_CREAR = 'CtTipoGasto/Crear'
export const TBGASTO_CREAR = 'TbSerGast/Crear'
export const TIPOGASTO_EDITAR = 'CtTipoGasto/Editar'
export const TBGASTO_EDITAR = 'TbSerGast/Editar'
export const TBGASTO_BYID = 'TbSerGast/ObtenerTipoGastoPorId/'
export const TIPOGASTO_BYID = 'CtTipoGasto/ObtenerPorId/'

//AREA
export const AREA_LIST = 'CtArea/ObtenerLista'

//SUBAREA
export const SUBAREA_BYIDAREA = 'CtSubarea/ObtenerPorIdArea?id='
export const SUBAREA_LIST = 'CtSubarea/ObtenerLista'

//REGION
export const REGION_LIST = 'CtRegion/ObtenerLista'

//EMPRESA
export const EMPRESA_LIST = 'CtEmpresa/ObtenerLista'

//SALDO
export const SALDO_CREAR = 'TbSaldo/Crear'
export const SALDO_EDITAR = 'TbSaldo/Editar'
export const SALDO_BYID = 'TbSaldo/ObtenerPorId/'

//TIPOSALDO
export const TIPOSALDO_LISTA = 'CtTipoSaldo/ObtenerLista'