import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@emotion/react'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Link, useNavigate, useParams } from 'react-router-dom'

import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import '../Styles/StylesPrint.css'
import { set } from 'date-fns';

const CheckListJuridicoPrint = (props) => {
    const { id } = useParams()
    let navigate = useNavigate()

    const [_Data, setData] = useState({
        lngIdCheckList: 0,
        txtCliente: "",
        txtPlaza: "",
        txtRepresentanteLegal: "",
        txtConcepto: "",
        dblMontoContrato: "",
        dblIva: "",
        bolActaConstitucion: false,
        bolPoderRepresentante: false,
        bolIdentificacionOficial: false,
        bolRucNit: false,
        bolComprobanteDomicilio: false,
        bolEmail: false,
        bolDatosBancarios: false,
        bolIdentificacionOficialNat: false,
        bolNit: false,
        bolComprobanteDomicilioNat: false,
        bolEmailNat: false,
        bolDatosBancariosNat: false,
        bolInforme: false,
        bolManual: false,
        bolOtros: false,
        txtComentarios: "",
        txtContrato: "",
        bolActivo: false,
        txtEmpresa: "",
        txtGiro: "",
        dblComisionPactada: "",
        fecInicioOperacion: "",
        txtPeriodicidad: "",
        dblMontoOperar: "",
        txtTipoEntorno: "",
        numTerminales: 0,
        txtContactoCliente: "",
        txtTelefono: "",
        txtEmailFacturas: "",
        fecImpresion: "",
        fecCreo: ""
    });

    const captura = async () => {
        var img1 = '';
        var img2 = '';
        var doc = new jsPDF();
        html2canvas(document.querySelector("#Captura")).then(canvas => {
            img1 = canvas.toDataURL('image/jpeg');
            doc.addImage(img1, 'JPEG', 15, 20, 180, 250);
            doc.addPage();
            html2canvas(document.querySelector("#Captura2")).then(canvas => {
                img2 = canvas.toDataURL('image/jpeg');
                doc.addImage(img2, 'JPEG', 15, 20, 180, 140);
                doc.output('dataurlnewwindow');
            });
        });
    }

    const getData = () => {
        props.setOpenLoadingScreen()
        requests
            .get('TbCheckListJuri/CheckListPrint', id)
            .then((response) => {
                asignarValores(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const getPdf = () => {
        props.setOpenLoadingScreen()
        let url = requests.Url + 'GenerarPDF/GenerarPdf/'+ _Data.lngIdCheckList
		fetch(url)
            .then((response) => {
                response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
				})
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }


    useEffect(() => {
        getData()
    }, [])

    const [BolActaConst, setBolActaConst] = useState(false)
    const [BolActaConst2, setBolActaConst2] = useState(false)

    const [BolPoderRep, setBolPoderRep] = useState(false)
    const [BolPoderRep2, setBolPoderRep2] = useState(false)

    const [BolIdenOf, setBolIdenOf] = useState(false)
    const [BolIdenOf2, setBolIdenOf2] = useState(false)

    const [BolRucNic, setBolRucNic] = useState(false)
    const [BolRucNic2, setBolRucNic2] = useState(false)

    const [BolComDom, setBolComDom] = useState(false)
    const [BolComDom2, setBolComDom2] = useState(false)

    const [BolEmail, setBolEmail] = useState(false)
    const [BolEmail2, setBolEmail2] = useState(false)

    const [BolDatBanc, setBolDatBanc] = useState(false)
    const [BolDatBanc2, setBolDatBanc2] = useState(false)

    const [BolIdenOfNat, setBolIdenOfNat] = useState(false)
    const [BolIdenOfNat2, setBolIdenOfNat2] = useState(false)

    const [BolNit, setBolNit] = useState(false)
    const [BolNit2, setBolNit2] = useState(false)

    const [BolComDomNat, setBolComDomNat] = useState(false)
    const [BolComDomNat2, setBolComDomNat2] = useState(false)

    const [BolEmailNat, setBolEmailNat] = useState(false)
    const [BolEmailNat2, setBolEmailNat2] = useState(false)

    const [BolDatBancNat, setBolDatBancNat] = useState(false)
    const [BolDatBancNat2, setBolDatBancNat2] = useState(false)

    const [BolInforme, setBolInforme] = useState(false)
    const [BolInforme2, setBolInforme2] = useState(false)

    const [BolManual, setBolManual] = useState(false)
    const [BolManual2, setBolManual2] = useState(false)

    const [BolOtros, setBolOtros] = useState(false)
    const [BolOtros2, setBolOtros2] = useState(false)

    const asignarValores = (objeto) => {
        setData({
            ..._Data,
            lngIdCheckList: objeto.lngIdCheckList === null ? '' : objeto.lngIdCheckList,
            txtCliente: objeto.txtCliente === null ? '' : objeto.txtCliente,
            txtPlaza: objeto.txtPlaza === null ? '' : objeto.txtPlaza,
            txtRepresentanteLegal: objeto.txtRepresentanteLegal === null ? '' : objeto.txtRepresentanteLegal,
            txtConcepto: objeto.txtConcepto === null ? '' : objeto.txtConcepto,
            dblMontoContrato: objeto.dblMontoContrato === null ? '' : objeto.dblMontoContrato,
            dblIva: objeto.dblIva === null ? '' : objeto.dblIva,
            bolActaConstitucion: objeto.bolActaConstitucion === null ? '' : objeto.bolActaConstitucion,
            bolPoderRepresentante: objeto.bolPoderRepresentante === null ? '' : objeto.bolPoderRepresentante,
            bolIdentificacionOficial: objeto.bolIdentificacionOficial === null ? '' : objeto.bolIdentificacionOficial,
            bolRucNit: objeto.bolRucNit === null ? '' : objeto.bolRucNit,
            bolComprobanteDomicilio: objeto.bolComprobanteDomicilio === null ? '' : objeto.bolComprobanteDomicilio,
            bolEmail: objeto.bolEmail === null ? '' : objeto.bolEmail,
            bolDatosBancarios: objeto.bolDatosBancarios === null ? '' : objeto.bolDatosBancarios,
            bolIdentificacionOficialNat: objeto.bolIdentificacionOficialNat === null ? '' : objeto.bolIdentificacionOficialNat,
            bolNit: objeto.bolNit === null ? '' : objeto.bolNit,
            bolComprobanteDomicilioNat: objeto.bolComprobanteDomicilioNat === null ? '' : objeto.bolComprobanteDomicilioNat,
            bolEmailNat: objeto.bolEmailNat === null ? '' : objeto.bolEmailNat,
            bolDatosBancariosNat: objeto.bolDatosBancariosNat === null ? '' : objeto.bolDatosBancariosNat,
            bolInforme: objeto.bolInforme === null ? '' : objeto.bolInforme,
            bolManual: objeto.bolManual === null ? '' : objeto.bolManual,
            bolOtros: objeto.bolOtros === null ? '' : objeto.bolOtros,
            txtComentarios: objeto.txtComentarios === null ? '' : objeto.txtComentarios,
            txtContrato: objeto.txtContrato === null ? '' : objeto.txtContrato,
            bolActivo: objeto.bolActivo === null ? '' : objeto.bolActivo,
            txtEmpresa: objeto.txtEmpresa === null ? '' : objeto.txtEmpresa,
            txtGiro: objeto.txtGiro === null ? '' : objeto.txtGiro,
            dblComisionPactada: objeto.dblComisionPactada === null ? '' : objeto.dblComisionPactada,
            fecInicioOperacion: objeto.fecInicioOperacion === null ? '' : objeto.fecInicioOperacion,
            txtPeriodicidad: objeto.txtPeriodicidad === null ? '' : objeto.txtPeriodicidad,
            dblMontoOperar: objeto.dblMontoOperar === null ? '' : objeto.dblMontoOperar,
            txtTipoEntorno: objeto.txtTipoEntorno === null ? '' : objeto.txtTipoEntorno,
            numTerminales: objeto.numTerminales === null ? '' : objeto.numTerminales,
            txtContactoCliente: objeto.txtContactoCliente === null ? '' : objeto.txtContactoCliente,
            txtTelefono: objeto.txtTelefono === null ? '' : objeto.txtTelefono,
            txtEmailFacturas: objeto.txtEmailFacturas === null ? '' : objeto.txtEmailFacturas,
            fecImpresion: objeto.fecImpresion === null ? '' : objeto.fecImpresion,
            fecCreo: objeto.fecCreo === null ? '' : objeto.fecCreo
        })
        if (objeto.bolActaConstitucion === true) {
            setBolActaConst(true);
            setBolActaConst2(false);
        } if (objeto.bolActaConstitucion === false){
            setBolActaConst(false);
            setBolActaConst2(true);
        }if (objeto.bolActaConstitucion === null) {
            setBolActaConst('');
            setBolActaConst2('');
        }

        if (objeto.bolPoderRepresentante === true) {
            setBolPoderRep(true);
            setBolPoderRep2(false);
        } if (objeto.bolPoderRepresentante === false)  {
            setBolPoderRep(false);
            setBolPoderRep2(true);
        }
        if (objeto.bolPoderRepresentante === null)  {
            setBolPoderRep('');
            setBolPoderRep2('');
        }

        if (objeto.bolIdentificacionOficial === true) {
            setBolIdenOf(true)
            setBolIdenOf2(false)
        } if (objeto.bolIdentificacionOficial === false) {
            setBolIdenOf(false)
            setBolIdenOf2(true)
        } if (objeto.bolIdentificacionOficial === null) {
            setBolIdenOf('')
            setBolIdenOf2('')
        }

        if (objeto.bolRucNit === true) {
            setBolRucNic(true)
            setBolRucNic2(false)
        } if (objeto.bolRucNit === false) {
            setBolRucNic(false)
            setBolRucNic2(true)
        }if (objeto.bolRucNit === null) {
            setBolRucNic('')
            setBolRucNic2('')
        }
        if (objeto.bolComprobanteDomicilio === true) {
            setBolComDom(true)
            setBolComDom2(false)
        } if (objeto.bolComprobanteDomicilio === false) {
            setBolComDom(false)
            setBolComDom2(true)
        }
        if (objeto.bolComprobanteDomicilio === null) {
            setBolComDom('')
            setBolComDom2('')
        }
        if (objeto.bolEmail === true) {
            setBolEmail(true)
            setBolEmail2(false)
        } if (objeto.bolEmail === false) {
            setBolEmail(false)
            setBolEmail2(true)
        }
        if (objeto.bolEmail === null) {
            setBolEmail('')
            setBolEmail2('')
        }
        if (objeto.bolDatosBancarios === true) {
            setBolDatBanc(true)
            setBolDatBanc2(false)
        } if (objeto.bolDatosBancarios === false) {
            setBolDatBanc(false)
            setBolDatBanc2(true)
        }
        if (objeto.bolDatosBancarios === null) {
            setBolDatBanc('')
            setBolDatBanc2('')
        }
        if (objeto.bolIdentificacionOficialNat === true) {
            setBolIdenOfNat(true)
            setBolIdenOfNat2(false)
        } if (objeto.bolIdentificacionOficialNat === false) {
            setBolIdenOfNat(false)
            setBolIdenOfNat2(true)
        }if (objeto.bolIdentificacionOficialNat === null) {
            setBolIdenOfNat('')
            setBolIdenOfNat2('')
        }
        if (objeto.bolNit === true) {
            setBolNit(true)
            setBolNit2(false)
        } if (objeto.bolNit === false) {
            setBolNit(false)
            setBolNit2(true)
        }if (objeto.bolNit === null) {
            setBolNit('')
            setBolNit2('')
        }
        if (objeto.bolComprobanteDomicilioNat === true) {
            setBolComDomNat(true)
            setBolComDomNat2(false)
        } if (objeto.bolComprobanteDomicilioNat === false) {
            setBolComDomNat(false)
            setBolComDomNat2(true)
        }if (objeto.bolComprobanteDomicilioNat === null) {
            setBolComDomNat('')
            setBolComDomNat2('')
        }
        if (objeto.bolEmailNat === true) {
            setBolEmailNat(true)
            setBolEmailNat2(false)
        } if (objeto.bolEmailNat === false) {
            setBolEmailNat(false)
            setBolEmailNat2(true)
        }if (objeto.bolEmailNat === null) {
            setBolEmailNat('')
            setBolEmailNat2('')
        }
        if (objeto.bolDatosBancariosNat === true) {
            setBolDatBancNat(true)
            setBolDatBancNat2(false)
        } if (objeto.bolDatosBancariosNat === false) {
            setBolDatBancNat(false)
            setBolDatBancNat2(true)
        }if (objeto.bolDatosBancariosNat === null) {
            setBolDatBancNat('')
            setBolDatBancNat2('')
        }
        if (objeto.bolInforme === true) {
            setBolInforme(true)
            setBolInforme2(false)
        } if (objeto.bolInforme === false) {
            setBolInforme(false)
            setBolInforme2(true)
        }if (objeto.bolInforme === null) {
            setBolInforme('')
            setBolInforme2('')
        }
        if (objeto.bolManual === true) {
            setBolManual(true)
            setBolManual2(false)
        } if (objeto.bolManual === false) {
            setBolManual(false)
            setBolManual2(true)
        }if (objeto.bolManual === null) {
            setBolManual('')
            setBolManual2('')
        }
        if (objeto.bolOtros === true) {
            setBolOtros(true)
            setBolOtros2(false)
        }  if (objeto.bolOtros === false) {
            setBolOtros(false)
            setBolOtros2(true)
        }if (objeto.bolOtros === null) {
            setBolOtros('')
            setBolOtros2('')
        }
    }

    return (
        <ThemeProvider theme={Theme}>
            <Grid
                item
                xs={12}
                sm={8}
                md={10}
                lg={12}
                elevation={12}
                sx={{ py: 2 }}
            >
                <Paper
                    elevation={0}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className='containerAlignHeader'>
                        <Link to='/CheckList' style={{ textDecoration: 'none' }}>
                            <Button
                                size='small'
                                className='btn-Header-back '
                                startIcon={<ArrowBackIosNewSharpIcon />}
                            >
                                Regresar
                            </Button>
                        </Link>

                        <Chip icon={<PlaylistAddCheckIcon />} label='Check List / Imprimir' color='primary' className='back-transparent font-letter font-barter font-20' />
                    </div>
                </Paper>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid
                    item
                    
                >
                    <Tooltip title='Imprimir check list' arrow aria-label='add'>
                        <Button
                            className='btn-Header-back '
                            onClick={function () {
                                getPdf()
                            }}
                        >
                            <LocalPrintshopIcon /> imprimir
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <div id="Captura" className='w100'>
                <div class='flex-column'>
                    <div class='w105 center header-top'>

                        <div class='flex-between  py-20'>
                            <p class='bold'>ELABORACION DE CONTRATOS</p>
                            <h2 class='bold'>CHECK LIST JURIDICO</h2>
                            <p class='bold'>FECHA: {_Data.fecCreo}</p>
                        </div>

                        <div class='flex-between'>
                            <div>
                                <img src='https://dl.dropbox.com/scl/fi/6chd35hgtfo06ok7o2d6a/systrackhead.png?rlkey=sywsijy8wz8y8ebgio64j7uzi&dl=0' height='80' />
                            </div>
                            <div class='flex '>
                                <p class='mr1'>Contrato:</p>
                                <div class='input-date line-height '>
                                    <p>{_Data.txtContrato}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='w105 center header-top  '>
                        <div class='flex'>
                            <fieldset class=' w70 '>
                                <legend>Cliente</legend>
                                <p>{_Data.txtCliente}</p>
                            </fieldset>
                            <fieldset class='w30 '>
                                <legend>Plaza</legend>
                                <p>{_Data.txtPlaza}</p>
                            </fieldset>
                        </div>

                        <div class='flex mt2'>
                            <fieldset class=' w100 '>
                                <legend>Resentante Legal</legend>
                                <p>{_Data.txtRepresentanteLegal}</p>
                            </fieldset>
                        </div>

                        <div class='flex mt2'>
                            <fieldset class=' w100 '>
                                <legend>Concepto</legend>
                                <p>{_Data.txtConcepto}</p>
                            </fieldset>
                        </div>

                        <div class='flex mt2'>
                            <fieldset class=' w50 '>
                                <legend>Monto de Contrato</legend>
                                <p>{_Data.dblMontoContrato}</p>
                            </fieldset>
                            <fieldset class=' w50 '>
                                <legend>Más IVA</legend>
                                <p>{_Data.dblIva}</p>
                            </fieldset>
                        </div>
                    </div>
                    <div class='w105 center '>
                        <div class='header-tab flex'>
                            <div class='w10 text-center'>
                                <p>SL</p>
                            </div>
                            <div class='w10 text-center'>
                                <p>AP</p>
                            </div>
                            <div class='w80 text-center'>
                                <p>PERSONA JURIDICA</p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolActaConst} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolActaConst2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>Acta de Costitución </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolPoderRep} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolPoderRep2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>Poder Representante Legal </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolIdenOf} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolIdenOf2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>Identificación Oficial del Representante Legal </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolRucNic} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolRucNic2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>RUC/NIT (de la empresa y del rep. legal) </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolComDom} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolComDom2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>Comprobante de Domicilio (Recibo energía o agua) </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolEmail} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolEmail2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80 '>
                                <p>Correo electrónico de contacto y envío de facturas </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolDatBanc} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolDatBanc2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Datos bancarios: Banco, número de cuenta y clabe interbancaria </p>
                                <p>(cuando aplique)</p>
                            </div>
                        </div>

                        {/* <!-- Persona Natural --> */}


                        <div class='header-tab flex'>
                            <div class='w10 text-center'>
                                <p>SL</p>
                            </div>
                            <div class='w10 text-center'>
                                <p>AP</p>
                            </div>
                            <div class='w80 text-center'>
                                <p>PERSONA NATURAL </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolIdenOfNat} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolIdenOfNat2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Identificación Oficial (cédula o pasaporte)</p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolNit} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolNit2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>NIT (cuando aplique)</p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolComDomNat} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolComDomNat2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Comprobante de Domicilio (hasta 3 meses de antigüedad)</p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolEmailNat} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolEmailNat2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Correo electrónico de contacto y envío de facturas</p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolDatBancNat} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolDatBancNat2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Datos bancarios: Banco, número de cuenta y clabe interbancaria</p>
                                <p>(cuando aplique)</p>
                            </div>
                        </div>

                        <div class='header-tab flex'>
                            <div class='w10 text-center'>
                                <p>SL</p>
                            </div>
                            <div class='w10 text-center'>
                                <p>AP</p>
                            </div>
                            <div class='w80 text-center'>
                                <p>ENTREGABLES </p>
                            </div>
                        </div>

                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolInforme} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolInforme2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Informe </p>
                            </div>
                        </div>
                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolManual} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolManual2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Manual </p>
                            </div>
                        </div>
                        <div class='tab-item flex'>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolOtros} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w10 text-center'>
                                <Checkbox checked={BolOtros2} inputProps={{ 'aria-label': 'controlled' }} size='small' />
                            </div>
                            <div class='w80'>
                                <p>Otros </p>
                            </div>
                        </div>
                    </div>
                    <div class='mt2 w105 center header-top  '>
                        <div class='flex'>
                            <fieldset class=' w100 '>
                                <legend>Empresa</legend>
                                <p>{_Data.txtEmpresa}</p>
                            </fieldset>
                        </div>
                        <div class='flex mt2'>
                            <fieldset class=' w50 '>
                                <legend>Giro</legend>
                                <p>{_Data.txtGiro}</p>
                            </fieldset>
                            <fieldset class=' w50 '>
                                <legend>Comisión pactada</legend>
                                <p>{_Data.dblComisionPactada} %</p>
                            </fieldset>
                        </div>

                        <div class='flex mt2'>
                            <fieldset class=' w33 '>
                                <legend>Inicio de operación</legend>
                                <p>{_Data.fecInicioOperacion}</p>
                            </fieldset>
                            <fieldset class=' w33 '>
                                <legend>Periodicidad</legend>
                                <p>{_Data.txtPeriodicidad}</p>
                            </fieldset>
                            <fieldset class=' w33 '>
                                <legend>Monto  a operar</legend>
                                <p>{_Data.dblMontoOperar}</p>
                            </fieldset>
                        </div>

                        <div class='flex mt2'>
                            <fieldset class=' w33 '>
                                <legend>Tipo de retorno</legend>
                                <p>{_Data.txtTipoEntorno}</p>
                            </fieldset>
                            <fieldset class=' w33 '>
                                <legend>No. terminales</legend>
                                <p>{_Data.numTerminales}</p>
                            </fieldset>
                            <fieldset class=' w33 '>
                                <legend>Contacto del cliente</legend>
                                <p>{_Data.txtContactoCliente}</p>
                            </fieldset>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div id="Captura2" className='w100'>
                <div class='flex-column'>
                <div class='mt2 w105 center header-top'>
                        <div class='flex mt2'>
                            <fieldset class=' w33 '>
                                <legend>Teléfono de Oficina</legend>
                                <p>{_Data.txtTelefono}</p>
                            </fieldset>
                            <fieldset class=' w70 '>
                                <legend>Correo de contacto y envío de facturas</legend>
                                <p>{_Data.txtEmailFacturas}</p>
                            </fieldset>
                        </div>

                        <div class='flex mt3'>
                            <fieldset class='line-height2 w100'>
                                <legend>COMENTARIOS</legend>
                                <p>{_Data.txtComentarios}</p>
                            </fieldset>
                        </div>
                    </div>

                    <div class='mt7 w105 center '>
                        <div class=' mt7'>
                            <div class='flex-between w-150 text-center'>
                                <div class='w30 border-top '>
                                    <p>DIRECCIÓN GENERAL (cuando aplique)</p>
                                </div>
                                <div class='w30 border-top '>
                                    <p>Gerencia comercial </p>
                                </div>
                                <div class='w30 border-top '>
                                    <p>Gerencia de operaciones</p>
                                </div>
                            </div>
                            <div class='flex-between mt10 text-center'>
                                <div class='w40 border-top'>
                                    <p>Ejecutivo de Cuenta/ Implementación </p>
                                </div>
                                <div class='w40 border-top'>
                                    <p>Promotor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid
                    item
                    
                >
                    <Tooltip title='Imprimir check list' arrow aria-label='add'>
                        <Button
                            className='btn-Header-back '
                            onClick={function () {
                                captura()
                            }}
                        >
                            <LocalPrintshopIcon /> imprimir
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default CheckListJuridicoPrint