import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { format, compareAsc } from 'date-fns'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import requests from '../AxiosCalls/AxiosCall'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import AsyncSelect from 'react-select/async'
import '../Styles/Styles.css'
import SaveIcon from '@mui/icons-material/Save'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import RutaCancelar from './RutaCancelar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import ListItemText from '@mui/material/ListItemText'
import AddIcon from '@mui/icons-material/Add'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material/styles'
import { getListItemAvatarUtilityClass } from '@mui/material'
import { Propane } from '@mui/icons-material'
import CREAR_RUTASOL, { EMPRESASPG_LISTA, NEGOCIO_LISTA } from '../../Constants/apiConstnats'
import CxpAddNew from './CxpAddNew'


const Input = styled('input')({
	display: 'none',
})
const SolictudCxpEdit = (props) => {
	const { id } = useParams()
	let urlEditarDetalles = '/SolicitudCXP/EditDetails/' + id
	const classes = StyleGeneral()
	const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()
	const [rutaSol, setRutaSol] = useState(null)

	const [open, setOpen] = React.useState(false)

	const InfoData = JSON.parse(sessionStorage.getItem('TokenSYSUser'))

	const [_Registro, SetRegistro] = useState({
		intIdSolicitdCxp: '',
		txtSolicitante: '',
		txtRegistro: '',
		intIdUsuario: '',
		intIdDepartamentos: '',
		intIdPlaza: '',
		intIdNegocio: '',
		intIdTipoServicio: '',
		intIdTipoGasto: '',
		txtFolio: '',
		txtConcepto: '',
		txtRazonSocial: '',
		txtBeneficiario: '',
		txtRfc: '',
		intTipoPago: '',
		txtTipoPago: '',
		intIdMoneda: '',
		dblImporte: '',
		dblIva: '',
		dblTotal: '',
		txtImporteTexto: '',
		fecDia: '',
		fecPago: '',
		fecProxVencimiento: '',
		intIdEmpresaPg: '',
		intIdRegCuenta: '',
		txtComentarios: '',
		intIdBanco: null,
		txtTarjeta: '',
		txtClabe: '',
		txtCuenta: '',
		txtIban: '',
		intIdStatus: '',
		lngIdProveedor: null,
		intIdProveedor: '',
		txtReferencia: '',
		bolCongelado: false,
		bolPriodidad: false,
		bolGastofijo: false,
	})
	const [_ListDepart, setListDepart] = useState([])
	const [_ListPlaza, setListPlaza] = useState([])
	const [_ListNegocio, setListnegocio] = useState([])
	const [_ListTipoServicio, setListTipoServicio] = useState([])
	const [_ListTipoGasto, setListTipoGasto] = useState([])
	const [_ListTipoPago, setListTipoPago] = useState([])
	const [_ListBanco, setListBanco] = useState([])
	const [_ListStatus, setListStatus] = useState([])
	const [_ListMonenda, setListMoneda] = useState([])
	const [_ListCuentasProv, setListCuentasProv] = useState([])
	const [_ListCuentasPg, setListCuentasPg] = useState([])
	const [_ListsProv, setListProv] = useState([])
	const [_ListaFacturas, setListaFacturas] = useState([])
	const [_ListEmpresaPG, setListEmpresaPG] = useState([])
	const [_sumaFactura, setsumaFactura] = useState([])
	
	const [open2, setOpen2] = useState(false);
	const handleOpen2 = () => {
		setOpen2(!open2);
	};

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const getComprobante = (id) => {
		var link = document.createElement('a')
		link.target = '_blank'
		link.href = requests.Url + 'TbRutaSolicitud/GetById/' + id
		document.body.appendChild(link)
		link.click()
	}

	const getDepartamentos = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtDepartamento/ObtenerLista')
			.then((response) => {
				setListDepart(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getplaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getNegocio = () => {
		props.setOpenLoadingScreen()
		requests
			.get(NEGOCIO_LISTA)
			.then((response) => {
				console.log('console', response );
				setListnegocio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getTipoServicio = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtTipoServicio/ObtenerLista')
			.then((response) => {
				setListTipoServicio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getTipoGasto = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtTipoGasto/ObtenerLista')
			.then((response) => {
				setListTipoGasto(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getTipoPago = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtTipoPago/ObtenerLista')
			.then((response) => {
				setListTipoPago(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_BancoRequired, setBancoRequired] = useState(true)
	const handleTipoPago = (event) => {
		if (event.target.value === 1) {
			setBancoRequired(false)
		} else {
			setBancoRequired(true)
		}
		SetRegistro({ ..._Registro, intTipoPago: event.target.value })
	}
	const getBanco = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtBanco/ObtenerLista')
			.then((response) => {
				setListBanco(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getregistro = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbSolicitudCxp/ObtenerPorId/' + id)
			.then((response) => {
				AsignarValores(response)

				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const getStatus = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtStatus/ObtenerLista')
			.then((response) => {
				setListStatus(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getMonenda = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtMoneda/ObtenerLista')
			.then((response) => {
				setListMoneda(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getproveedores = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbProveedores/ObtenerLista')
			.then((response) => {
				setListProv(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getEmpresaPg = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPRESASPG_LISTA)
			.then((response) => {
				setListEmpresaPG(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getListFacturas = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbRutaSolicitud/ObtenerListaPorIdSol/' + id)
			.then((response) => {
				setListaFacturas(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const [ListaDetalles, setListaDetalles] = useState([])

	const getListDetalles = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbCxpDetalle/ObtenerPorIdSol?id=' + id)
			.then((response) => {
				setListaDetalles(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getregistro()
		getDepartamentos()
		getplaza()
		getNegocio()
		getTipoServicio()
		getTipoPago()
		getBanco()
		getStatus()
		getMonenda()
		getListFacturas()
		getproveedores()
		getListDetalles()
		getTipoGasto()
		getEmpresaPg()
	}, [])

	const getUser = () => {
		return JSON.parse(sessionStorage.getItem('TokenSYSUser'))
	}
	
	const [_BloqueoBtn, setBloqueoBtn] = useState(false)
	
	const [_BloqueoTxt, setBloqueoTxt] = useState(false)
	
	
	const handleBolPrio = (e) => {

		SetRegistro({ ..._Registro, bolPriodidad: e.target.checked })
	}

	const handleBolGasto = (e) => {
		SetRegistro({ ..._Registro, bolGastofijo: e.target.checked })
	}

	const AsignarValores = (objeto) => {
		SetRegistro({
			..._Registro,
			intIdSolicitdCxp: objeto === null ? '' : objeto.intIdSolicitdCxp,
			txtSolicitante: objeto === null ? '' : objeto.txtSolicitante,
			txtRegistro: objeto === null ? '' : objeto.txtRegistro,
			intIdNegocio: objeto === null ? '' : objeto.intIdNegocio,
			txtConcepto: objeto === null ? '' : objeto.txtConcepto,
			intIdUsuario: objeto === null ? '' : objeto.intIdUsuario,
			intIdDepartamentos: objeto === null ? '' : objeto.intIdDepartamentos,
			intIdPlaza: objeto === null ? '' : objeto.intIdPlaza,
			intIdTipoServicio: objeto === null ? '' : objeto.intIdTipoServicio,
			intIdTipoGasto: objeto === null ? '' : objeto.intIdTipoGasto,
			txtFolio: objeto === null ? '' : objeto.txtFolio,
			txtRazonSocial: objeto === null ? '' : objeto.txtRazonSocial,
			txtBeneficiario: objeto === null ? '' : objeto.txtBeneficiario,
			txtRfc: objeto === null ? '' : objeto.txtRfc,
			intTipoPago: objeto === null ? '' : objeto.intTipoPago,
			intIdMoneda: objeto === null ? '' : objeto.intIdMoneda,
			dblTotal: objeto === null ? '' : ((objeto.dblIva * objeto.dblImporte) /100) + objeto.dblImporte,
			dblImporte: objeto === null ? '' : objeto.dblImporte,
			dblIva: objeto === null ? '' : objeto.dblIva,
			txtImporteTexto: objeto === null ? '' : objeto.txtImporteTexto,
			fecDia: objeto === null ? '' : objeto.fecDia,
			fecPago: objeto === null ? '' : objeto.fecPago,
			fecProxVencimiento: objeto === null ? '' : objeto.fecProxVencimiento,
			intIdEmpresaPg: objeto === null ? '' : objeto.intIdEmpresaPg,
			intIdRegCuenta: objeto === null ? '': objeto.intIdRegCuenta,
			txtComentarios: objeto === null ? '' : objeto.txtComentarios,
			intIdBanco: objeto === null ? '' : objeto.intIdBanco,
			txtTarjeta: objeto === null ? '' : objeto.txtTarjeta,
			txtClabe: objeto === null ? '' : objeto.txtClabe,
			txtCuenta: objeto === null ? '' : objeto.txtCuenta,
			txtIban: objeto === null ? '' : objeto.txtIban,
			intIdStatus: objeto === null ? '' : objeto.intIdStatus,
			lngIdProveedor: objeto === null ? '' : objeto.lngIdProveedor,
			txtReferencia: objeto == null ? '' : objeto.txtReferencia,
			bolGastofijo: objeto == null ? '' : objeto.bolGastofijo,
			bolPriodidad: objeto == null ? '' : objeto.bolPriodidad,
			intIdProveedor: objeto === null ? '' : objeto.intIdProveedor,
		})
		requests
		.get('TbCuentasPg/ObtenerListaEmpPgVM/' + objeto.intIdEmpresaPg)
		.then((response) => {
			setListCuentasPg(response)
			props.setCloseLoadingScreen()
		})
		.catch((error) => {
			console.log('Error: ' + error)
			props.setCloseLoadingScreen()
		})

		if (objeto.intTipoPago === 1) {
			setBancoRequired(false)
		} else {
			setBancoRequired(true)
		}
		
		if (objeto.intIdStatus === 6 || objeto.intIdStatus === 7 || objeto.intIdStatus === 1 || (objeto.intIdStatus === 5 && InfoData.departamento !== 1 )) {
			setBloqueoBtn(true)
		}else if (objeto.intIdStatus === 5 && InfoData.departamento === 1){
			setBloqueoBtn(false)
		}else if (objeto.intIdStatus === 2 || objeto.intIdStatus === 4){
			setBloqueoTxt(true)
		}



		setfecPago(Date(objeto.fecProxVencimiento.toString()))

		// console.log(_Registro)
		// requests
		// 	.get('TbCuentasProv/ObtenerPorIdProveedor/' + objeto.intIdProveedor)
		// 	.then((response) => {
		// 		setListCuentasProv(response)
		// 		props.setCloseLoadingScreen()
		// 	})
		// 	.catch((error) => {
		// 		console.log('Error: ' + error)
		// 		props.setCloseLoadingScreen()
		// 	})
	}

	const handleTextTipoSErv = (event) => {
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
		requests
			.get('CtTipoGasto/ObtnerListaPorServicio/' + event.target.value)
			.then((response) => {
				setListTipoGasto(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const handleText = (event) => {
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
	}

	const handleOblur = (event) => {
		let importe =
			(parseFloat(_Registro.dblIva) * parseFloat(_Registro.dblImporte)) / 100
		let importeiva = importe + parseFloat(_Registro.dblImporte)
		SetRegistro({ ..._Registro, dblTotal: importeiva })
	}
	const [_fecPago, setfecPago] = React.useState(null)
	const handlefecPago = (date) => {
		setfecPago(date)
		SetRegistro({ ..._Registro, fecProxVencimiento: date })
	}
	
	// const handleTextProveedor = (event) => {

	// 	requests
	// 		.get('TbProveedores/ObtenerPorId/' + event.target.value)
	// 		.then((response) => {
	// 			SetRegistro({
	// 				..._Registro,
	// 				intIdProveedor: response.lngIdProveedor,
	// 			})
	// 			requests
	// 				.get('TbCuentasProv/ObtenerPorIdProveedor/' + response.lngIdProveedor)
	// 				.then((response) => {
	// 					setListCuentasProv(response)
	// 					props.setCloseLoadingScreen()
	// 				})
	// 				.catch((error) => {
	// 					console.log('Error: ' + error)
	// 					props.setCloseLoadingScreen()
	// 				})
	// 		})
	// 		.catch((error) => {
	// 			console.log('Error: ' + error)
	// 			props.setCloseLoadingScreen()
	// 		})
		
	// }

	const handleTextEmpresa = (event) => {
		SetRegistro({ ..._Registro, intIdEmpresaPg: event.target.value })
		requests
			.get('TbCuentasPg/ObtenerListaEmpPgVM/' + event.target.value)
			.then((response) => {
				setListCuentasPg(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const [selectedFile, setSelectedFile] = useState(null)

	const handleFileInput = (e) => {
		setSelectedFile(e.target.files[0])
		props.setOpenLoadingScreen()

		const formData = new FormData()
		formData.append('Files', e.target.files[0])

		let url = 'TbRutaSolicitud/Crear?id=' + id
		requests
			.post(url, formData)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				window.location.reload()
			})
			.catch((error) => {

				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbSolicitudCxp/Editar'
		if (_Registro.intIdStatus === 1 && InfoData.departamento === 1 || _Registro.intIdStatus !== 1 ) {
			requests.put(url, _Registro)
				.then((response) => {

					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					window.location.reload()
					//navigate('/SolicitudCXP/Edit/' + id)
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.message)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar('Permissões limitadas para salvar o registro [ENCERRADA]')
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}
	const [_CuentaProv, setCuentaProv] = useState(null)
	const [_Cuenta, setCuenta] = useState(null)

	const handleCuentasProv = (event) => {
		setCuentaProv(event.target.value)
		SetRegistro({ ..._Registro, intIdRegCuenta: event.target.value })
	}

	const [_CuentaPg, setCuentaPg] = useState(null)
	const handleCuentasPg = (event) => {
		if(event.target.value === '' || event.target.value === ""){
			setCuentaPg(event.target.value)
			SetRegistro({ ..._Registro, intIdRegCuenta: null })	
		}else{
			setCuentaPg(event.target.value)
			SetRegistro({ ..._Registro, intIdRegCuenta: event.target.value })
		}
	}

	const formatearFecha = (fecha) => {
		const date = new Date(fecha);
		return date.toLocaleDateString('es-ES');
	};

	return (
		<ThemeProvider theme={Theme}>

			<Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: '10px;' }}>
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12} sx={{ py: 2 }}>
					<Paper
						elevation={0}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '10px 0px 10px 0px',
							backgroundColor: 'transparent',
						}}
					>
						<div className='containerAlignHeader '>
							<Link to='/SolicitudCXP' style={{ textDecoration: 'none' }}>
								<Button
									className='btn-Header-back'
									startIcon={<ArrowBackIosNewSharpIcon />}
								>
									Voltar
								</Button>
							</Link>
							<Chip
								icon={<MonetizationOnIcon />}
								label='Contas por Pagar / Editar'
								color='primary'
								className='back-transparent font-letter font-barter font-20'
							/>
						</div>

						<div className='containerAlignHeader'>
							<div className='container'>
								<div style={{ display: 'flex' }}>
									<h4 className='title-solicitud'>Solicitado por: </h4>
									<h4 className='subtitle-name'>{_Registro.txtSolicitante}</h4>
								</div>

								<div className='container-folio'>
									<h4 className='title-solicitud'>Fatura: </h4>
									<h4 className='subtitle-name'>{_Registro.txtRegistro}</h4>
								</div>
							</div>
						</div>
					</Paper>
				</Grid>

				<Grid
					item
					xs={12}
					md={11}
					lg={12}
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					<Paper elevation={8} sx={{ p: 3, borderRadius: '10px' }}>
						<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
							<Paper elevation={0}>
							<Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={2}
								>
									<Grid item xs={4}>
										<TextField
											required
											id='txtSolicitante'
											name='txtSolicitante'
											label='Candidato'
											variant='standard'
											fullWidth
											value={_Registro.txtSolicitante}
											disabled='true'
										/>
									</Grid>
									<Grid item xs={2}>
										<TextField
											sx={{fontSize: '.5'}}
											size="small"
											id='fecDia'
											name='fecDia'
											label='Fecha'
											variant='standard'
											fullWidth
											value={formatearFecha(_Registro.fecDia)}
											disabled='true'
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdNegocio'>
												Listagem de empresas
											</InputLabel>
											<Select
												required
												disabled={_BloqueoTxt}
												value={_Registro.intIdNegocio}
												onChange={handleText}
												label='intIdNegocio'
												inputProps={{
													name: 'intIdNegocio',
													id: 'intIdNegocio',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListNegocio.map((object, index) => (
													<MenuItem value={object.intIdNegocio}>
														{object.txtNegocio}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel control={<Checkbox checked={_Registro.bolPriodidad} onChange={handleBolPrio} disabled={_BloqueoTxt} />} label="Prioridade" />
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdDepartamentos'>
												Departamentos
											</InputLabel>
											<Select
												required
												disabled={_BloqueoTxt}
												value={_Registro.intIdDepartamentos}
												onChange={handleText}
												label='intIdDepartamentos'
												inputProps={{
													name: 'intIdDepartamentos',
													id: 'intIdDepartamentos',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListDepart.map((object, index) => (
													<MenuItem value={object.intIdDepartamentos}>
														{object.txtDepartamento}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<LocalizationProvider
											size='small'
											dateAdapter={AdapterDateFns}
											required
										>
											<DatePicker
												required
												disabled={_BloqueoTxt}
												name='fecPago'
												label='Data de pagamento'
												inputFormat='dd/MM/yyyy'
												value={_Registro.fecProxVencimiento}
												onChange={handlefecPago}
												renderInput={(params) => (
													<TextField
														required
														variant='standard'
														fullWidth
														size='small'
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdPlaza'>Listagem de praças</InputLabel>
											<Select
												required
												disabled={_BloqueoTxt}
												value={_Registro.intIdPlaza}
												onChange={handleText}
												label='intIdPlaza'
												inputProps={{
													name: 'intIdPlaza',
													id: 'intIdPlaza',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListPlaza.map((object, index) => (
													<MenuItem value={object.intIdPlaza}>
														{object.txtPlaza}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel control={<Checkbox checked={_Registro.bolGastofijo} disabled={_BloqueoTxt} onChange={handleBolGasto} />}
											label="Despesa Fixa (Aluguel, pagamento de luz, água, papelaria, etc.)" />
									</Grid>
									<Grid item xs={2}>
										<TextField
											disabled={_BloqueoTxt}
											id='txtFolio'
											name='txtFolio'
											label='Nota Fiscal'
											variant='standard'
											fullWidth
											value={_Registro.txtFolio}
											onChange={handleText}
											inputProps={{ maxLength: 20 }}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdTipoServicio'>
												Tipo de serviço
											</InputLabel>
											<Select
												required
												disabled={_BloqueoTxt}
												value={_Registro.intIdTipoServicio}
												onChange={handleTextTipoSErv}
												label='intIdTipoServicio'
												inputProps={{
													name: 'intIdTipoServicio',
													id: 'intIdTipoServicio',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListTipoServicio.map((object, index) => (
													<MenuItem value={object.intIdTipoServicio}>
														{object.txtTipoServicio}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl required variant='standard' fullWidth>
											<InputLabel id='intIdTipoGasto'>
												Tipo de despesa
											</InputLabel>
											<Select
												required
												disabled={_BloqueoTxt}
												value={_Registro.intIdTipoGasto}
												onChange={handleText}
												label='intIdTipoGasto'
												inputProps={{
													name: 'intIdTipoGasto',
													id: 'intIdTipoGasto',
												}}
											>
												<MenuItem aria-label='None' value='' />
												{_ListTipoGasto.map((object, index) => (
													<MenuItem value={object.intIdTipoGasto}>
														{object.txtTipoGasto}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<TextField
											required
											disabled={_BloqueoTxt}
											id='txtConcepto'
											name='txtConcepto'
											label='Conceito'
											variant='standard'
											fullWidth
											value={_Registro.txtConcepto}
											onChange={handleText}
										/>
									</Grid>
								</Grid>

								<fieldset
									style={{
										borderRadius: '20px',
										border: 'solid 3px #c3e0f3',
										marginTop: '20px',
									}}
								>
									<legend style={{ fontWeight: 'bold', fontSize: '20px' }}>
										&nbsp; Dados para pagamento &nbsp;
									</legend>
									<Grid container sx={{ p: 2, px: 1 }}>
										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='flex-start'
											alignItems='center'
										>

											{/* <Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='intIdProveedor'>
														Razão Social
													</InputLabel>
													<Select

														value={_Registro.intIdProveedor}
														onChange={handleTextProveedor}
														label='intIdProveedor'
														inputProps={{
															name: 'intIdProveedor',
															id: 'intIdProveedor',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListsProv.map((object, index) => (
															<MenuItem value={object.lngIdProveedor}>
																{object.txtNombreComercial +
																	' ' +
																	object.txtRfc}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid> */}
											{/* <Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='lngIdCuentasProv'>
													Lista de contas
													</InputLabel>
													<Select
														value={_CuentaProv}
														onChange={handleCuentasProv}
														label='Cuentas'
														inputProps={{
															name: 'lngIdCuentasProv',
															id: 'lngIdCuentasProv',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListCuentasProv.map((object, index) => (
															<MenuItem value={object.lngIdCuentasProv}>
																{object.txtCuenta}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid> */}

											<Grid item xs={6}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdEmpresaPg'>
														Empresa Pagadora
													</InputLabel>
													<Select
														disabled={_BloqueoTxt}
														value={_Registro.intIdEmpresaPg}
														onChange={handleTextEmpresa}
														label='intIdEmpresaPg'
														inputProps={{
															name: 'intIdEmpresaPg',
															id: 'intIdEmpresaPg',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListEmpresaPG.map((object, index) => (
															<MenuItem value={object.intIdEmpresaPg}>
																{object.txtEmpresaPg +
																	' ' +
																	object.txtRfc}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl variant='standard' fullWidth>
													<InputLabel id='intIdRegCuenta'>
													Lista de contas
													</InputLabel>
													<Select
														disabled={_BloqueoTxt}
														value={_Registro.intIdRegCuenta}
														onChange={handleCuentasPg}
														label='intIdRegCuenta'
														inputProps={{
															name: 'intIdRegCuenta',
															id: 'intIdRegCuenta',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListCuentasPg.map((object, index) => (
															<MenuItem value={object.intIdCuentasPg}>
																{object.txtBanco }
																&nbsp; - &nbsp;
																{ object.numCuenta}
																&nbsp; - &nbsp;
																{ object.numClabe}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required
													disabled={_BloqueoTxt}
													id='txtBeneficiario'
													name='txtBeneficiario'
													label='Beneficiário'
													variant='standard'
													fullWidth
													value={_Registro.txtBeneficiario}
													onChange={handleText}
													inputProps={{ maxLength: 200 }}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required
													disabled={_BloqueoTxt}
													id='txtRfc'
													name='txtRfc'
													label='CPF/CNPJ'
													variant='standard'
													fullWidth
													value={_Registro.txtRfc}
													onChange={handleText}
													inputProps={{ maxLength: 14 }}
												/>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='center'
											alignItems='center'
										>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdMoneda'>
													Lista de moedas
													</InputLabel>
													<Select
														required
														disabled={_BloqueoTxt}
														value={_Registro.intIdMoneda}
														onChange={handleText}
														label='Bancos'
														inputProps={{
															name: 'intIdMoneda',
															id: 'intIdMoneda',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListMonenda.map((object, index) => (
															<MenuItem value={object.intIdmoneda}>
																{object.txtMoneda + '-' + object.txtNomCorto}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<NumberFormat
													required
													disabled={_BloqueoTxt}
													label='Quantia'
													variant='standard'
													value={_Registro.dblImporte}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													onValueChange={(values) => {
														SetRegistro({
															..._Registro,
															dblImporte: values.floatValue,
														})
													}}
													onBlur={handleOblur}
													size='small'
													name='dblImporte'
												/>
											</Grid>
											<Grid item xs={2}>
												<NumberFormat
													required
													disabled={_BloqueoTxt}
													label='Impostos'
													variant='standard'
													value={_Registro.dblIva}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													suffix={'%'}
													fullWidth
													onValueChange={(values) => {
														SetRegistro({
															..._Registro,
															dblIva: values.floatValue,
														})
													}}
													onBlur={handleOblur}
													size='small'
													name='dblIva'
												/>
											</Grid>
											<Grid item xs={2}>
												<NumberFormat
													readOnly
													disabled
													label='Total'
													variant='standard'
													value={_Registro.dblTotal}
													customInput={TextField}
													thousandSeparator={true}
													fixedDecimalScale={true}
													decimalScale={2}
													prefix={'$'}
													fullWidth
													size='dblTotal'
													name='dblTotal'
												/>
											</Grid>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intTipoPago'>
														Tipo de pagamento
													</InputLabel>
													<Select
														required
														disabled={_BloqueoTxt}
														value={_Registro.intTipoPago}
														onChange={handleTipoPago}
														label='intTipoPago'
														inputProps={{
															name: 'intTipoPago',
															id: 'intTipoPago',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListTipoPago.map((object, index) => (
															<MenuItem value={object.intTipoPago}>
																{object.txtTipoPago}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={8}>
												<TextField
													disabled={_BloqueoTxt}
													required = {
														_Registro.intTipoPago === 2 || _Registro.intTipoPago === 7 ||
														_Registro.intTipoPago === 8 || _Registro.intTipoPago === 9 ? true : false
													}
													id='txtIban'
													name='txtIban'
													label='Referencia'
													variant='standard'
													fullWidth
													value={_Registro.txtIban}
													onChange={handleText}
													inputProps={{ maxLength: 250 }}
												/>
											</Grid>										
										</Grid>

										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='flex-start'
											alignItems='center'
										>
											<Grid item xs={4}>
												<FormControl
													variant='standard'
													fullWidth
													disabled={_BloqueoTxt}
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
												>
													<InputLabel id='intIdBanco'>
													Lista de Bancos
													</InputLabel>
													<Select
														value={_Registro.intIdBanco}
														onChange={handleText}
														label='Bancos'
														inputProps={{
															name: 'intIdBanco',
															id: 'intIdBanco',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_ListBanco.map((object, index) => (
															<MenuItem value={object.intIdBanco}>
																{object.txtBanco}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<TextField
													disabled={_BloqueoTxt}
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
													id='txtClabe'
													name='txtClabe'
													label='Agencia'
													variant='standard'
													fullWidth
													value={_Registro.txtClabe}
													onChange={handleText}
													inputProps={{ maxLength: 10 }}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													required = {
														_Registro.intTipoPago === 1 || _Registro.intTipoPago === 3 ||
														_Registro.intTipoPago === 5 || _Registro.intTipoPago === 4 ? true : false
													}
													disabled={_BloqueoTxt}
													id='txtCuenta'
													name='txtCuenta'
													label='Conta'
													variant='standard'
													fullWidth
													value={_Registro.txtCuenta}
													onChange={handleText}
													inputProps={{ maxLength: 25 }}
												/>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={2}
											direction='row'
											alignItems='center'
										>
											
											<Grid item xs={4}>
												<TextField
													multiline
													maxRows={4}
													disabled={_BloqueoTxt}
													id='txtComentarios'
													name='txtComentarios'
													label='Observação'
													variant='standard'
													fullWidth
													value={_Registro.txtComentarios}
													onChange={handleText}
												/>
											</Grid>
											<Grid item xs={4}>
												<FormControl required variant='standard' fullWidth>
													<InputLabel id='intIdStatus'>Status</InputLabel>
												{_Registro.intIdStatus === 1 || _Registro.intIdStatus === 7 ? 
													<Select
														required
														disabled={true}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
													>													
														<MenuItem value={1}>
															ENCERRADA
														</MenuItem>
														<MenuItem value={7}>
															RECUSADA
														</MenuItem>													
													</Select>
													:
													_Registro.intIdStatus === 2 && InfoData.departamento !== 11  ? 
														<Select
															required
															disabled={false}
															value={_Registro.intIdStatus}
															onChange={handleText}
															label='Status'
															inputProps={{
																name: 'intIdStatus',
																id: 'intIdStatus',
															}}
														>													
															<MenuItem value={2}>
																APROVADA
															</MenuItem>		
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>									
														</Select>
													:
													_Registro.intIdStatus === 2 && InfoData.departamento === 11 ? 
														<Select
															required
															disabled={false}
															value={_Registro.intIdStatus}
															onChange={handleText}
															label='Status'
															inputProps={{
																name: 'intIdStatus',
																id: 'intIdStatus',
															}}
														>													
															<MenuItem value={2}>
																APROVADA
															</MenuItem>
															<MenuItem value={4}>
																PROCESSADA
															</MenuItem>
															<MenuItem value={7}>
																RECUSADA
															</MenuItem>	
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>											
														</Select>
														:
														_Registro.intIdStatus === 3 && InfoData.departamento === 1 ? 
														<Select
															required
															disabled={false}
															value={_Registro.intIdStatus}
															onChange={handleText}
															label='Status'
															inputProps={{
																name: 'intIdStatus',
																id: 'intIdStatus',
															}}
														>													
															<MenuItem value={2}>
																APROVADA
															</MenuItem>
															<MenuItem value={3}>
																EM VALIDAÇÃO
															</MenuItem>
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>											
															<MenuItem value={7}>
																RECUSADA
															</MenuItem>	
														</Select>
														:
														_Registro.intIdStatus === 3 && InfoData.departamento !== 1? 
														<Select
															required
															disabled={false}
															value={_Registro.intIdStatus}
															onChange={handleText}
															label='Status'
															inputProps={{
																name: 'intIdStatus',
																id: 'intIdStatus',
															}}
														>
															<MenuItem value={3}>
																EM VALIDAÇÃO
															</MenuItem>
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>
														</Select>
														:
														_Registro.intIdStatus === 4 && InfoData.departamento === 11? 
														<Select
														required
														disabled={false}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
														>												
															<MenuItem value={4}>
																PROCESSADA
															</MenuItem>
															<MenuItem value={5}>
																PAGA
															</MenuItem>
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>												
														</Select>
														:
														_Registro.intIdStatus === 4 && InfoData.departamento !== 11 ? 
														<Select
														required
														disabled={false}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
														>												
															<MenuItem value={4}>
																PROCESSADA
															</MenuItem>
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>												
														</Select>
														:
														_Registro.intIdStatus === 5 && InfoData.departamento === 1 ? 
														<Select
														required
														disabled={false}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
														>												
															<MenuItem value={1}>
																ENCERRADA
															</MenuItem>
															<MenuItem value={5}>
																PAGA
															</MenuItem>												
														</Select>
														:
														_Registro.intIdStatus === 5 && InfoData.departamento !== 1 ? 
														<Select
														required
														disabled={true}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
														>
															<MenuItem value={5}>
																PAGA
															</MenuItem>												
														</Select>
														: <Select
														required
														disabled={true}
														value={_Registro.intIdStatus}
														onChange={handleText}
														label='Status'
														inputProps={{
															name: 'intIdStatus',
															id: 'intIdStatus',
														}}
														>													
															<MenuItem value={6}>
																CANCELADA
															</MenuItem>											
														</Select>
													}
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</fieldset>

								<div className='containerHeader'>
									<label htmlFor='contained-button-file'>
										<Input
											id='contained-button-file'
											//required
											type='file'
											onChange={handleFileInput}
										/>
										<Button className='btn-footer' startIcon={<AddIcon />} variant='contained' component='span'>
										Adicionar comprovante
										</Button>
									</label>

									<Button className='btn-footer' variant='contained' startIcon={<EditIcon />} disabled={_BloqueoBtn}>
										<Link
											to={urlEditarDetalles}
											style={{ textDecoration: 'none', color: '#073763', fontFamily: "Roboto, Helvetica, Arial", fontWeight: 'bold' }}
										>
											Adicionar detalhes
										</Link>
									</Button>

									<Button
										onClick={function () {
											handleOpen2()
										}}
										className='btn-footer '
										variant='contained'
										startIcon={<SaveIcon />}
										size='small'
									>
										NOVO
									</Button>

									<Button
										type='submit'
										className='btn-footer '
										variant='contained'
										startIcon={<SaveIcon />}
										size='small'
										disabled={_BloqueoBtn}
									>
										Atualizar
									</Button>
								</div>

								<TableContainer
									sx={{
										mt: 3,
										maxHeight: 500,
										overFlow: 'auto',
										backgroundColor: 'rgba(70, 104, 70, 0.5)',
									}}
								>
									<Table stickyHeader aria-label='sticky table' size={'small'}>
										<TableHead>
											<TableRow>
												<TableCell
													align='center'
													colSpan={5}
													style={{
														fontWeight: 700,
														backgroundColor: '#073763',
														color: '#fff',
													}}
												>
													Lista de comprovante
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													align='center'
													style={{
														fontWeight: 700,
														color: '#fff',
														backgroundColor: '#073763',
													}}
												>
													Nome
												</TableCell>
												<TableCell
													align='center'
													style={{
														fontWeight: 700,
														color: '#fff',
														backgroundColor: '#073763',
													}}
												>
													Data
												</TableCell>
												<TableCell
													align='center'
													style={{
														fontWeight: 700,
														color: '#fff',
														backgroundColor: '#073763',
													}}
												>
													Descarga
												</TableCell>
												{InfoData.departamento === 1 || InfoData.departamento === 11  ? (
													<TableCell
														align='center'
														style={{
															fontWeight: 700,
															color: '#fff',
															backgroundColor: '#073763',
														}}
													>
														Eliminar
													</TableCell>
												) : (null)}

											</TableRow>
										</TableHead>
										<TableBody
											sx={{
												backgroundColor: '#bbdefb'
											}} >
											{_ListaFacturas.map((row) => {
												return (
													<TableRow key={row.intIdRutaArchivo}>
														<TableCell align='center'>
															{row.txtNombre}
														</TableCell>
														<TableCell align='center'>
															{format(
																new Date(row.fecRutaSolicitud),
																'dd / MM / yyyy'
															)}
														</TableCell>
														<TableCell align='center'>
															<Button
																className='btn-imprimir'
																align='right'
																onClick={function () {

																	if (row.txtRutaSolicitud === null) {
																		getComprobante(row.intIdRutaSolicitud)
																	}
																	window.open(row.txtRutaSolicitud, '_blank')
																}}
																startIcon={<CloudDownloadIcon />}
																size='small'
															>
																Descargar
															</Button>
														</TableCell>
														{InfoData.departamento === 1 || InfoData.departamento === 11 ? (
															<TableCell align='center'>
																<Button
																	className='font-btn-eliminar'
																	align='right'
																	onClick={function () {
																		setRutaSol(row.intIdRutaSolicitud)
																		handleOpen()
																	}}
																	startIcon={<DeleteIcon />}
																	size='small'
																>
																	Cancelar
																</Button>
															</TableCell>
														) : (null)}

													</TableRow>
												)
											})
											}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</form>
					</Paper>
				</Grid>
			</Grid>
			<RutaCancelar
				IntIdRutaSolicitud={rutaSol}
				open={open}
				getRegistros={getregistro}
				onClose={handleClose}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			
			<CxpAddNew
				open={open2}
				onClose={handleOpen2}
				getRegistros ={_Registro}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</ThemeProvider>
	)
}

export default SolictudCxpEdit
