import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@emotion/react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html-to-pdf-js'
import { Link, useNavigate, useParams } from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import '../Styles/StylesSolicitudCxpDetalles.css'
import { Divider } from '@mui/material'

const SolicitudCXPPrintDetails = (props) => {
	const { id } = useParams()
	let navigate = useNavigate()
	const [_Data, setData] = useState({
		solicitante: '',
		departamento: '',
		plaza: '',
		negocio: '',
		tipoServicio: '',
		tipoGasto: '',
		folio: '',
		concepto: '',
		razonSocial: '',
		beneficiario: '',
		proveedor: '',
		rfc: '',
		tipoPago: '',
		registro: '',
		moneda: '',
		dblImporte: '',
		dblIva: '',
		txtImporteTexto: '',
		banco: '',
		clabe: '',
		cuenta: '',
		txtIban: '',
		fecDia: '',
		fecProxVencimiento: '',
		fecPago: '',
		txtComentarios: '',
		status: '',
		txtMotivo: '',
		tarjeta: '',
		dblTotal: '',
	})

	const captura = async () => {
		let url = requests.Url + 'GenerarPDF/GenerarPDFDetalleSpp/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getData = () => {
		props.setOpenLoadingScreen()
		requests
			.get('Reports/Impreso', id)
			.then((response) => {
				asignarValores(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getData()
		getListDetalles()
	}, [])

	const [ListaDetalles, setListaDetalles] = useState([])

	let urlEditarDetalles = '/SolicitudCXP/EditDetails/' + id

	const getListDetalles = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbCxpDetalle/ObtenerListaPorIdSolm?id=' + id)
			.then((response) => {
				setListaDetalles(response)
				ListaDetalles.fecRegistro =
					ListaDetalles.fecRegistro.ToString('yyyy-MM-dd')
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const asignarValores = (objeto) => {
		setData({
			..._Data,
			solicitante: objeto.solicitante === null ? '' : objeto.solicitante,
			departamento: objeto.departamento === null ? '' : objeto.departamento,
			plaza: objeto.plaza === null ? '' : objeto.plaza,
			negocio: objeto.negocio === null ? '' : objeto.negocio,
			tipoServicio: objeto.tipoServicio === null ? '' : objeto.tipoServicio,
			tipoGasto: objeto.tipoGasto === null ? '' : objeto.tipoGasto,
			folio: objeto.folio === null ? '' : objeto.folio,
			concepto: objeto.concepto === null ? '' : objeto.concepto,
			razonSocial: objeto.razonSocial === null ? '' : objeto.razonSocial,
			beneficiario: objeto.beneficiario === null ? '' : objeto.beneficiario,
			proveedor: objeto.proveedor === null ? '' : objeto.proveedor,
			rfc: objeto.rfc === null ? '' : objeto.rfc,
			tipoPago: objeto.tipoPago === null ? '' : objeto.tipoPago,
			registro: objeto.registro === null ? '' : objeto.registro,
			moneda: objeto.moneda === null ? '' : objeto.moneda,
			dblImporte: objeto.dblImporte === null ? '' : objeto.dblImporte,
			dblIva: objeto.dblIva === null ? '' : objeto.dblIva,
			txtImporteTexto:
				objeto.txtImporteTexto === null ? '' : objeto.txtImporteTexto,
			banco: objeto.banco === null ? '' : objeto.banco,
			clabe: objeto.clabe === null ? '' : objeto.clabe,
			cuenta: objeto.cuenta === null ? '' : objeto.cuenta,
			txtIban: objeto.txtIban === null ? '' : objeto.txtIban,
			fecDia: objeto.fecDia === null ? '' : objeto.fecDia,
			fecProxVencimiento:
				objeto.fecProxVencimiento === null ? '' : objeto.fecProxVencimiento,
			fecPago: objeto.fecPago === null ? '' : objeto.fecPago,
			txtComentarios:
				objeto.txtComentarios === null ? '' : objeto.txtComentarios,
			status: objeto.status === null ? '' : objeto.status,
			txtMotivo: objeto.txtMotivo === null ? '' : objeto.txtMotivo,
			tarjeta: objeto.tarjeta === null ? '' : objeto.tarjeta,
			dblTotal: objeto.dblTotal === null ? '' : objeto.dblTotal,
		})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Grid item xs={12} sm={8} md={10} lg={12} elevation={12} sx={{ py: 2 }}>
				<Paper
					elevation={0}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div className='containerAlignHeader'>
						<Link to={urlEditarDetalles} style={{ textDecoration: 'none' }}>
							<Button
								size='small'
								className='btn-Header-back '
								startIcon={<ArrowBackIosNewSharpIcon />}
							>
								Regresar
							</Button>
						</Link>
						<Chip
							icon={<MonetizationOnIcon />}
							label='Contas por pagar / Imprimir Detalles'
							color='primary'
							className='back-transparent font-letter font-barter font-20'
						/>
					</div>
				</Paper>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
			>
				<Grid item>
					<Tooltip title='Imprimir solicitud' arrow aria-label='add'>
						<Button
							className='btn-Header-back '
							onClick={function () {
								captura()
							}}
							sx={{ mb: 2 }}
						>
							<LocalPrintshopIcon /> imprimir
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
			<div id='Captura' className='w100'>
				<div>
					<table
						className='auto-style63'
						style={{
							// borderStyle: 'solid',
							width: '100%',
							// borderCollapse: 'collapse',
						}}
					>
						<tr>
							<td className='auto-style39' colSpan='7'>
								<h2 className='auto-style137' style={{ background: '#C0C0C0' }}>
									<strong style={{ fontFamily: 'Century Gothic' }}>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DETALLES&nbsp;
										{_Data.tipoGasto}&nbsp;
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
									</strong>
								</h2>
							</td>
						</tr>

						<tr>
							<td
								className='auto-style113'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								SOLICITADO POR:
							</td>
							<td
								className='auto-style114'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.solicitante}</strong>
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								DEPARTAMENTO:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.departamento}</strong>
							</td>
						</tr>
						<tr>
							<td
								className='auto-style94'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								PLAZA:
							</td>
							<td
								className='auto-style120'
								style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
							>
								<strong>{_Data.plaza}</strong>
							</td>
						</tr>
					</table>

					<table
						className='auto-style63'
						style={{
							// borderStyle: 'solid',
							width: '100%',
							marginTop: 5,
							// borderCollapse: 'collapse',
						}}
					>
						<tr>
							<td colSpan='7'>
								<Divider />
							</td>
						</tr>
						<tr style={{ border: '1px solid' }}>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										Registro
									</strong>
								</h2>
							</td>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										Concepto
									</strong>
								</h2>
							</td>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										Nombre
									</strong>
								</h2>
							</td>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										Iva
									</strong>
								</h2>
							</td>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										SubTotal
									</strong>
								</h2>
							</td>
							<td className='auto-style145'>
								<h2 className='tabla-cxp' style={{ background: '#5d7b9d' }}>
									<strong
										style={{ fontFamily: 'Century Gothic', fontSize: 'medium' }}
									>
										Total
									</strong>
								</h2>
							</td>
						</tr>
						{ListaDetalles.map((row) => {
							return (
								<tr key={row.intIdRutaArchivo}>
									<td
										className='auto-style145'
										align='left'
										style={{ border: '1px solid' }}
									>
										{row.fecRegistro}
									</td>
									<td
										className='auto-style145'
										align='left'
										style={{ border: '1px solid', width: 10 }}
									>
										{row.txtConcepto}
									</td>

									<td
										className='auto-style144'
										align='left'
										style={{ border: '1px solid' }}
									>
										{row.txtNombre}
									</td>
									<td
										className='auto-style145'
										align='left'
										style={{ border: '1px solid' }}
									>
										{row.dblIva.toFixed(2) + '%'}
									</td>
									<td
										className='auto-style145'
										align='left'
										style={{ border: '1px solid' }}
									>
										{/* {parseFloat(row.dblSubTotal)} */}
										{row.dblSubTotal.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										})}
									</td>

									<td
										className='auto-style145'
										align='left'
										style={{ border: '1px solid' }}
									>
										{(row.dblSubTotal * ((row.dblIva * 0.01) + 1)).toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										})}
									</td>
								</tr>
							)
						})}
					</table>
					<tr>
						<td class='auto-style144'>&nbsp;</td>
						<td colspan='2'>Documentos Adjuntos a reporte: </td>
						{/* <td
							class='auto-style143'
							colspan='3'
							style="font-family: 'Century Gothic'; font-size: larger"
						>
							Total:&nbsp;&nbsp;{' '}
							<asp:TextBox
								ID='Txt_Total'
								runat='server'
								BorderStyle='None'
								Height='25px'
								Width='165px'
								Font-Names='Bahnschrift SemiBold'
								Font-Size='Large'
							></asp:TextBox>
						</td> */}
						<tr>
							<td
								colspan='4'
								style={{ fontfamily: 'Century Gothic', fontsize: 'larger' }}
							>
								Total: <strong>{_Data.dblTotal}</strong>
							</td>
						</tr>
						<td class='auto-style138'>&nbsp;</td>
					</tr>
					<tr>
						<td class='auto-style146'></td>
						<td colspan='3' class='auto-style147'>
							Todos los comprobantes deberan de tener los datos de facturación
							correctos, RFC y dirección etc.
							{/* <asp:SqlDataSource
								ID='SqlDSDetCxp'
								runat='server'
								ConnectionString='<%$ ConnectionStrings:BaseStrategiasUatConnectionString %>'
								SelectCommand='SELECT [Fec_Registro], [Txt_Concepto], [Txt_Nombre], [Dbl_Iva], [Dbl_SubTotal] , [Dbl_Iva]+[Dbl_SubTotal] as TOTAL FROM [Tb_CxpDetalle] WHERE ([Int_IdSolicitdCxp] = @Int_IdSolicitdCxp) ORDER BY [Int_IdCxpDetalle]'
							>
								<SelectParameters>
									<asp:ControlParameter
										ControlID='Txt_IdSolicitdCxp'
										Name='Int_IdSolicitdCxp'
										PropertyName='Text'
										Type='Int32'
									/>
								</SelectParameters>
							</asp:SqlDataSource> */}
						</td>
						<td class='auto-style142'></td>
						<td class='auto-style147'></td>
						<td class='auto-style148'></td>
					</tr>
				</div>
			</div>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
			>
				<Grid item>
					<Tooltip title='Imprimir solicitud' arrow aria-label='add'>
						<Button
							className='btn-Header-back '
							onClick={function () {
								captura()
							}}
							sx={{ mt: 2 }}
						>
							<LocalPrintshopIcon /> imprimir
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default SolicitudCXPPrintDetails
