import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import HeaderLayout from '../Components/Layout/HeaderLayout'
import MainContainerLayout from '../Components/Layout/MainContainerLayout'
import MainContentLayout from '../Components/Layout/MainContentLayout'

import LoadingScreen from '../Components/Styles/LoadingScreen'
import MySnackBar from '../Components/Styles/MySnackBar'

import InicioView from '../View/InicioView'
import DepartamentosView from '../View/CatalogosView/DepartamentosView'
import MovimientosView from '../View/CatalogosView/MovimientosView'
import RetornoView from '../View/CatalogosView/RetornoView'
import NegociosView from '../View/CatalogosView/NegociosView'
import PlazasView from '../View/CatalogosView/PlazasView'
import SolicitudCXPView from '../View/SolicitudCXPView'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StatusView from '../View/CatalogosView/StatusView'
import TipoGastoView from '../View/CatalogosView/TipoGastoView'
import TipoPagosView from '../View/CatalogosView/TipoPagosView'
import BancosView from '../View/CatalogosView/BancosView'
import CuentasPgView from '../View/CuentasPgView'
import EmpresasPgView from '../View/EmpresasPgView'
import TipoDeServicio from '../View/CatalogosView/TipoDeServicio'
import SolictudCxpAdd from '../Components/SolicitudCxp/SolictudCxpAdd'
import SolictudCxpEdit from '../Components/SolicitudCxp/SolictudCxpEdit'
import SolictudCxpEditDetails from '../Components/SolicitudCxp/SolictudCxpDetails'
import ReporteVencimiento from '../View/Reportes/ReporteVencimiento'
import ReporteFechaRegistro from '../View/Reportes/ReporteFechaRegistro'
import ReporteFechaPago from '../View/Reportes/ReporteFechaPago'
import ReporteConsecutivo from '../View/Reportes/ReporteConsecutivo'
import ReporteConcepto from '../View/Reportes/ReporteConcepto'
import ReporteComprobante from '../View/Reportes/ReporteComprobante'
import SolicitudCxpPrint2 from '../Components/SolicitudCxp/SolicitudCxpPrint2'
import SolicitudCXPPrintDetails from '../Components/SolicitudCxp/SolicitudCXPPrintDetails'
import MonedaView from '../View/CatalogosView/MonedaView'
import UsuariosView from '../View/UsuariosView'
import ProveedoresView from '../View/ProveedoresView'
import SaldosView from '../View/SaldosView'
import CreditosView from '../View/CreditosView'
import CuentasProvView from '../View/CuentasProvView'
import TipoDocumentosView from '../View/CatalogosView/TipoDocumentosView'
import PaisesView from '../View/CatalogosView/PaisesView'
import EstadoCivilView from '../View/CatalogosView/EstadoCivilView'
import TipoProfesionalView from '../View/CatalogosView/TipoProfesionalView'
import TipoResidenciaView from '../View/CatalogosView/TipoResidenciaView'
import ClientesAdd from '../Components/Clientes/ClientesAdd'
import ClientesEdit from '../Components/Clientes/ClientesEdit'
import ClientesView from '../View/Clientes/ClientesView'
import ClientesPrint from '../Components/Clientes/ClientesPrint'
import ChecKListJuridicoAdd from '../Components/ChecKListJuridico/ChecKListJuridicoAdd'
import ChecKListJuridicoEdit from '../Components/ChecKListJuridico/ChecKListJuridicoEdit'
import ChecKListJuridicoView from '../View/ChecKListJuridico/ChecKListJuridicoView'
import CheckListJuridicoPrint from '../Components/ChecKListJuridico/CheckListJuridicoPrint'

import MonedasOpView from '../View/CatalogosView/MonedasOpView'
import ActividadEcoView from '../View/CatalogosView/ActividadEcoView'
import AreaView from '../View/CatalogosView/AreaView'
import CfdiView from '../View/CatalogosView/CfdiView'

import MantenimientoView from '../View/Mantenimiento/MantenimientoView'

import ProveedoresOpView from '../View/ProveedoresOp/ProveedoresOpView'
import StatusUtilidadView from '../View/StatusUtilidad/StatusUtilidadView'
import StatusPagoView from '../View/StatusPagos/StatusPagoView'

import OperacionesView from '../View/Operaciones/OperacionesView'
import OperacionesAdd from '../Components/Operaciones/OperacionesAdd'
import OperacionesEdit from '../Components/Operaciones/OperacionesEdit'

import ReporteGeneralCxp from '../View/Reportes/ReporteGeneralCxp'

import ReporteOperaciones from '../View/Reportes/ReporteOperaciones'


import {
	PROVEEDORES_OP_VIEW,
	OPERACIONES_CREATE,
	OPERACIONES_UPDATE,
	OPERACIONES_VIEW,
	STATUS_CLI_VIEW,
	STATUS_UTILIDAD_VIEW,
} from '../Constants/routesConstants'
import ProductosView from '../View/Productos/productosView'
import ProductosAdd from '../Components/Productos/ProductosAdd'
import ProductosEdit from '../Components/Productos/ProductosEdit'
import PromotoresView from '../View/Promotores/promotoresView'
import PromotoresAdd from '../Components/Promotores/promotoresAdd'
import PromotoresEdit from '../Components/Promotores/PromotoresEdit'

const RoutesOperaciones = () => {

	const [LSopen, setLSOpen] = useState(false)
	const [SBOpen, setSBOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [Msj, setMsj] = useState('')
	const [type, setType] = useState('')

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setSBOpen(false)
	}

	const openLoadingScreen = () => {
		setLSOpen(true)
	}

	const closeLoadingScreen = () => {
		setLSOpen(false)
	}

	return (
		<>
			<MySnackBar
				Open={SBOpen}
				Type={type}
				Message={message}
				HandleClose={handleClose}
			/>
			<LoadingScreen open={LSopen} />

            <MainContainerLayout>
							<HeaderLayout />
							<MainContentLayout>
								<Routes>
									<Route
										exact
										path='/'
										element={
											<InicioView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ActividadEcoView'
										element={
											<ActividadEcoView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/AreaView'
										element={
											<AreaView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CfdiView'
										element={
											<CfdiView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/DepartamentosView'
										element={
											<DepartamentosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/MovimientosView'
										element={
											<MovimientosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/RetornoView'
										element={
											<RetornoView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/NegociosView'
										element={
											<NegociosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/PlazasView'
										element={
											<PlazasView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP'
										element={
											<SolicitudCXPView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>

									<Route
										exact
										path='/StatusView'
										element={
											<StatusView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoGastoView'
										element={
											<TipoGastoView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoPagosView'
										element={
											<TipoPagosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoDeServicio'
										element={
											<TipoDeServicio
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CuentasPg'
										element={
											<CuentasPgView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/BancosView'
										element={
											<BancosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP/Add'
										element={
											<SolictudCxpAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP/Edit/:id'
										element={
											<SolictudCxpEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP/EditDetails/:id'
										element={
											<SolictudCxpEditDetails
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP/PrintDetails/:id'
										element={
											<SolicitudCXPPrintDetails
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/SolicitudCXP/Imprimir/:id'
										element={
											<SolicitudCxpPrint2
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/EmpresasPgView'
										element={
											<EmpresasPgView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteVencimiento'
										element={
											<ReporteVencimiento
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteFechaRegistro'
										element={
											<ReporteFechaRegistro
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteFechaPago'
										element={
											<ReporteFechaPago
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteConsecutivo'
										element={
											<ReporteConsecutivo
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteConcepto'
										element={
											<ReporteConcepto
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteComprobante'
										element={
											<ReporteComprobante
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Monedas'
										element={
											<MonedaView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Usuarios'
										element={
											<UsuariosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Proveedores'
										element={
											<ProveedoresView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Productos'
										element={
											<ProductosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>

									<Route
										exact
										path='/Productos/Add'
										element={
											<ProductosAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Productos/Edit/:id'
										element={
											<ProductosEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Promotores'
										element={
											<PromotoresView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>

									<Route
										exact
										path='/Promotores/Add'
										element={
											<PromotoresAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Promotores/Edit/:id'
										element={
											<PromotoresEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Saldos'
										element={
											<SaldosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Creditos'
										element={
											<CreditosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Cuentas'
										element={
											<CuentasProvView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoDocumentos'
										element={
											<TipoDocumentosView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Paises'
										element={
											<PaisesView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/EstadoCivil'
										element={
											<EstadoCivilView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoProfesional'
										element={
											<TipoProfesionalView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/TipoResidencia'
										element={
											<TipoResidenciaView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Clientes/Add'
										element={
											<ClientesAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Clientes'
										element={
											<ClientesView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Clientes/Edit/:id'
										element={
											<ClientesEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Clientes/Print/:id'
										element={
											<ClientesPrint
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CheckList/Add/:id'
										element={
											<ChecKListJuridicoAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CheckList/New'
										element={
											<ChecKListJuridicoAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CheckList/Edit/:id'
										element={
											<ChecKListJuridicoEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CheckList'
										element={
											<ChecKListJuridicoView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/CheckList/Print/:id'
										element={
											<CheckListJuridicoPrint
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={OPERACIONES_VIEW}
										element={
											<OperacionesView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={OPERACIONES_CREATE}
										element={
											<OperacionesAdd
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={OPERACIONES_UPDATE + ':id'}
										element={
											<OperacionesEdit
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Reporte-operaciones'
										element={
											<ReporteOperaciones
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={PROVEEDORES_OP_VIEW}
										element={
											<ProveedoresOpView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={STATUS_UTILIDAD_VIEW}
										element={
											<StatusUtilidadView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path={STATUS_CLI_VIEW}
										element={
											<StatusPagoView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/ReporteGeneral'
										element={
											<ReporteGeneralCxp
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
									<Route
										exact
										path='/Monedas-operaciones'
										element={
											<MonedasOpView
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
											/>
										}
									/>
								</Routes>
							</MainContentLayout>
						</MainContainerLayout>
		</>
	)
}

export default RoutesOperaciones
