import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import SaveIcon from '@mui/icons-material/Save'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import deLocale from 'date-fns/locale/es'
import Chip from '@mui/material/Chip'
import TimelineIcon from '@mui/icons-material/Timeline'
import requests from '../AxiosCalls/AxiosCall'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import Theme from '../Styles/Theme'
import NumberFormat from 'react-number-format'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import '../Styles/Styles.css'

function Row({
	onChange,
	onRemove,
	txtConcepto,
	txtNombre,
	fecRegistro,
	DblIva,
	DblSubTotal,
	txtNumFactura,
}) {
	const [_FecRegistro, setFecRegistro] = useState(null)
	const handleFecRegistro = (date) => {
		setFecRegistro(date)
		onChange('fecRegistro', date)
	}
	return (
		<>
			<Grid
				container
				spacing={2}
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
			>
				<Grid item xs={4}>
					<TextField
						required
						id='txtConcepto'
						name='txtConcepto'
						label='Concepto'
						variant='standard'
						fullWidth
						value={txtConcepto}
						onChange={(e) => onChange('txtConcepto', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						required
						id='txtNombre'
						name='txtNombre'
						label='Nombre'
						variant='standard'
						fullWidth
						value={txtNombre}
						onChange={(e) => onChange('txtNombre', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						required
						id='txtNumFactura'
						name='txtNumFactura'
						label='Numero de factura'
						variant='standard'
						fullWidth
						value={txtNumFactura}
						onChange={(e) => onChange('txtNumFactura', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						required
						id='DblIva'
						name='DblIva'
						label='% IVA'
						variant='standard'
						type='number'
						fullWidth
						value={DblIva}
						onChange={(e) => onChange('DblIva', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						required
						id='DblSubTotal'
						name='DblSubTotal'
						label='Total'
						variant='standard'
						type='number'
						fullWidth
						value={DblSubTotal}
						onChange={(e) => onChange('DblSubTotal', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={4}>
					<LocalizationProvider
						size='small'
						dateAdapter={AdapterDateFns}
						locale={deLocale}
						//required
					>
						<DesktopDatePicker
							//required
							name='fecRegistro'
							label='Fecha Registro'
							inputFormat='dd/MM/yyyy'
							value={_FecRegistro}
							onChange={handleFecRegistro}
							renderInput={(params) => (
								<TextField
									required
									variant='standard'
									fullWidth
									size='small'
									{...params}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={1}>
					<Tooltip title='Eliminar' arrow aria-label='add'>
						<Button onClick={onRemove} className='font-btn-eliminar'>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
		</>
	)
}

const DetalleAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()

	const getInfoData = () => {
		return JSON.parse(sessionStorage.getItem('TokenSysTrackLatamUSer'))
	}
	const infoData = getInfoData()

	const [_Detalles, setDetalles] = useState([])

	const guardar = () => {
		props.setOpenLoadingScreen()
		//let url = 'TbRegVentum/Crear2'
		if (rows.length > 0) {
			if (rows.length > 0) {
				for (var a = 0; a < rows.length; a++) {
					let reg = rows[a]
					let Info = {
						/////lngIdIndividual: 0,
						txtConcepto: reg.txtConcepto,
						txtNombre: reg.txtNombre,
						txtNumFactura: reg.txtNumFactura,
						DblIva: reg.DblIva,
						DblSubTotal: reg.DblSubTotal,
						dblValor: reg.dblValor,
						fecRegistro: reg.fecRegistro,
					}
					_Detalles.push(Info)
				}
			}
		}
		requests
			.post('TbCxpDetalle/Crear', _Detalles)
			.then((response) => {

				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				//navigate('/Ventas')
			})
			.catch((error) => {
				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}
	//__-=-__-=-__-=-__-=-__-=-__-=-__ AGREGADO DINAMICO  __-=-__-=-__-=-__-=-__-=-__-=-__

	const defaultState = {
		//lngIdIndividual: 0,
		txtConcepto: '', ///arregar campos de detalles
		txtNombre: '',
		txtNumFactura: '',
		DblIva: 0,
		DblSubTotal: 0,
		fecRegistro: '',
	}

	// const handleClose = () => {
	// 	props.onClose()
	// }

	const [rows, setRows] = useState([defaultState])

	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			[name]: value,
		}
		setRows(copyRows)
	}

	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	const handleOnRemove = (index) => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				//onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Paper
						elevation={0}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar detalles
						</DialogTitle>

						{/* <Button onClick={handleClose}> */}
						<Button>
							<CloseIcon
								fontSize='large'
								sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
							/>
						</Button>
					</Paper>

					<Grid
						item
						xs={12}
						md={12}
						lg={12}
						direction='row'
						justifyContent='center'
						alignItems='center'
					>
						{/* start */}
						<Paper elevation={12} sx={{ p: 3, borderRadius: '10px' }}>
							<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
								<Paper elevation={0}>
									<Grid
										container
										sx={{ pb: 2, px: 1 }}
										direction='row'
										justifyContent='center'
										alignItems='center'
									>
										{/*START*/}
										<Grid
											container
											spacing={2}
											direction='row'
											justifyContent='flex-end'
											alignItems='center'
										>
											<Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
												{rows.map((row, index) => (
													<fieldset
														style={{
															borderRadius: '20px',
															border: 'solid 3px rgb(195, 224, 243)',
															marginTop: '10px',
														}}
													>
														<Row
															{...row}
															onChange={(name, value) =>
																handleOnChange(index, name, value)
															}
															onRemove={() => handleOnRemove(index)}
															key={index}
														/>
													</fieldset>
												))}
											</Grid>
											<Grid
												container
												spacing={2}
												direction='row'
												justifyContent='flex-end'
												alignItems='center'
											>
												<Grid item xs={2}>
													<Button
														variant='contained'
														component='label'
														className='m-auto'
														onClick={handleOnAdd}
														startIcon={<AddIcon />}
													>
														Añadir individual
													</Button>
												</Grid>
											</Grid>
											{/* </fieldset> */}
											{/* </Grid> */}
										</Grid>

										{/*END */}
									</Grid>
									<Grid sx={{ marginTop: '30px' }}>
										<Button
											type='submit'
											className='btn-footer'
											startIcon={<SaveIcon />}
											size='small'
										>
											Guardar
										</Button>
									</Grid>
								</Paper>
							</form>
						</Paper>
					</Grid>
				</Grid>
				{/* </Paper> */}
			</Dialog>
		</ThemeProvider>
	)
}

export default DetalleAdd
