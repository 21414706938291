import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Theme from '../Styles/Theme'
import StyleGeneral from '../Styles/StyleGeneral'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { format, compareAsc, getDate } from 'date-fns'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import requests from '../AxiosCalls/AxiosCall'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import Typography from '@mui/material/Typography'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import AsyncSelect from 'react-select/async'
import '../Styles/Styles.css'
import SaveIcon from '@mui/icons-material/Save'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import DetalleAdd from './SolicitudCxpDetailsAdd'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import ListItemText from '@mui/material/ListItemText'
import AddIcon from '@mui/icons-material/Add'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Chip from '@mui/material/Chip'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import deLocale from 'date-fns/locale/es'
import DetalleDelete from './Detalles/DetalleDelete'
import DetalleEditar from './Detalles/DetalleEditar'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

function Row({
	onChange,
	onRemove,
	onBlur,
	txtConcepto,
	txtNombre,
	fecRegistro,
	DblIva,
	DblSubTotal,
	txtNumFactura,
}) {
	const [_FecRegistro, setFecRegistro] = useState(null)
	const handleFecRegistro = (date) => {
		setFecRegistro(date)
		onChange('fecRegistro', date)
	}

	const [_DblTotal, setDblTotal] = useState(null)
	const handleDblTotal = (value) => {
		let importe = (parseFloat(DblIva) * parseFloat(DblSubTotal)) / 100
		let importeiva = importe + DblSubTotal
		//SetRegistro({ ..._Registro, dblTotal: importeiva })
		setDblTotal(importeiva)
		onChange('DblSubTotal', importeiva)
	}

	return (
		<>
			<Grid
				container
				spacing={2}
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
			>
				<Grid item xs={4}>
					<TextField
						required
						id='txtConcepto'
						name='txtConcepto'
						label='Conceito'
						variant='standard'
						fullWidth
						value={txtConcepto}
						onChange={(e) => onChange('txtConcepto', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						required
						id='txtNombre'
						name='txtNombre'
						label='Nome'
						variant='standard'
						fullWidth
						value={txtNombre}
						onChange={(e) => onChange('txtNombre', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						required
						id='txtNumFactura'
						name='txtNumFactura'
						label='Numero de Fatura'
						variant='standard'
						fullWidth
						value={txtNumFactura}
						onChange={(e) => onChange('txtNumFactura', e.target.value)}
						size='small'
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						required
						id='DblIva'
						name='DblIva'
						label='% Imposto'
						variant='standard'
						type='number'
						fullWidth
						value={DblIva}
						onChange={(e) => onChange('DblIva', e.target.value)}
						onBlur={handleDblTotal}
						size='small'
					/>
				</Grid>
				<Grid item xs={2}>
					<NumberFormat
						required
						label='Subtotal'
						variant='standard'
						value={DblSubTotal}
						customInput={TextField}
						thousandSeparator={true}
						fixedDecimalScale={true}
						decimalScale={2}
						prefix={'$'}
						onValueChange={(e) => onChange('DblSubTotal', e.floatValue)}
						onBlur={handleDblTotal}
						size='small'
						name='DblSubTotal'
					/>
				</Grid>
				<Grid item xs={2}>
					<NumberFormat
						required
						disabled
						label='Total' //PROGRAMAR TOTAL
						variant='standard'
						value={_DblTotal}
						customInput={TextField}
						thousandSeparator={true}
						fixedDecimalScale={true}
						decimalScale={2}
						prefix={'$'}
						//onBlur={(e) => onChange('DblTotal', e.target.value)}
						size='small'
						name='DblSubTotal'
					/>
				</Grid>
				<Grid item xs={4}>
					<LocalizationProvider
						size='small'
						dateAdapter={AdapterDateFns}
						locale={deLocale}
						//required
					>
						<DatePicker
							//required
							name='fecRegistro'
							label='Data do Registro'
							inputFormat='dd/MM/yyyy'
							value={_FecRegistro}
							onChange={handleFecRegistro}
							renderInput={(params) => (
								<TextField
									required
									variant='standard'
									fullWidth
									size='small'
									{...params}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={1}>
					<Tooltip title='Eliminar' arrow aria-label='add'>
						<Button onClick={onRemove} className='font-btn-eliminar'>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
		</>
	)
}

const SolictudCxpEditDetails = (props) => {
	const { id } = useParams()
	const classes = StyleGeneral()
	let urlEditar = '/SolicitudCXP/Edit/' + id
	let urlPrintDetalles = '/SolicitudCXP/PrintDetails/' + id
	const current = new Date()
	const date =
		current.getDate() +
		'/' +
		(current.getMonth() + 1) +
		'/' +
		current.getFullYear()
	const methods = useForm()
	const { handleSubmit } = methods
	let navigate = useNavigate()

	const [open, setOpen] = React.useState(false)
	const [open1, setOpen1] = React.useState(false)

	const [_Registro, SetRegistro] = useState({})
	const [ListaDetalles, setListaDetalles] = useState([])

	const getListDetalles = () => {
		
		props.setOpenLoadingScreen()
		requests
			.get('TbCxpDetalle/ObtenerListaPorIdSolm?id=' + id)
			.then((response) => {
				
				setListaDetalles(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const captura = async () => {
		props.setOpenLoadingScreen()
		let url = requests.Url + 'GenerarPDF/GenerarPDFDetalleSpp/' + id
		fetch(url)
			.then((response) => {
				response.blob().then((blob) => {
					const archivo = window.URL.createObjectURL(blob)
					window.open(archivo);
					props.setCloseLoadingScreen()
				})
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
				console.log(error)
			})
	}

	const getregistroSolicitudCxP = () => {
		props.setOpenLoadingScreen()
		requests
			.get('TbSolicitudCxp/ObtenerPorId/' + id)
			.then((response) => {
				AsignarValores(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const AsignarValores = (objeto) => {
		SetRegistro({
			..._Registro,
			intIdSolicitdCxp: objeto === null ? '' : objeto.intIdSolicitdCxp,
			txtSolicitante: objeto === null ? '' : objeto.txtSolicitante,
			txtRegistro: objeto === null ? '' : objeto.txtRegistro,
			intIdNegocio: objeto === null ? '' : objeto.intIdNegocio,
			txtConcepto: objeto === null ? '' : objeto.txtConcepto,
			intIdUsuario: objeto === null ? '' : objeto.intIdUsuario,
			intIdDepartamentos: objeto === null ? '' : objeto.intIdDepartamentos,
			intIdPlaza: objeto === null ? '' : objeto.intIdPlaza,
			intIdTipoServicio: objeto === null ? '' : objeto.intIdTipoServicio,
			intIdTipoGasto: objeto === null ? '' : objeto.intIdTipoGasto,
			txtFolio: objeto === null ? '' : objeto.txtFolio,
			txtRazonSocial: objeto === null ? '' : objeto.txtRazonSocial,
			txtBeneficiario: objeto === null ? '' : objeto.txtBeneficiario,
			txtRfc: objeto === null ? '' : objeto.txtRfc,
			intTipoPago: objeto === null ? '' : objeto.intTipoPago,
			intIdMoneda: objeto === null ? '' : objeto.intIdMoneda,
			dblTotal: objeto === null ? '' : objeto.dblTotal,
			dblImporte: objeto === null ? '' : objeto.dblImporte,
			dblIva: objeto === null ? '' : objeto.dblIva,
			txtImporteTexto: objeto === null ? '' : objeto.txtImporteTexto,
			fecDia: objeto === null ? '' : objeto.fecDia,
			fecPago: objeto === null ? '' : objeto.fecPago,
			fecProxVencimiento: objeto === null ? '' : objeto.fecProxVencimiento,
			intIdEmpresaPg: objeto === null ? '' : objeto.intIdEmpresaPg,
			txtComentarios: objeto === null ? '' : objeto.txtComentarios,
			intIdBanco: objeto === null ? '' : objeto.intIdBanco,
			txtTarjeta: objeto === null ? '' : objeto.txtTarjeta,
			txtClabe: objeto === null ? '' : objeto.txtClabe,
			txtCuenta: objeto === null ? '' : objeto.txtCuenta,
			txtIban: objeto === null ? '' : objeto.txtIban,
			intIdStatus: objeto === null ? '' : objeto.intIdStatus,
			lngIdProveedor: objeto === null ? '' : objeto.lngIdProveedor,	
			intIdProveedor: objeto === null ? '' : objeto.intIdProveedor,			
			txtReferencia: objeto === null ? '' : objeto.txtReferencia,
			bolGastofijo: objeto === null ? '' : objeto.bolGastofijo,
			bolPrioridad: objeto === null ? '' : objeto.bolPrioridad,
			bolGastoVariable: objeto === null ? '' : objeto.bolGastoVariable,
			intIdRegCuenta: objeto === null ? '' : objeto.intIdRegCuenta,
		})
	}

	const handleOpen = () => {
		setOpen(!open);
	}
	
	const handleOpen1 = () => {
		setOpen1(!open1);
	}

	const [_Detalles, setDetalles] = useState([])
	
	const guardar = () => {
		
		props.setOpenLoadingScreen()
		let percMasAlto = 0
		if (rows.length > 0) {
			percMasAlto = ListaDetalles.length > 0 ? _Registro.dblIva : 0
			for (var a = 0; a < rows.length; a++) {
				let reg = rows[a]
				percMasAlto = reg.DblIva > percMasAlto ? reg.DblIva : percMasAlto
				let InfoDetalles = {
					intIdSolicitdCxp: _Registro.intIdSolicitdCxp,
					txtConcepto: reg.txtConcepto,
					txtNombre: reg.txtNombre,
					dblIva: reg.DblIva,
					dblSubTotal: reg.DblSubTotal,
					fecRegistro: reg.fecRegistro,
					txtNumFactura: reg.txtNumFactura,
				}
				_Detalles.push(InfoDetalles)
			}
		}


		requests
			.post('TbCxpDetalle/Crear', _Detalles)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				getListDetalles()
			})
			.catch((error) => {
				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
			
		var importe = 0;
		{ListaDetalles.map((row) => {
			var total = (row.dblIva * row.dblSubTotal)/100 ;
			var totales = total + row.dblSubTotal;
			importe = importe + totales;
		})}

		_Registro.dblIva = percMasAlto
		_Registro.dblImporte = calculoSubtotal + importe
		requests
			.put('TbSolicitudCxp/Editar', _Registro)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				window.location.reload()
				//navigate('/SolicitudCXP/Edit/' + id)
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getregistroSolicitudCxP()
		getListDetalles()
	}, [])

	//__-=-__-=-__-=-__-=-__-=-__-=-__ AGREGADO DINAMICO  __-=-__-=-__-=-__-=-__-=-__-=-__

	const defaultState = {
		//lngIdIndividual: 0,
		txtConcepto: '', ///arregar campos de detalles
		txtNombre: '',
		txtNumFactura: '',
		DblIva: '',
		DblSubTotal: '',
		DblTotal: '',
		fecRegistro: '',
	}

	const [rows, setRows] = useState([defaultState])
	const [calculoSubtotal, setCalculoSubtotal] = useState(null)

	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			[name]: value,
		}
		setRows(copyRows)
		consolidado(copyRows)
	}

	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	const handleOnRemove = (index) => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
		consolidado(copyRows)
	}

	const consolidado = (rows) => {
		let totalConsolidado = 0.0
		// let totalConsolidado = ListaDetalles.length > 0 ? _Registro.dblTotal : 0.0

		rows.forEach((row, index) => {
			if (row.DblSubTotal !== '') {
				// let monto = row.DblTotal.replace(/[,]/g, '')
				//let monto = row.DblTotal.replace(/[,]/g, '')
				// totalConsolidado += parseFloat(monto.replace('$', ''))
				totalConsolidado += row.DblSubTotal
			}
		})
		setCalculoSubtotal(totalConsolidado)
	}

	
	const [DetalleId, SetDetalleId] = useState(null) 
	const [_SubTotal,setSubTotal] = useState(null)

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: '10px;' }}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Paper
						elevation={0}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '10px 0px 10px 0px',
							backgroundColor: 'transparent',
						}}
					>
						<div className='containerAlignHeader '>
							<Link to={urlEditar} style={{ textDecoration: 'none' }}>
								<Button
									className='btn-Header-back'
									startIcon={<ArrowBackIosNewSharpIcon />}
								>
									Voltar
								</Button>
							</Link>
							<Chip
								icon={<MonetizationOnIcon />}
								label='Contas por Pagar / Detalhes'
								color='primary'
								className='back-transparent font-letter font-barter font-20'
							/>
						</div>

						{/* <div className='containerAlignHeader'>
							<div className='container'>
								<div style={{ display: 'flex' }}>
									<h4 className='title-solicitud'>Fecha: </h4>
									<h4 className='subtitle-name'>{date}</h4>
								</div>

								<div className='container-folio'>
									<h4 className='title-solicitud'>Folio: </h4>
									<h4 className='subtitle-name'>{_Registro.txtFolio}</h4>
								</div>
							</div>
						</div> */}
					</Paper>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					direction='row'
					justifyContent='center'
					alignItems='center'
				>
					{/* <Grid
						container
						spacing={2}
						direction='row'
						justifyContent='flex-start'
						alignItems='center'
					>
						<Grid item xs={2}>
							<div>
								<h5 className='title-solicitud'>Subtotal: </h5>
								{calculoSubtotal !== null ? (
									<NumberFormat
										value={calculoSubtotal === null ? '' : calculoSubtotal}
										displayType={'text'}
										fixedDecimalScale={true}
										decimalScale={2}
										thousandSeparator={true}
										prefix={'$'}
									/>
								) : null}
							</div>
						</Grid>
					</Grid> */}
					<Paper elevation={8} sx={{ p: 3, borderRadius: '10px' }}>
						<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
							<Paper elevation={0}>
								<Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
									{rows.map((row, index) => (
										<fieldset
											style={{
												borderRadius: '20px',
												border: 'solid 3px rgb(195, 224, 243)',
												marginTop: '10px',
											}}
										>
											<Row
												{...row}
												onChange={(name, value) =>
													handleOnChange(index, name, value)
												}
												onRemove={() => handleOnRemove(index)}
												key={index}
											/>
										</fieldset>
									))}
								</Grid>
								<Grid
									container
									//spacing={2}
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
								>
									<Grid item xs={4}>
										<Button
											type='submit'
											className='btn-footer'
											startIcon={<SaveIcon />}
											size='small'
										>
											Salvar
										</Button>
									</Grid>
									<Grid item xs={4}>
											<Button
												className='btn-footer'
												startIcon={<LocalPrintshopIcon />}
												onClick={function () {
													captura()
												}}
											>
												Imprimir detalhes
											</Button>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant='contained'
											component='label'
											className='btn-footer'
											onClick={handleOnAdd}
											startIcon={<AddIcon />}
										>
											Adicionar detalhes
										</Button>
									</Grid>
								</Grid>
								{/* </fieldset> */}
								{/* </Grid> */}
							</Paper>
						</form>
						<TableContainer
							sx={{
								mt: 3,
								maxHeight: 500,
								overFlow: 'auto',
								backgroundColor: '#fff',
							}}
						>
							<Table stickyHeader aria-label='sticky table' size={'small'}>
								<TableHead>
									<TableRow>
										<TableCell
											align='center'
											colSpan={9}
											style={{
												fontWeight: 700,
												backgroundColor: '#073763',
												color: '#fff',
											}}
										>
											Listado de Detales
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Conceito
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Nome
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Imposto
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Subtotal
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Total
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Data do Registro
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Num. Factura
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Editar
										</TableCell>
										<TableCell
											align='center'
											style={{
												fontWeight: 700,
												color: '#fff',
												backgroundColor: '#073763',
											}}
										>
											Eliminar
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{ListaDetalles.map((row) => {
										var total = (row.dblIva * row.dblSubTotal)/100 ;
										var totales = total + row.dblSubTotal;
										// ((row.dblIva * row.dblSubTotal)/100) + row.DblSubTotal;
										return (
											<TableRow key={row.intIdRutaArchivo}>
												<TableCell align='center'>{row.txtConcepto}</TableCell>
												<TableCell align='center'>{row.txtNombre}</TableCell>
												<TableCell align='center'>{row.dblIva}</TableCell>
												<TableCell align='center'>
													<NumberFormat
														displayType={'text'}
														value={row.dblSubTotal}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														prefix={'$'}
													/>
													</TableCell>
												<TableCell align='center'>
													<NumberFormat
														displayType={'text'}
														value={totales}
														thousandSeparator={true}
														fixedDecimalScale={true}
														decimalScale={2}
														prefix={'$'}
													/></TableCell>
												<TableCell align='center'>{row.fecRegistro}</TableCell>
												<TableCell align='center'>
													{row.txtNumFactura}
												</TableCell>
												<TableCell align='center'>
													{/* Editar */}
													<Tooltip title='Editar solicitud' arrow aria-label='add'>
														<Button
															className='font-btn-editar'
															onClick={function () {
																SetDetalleId(row.intIdCxpDetalle)
																handleOpen();
																// navigate('/SolicitudCXP/Edit/' + tableMeta.rowData[0])
															}}
														>
															<EditIcon sx={{ mr: 1 }} />
														</Button>
													</Tooltip>
												</TableCell>
												<TableCell align='center'>
													 {/* Eliminar  */}
													 <Tooltip title='Eliminar solicitud' arrow aria-label='add'>
														<Button
															className='font-btn-eliminar'
															onClick={function () {
																SetDetalleId(row.intIdCxpDetalle)
																setSubTotal(row.dblSubTotal)
																handleOpen1();
															}}
														>
															<DeleteIcon sx={{ mr: 1 }} />
														</Button>
													</Tooltip>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Grid>
			</Grid>
			<DetalleEditar
				DetalleId={DetalleId}
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			<DetalleDelete
				open={open1}
				DetalleId={DetalleId}
				Registro={_Registro}
				SubTotal={_SubTotal}
				onClose={handleOpen1}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			{/* </Paper> */}
		</ThemeProvider>
	)
}

export default SolictudCxpEditDetails
