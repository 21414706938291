import { createTheme } from '@mui/material/styles';
const Theme = createTheme({
  palette: {
    primary: {
      light: "#073763",
      main: "#073763",
      dark: "#073763",
      contrastText: "#e0f2f1",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#000",
    },
  },
  components: {
    MuiTextField: {
    defaultProps: {
      margin: 'dense',
    },
  },
},
typography: {
  // In Chinese and Japanese the characters are usually larger,
  // so a smaller fontsize may be appropriate.
  //fontSize: 12,
},
});
export default Theme;