import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { format} from 'date-fns'
import '../../Components/Styles/Styles.css'
// import MUIDataTableOP from '../../Components/Util/MUIDataTableOp'
 import MUIDataTableOP from '../../Util/MUIDataTableOp'

import TextField from '@mui/material/TextField'
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import SearchIcon from '@mui/icons-material/Search'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Chip from '@mui/material/Chip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
// import DateRangePicker from '@mui/lab/DateRangePicker'
import Box from '@mui/material/Box'
import AssessmentIcon from '@mui/icons-material/Assessment';

const ReporteOperaciones = (props) => {
	const [registros, setRegistros] = useState([])
	const [param, setparam] = useState({
		intIdPlaza: '',
		fechaIncio: '',
		fechaFin: '',
	})
	const [fecha, setfecha] = React.useState([null, null])
	const handlefecha = (date) => {
		setfecha(date)
		setparam({ ...param, fechaIncio: date })
	}

	const [fechafin, setfechafin] = React.useState([null, null])
	const handlefechafin = (date) => {
		setfechafin(date)
		setparam({ ...param, fechaFin: date })
	}

	const Consultar = () => {
		param.fechaIncio = '' ? null : param.fechaIncio
		param.fechaFin = '' ? null : param.fechaFin
		props.setOpenLoadingScreen()
		let url = 'TbFlujo/ReporteOperaciones'
		requests
			.post(url, param)
			.then((response) => {
				setRegistros(response)
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const GetReporte = () => {
		param.fechaIncio = fecha[0]
		param.fechaFin = fecha[1]
		let url =
			requests.Url +
			'TbFlujo/ReporteFlujoOperaciones?Plaza=' + param.intIdPlaza + '&Dia1=' +
			format(new Date(param.fechaIncio), 'yyyy-MM-dd') +
			'&Dia2=' +
			format(new Date(param.fechaFin), 'yyyy-MM-dd')

		var link = document.createElement('a')
		link.target = '_blank'
		link.href = url
		document.body.appendChild(link)
		link.click()
	}

	const handleParam = (event) => {
		setparam({ ...param, [event.target.name]: event.target.value })
	}

	const columns = [
		{
			name: 'lngIdRegistro',
			label: 'lngIdRegistro',
			options: {
				display: "excluded",
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'txtRegistro',
			label: 'Registro',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<strong>{tableMeta.rowData[2]}</strong>
					</div>
					);
				}
			},
		}, {
			name: 'fecha',
			label: 'Fecha',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'proveedor',
			label: 'Proveedor ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'statusCli',
			label: 'Pago cliente ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'cliente',
			label: 'Cliente',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'importeDes',
			label: 'Imp. a dispersar ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'comisionBan',
			label: 'Comisión bancaria',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'totalDls',
			label: 'Total USD',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tc',
			label: 'TC',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'totalPesos',
			label: 'Total pesos',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'baseComision',
			label: 'Base comisión',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'porComision',
			label: '%',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'comisionXoper',
			label: 'Comisión x oper',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'pagoCliente',
			label: 'Pago cliente',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'comisionesBnCon',
			label: 'Comision Banpay/Cometra',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'importeDisper',
			label: 'Imp. a dispersar',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'comisionesBn',
			label: 'Comisión bancaria',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'totalUsdUtiCam',
			label: 'Total USD',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tcusdUtiCam',
			label: 'TC',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'totalPesosUtiCam',
			label: 'Total pesos',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'utilidaCam',
			label: 'Utilidad cambiaria',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'promotor',
			label: 'Promotor',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'utilidaOper',
			label: 'Utilidad por operación',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'utilidaGeneral',
			label: 'Utilidad general',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'statusUtilidad',
			label: 'Status utilidad',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'descripcionCuenta',
			label: 'Descripción',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'comentarios',
			label: 'Comentarios',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'fecCierre',
			label: 'Fecha cierre',
			options: {
				display: "excluded",
				filter: false,
				print: true,
				download: true,
			},
		},
	];

	const [_Plaza, setPlaza] = useState([])
	const getPlaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getPlaza()
	}, [])

	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={8} md={10} lg={12} >
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Chip icon={<AssessmentIcon />} label='Reporte operaciones' color='primary' className='back-transparent font-letter font-barter font-20' />
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center'>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateRangePicker
									startText='Fecha Inicio'
									endText='Fecha Fin'
									inputFormat='dd/MM/yyyy'
									value={fecha}
									onChange={handlefecha}
									renderInput={(startProps, endProps) => (
										<React.Fragment>
											<TextField
												fullWidth
												size='small'
												required
												variant='standard'
												{...startProps}
											/>
											<Box sx={{ mx: 3 }}> A </Box>
											<TextField
												fullWidth
												size='small'
												required
												variant='standard'
												{...endProps}
											/>
										</React.Fragment>
									)}
								/>
							</LocalizationProvider> */}
							<LocalizationProvider
								size='small'
								dateAdapter={AdapterDateFns}
							>
								<DatePicker
									name='fechaInicio'
									label='Fecha Inicio'
									inputFormat='dd/MM/yyyy'
									value={fecha}
									onChange={handlefecha}
									renderInput={(params) => (
										<TextField
											variant='standard'
											fullWidth
											size='small'
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>

							<LocalizationProvider
								size='small'
								dateAdapter={AdapterDateFns}
							>
								<DatePicker
									name='fechaFin'
									label='Fecha Fin'
									inputFormat='dd/MM/yyyy'
									value={fechafin}
									onChange={handlefechafin}
									renderInput={(params) => (
										<TextField
											variant='standard'
											fullWidth
											size='small'
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={2}>
							<FormControl variant='standard' size='small' fullWidth required>
                          <InputLabel id='intIdPlaza'>
                            Plaza
                          </InputLabel>
                          <Select
                            //required
                            value={param.intIdPlaza}
                            onChange={handleParam}
                            label='intIdPlaza'
                            inputProps={{
                              name: 'intIdPlaza',
                              id: 'intIdPlaza',
                            }}
                            size='small'
                          >
                            <MenuItem aria-label='None' value='' />
                            {_Plaza.map((object, index) => (
                              <MenuItem value={object.intIdPlaza}>
                                {object.txtPlaza}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
							</Grid>
						<Grid item xs={1}>
							<Button
								className='btn-Header2'
								startIcon={<SearchIcon />}
								onClick={() => {
									Consultar()
								}}
							>
								Buscar
							</Button>
						</Grid>
						<Grid item xs={1} sx={{ ml: 6 }}>
							<Button
								className='btn-Header2'
								startIcon={<LocalPrintshopIcon />}
								onClick={() => {
									GetReporte()
								}}
							>
								Descargar
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} md={12} lg={12} sx={{mt: 2}} alignItems='center'>
					<MUIDataTableOP
						title={'Listado de operaciones'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ReporteOperaciones