import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { format, compareAsc } from 'date-fns'
import '../../Components/Styles/Styles.css'
import MUIDataTable from '../../Util/MUIDataTable'
import TextField from '@mui/material/TextField'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NumberFormat from 'react-number-format'
import requests from '../../Components/AxiosCalls/AxiosCall'
import Theme from '../../Components/Styles/Theme'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import SearchIcon from '@mui/icons-material/Search'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import DownloadingIcon from '@mui/icons-material/Downloading';
import { DEPTO_LISTA, PROVE_LISTA, REP_CONSECUTIVO, REP_FECPAGO, REP_FECPAGOEX, STATUS_LIST, TIPOPAGO_LISTA, USU_LISTA } from '../../Constants/apiConstnats'

const ReporteFechaPago = (props) => {
	const [registros, setRegistros] = useState([])
	const [LisStatus, setLisStatus] = useState([])
	const [Lisuser, setLisuser] = useState([])
	const [Lisprovee, setLisprovee] = useState([])
	const [Listipog, setListipog] = useState([])
	const [LisDepa, setLisDepa] = useState([])
	const [param, setparam] = useState(null)
	const Consultar = () => {
		
		if(param !== null){

		props.setOpenLoadingScreen()
		let url = REP_CONSECUTIVO
		requests
			.get(url + param)
			.then((response) => {
				
				setRegistros(response)
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
		}else{
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar("Ingresa un Id")
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}

	const GetReporte = () => {
		if(param !== null){
			let url =
				requests.Url + "Reports/ReporteConsecutivoExcel/" + param

			var link = document.createElement('a')
			link.target = '_blank'
			link.href = url
			document.body.appendChild(link)
			link.click()
		}else{
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar("Ingresa Id ")
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}
	
	const getStatus = () => {
		props.setOpenLoadingScreen()
		requests
			.get(STATUS_LIST)
			.then((response) => {
				setLisStatus(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getUsuario = () => {
		props.setOpenLoadingScreen()
		requests
			.get(USU_LISTA)
			.then((response) => {
				setLisuser(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const gettipopago = () => {
		props.setOpenLoadingScreen()
		requests
			.get(TIPOPAGO_LISTA)
			.then((response) => {
				setListipog(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getProveedor = () => {
		props.setOpenLoadingScreen()
		requests
			.get(PROVE_LISTA)
			.then((response) => {
				setLisprovee(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getDepa = () => {
		props.setOpenLoadingScreen()
		requests
			.get(DEPTO_LISTA)
			.then((response) => {
				setLisDepa(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	
	const handleText = (event) => {
		setparam(event.target.value)
	}

	useEffect(() => {
		getStatus()
		getUsuario()
		gettipopago()
		getProveedor()
		getDepa()
	}, [])

	const columns = [
		{
			name: 'id',
			label: '#',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
			},
		},
		{
			name: 'registro',
			label: 'Reg ',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
					<div>
						<strong>{tableMeta.rowData[1]}</strong>
					</div>
					);
				}
			},
		},
		{
			name: 'concepto',
			label: 'Conceito',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'solicitante',
			label: 'Candidato',
			options: {
				columnOrder: true,
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'beneficiario',
			label: 'Beneficiario',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'departamento',
			label: 'Departamento ',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'plaza',
			label: 'Praça',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'negocio',
			label: 'Negócios',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoServicio',
			label: 'Tipo de serviço',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'tipoGasto',
			label: 'Tipo gasto',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'importe',
			label: 'Quantia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <NumberFormat className='bold mr1 ml1'
						 value={tableMeta.rowData[10]} displayType={'text'} fixedDecimalScale={true}
                            decimalScale={2} thousandSeparator={true} prefix={'$'} />
                    );
                },
			},
		},
		{
			name: 'referencia',
			label: 'Referencia',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: false,
				print: true,
				download: true,
			},
		},
		{
			name: 'dia',
			label: 'Data Dia',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[13] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'proxVencimiento',
			label: 'Expiração',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[14] !== '01/01/0001'? value : ''
				},
			},
		},
		{
			name: 'pago',
			label: 'Data do Pagamento',
			options: {
				filter: false,
				print: true,
				download: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return tableMeta.rowData[15] !== '01/01/0001'? value : ''
				},
			},
		},
	]
	return (
		<ThemeProvider theme={Theme}>
			<Grid xs={12} sm={12} md={12} lg={12} >
				<Grid item xs={12} sm={12} md={12} lg={12} elevation={12}>
					<Paper elevation={0} className='title-views'>
						<div className='containerHeader'>
							<div style={{ width: '100%', paddingTop: '20px' }}>
								<Grid container direction="row"  justifyContent="space-evenly" alignItems="center" spacing={2}>
                                    <Grid item xs={12} lg={4}>
                                        <TextField
                                            multiline
                                            maxRows={4}
                                            id='idSolicitdCxp'
                                            name='idSolicitdCxp'
                                            label='Numero'
                                            variant='standard'
                                            type='number'
                                            fullWidth
                                            value={param}
                                            onChange={handleText}
                                            size='small'
                                        />
                                    </Grid>
									<Grid item xs={6} lg={1}>
										<Button
											className='btn-Header2'
											startIcon={<SearchIcon />}
											onClick={() => {
												Consultar()
											}}
										>
											Buscar
										</Button>
									</Grid>
									<Grid item xs={12} lg={2}>
										<Button
											className='btn-Header2'
											startIcon={<DownloadingIcon />}
											onClick={() => {
												GetReporte()
											}}
										>
											Excel
										</Button>
									</Grid>
								</Grid>
							</div>
						</div>
					</Paper>
				</Grid>

				<Grid item xs={12} md={12} lg={12} alignItems='center' elevation={12}>
					<MUIDataTable
						title={'Lista de solicitudes'}
						data={registros}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}
export default ReporteFechaPago
